import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import {
  IconButton,
  Icon,
  FormControlLabel,
  Switch,
  Button,
  MenuItem,
  useMediaQuery,
  Select as MaterialSelect,
} from '@material-ui/core';
import { KeyboardArrowDown, Store } from '@material-ui/icons';
import {
  ButtonContainer,
  CheckBoxContainer,
  CheckboxContent,
  Container,
  ReportButton,
  selectStyle,
  labelStyle,
  Select,
} from './styles';
import { download } from '../../services/Download';
import { userIsAdmin } from '../../utils/globalFunctions';
import { Page } from '../../interfaces/pages';
import { RootState } from '../../interfaces/redux';
import { getCompanies } from '../../redux/actions/companies';
import SubHeader from '../../sharedComponents/SubHeader';
import {
  DatePicker,
  DatePickersContainer,
  StyledTextField,
} from '../../sharedComponents/SectionHeader/styles';

import { SubTitle } from '../../sharedComponents/SubHeader/styles';
import { Company } from '../../interfaces/companies';
import Spinner from '../../sharedComponents/Spinner';
import { DateRange, ReportResult } from '../../interfaces/reports';

const reportOptions: {
  type: string;
  label: string;
}[] = [
  {
    type: 'online',
    label: 'Online',
  },
  {
    type: 'fisico',
    label: 'Físico',
  },
];
const ReportGMV: React.FC<Page> = ({ loading, loggedUser }) => {
  const isDesktop = useMediaQuery('(max-width:1080px)');
  const isTablet = useMediaQuery('(max-width:860px)');
  const isMobile = useMediaQuery('(max-width:767px)');

  const selectClasses = selectStyle();
  const labelClasses = labelStyle();

  const dispatch = useDispatch();
  const companies: Company[] = useSelector(
    (state: RootState) => state.companies.docs,
  );
  const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([]);
  useEffect(() => {
    dispatch(getCompanies({ listAll: true }));
  }, []);

  useEffect(() => {
    if (companies.length > 0) setSelectedCompanies(companies);
  }, [companies]);

  const [reportType, setReportType] = useState<{
    type: string;
    label: string;
  }>({
    type: 'fisico',
    label: 'Físico',
  });

  const handleReportType = (data: any) => {
    setReportType(
      reportOptions.find((option) => option.type === data.target.value) ||
        reportOptions[0],
    );
  };

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: new Date(
      moment()
        .startOf('year')
        .toString(),
    ),
    endDate: new Date(
      moment()
        .endOf('month')
        .toString(),
    ),
  });

  const handleDateRange = (field: any) => (date: any) => {
    setDateRange((currentRange) => ({ ...currentRange, [field]: date }));
  };
  const handleSelectCompanies = (event: any) => {
    if (event.target.value.includes('all')) {
      setSelectedCompanies(companies);
    } else if (event.target.value.includes('none')) {
      setSelectedCompanies([]);
    } else {
      setSelectedCompanies(
        companies.filter((company) => event.target.value.includes(company.id)),
      );
    }
  };

  const handleDownload = () =>
    download(
      'reports/gmv',
      {
        dateGte: dateRange.startDate.toISOString(),
        dateLte: dateRange.endDate.toISOString(),
        companyIds: selectedCompanies.map((company) => company.id),
        reportType: reportType.type,
        isAdmin: userIsAdmin(loggedUser),
      },
      {
        filename: `gmvReport-${new Date().toISOString()}`,
        fileExtension: 'xlsx',
        callback: ({ result }: { result: ReportResult[] }) =>
          result.map((item: ReportResult) => {
            let customName = `${item.name}`;
            if (item.fantasyName) {
              customName = `${item.name} (${item.fantasyName})`;
            }
            const final: any = {};
            final.id = item.id;
            final.nome = customName;
            if (item.values && item.values.length > 0) {
              item.values.map((value) => {
                const year = value.month.split('-')[0];
                const month = value.month.split('-')[1];
                final[`${month}/${year}`] = parseFloat(
                  value.value?.toString() || '0',
                );
              });
            }

            return final;
          }),
      },
    );

  return (
    <Container>
      <SubHeader
        title="Relatório GMV"
        subtitle="Personalize os parâmetros ou gere o relatório"
        style={{}}
        breakOnSmallScreens
      />

      {loading && (
        <Spinner parent="CheckboxContent" color="#192D4D" style={{}} />
      )}

      {!loading && (
        <>
          <ButtonContainer>
            <ReportButton
              variant="contained"
              startIcon={<Icon>cloud_download</Icon>}
              onClick={handleDownload}
            >
              Gerar e Baixar Relatório
            </ReportButton>
          </ButtonContainer>

          <Container>
            <SubTitle>Tipo de Relatório</SubTitle>
          </Container>
          <Container>
            <MaterialSelect
              value={reportType.type}
              variant="outlined"
              onChange={handleReportType}
              fullWidth
              // InputLabelProps={{ shrink: false, classes: labelClasses }}
              style={{
                ...(isDesktop && { flex: 1 }),
                ...(isTablet
                  ? { width: '100%', marginTop: 16 }
                  : { marginLeft: 0 }),
              }}
            >
              {reportOptions.map((option) => (
                <MenuItem key={option.type} value={option.type}>
                  {option.label}
                </MenuItem>
              ))}
            </MaterialSelect>
          </Container>

          <Container>
            <SubTitle>Período do relatório</SubTitle>
          </Container>
          <Container>
            <DatePickersContainer>
              <DatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                label="Data Início"
                value={dateRange.startDate}
                onChange={handleDateRange('startDate')}
                InputLabelProps={{
                  hidden: true,
                  style: {
                    color: 'var(--primary-text-color)',
                    backgroundColor: 'transparent',
                  },
                }}
                inputVariant="outlined"
                autoOk
                TextFieldComponent={(props) => (
                  <StyledTextField
                    {...props}
                    InputProps={{
                      startAdornment: (
                        <IconButton style={{ paddingLeft: 0 }}>
                          <Icon style={{ color: '#192D4D' }}>
                            calendar_today
                          </Icon>
                        </IconButton>
                      ),
                      endAdornment: (
                        <IconButton style={{ paddingRight: 0 }}>
                          <Icon style={{ color: '#192D4D' }}>expand_more</Icon>
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />

              <DatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                label="Data Fim"
                value={dateRange.endDate}
                onChange={handleDateRange('endDate')}
                InputLabelProps={{
                  hidden: true,
                  style: {
                    color: 'var(--primary-text-color)',
                    backgroundColor: 'transparent',
                  },
                }}
                inputVariant="outlined"
                autoOk
                TextFieldComponent={(props) => (
                  <StyledTextField
                    {...props}
                    InputProps={{
                      startAdornment: (
                        <IconButton style={{ paddingLeft: 0 }}>
                          <Icon
                            style={{ color: '#192D4D' }}
                            className="material-icons-outlined"
                          >
                            calendar_today
                          </Icon>
                        </IconButton>
                      ),
                      endAdornment: (
                        <IconButton style={{ paddingRight: 0 }}>
                          <Icon style={{ color: '#192D4D' }}>expand_more</Icon>
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </DatePickersContainer>
          </Container>

          <Container>
            <SubTitle>Selecione as Padarias</SubTitle>

            <MaterialSelect
              value={selectedCompanies.map((company) => company.id)}
              variant="outlined"
              multiple
              onChange={handleSelectCompanies}
              placeholder="Selecione pelo menos uma padaria"
              fullWidth
              style={{
                ...(isDesktop && { flex: 1 }),
                ...(isTablet
                  ? { width: '100%', marginTop: 16 }
                  : { marginLeft: 0 }),
              }}
              IconComponent={KeyboardArrowDown}
            >
              <MenuItem
                value={
                  companies.length === selectedCompanies.length ? 'none' : 'all'
                }
              >
                {companies.length === selectedCompanies.length
                  ? 'Nenhuma'
                  : 'Todas'}
              </MenuItem>
              {companies.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
            </MaterialSelect>
          </Container>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  loggedUser: state.loggedUser,
  loading: state.ui.companiesLoading,
});

export default connect(mapStateToProps)(ReportGMV);
