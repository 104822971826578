import styled, { css } from 'styled-components';

const Container = styled.div`
  padding: 1rem;
  ${({ flex }) => flex && css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `}
  @media (max-width: 567px) {
    padding: 0.5rem;
  }
`;

export default Container;
