import { userIsAdmin } from '../../utils/globalFunctions';
import Toastify from '../../utils/Toastify';

export const initSalesIntegration = () => {
  const script = document.createElement('script');
  const token =
    '6B2CaA8A59015489A507C422Ac004904E46c862b12B7AD1556A8Da83122cCe6410A5C332D1769378';
  script.src = `https://download.areacentral.com.br/docs/login/assets/loginac-min.js?t=${token}`;
  document.head.appendChild(script);
};

const defaultIntegration = 'admin@bakerytech.com.br';

export const executeSalesIntegration = (loggedUser) => {
  try {
    let salesIntegration;
    if (loggedUser && userIsAdmin(loggedUser)) {
      if (loggedUser.metadata) {
        salesIntegration = loggedUser.metadata.salesIntegration;
      } else {
        salesIntegration = defaultIntegration;
      }
    } else {
      salesIntegration = loggedUser.group.metadata?.salesIntegration;
    }
    if (!salesIntegration) {
      throw Error(
        'Ocorreu um erro com o carregamento dos seus dados de compras, por favor, entre em contato com o suporte.',
      );
    }
    const integrationSplitted = salesIntegration.split('@');
    const p = integrationSplitted.pop();
    const u = integrationSplitted.join('@');
    // eslint-disable-next-line no-undef
    LoginIntegradoAC.logon(u, p);
  } catch (error) {
    Toastify.addError(
      'Ocorreu um erro com o carregamento dos seus dados de compras, por favor, entre em contato com o suporte.',
      error,
    );
  }
};
