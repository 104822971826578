/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DatePicker } from '@material-ui/pickers';
import { TextField, IconButton, Icon } from '@material-ui/core';
import { TransferModalProvider } from '../../../../providers/Transfers';
import Toastify from '../../../../utils/Toastify';
import BulkOperationModal from '../../../../sharedComponents/BulkOperationModal';
import { InputContent } from './styles';

const BulkApproveTransferModal = ({ handleClose, allCompanies }) => {
  const [transferDate, setTransferDate] = useState(null);

  const handleBulkApprove = async ({ companyDocuments, refDate }) => {
    TransferModalProvider.bulkTransferApproval({
      companyDocuments,
      refDate,
      transferDate,
    })
      .then((response) => {
        Toastify.addSuccess(
          response.count
            ? `${response.count} transferências foram aprovadas.`
            : 'Não há transferências para aprovar.',
        );
      })
      .catch((error) => {
        Toastify.addError(
          'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
          error,
        );
      });
    handleClose();
  };

  return (
    <BulkOperationModal
      title="Aprovação massiva de Transferências"
      operationText="Aprovar repasses"
      handleOperation={handleBulkApprove}
      handleClose={handleClose}
      companies={allCompanies}
    >
      <InputContent>
        <DatePicker
          label={!transferDate ? 'Data de Transferência' : ''}
          inputVariant="outlined"
          value={transferDate}
          onChange={setTransferDate}
          ampm={false}
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          minDate={new Date('2021-01-01T00:00:00.000-03:00')}
          fullWidth
          InputLabelProps={{
            hidden: true,
            shrink: false,
            style: {
              marginLeft: '3rem',
            },
          }}
          autoOk
          TextFieldComponent={(props) => (
            <TextField
              {...props}
              InputProps={{
                startAdornment: (
                  <IconButton style={{ paddingLeft: 0 }}>
                    <Icon className="material-icons-outlined">
                      calendar_today
                    </Icon>
                  </IconButton>
                ),
                endAdornment: (
                  <IconButton style={{ paddingRight: 0 }}>
                    <Icon>expand_more</Icon>
                  </IconButton>
                ),
              }}
            />
          )}
        />
      </InputContent>
    </BulkOperationModal>
  );
};

export default connect((state) => ({
  allCompanies: state.companies.docs || state.companies,
}))(BulkApproveTransferModal);
