export const GET_TRANSFER = '[transfer] GET';
export const UPDATE_TRANSFER = '[transfer] UPDATE';
export const CREATE_TRANSFER = '[transfer] CREATE';
export const BULK_CREATE_TRANSFER = '[transfer] BULK CREATE';
export const DELETE_TRANSFER = '[transfer] DELETE';
export const UPDATE_OBJECT_TRANSFER = '[transfer] UPDATE OBJECT TRANSFER';
// UI
export const SHOW_TRANSFER_MODAL = '[transfer] SHOW MODAL';
export const HIDE_TRANSFER_MODAL = '[transfer] HIDE MODAL';
export const SHOW_BULK_TRANSFER_MODAL = '[transfer] SHOW BULK MODAL';
export const HIDE_BULK_TRANSFER_MODAL = '[transfer] HIDE BULK MODAL';
// Spinner
export const UPDATE_TRANSFER_SUCCESS = '[transfer] Update success';
export const BULK_CREATE_TRANSFER_SUCCESS = '[transfer] bulk create success';
export const UPDATE_TRANSFER_ERROR = '[transfer] Update Error';
export const FETCH_TRANSFER_SUCCESS = '[transfer] Fetch success';
export const FETCH_TRANSFER_ERROR = '[transfer] Fetch Error';
export const SHOW_TRANSFER_SPINNER = '[transfer - ui] show spinner';
export const HIDE_TRANSFER_SPINNER = '[transfer - ui] hide spinner';

export const getTransfer = (id) => ({
  type: GET_TRANSFER,
  payload: { _id: id },
});

export const createTransfer = (data) => ({
  type: CREATE_TRANSFER,
  payload: data,
});

export const bulkCreateTransfer = (data) => ({
  type: BULK_CREATE_TRANSFER,
  payload: data,
});

export const updateTransfer = (data) => ({
  type: UPDATE_TRANSFER,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_TRANSFER_MODAL,
});

export const hideModal = () => ({
  type: HIDE_TRANSFER_MODAL,
});

export const showBulkModal = () => ({
  type: SHOW_BULK_TRANSFER_MODAL,
});

export const hideBulkModal = () => ({
  type: HIDE_BULK_TRANSFER_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_TRANSFER_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_TRANSFER_SPINNER,
});

export const updateObjectTransfer = (data) => ({
  type: UPDATE_OBJECT_TRANSFER,
  payload: data,
});
