import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  TextField,
  FormGroup,
  MenuItem,
  Button,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Alert from '../../../sharedComponents/Alert/index';

import Spinner from '../../../sharedComponents/Spinner/index';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  StyledLine,
} from './styles';
import { MONTHS } from '../../../config/consts';

import {
  getBakeryAdditionalInformation,
  createBakeryAdditionalInformation,
  updateBakeryAdditionalInformation,
} from '../../../redux/actions/bakeryAdditionalInformation';

const styles = {
  formButton: {
    margin: 2,
  },
};

const today = new Date();
const thisMonth = today.getMonth();
const thisYear = today.getFullYear();

const ItemModal = ({
  id,
  customerId,
  dispatch,
  handleClose,
  itemSpinner,
  bakeryAdditionalInformation,
  currentMonth,
}) => {
  const [data, setData] = useState({
    customer: customerId,
    month: MONTHS[thisMonth],
    year: thisYear,
    production_area: '',
    attendance_area: '',
    production_employees_number: '',
    attendance_employees_number: '',
    management_employees_number: '',
    paycheck_expenses: '',
    energy_gas_expenses: '',
    water_expense: '',
    rent_expense: '',
    management_expenses: '',
    other_expenses: '',
  });

  const {
    _id,
    month,
    year,
    production_area,
    attendance_area,
    production_employees_number,
    attendance_employees_number,
    management_employees_number,
    paycheck_expenses,
    energy_gas_expenses,
    water_expense,
    rent_expense,
    management_expenses,
    other_expenses,
  } = data;

  const isTablet = useMediaQuery('(max-width:812px)');
  const isMobile = useMediaQuery('(max-width:480px)');

  useEffect(() => {
    if (id !== 'new' || currentMonth) {
      dispatch(
        getBakeryAdditionalInformation(
          currentMonth ? `${customerId}/${MONTHS[thisMonth]}/${thisYear}` : id,
        ),
      );
    }
  }, [id, customerId]);

  useEffect(() => {
    if (
      bakeryAdditionalInformation._id &&
      !bakeryAdditionalInformation._id.includes('/')
    ) {
      setData(
        currentMonth
          ? {
              ...bakeryAdditionalInformation,
              month: MONTHS[thisMonth],
              year: thisYear,
            }
          : bakeryAdditionalInformation,
      );
    }
  }, [bakeryAdditionalInformation]);

  const handleSave = async () => {
    const err = false;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new' || (currentMonth && !_id)) {
          dispatch(createBakeryAdditionalInformation(data));
        } else {
          dispatch(updateBakeryAdditionalInformation(data));
        }
      }
    }
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  const handleCurrency = (e, value) => {
    const { name } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  return (
    <>
      {itemSpinner && <Spinner overlay />}
      <Paper elevation={2} fullWidth style={{ height: 'fit-content' }}>
        <Container style={{ opacity: itemSpinner ? 0.5 : 1 }}>
          <ModalTitle>Informações Adicionais</ModalTitle>
          <FormGroup>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <TextField
                    label="Mês"
                    variant="outlined"
                    value={month}
                    name="month"
                    onChange={handleEdit}
                    select
                    fullWidth
                    disabled={currentMonth}
                  >
                    {MONTHS.map((m, i) => (
                      <MenuItem value={m} key={i}>
                        {m}
                      </MenuItem>
                    ))}
                  </TextField>
                </InputContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <TextField
                    label="Ano"
                    variant="outlined"
                    value={year}
                    name="year"
                    onChange={handleEdit}
                    fullWidth
                    disabled={currentMonth}
                    type="number"
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <CurrencyTextField
                    label={
                      isTablet
                        ? 'Área para produção'
                        : 'Área da padaria para produção'
                    }
                    variant="outlined"
                    value={production_area}
                    name="production_area"
                    onChange={handleCurrency}
                    currencySymbol=""
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    fullWidth
                    InputProps={{ endAdornment: 'm²' }}
                    inputProps={{ style: { paddingRight: 10 } }}
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <CurrencyTextField
                    label={
                      isTablet
                        ? 'Área para atendimento'
                        : 'Área da padaria para atendimento'
                    }
                    variant="outlined"
                    value={attendance_area}
                    name="attendance_area"
                    currencySymbol=""
                    outputFormat="number"
                    decimalCharacter=","
                    onChange={handleCurrency}
                    digitGroupSeparator="."
                    fullWidth
                    InputProps={{ endAdornment: 'm²' }}
                    inputProps={{ style: { paddingRight: 10 } }}
                  />
                </InputContent>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <TextField
                    label={
                      isTablet
                        ? 'Funcionários na produção'
                        : 'Número de funcionários na produção'
                    }
                    type="number"
                    variant="outlined"
                    value={production_employees_number}
                    name="production_employees_number"
                    onChange={handleEdit}
                    fullWidth
                    inputProps={{
                      style: { textAlign: 'right', paddingRight: 10 },
                    }}
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <TextField
                    label={
                      isTablet
                        ? 'Funcionários no atendimento'
                        : 'Número de funcionários no atendimento'
                    }
                    type="number"
                    variant="outlined"
                    value={attendance_employees_number}
                    name="attendance_employees_number"
                    onChange={handleEdit}
                    inputProps={{
                      style: { textAlign: 'right', paddingRight: 10 },
                    }}
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <TextField
                    label={
                      isTablet
                        ? 'Funcionários no administrativo'
                        : 'Número de funcionários no administrativo'
                    }
                    type="number"
                    variant="outlined"
                    value={management_employees_number}
                    name="management_employees_number"
                    onChange={handleEdit}
                    inputProps={{
                      style: { textAlign: 'right', paddingRight: 10 },
                    }}
                    fullWidth
                  />
                </InputContent>
              </Grid>
            </Grid>
            {isMobile ? '' : <StyledLine />}
            <Grid container>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <CurrencyTextField
                    label="Despesas com folha de pagamento"
                    variant="outlined"
                    value={paycheck_expenses}
                    name="paycheck_expenses"
                    onChange={handleCurrency}
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <CurrencyTextField
                    label="Despesas com energia/gás"
                    variant="outlined"
                    value={energy_gas_expenses}
                    name="energy_gas_expenses"
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    fullWidth
                    onChange={handleCurrency}
                  />
                </InputContent>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <CurrencyTextField
                    label="Despesas com água"
                    variant="outlined"
                    value={water_expense}
                    name="water_expense"
                    onChange={handleCurrency}
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <CurrencyTextField
                    label="Despesas com aluguel"
                    variant="outlined"
                    value={rent_expense}
                    name="rent_expense"
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    fullWidth
                    onChange={handleCurrency}
                  />
                </InputContent>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <CurrencyTextField
                    label="Despesas administrativas"
                    variant="outlined"
                    value={management_expenses}
                    name="management_expenses"
                    onChange={handleCurrency}
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <CurrencyTextField
                    label="Outras despesas"
                    variant="outlined"
                    value={other_expenses}
                    name="other_expenses"
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    fullWidth
                    onChange={handleCurrency}
                  />
                </InputContent>
              </Grid>
            </Grid>

            <ButtonContainer>
              <Button
                color="secondary"
                onClick={handleClose}
                variant="contained"
                style={styles.formButton}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={handleSave}
                variant="contained"
                style={styles.formButton}
              >
                Salvar
              </Button>
            </ButtonContainer>
          </FormGroup>
        </Container>
      </Paper>
    </>
  );
};

export default connect((state) => ({
  item: state.item,
  itemModal: state.ui.itemModal,
  itemSpinner: state.ui.itemLoading,
  bakeryAdditionalInformation: state.bakeryAdditionalInformation,
}))(ItemModal);
