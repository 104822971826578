import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { ButtonContainer } from '../../../Extracts/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    height: 40,
    borderRadius: '1.5rem',
    padding: '1rem',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 20,
    },
  },
}));

const AddButton = ({ handleOpenModal }) => {
  const classes = useStyles();
  return (
    <ButtonContainer>
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        size="small"
        onClick={handleOpenModal}
        fullWidth
        startIcon={<AddCircleOutlineIcon />}
      >
        Adicionar caixa
      </Button>
    </ButtonContainer>
  );
};

export default AddButton;
