import styled from 'styled-components';
import { Paper, Button, Icon } from '@material-ui/core';

export const Container = styled.div`
  padding: 20px;
  height: fit-content;
`;

export const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    max-width: 90vw;
    height: fit-content;
    border-radius: 0.5rem;
    width: 30rem;
  }
`;

export const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  height: fit-content;
  margin-bottom: 0.7rem;
`;

export const StyledIcon = styled(Icon)`
  color: var(--primary-text-color);
`;

export const ModalTitle = styled.div`
  margin-top: 0.5rem;
  font-size: 1.3rem;
  color: var(--primary-text-color);
  font-weight: 500;
`;

export const InputContent = styled.form`
  margin: 0.5rem 0;
`;

export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const FormButton = styled(Button)`
  margin: 2px;
`;
