import React from 'react';
import Container from '../../sharedComponents/Container';
import SectionContent from '../../sharedComponents/SectionContent';
import SectionHeader from '../../sharedComponents/SectionHeader';

const Marketplace = () => (
  <Container id="section-container">
    <SectionHeader title="Comprador Digital" hideDatePicker />
    <SectionContent>
      <span>Sem dados disponíveis</span>
    </SectionContent>
  </Container>
);

export default Marketplace;
