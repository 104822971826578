export const GET_REPORT_SPLIT_VALUES = '[report_split_values] GET';

export const UPDATE_REPORT_SPLIT_VALUES = '[report_split_values] Update';

export const FETCH_REPORT_SPLIT_VALUES_SUCCESS =
  '[report_split_values] Fetch success';

export const FETCH_REPORT_SPLIT_VALUES_ERROR =
  '[report_split_values] Fetch Error';
export const SHOW_REPORT_SPLIT_VALUES_SPINNER =
  '[report_split_values - ui] show spinner';
export const HIDE_REPORT_SPLIT_VALUES_SPINNER =
  '[report_split_values - ui] hide spinner';

export const showSpinner = () => ({
  type: SHOW_REPORT_SPLIT_VALUES_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_REPORT_SPLIT_VALUES_SPINNER,
});

export const getReportSplitValues = (filter) => ({
  type: GET_REPORT_SPLIT_VALUES,
  payload: filter,
});

export const fetchReportSplitValuesSuccess = (data) => ({
  type: FETCH_REPORT_SPLIT_VALUES_SUCCESS,
  payload: data,
});
