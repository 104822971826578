import styled from 'styled-components';

export const InputContent = styled.form`
  width: 100%;
  padding: 0.25rem 0.5rem;

  .MuiOutlinedInput-input {
    padding: 0.5rem;
  }
`;
