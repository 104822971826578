import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import { EditRounded, ErrorRounded } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

import Spinner from '../../../../sharedComponents/Spinner/index';
import { Title, Summary, SummaryText } from '../../styles';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: '1rem',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '1.5rem',
    '&.filter': {
      padding: '1rem',
    },
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    height: 50,
    padding: '0px 15px',
    textAlign: 'center',
  },
  headerCell: {
    textAlign: 'center',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  headerGridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  alertDiffValues: {
    color: theme.palette.danger.main,
  },
}));

const SectionContent = ({
  title,
  subtitle,
  loading,
  columnsMap,
  rows,
  total,
  limit,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  updateCallBack,
}) => {
  const classes = useStyles();
  const headerLabels = Object.keys(columnsMap);
  const rowLabels = Object.values(columnsMap);

  const checkDiffValues = (value, sumValue) =>
    parseFloat(value).toFixed(2) === parseFloat(sumValue).toFixed(2);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Summary>
          <Title>{title}</Title>
          <SummaryText>
            {loading ? <Skeleton variant="text" /> : subtitle}
          </SummaryText>
        </Summary>
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                {headerLabels.map((label) => (
                  <TableCell key={label} className={classes.headerCell}>
                    {label}
                  </TableCell>
                ))}
                <TableCell className={classes.headerCell}>Editar</TableCell>
                <TableCell className={classes.headerCell} />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow className={classes.row}>
                  <TableCell
                    colSpan={headerLabels.length + 2}
                    className={classes.cell}
                  >
                    <Spinner parent="Table" />
                  </TableCell>
                </TableRow>
              )}
              {!loading &&
                rows.map((row) => (
                  <TableRow className={classes.row}>
                    {rowLabels.map((label) => {
                      let valueToShow;
                      if (typeof label === 'string') {
                        valueToShow = row[label];
                      } else {
                        label.forEach((labelItem) => {
                          valueToShow = valueToShow || row[labelItem];
                        });
                      }
                      return (
                        <TableCell
                          key={label}
                          className={[
                            classes.cell,
                            !checkDiffValues(
                              row.value,
                              row.expected_daily_ammount,
                            ) && classes.alertDiffValues,
                          ]}
                        >
                          {valueToShow}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      className={[
                        classes.cell,
                        !checkDiffValues(
                          row.value,
                          row.expected_daily_ammount,
                        ) && classes.alertDiffValues,
                      ]}
                    >
                      <EditRounded onClick={() => updateCallBack(row.id)} />
                    </TableCell>
                    <TableCell
                      className={[
                        classes.cell,
                        !checkDiffValues(
                          row.value,
                          row.expected_daily_ammount,
                        ) && classes.alertDiffValues,
                      ]}
                    >
                      {!checkDiffValues(
                        row.value,
                        row.expected_daily_ammount,
                      ) && (
                        <Tooltip title="Há diferença de valores">
                          <ErrorRounded color="red" />
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}

              {!loading && rows && rows.length === 0 && (
                <TableRow className={classes.row}>
                  <TableCell
                    colSpan={headerLabels.length + 2}
                    className={classes.headerCell}
                  >
                    Nenhum resultado para exibir
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={limit}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Resultados por página"
        />
      </Paper>
    </div>
  );
};

export default SectionContent;
