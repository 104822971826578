export const GET_COMPETITOR = '[competitor] GET';
export const UPDATE_COMPETITOR = '[competitor] UPDATE';
export const CREATE_COMPETITOR = '[competitor] CREATE';
export const DELETE_COMPETITOR = '[competitor] DELETE';
export const UPDATE_OBJECT_COMPETITOR = '[competitor] UPDATE OBJECT COMPETITOR';
// UI
export const SHOW_COMPETITOR_MODAL = '[competitor] SHOW MODAL';
export const HIDE_COMPETITOR_MODAL = '[competitor] HIDE MODAL';
// Spinner
export const UPDATE_COMPETITOR_SUCCESS = '[competitor] Update success';
export const UPDATE_COMPETITOR_ERROR = '[competitor] Update Error';
export const FETCH_COMPETITOR_SUCCESS = '[competitor] Fetch success';
export const FETCH_COMPETITOR_ERROR = '[competitor] Fetch Error';
export const SHOW_COMPETITOR_SPINNER = '[competitor - ui] show spinner';
export const HIDE_COMPETITOR_SPINNER = '[competitor - ui] hide spinner';

export const getCompetitor = (id) => ({
  type: GET_COMPETITOR,
  payload: { id },
});

export const createCompetitor = (data) => ({
  type: CREATE_COMPETITOR,
  payload: data,
});

export const updateCompetitor = (data) => ({
  type: UPDATE_COMPETITOR,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_COMPETITOR_MODAL,
});

export const hideModal = () => ({
  type: HIDE_COMPETITOR_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_COMPETITOR_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_COMPETITOR_SPINNER,
});

export const updateObjectCompetitor = (data) => ({
  type: UPDATE_OBJECT_COMPETITOR,
  payload: data,
});
