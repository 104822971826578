export const GET_COMMISSIONING = '[commissioning] GET';
export const UPDATE_COMMISSIONING = '[commissioning] UPDATE';
export const UPDATE_COMMISSION_VALUE = '[commissioning] UPDATE FIELD';
export const CREATE_COMMISSIONING = '[commissioning] CREATE';
export const DELETE_COMMISSIONING = '[commissioning] DELETE';
export const UPDATE_OBJECT_COMMISSIONING =
  '[commissioning] UPDATE OBJECT COMMISSIONING';
// UI
export const SHOW_COMMISSIONING_MODAL = '[commissioning] SHOW MODAL';
export const HIDE_COMMISSIONING_MODAL = '[commissioning] HIDE MODAL';
// Spinner
export const UPDATE_COMMISSIONING_SUCCESS = '[commissioning] Update success';
export const UPDATE_COMMISSIONING_ERROR = '[commissioning] Update Error';
export const FETCH_COMMISSIONING_SUCCESS = '[commissioning] Fetch success';
export const FETCH_COMMISSIONING_ERROR = '[commissioning] Fetch Error';
export const SHOW_COMMISSIONING_SPINNER = '[commissioning - ui] show spinner';
export const HIDE_COMMISSIONING_SPINNER = '[commissioning - ui] hide spinner';

export const getCommissioning = (id) => ({
  type: GET_COMMISSIONING,
  payload: { _id: id },
});

export const createCommissioning = (data) => ({
  type: CREATE_COMMISSIONING,
  payload: data,
});

export const updateCommissioning = (data) => ({
  type: UPDATE_COMMISSIONING,
  payload: data,
});

export const updateCommissionValue = (data) => ({
  type: UPDATE_COMMISSION_VALUE,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_COMMISSIONING_MODAL,
});

export const hideModal = () => ({
  type: HIDE_COMMISSIONING_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_COMMISSIONING_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_COMMISSIONING_SPINNER,
});

export const updateObjectCommissioning = (data) => ({
  type: UPDATE_OBJECT_COMMISSIONING,
  payload: data,
});
