import React, { useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import EditableTableCell from '../../../../sharedComponents/EditableTableCell';
import Toastify from '../../../../utils/Toastify';
import { useStyles } from './styles';
import { PAYMENT_KINDS } from '../../../../config/consts';
import { array2Map, isNumber } from '../../../../utils/globalFunctions';
import SubTable from '../SubTable';

const Row = ({
  row,
  companies,
  commissioningLoading,
  handleShowEditModal,
}) => {
  const { companyDocument, registers } = row;
  const [open, setOpen] = useState(false);
  const companyMap = array2Map(companies.docs, 'document');
  const companyName = companyMap[companyDocument]?.name;
  const classes = useStyles();

  const getComissionData = (paymentKind) => {
    const commissionData = registers.find((register) => register.paymentKind === paymentKind);
    const { commissionValue, validSince } = commissionData;
    return { commissionValue, validSince };
  };

  return row ? (
    <>
      <TableRow className={classes.row}>
        <TableCell className={classes.cell} align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.cell} align="center">
          {companyName}
        </TableCell>
        {PAYMENT_KINDS.map((paymentKind) => (
          <EditableTableCell
            key={paymentKind}
            value={row[paymentKind]}
            isLoading={commissioningLoading}
            onEditCallback={(commissionValue) => {
              const commissionData = getComissionData(paymentKind);
              handleShowEditModal({ commissionValue, companyDocument, paymentKind }, commissionData);
            }}
            validator={(value) => {
              const isValueInRange = value >= 0 && value <= 100;
              const isValidType = isNumber(value);
              if (!isValidType) {
                Toastify.addError('Valor não é um número.');
              } else if (!isValueInRange) {
                Toastify.addError(
                  'Valor fora do limite permitido para comissão.',
                );
              }
              return isValidType && isValueInRange;
            }}
          />
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={PAYMENT_KINDS.length + 3}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <SubTable registers={row.registers} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  ) : null;
};

export default Row;
