import {
  GET_BAKERY_ADDITIONAL_INFORMATIONS,
  UPDATE_BAKERY_ADDITIONAL_INFORMATIONS,
} from '../actions/bakeryAdditionalInformations';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function bakeryAdditionalInformationsReducer(
  bakeryAdditionalInformations = initState,
  action,
) {
  switch (action.type) {
    case GET_BAKERY_ADDITIONAL_INFORMATIONS:
      return action.payload;
    case UPDATE_BAKERY_ADDITIONAL_INFORMATIONS:
      return action.payload;

    default:
      return bakeryAdditionalInformations;
  }
}
