import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import {
  useMediaQuery,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Box,
  IconButton,
} from '@material-ui/core';
import {
  EditRounded,
  AddRounded,
  DeleteRounded,
  CheckCircle as ActiveIcon,
  CancelRounded as InactiveIcon,
} from '@material-ui/icons';
import { getProducts } from '../../redux/actions/products';
import {
  showModal as showProductModal,
  hideModal as hideProductModal,
  hideSpinner as hideProductSpinner,
  updateProduct,
} from '../../redux/actions/product';
import ModalContainer from '../../sharedComponents/ModalContainer/index';
import EditModal from '../Modals/Product';
import Spinner from '../../sharedComponents/Spinner/index';
import { Container } from './styles';
import Toastify from '../../utils/Toastify';
import Alert from '../../sharedComponents/Alert';

import SubHeader from '../../sharedComponents/SubHeader';
import { hasPermission } from '../../utils/globalFunctions';
import PATHS from '../../config/routePaths';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '1rem',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '1rem',
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
  },
  addButton: {
    border: '2px solid var(--primary-text-color)',
    marginLeft: '1rem',
    color: 'var(--primary-text-color)',
  },
}));

const searchStyles = makeStyles((theme) => ({
  root: {
    marginLeft: '1rem',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--primary-text-color)',
        borderRadius: '1rem',
        borderWidth: 2,
      },
      '&:hover fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
    },
    color: 'var(--primary-text-color)',
    minWidth: '8.313rem',
    fontWeight: 500,
    [theme.breakpoints.down(540)]: {
      marginLeft: 0,
    },
  },
}));

const Products = ({
  dispatch,
  products,
  productModal,
  productsLoading,
  company,
  customer,
}) => {
  const searchClasses = searchStyles();
  const classes = useStyles();
  const [productsFormated, setProductsFormated] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');

  const isTablet = useMediaQuery('(max-width:812px)');
  const isMobile = useMediaQuery('(max-width:480px)');

  useEffect(() => {
    dispatch(getProducts({ page: page + 1, limit }));
  }, [page, limit]);

  useEffect(() => {
    if (products?.docs) {
      setProductsFormated(products.docs);
      setTotal(products.total);
    }
  }, [products]);

  const handleOpenModal = () => {
    dispatch(showProductModal());
  };

  const handleCloseModal = () => {
    dispatch(hideProductModal());
  };

  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(
      getProducts({
        page: 1,
        limit,
        eanCodeFilter: searchText,
        nameFilter: searchText,
      }),
    );
  };

  const handleDeleteClick = (product) => {
    Alert('Tem certeza?', 'Deseja realmente excluir este produto?').then(
      ({ confirm }) => {
        if (!confirm) return;
        dispatch(
          updateProduct({
            ...product,
            active: false,
          }),
        );
      },
    );
  };

  const handleProductSuccess = () => {
    Toastify.addSuccess('Operação concluída com sucesso');
    dispatch(hideProductModal());
    dispatch(hideProductSpinner());
    dispatch(
      getProducts({
        page,
        limit,
        filter: { customer: customer?._id },
      }),
    );
  };

  const handleProductError = () => {
    const message = modalContentId === 'new' ? 'criar' : 'editar';
    Toastify.addError(`Não foi possível ${message} o produto`);
    dispatch(hideProductSpinner());
  };

  return (
    <>
      <ModalContainer open={productModal} handleClose={handleCloseModal}>
        <EditModal
          id={modalContentId}
          handleClose={handleCloseModal}
          handleSuccess={handleProductSuccess}
          handleError={handleProductError}
        />
      </ModalContainer>
      <Container>
        <SubHeader
          title={
            <span>
              Produtos
              <IconButton
                className={classes.addButton}
                onClick={() => handleClick('new')}
                disabled={!hasPermission(PATHS.SETTINGS_PRODUCTS, 'create')}
                size="small"
                style={{
                  margin: isMobile ? '1rem' : null,
                }}
              >
                <AddRounded />
              </IconButton>
            </span>
          }
        >
          <span
            style={{
              paddingTop: 4,
              width: isMobile ? '100%' : undefined,
              marginBottom: isMobile ? '1rem' : null,
              display: isMobile ? 'flex' : null,
              flexDirection: isMobile ? 'column' : null,
              justifyContent: isMobile ? 'center' : null,
            }}
          >
            <TextField
              style={{
                width: isTablet ? null : 300,
                marginTop: isMobile ? '1rem' : null,
                display: isMobile ? 'flex' : null,
                flexDirection: isMobile ? 'column' : null,
                justifyContent: isMobile ? 'center' : null,
              }}
              classes={searchClasses}
              label={!searchText ? 'Pesquisar' : ''}
              type="text"
              variant="outlined"
              value={searchText}
              name="vendor_name"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(e) => (e.key === 'Enter' ? handleSearch(e) : null)}
              fullWidth={!isTablet}
              InputProps={{
                style: {
                  color: 'var(--primary-text-color)',
                },
              }}
              InputLabelProps={{
                shrink: false,
                style: { color: 'var(--primary-text-color)' },
              }}
            />
          </span>
        </SubHeader>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Ativo</TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell colSpan={2}>Descrição</TableCell>
                    <TableCell>EAN</TableCell>
                    <TableCell>Estrutura mercadológica</TableCell>
                    <TableCell>Sub estrutura mercadológica</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Categoria</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productsLoading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="14" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!productsLoading &&
                    productsFormated.length === 0 &&
                    company && (
                      <TableRow>
                        <TableCell colSpan={14}>
                          <Box textAlign="center" fontSize="1.5rem">
                            Nenhum produto Encontrado.
                          </Box>
                        </TableCell>
                      </TableRow>
                  )}
                  {!productsLoading &&
                    productsFormated.map((product) => (
                      <TableRow key={product.ean_code} className={classes.row}>
                        <TableCell className={classes.cell}>
                          {product.active ? (
                            <ActiveIcon className={classes.checkButton} />
                          ) : (
                            <InactiveIcon className={classes.closeButton} />
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {product.name}
                        </TableCell>
                        <TableCell colSpan={2} className={classes.cell}>
                          {product.description}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {product.ean_code}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {product.market_structure}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {product.sub_market_structure}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {product.type}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {product.category}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          <IconButton onClick={() => handleClick(product.id)}>
                            <EditRounded />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteClick(product)}
                            disabled={
                              !hasPermission(
                                PATHS.TRANSFORMATION_ITEMS,
                                'update',
                              )
                            }
                            color="secondary"
                          >
                            <DeleteRounded />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Resultados por página"
            />
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedUser: state.loggedUser,
  company: state.company,
  customer: state.customer,
  productsLoading: state.ui.productsLoading,
  products: state.products,
  productModal: state.ui.productModal,
});

export default connect(mapStateToProps)(Products);
