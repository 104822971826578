export const GET_SUPPLIER = '[supplier] GET';
export const UPDATE_SUPPLIER = '[supplier] UPDATE';
export const CREATE_SUPPLIER = '[supplier] CREATE';
export const DELETE_SUPPLIER = '[supplier] DELETE';
export const UPDATE_OBJECT_SUPPLIER = '[supplier] UPDATE OBJECT SUPPLIER';
// UI
export const SHOW_SUPPLIER_MODAL = '[supplier] SHOW MODAL';
export const HIDE_SUPPLIER_MODAL = '[supplier] HIDE MODAL';
// Spinner
export const UPDATE_SUPPLIER_SUCCESS = '[supplier] Update success';
export const UPDATE_SUPPLIER_ERROR = '[supplier] Update Error';
export const FETCH_SUPPLIER_SUCCESS = '[supplier] Fetch success';
export const FETCH_SUPPLIER_ERROR = '[supplier] Fetch Error';
export const SHOW_SUPPLIER_SPINNER = '[supplier - ui] show spinner';
export const HIDE_SUPPLIER_SPINNER = '[supplier - ui] hide spinner';

export const getSupplier = (id) => ({
  type: GET_SUPPLIER,
  payload: { _id: id },
});

export const createSupplier = (data) => ({
  type: CREATE_SUPPLIER,
  payload: data,
});

export const updateSupplier = (data) => ({
  type: UPDATE_SUPPLIER,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_SUPPLIER_MODAL,
});

export const hideModal = () => ({
  type: HIDE_SUPPLIER_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_SUPPLIER_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_SUPPLIER_SPINNER,
});

export const updateObjectSupplier = (data) => ({
  type: UPDATE_OBJECT_SUPPLIER,
  payload: data,
});
