import styled from 'styled-components';

export const Container = styled.div`
  min-width: 60vw;
  padding: 20px;
`;

export const ModalTitle = styled.div`
  padding-left: 15px;
  padding-bottom: 20px;
  font-size: 1.3rem;
`;

export const InputContent = styled.form`
  width: 100%;
  padding: 5px 10px 10px 10px;
`;

export const CheckboxContent = styled.form`
  width: 100%;
  padding: 12px 10px 15px 0px;
`;

export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;
