/* eslint-disable react/jsx-props-no-spreading */
import { Grid, Icon, MenuItem, useMediaQuery } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker } from '@material-ui/pickers';
import { useTheme } from '@material-ui/styles';
import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ModalContainer from '../../../sharedComponents/ModalContainer';
import {
  FilterHeader,
  labelStyle,
  selectStyle,
  StyledCurrencyField,
  StyledTextField,
} from '../../Extracts/styles';
import ActionButtons from './Components/ActionButtons';
import { Divider, FieldTitle, Title } from './styles';
import AuditService from '../../../services/Audit';
import Toastify from '../../../utils/Toastify';
import Spinner from '../../../sharedComponents/Spinner';
import { getAcquirers } from '../../../redux/middleware/acquirers';
import {
  createDailyExpectedPayment,
  updateDailyExpectedPayment,
} from '../../../redux/actions/audit';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '60vw',
    paddingTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: 0,
      minHeight: '100vh',
    },
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '1.5rem',
    '&.filter': {
      padding: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    height: 50,
    padding: '0px 15px',
    textAlign: 'center',
  },
  buttons: {
    color: 'blue',
  },
  headerGridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  headerGridContainer: {
    marginLeft: '6px',
    marginRight: '6px',
    marginTop: '1rem',
    width: '100%',
    '&:first-child': {
      marginTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 16px)',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const AuditCreateExpectedPaymentModal = ({
  handleCloseModal,
  open,
  dateGte,
  dateLte,
  shouldUpdateItem,
  selectedItem,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const modalStyle = {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  };

  const isMobile = useMediaQuery('(max-width:480px)');

  return (
    <ModalContainer
      open={open}
      handleClose={handleCloseModal}
      modalStyle={modalStyle}
    >
      <div className={classes.root}>
        <Paper className={`${classes.paper} filter`}>
          <FilterHeader>
            <Title>
              {shouldUpdateItem
                ? 'Atualizar previsão de pagamento'
                : 'Cadastrar nova previsão de pagamento'}
            </Title>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon style={{ color: '#192D4D' }} />
            </IconButton>
          </FilterHeader>
          {isMobile && <Divider className="first-divider" />}
          <div>
            <FormGrid
              dateGte={dateGte}
              dateLtd={dateLte}
              handleClose={handleCloseModal}
              shouldUpdateItem={shouldUpdateItem}
              selectedItem={selectedItem}
            />
          </div>
        </Paper>
      </div>
    </ModalContainer>
  );
};

const FormGrid = connect((state) => ({
  companies: state.companies?.docs || state.companies || [],
  picPayOperators: state.picPayOperators?.docs || state.picPayOperators,
}))(({ dateGte, dateLte, handleClose, shouldUpdateItem, selectedItem }) => {
  const acquirers = useSelector((state) => state.acquirers?.docs);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getAcquirers({ listAll: true }));
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();
  const selectClasses = selectStyle();
  const labelClasses = labelStyle();
  const [innerDateGte, setInnerDateGte] = useState(dateGte);

  const [innerAcquirer, setInnerAcquirer] = useState(null);
  const [innerValue, setInnerValue] = useState(null);

  const fromDateRef = useRef();

  useEffect(() => {
    if (shouldUpdateItem && selectedItem) {
      setIsLoading(true);
      AuditService.getExpectedDailyPaymentById(selectedItem)
        .then(({ data }) => {
          setInnerAcquirer(data.acquirerId);
          setInnerDateGte(
            moment(new Date(data.initial_date).setHours(0, 0, 0, 0)).startOf(
              'day',
            ),
          );
          setInnerValue(data.value);
          setIsLoading(false);
        })
        .catch((error) => {
          Toastify.addError('Erro ao buscar o item solicitado');
        });
    }
  }, [shouldUpdateItem, selectedItem]);

  const handleSubmit = () => {
    setIsLoading(true);

    if (!shouldUpdateItem && !selectedItem) {
      dispatch(
        createDailyExpectedPayment({
          acquirerId: innerAcquirer,
          initial_date: moment(
            new Date(innerDateGte).setHours(0, 0, 0, 0),
          ).startOf('day'),
          value: innerValue,
        }),
      );

      handleClose();

      setIsLoading(false);
    } else {
      dispatch(
        updateDailyExpectedPayment({
          id: selectedItem,
          acquirerId: innerAcquirer,
          initial_date: innerDateGte,
          value: innerValue,
        }),
      );

      handleClose();
    }
  };

  return (
    <>
      <Grid container spacing={2} className={classes.headerGridContainer}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Grid item xs={12} md={3}>
              <FieldTitle ref={fromDateRef}>Período</FieldTitle>
            </Grid>
            <Grid item xs={12} md={9}>
              <DatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                ampm={false}
                label="De"
                value={innerDateGte}
                onChange={setInnerDateGte}
                InputLabelProps={{
                  hidden: true,
                  style: {
                    color: 'var(--primary-text-color)',
                    backgroundColor: 'transparent',
                  },
                }}
                PopoverProps={{ anchorEl: () => fromDateRef.current }}
                inputVariant="outlined"
                maxDate={new Date()}
                fullWidth
                autoOk
                TextFieldComponent={(props) => (
                  <StyledTextField
                    {...props}
                    ref={fromDateRef}
                    InputProps={{
                      startAdornment: (
                        <IconButton style={{ paddingLeft: 0 }}>
                          <Icon
                            style={{ color: '#192D4D' }}
                            className="material-icons-outlined"
                          >
                            calendar_today
                          </Icon>
                        </IconButton>
                      ),
                      endAdornment: (
                        <IconButton style={{ paddingRight: 0 }}>
                          <Icon style={{ color: '#192D4D' }}>expand_more</Icon>
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid container spacing={2} className={classes.headerGridContainer}>
              <Grid item xs={12} md={3}>
                <FieldTitle>Adquirente</FieldTitle>
              </Grid>
              <Grid item xs={12} md={9}>
                <StyledTextField
                  label={!innerAcquirer ? 'Selecione' : ''}
                  type="text"
                  value={innerAcquirer || null}
                  variant="outlined"
                  name="acquirerId"
                  onChange={(e) => setInnerAcquirer(e.target.value)}
                  fullWidth
                  InputLabelProps={{ shrink: false, classes: labelClasses }}
                  select
                  SelectProps={{
                    IconComponent: KeyboardArrowDown,
                    classes: selectClasses,
                  }}
                  InputProps={{
                    startAdornment: (
                      <Icon
                        style={{ color: '#192D4D', marginRight: '0.7rem' }}
                        className="material-icons-outlined"
                      >
                        credit_card
                      </Icon>
                    ),
                  }}
                >
                  {acquirers.map((acquirer) => (
                    <MenuItem key={acquirer.id} value={acquirer.id}>
                      {acquirer.name}
                    </MenuItem>
                  ))}
                </StyledTextField>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} className={classes.headerGridContainer}>
              <Grid item xs={12} md={3}>
                <FieldTitle>Valor</FieldTitle>
              </Grid>
              <Grid item xs={12} md={9}>
                <StyledCurrencyField
                  placeholder={!innerValue ? 'Digite' : ''}
                  variant="outlined"
                  value={innerValue || ''}
                  name="value"
                  currencySymbol={
                    <Icon
                      style={{ color: '#192D4D', marginRight: '0.7rem' }}
                      className="material-icons-outlined"
                    >
                      paid
                    </Icon>
                  }
                  outputFormat="number"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  fullWidth
                  onChange={(e, value) => setInnerValue(value)}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      <Divider />
      <ActionButtons
        refDate={dateGte && dateLte}
        handleSubmit={handleSubmit}
        shouldUpdateItem={shouldUpdateItem}
      />
    </>
  );
});

export default AuditCreateExpectedPaymentModal;
