import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { CloudDownload } from '@material-ui/icons';
import { ButtonContainer } from '../../styles';

const useStyles = makeStyles((theme) => ({
  button: {
    height: 40,
    borderRadius: '1.5rem',
    padding: '1rem',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 20,
    },
  },
}));

const DownloadButton = ({ refDate, downloadCallback }) => {
  const classes = useStyles();
  return (
    <Tooltip title={!refDate ? 'Selecione uma data para baixar os dados' : ''}>
      <ButtonContainer>
        <Button
          className={classes.button}
          color="secondary"
          variant="contained"
          size="small"
          onClick={downloadCallback}
          disabled={!refDate}
          fullWidth
          endIcon={<CloudDownload />}
        >
          Baixar
        </Button>
      </ButtonContainer>
    </Tooltip>
  );
};

export default DownloadButton;
