import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';

export const Container = styled.div`
  padding: 20px;
`;

export const PaperStyled = styled(Paper)`
  padding: 15px;
  width: auto;
  margin-bottom: 40px;
`;

export const Title = styled.span`
  font-size: 1.4rem;
  font-weight: bold;
`;
export const InputContainer = styled.div`
  padding: 0px 10px 10px 10px;
`;

export const TableTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  padding-top: 20px;
`;

export const ExcelTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid #878787;
  border-radius: 3px;
  margin: 0px 0px 15px 0px;
  padding: 10px;
`;

export const Column = styled.div`
  padding: 5px;
`;

export const ColumnHeader = styled.div`
  text-align: center;
  font-size: 1.1rem;
`;

export const ColumnContent = styled.div`
  min-width: 200px;
`;

export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const AddButton = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;

export const AddMoreButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  text-align: right;
`;
