import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Typography, CardContent, Icon } from '@material-ui/core';
import { Thumbnail, Header, Button } from './styles';
import PlaceholderImg from '../../../../assets/images/placeholder.svg';

function formatMoney(value) {
  const formatter = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return formatter.format(value);
}

const ProductCard = ({ offer, companyDocument }) => {
  const companyOffer = offer.companyOffers?.find(
    (item) => item.companyDocument === companyDocument,
  );

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card style={{ clear: 'both' }}>
        <Thumbnail
          title={offer.product.name}
          component="img"
          image={offer.product?.mainPicture?.url || PlaceholderImg}
          style={
            !offer.product?.mainPicture?.url ? { opacity: 0.1 } : undefined
          }
        />
        <Header
          title={offer.product.name}
          subheader={offer.product.description}
        />
        <CardContent>
          <Typography variant="h6">
            {formatMoney(companyOffer?.sellingPrice)}
          </Typography>
          {companyOffer?.lastExternalPurchase && (
            <Typography variant="caption">
              Última compra:{' '}
              <span style={{ color: '#ef5350' }}>
                {formatMoney(companyOffer?.lastExternalPurchase)}
              </span>
            </Typography>
          )}
        </CardContent>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={companyOffer?.metadata?.formsLink}
        >
          <Button
            disabled={!companyOffer?.metadata?.formsLink}
            size="small"
            variant="contained"
            color="primary"
            startIcon={<Icon>shopping_basket</Icon>}
          >
            Ir para compra
          </Button>
        </a>
      </Card>
    </Grid>
  );
};

ProductCard.propTypes = {
  offer: PropTypes.shape({
    product: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      mainPicture: PropTypes.shape({ url: PropTypes.string }),
    }),
    companyOffers: PropTypes.arrayOf(
      PropTypes.shape({
        companyDocument: PropTypes.string,
        sellingPrice: PropTypes.number,
        lastExternalPurchase: PropTypes.number,
        metadata: { formsLink: PropTypes.string },
      }),
    ),
  }).isRequired,
  companyDocument: PropTypes.string.isRequired,
};

export default ProductCard;
