/* eslint-disable react/jsx-props-no-spreading */
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardArrowDown } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import ModalContainer from '../../../sharedComponents/ModalContainer';
import TextButton from '../../../sharedComponents/TextButton';
import Toastify from '../../../utils/Toastify';
import { isEmptyObject } from '../../../utils/globalFunctions';
import { FilterHeader } from '../../Extracts/styles';
import {
  Divider,
  FieldTitle,
  Title,
  ButtonsContainer,
  useStyles,
} from './styles';

const Modal = ({
  companies,
  company,
  cashier,
  paymentMachine,
  picPayOperator,
  getAvailablePos,
  handleClose,
  handleSave,
  open,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const modalStyle = {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  };

  const isMobile = useMediaQuery('(max-width:480px)');

  return (
    <ModalContainer
      open={open}
      handleClose={handleClose}
      modalStyle={modalStyle}
    >
      <div className={classes.root}>
        <Paper className={`${classes.paper} filter`}>
          <FilterHeader>
            <Title>Adicionar/Editar Caixa</Title>
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ color: '#192D4D' }} />
            </IconButton>
          </FilterHeader>
          {isMobile && <Divider className="first-divider" />}
          <div>
            <ModalGrid
              companies={companies}
              company={company}
              cashier={cashier}
              paymentMachine={paymentMachine}
              picPayOperator={picPayOperator}
              getAvailablePos={getAvailablePos}
              handleClose={handleClose}
              handleSave={handleSave}
            />
          </div>
        </Paper>
      </div>
    </ModalContainer>
  );
};

const ModalGrid = ({
  companies,
  company,
  cashier,
  paymentMachine,
  picPayOperator,
  getAvailablePos,
  handleClose,
  handleSave,
}) => {
  const classes = useStyles();
  const {
    id: cashierId,
    name: cashierName,
    paymentMachines: cashierPaymentMachines,
    picPayOperators: cashierPicPayOperators,
  } = cashier;

  const [innerCompanyId, setInnerCompanyId] = useState(company?.id);
  const [innerCashierName, setInnerCashierName] = useState(cashierName);
  const [innerPaymentMachines, setInnerPaymentMachines] =
    useState(cashierPaymentMachines || (paymentMachine ? [paymentMachine] : []));
  const [innerPicPayOperators, setInnerPicPayOperators] =
    useState(cashierPicPayOperators || (picPayOperator ? [picPayOperator] : []));
  const [availablePaymentMachines, setAvailablePaymentMachines] =
    useState([]);
  const [availablePicPayOperators, setAvailablePicPayOperators] =
    useState([]);
  let innerCompanyDoc = company?.document;

  const fetchAvailablePos = async (companyDocument) => {
    try {
      const { paymentMachines, picPayOperators } = await getAvailablePos({
        refDate: new Date(),
        companyDocument,
      });
      setAvailablePaymentMachines(paymentMachines);
      setAvailablePicPayOperators(picPayOperators);
    } catch (error) {
      Toastify.addError(
        'Ocorreu um erro com o carregamento dos seus dados, por favor, recarregue a página ou entre em contato com o suporte.',
        error,
      );
    }
  };

  const saveMiddleware = () => {
    const payload = {
      name: innerCashierName,
      company_document: innerCompanyDoc,
      payment_machines: innerPaymentMachines,
      pic_pay_operators: innerPicPayOperators,
    };
    const params = {
      payload,
      ...(!isEmptyObject(cashier) && { id: cashierId }),
    };
    handleSave(params);
  };

  useEffect(() => {
    fetchAvailablePos(company?.document);
  }, []);

  useEffect(() => {
    innerCompanyDoc =
      companies.find(({ id }) => id === innerCompanyId)?.document;
    fetchAvailablePos(innerCompanyDoc);
  }, [innerCompanyId]);

  return (
    <>
      <Grid container spacing={3} className={classes.headerGridContainer}>
        <Grid item xs={12} md={3}>
          <FieldTitle>Unidade</FieldTitle>
        </Grid>
        <Grid item xs={12} md={8}>
          <CompanySelector
            innerCompany={innerCompanyId}
            availableCompanies={companies}
            setInnerCompany={setInnerCompanyId}
            disabled={!isEmptyObject(cashier)}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={3} className={classes.headerGridContainer}>
        <Grid item xs={12} md={3}>
          <FieldTitle>Nome do Caixa</FieldTitle>
        </Grid>
        <Grid item xs={12} md={8}>
          <CashierNameField
            name={innerCashierName}
            setName={setInnerCashierName}
            className={classes.input}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={3} className={classes.headerGridContainer}>
        <Grid item xs={12} md={3}>
          <FieldTitle>POS</FieldTitle>
        </Grid>
        <Grid item xs={12} md={8}>
          <PaymentMachineSelector
            innerMachines={innerPaymentMachines}
            availableMachines={availablePaymentMachines}
            setInnerMachines={setInnerPaymentMachines}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={3} className={classes.headerGridContainer}>
        <Grid item xs={12} md={3}>
          <FieldTitle>Operador PicPay</FieldTitle>
        </Grid>
        <Grid item xs={12} md={8}>
          <PicPaySelector
            innerOperators={innerPicPayOperators}
            availableOperators={availablePicPayOperators}
            setInnerOperators={setInnerPicPayOperators}
          />
        </Grid>
      </Grid>
      <Divider />
      <ButtonsContainer>
        <TextButton
          text="Cancelar"
          outlined
          fullWidth
          onClick={handleClose}
        />
        <TextButton
          text="Salvar"
          fullWidth
          onClick={saveMiddleware}
        />
      </ButtonsContainer>
    </>
  );
};

function CashierNameField({
  name,
  setName,
  className,
}) {
  return (
    <TextField
      label={name ? '' : 'Digite'}
      type="text"
      variant="outlined"
      value={name}
      name="cashier-name"
      onChange={(e) => setName(e.target.value)}
      fullWidth
      InputLabelProps={{ shrink: false }}
      InputProps={{ className }}
    />
  );
}

function CompanySelector({
  innerCompany,
  availableCompanies,
  setInnerCompany,
  disabled,
}) {
  return (
    <Select
      type="text"
      variant="outlined"
      label={innerCompany ? '' : 'Selecione'}
      value={innerCompany}
      disabled={disabled}
      name="payment-machines"
      onChange={(e) => setInnerCompany(e.target.value)}
      fullWidth
      IconComponent={KeyboardArrowDown}
      style={{ borderRadius: '1rem' }}
    >
      {availableCompanies.map(({ name, id }) =>
        <MenuItem key={id} value={id}>{name}</MenuItem>,
      )}
    </Select>
  );
}

function PaymentMachineSelector({
  innerMachines,
  availableMachines,
  setInnerMachines,
}) {
  return (
    <FormControl fullWidth>
      <InputLabel id="label-pos" shrink={false} variant="outlined">
        {innerMachines.length ? '' : 'Selecione'}
      </InputLabel>
      <StyledMultipleSelect
        labelId="label-pos"
        value={innerMachines}
        name="payment-machines"
        onChange={(e) => setInnerMachines(e.target.value)}
        content={availableMachines.map(({ serial_number: pos }) =>
          <MenuItem key={pos} value={pos}>{pos}</MenuItem>,
        )}
      />
    </FormControl>
  );
}

function PicPaySelector({
  innerOperators,
  availableOperators,
  setInnerOperators,
}) {
  return (
    <FormControl fullWidth>
      <InputLabel id="label-pic-pay" shrink={false} variant="outlined">
        {innerOperators.length ? '' : 'Selecione'}
      </InputLabel>
      <StyledMultipleSelect
        labelId="label-pic-pay"
        value={innerOperators}
        name="pic-pay-operators"
        onChange={(e) => setInnerOperators(e.target.value)}
        content={availableOperators.map(({ operator }) =>
          <MenuItem key={operator} value={operator}>{operator}</MenuItem>,
        )}
      />
    </FormControl>
  );
}

function StyledMultipleSelect({
  labelId,
  value,
  name,
  onChange,
  content,
}) {
  return (
    <Select
      type="text"
      variant="outlined"
      labelId={labelId}
      name={name}
      multiple
      value={value}
      onChange={onChange}
      style={{ borderRadius: '1rem' }}
      IconComponent={KeyboardArrowDown}
    >
      {content}
    </Select>
  );
}

export default Modal;
