import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import { Link } from 'react-router-dom';
import { useMediaQuery, Icon } from '@material-ui/core';
import Markdown from 'react-markdown';
import { userIsAdmin } from '../../../../utils/globalFunctions';
import { Divider, NoteCard } from '../../styles';
import InfoIcon from '../../../../assets/images/info.svg';
import InvoiceIcon from '../../../../assets/images/invoice.svg';
import { DetailsCardHeader } from '../DetailsCardHeader';
import { DetailsCardSubtitle } from '../DetailsCardSubtitle';
import { DetailsCardItem } from '../DetailsCardItem';
import PATHS from '../../../../config/routePaths';

export function DetailsCard({
  isShowing,
  onCloseCallback,
  details,
  actionClickCallback,
  canEdit,
  top,
  offsetTop,
  loggedUser,
}) {
  const isTablet = useMediaQuery('(max-width:900px)');
  if (!isShowing) return null;
  const {
    physical_store_credit,
    physical_store_debit,
    physical_store_pix,
    physical_store_pic_pay,
    physical_store_voucher,
    online_store_card,
    online_store_cash,
    online_store_online,
    adjusts,
    loan_installment,
    purchase_installment,
    fixed,
    currentBalance,
    finalBalance,
  } = details;

  const total = details.totalValue;
  const subtotal = details.subtotalValue;
  const divergence = details.values.onlineSalesDivergence;
  const formattedDivergence = Math.abs(divergence).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });

  const divergenceMessage = `Nosso sistema registrou um valor ${
    divergence < 0 ? 'adicional' : 'faltante'
  } de ${formattedDivergence} nos POS alocados para a venda online. Dessa forma, a diferença entre os valores foi considerada como ${
    divergence < 0
      ? 'crédito em venda na loja física.'
      : 'dinheiro em venda online.'
  }`;

  const haveDivergence = Math.abs(divergence) >= 0.01;

  const OnlineSalesDivergenceWarning = ({ style }) => {
    if (!userIsAdmin(loggedUser)) {
      return null;
    }
    if (!haveDivergence) {
      return null;
    }
    return (
      <div
        style={{
          marginTop: 8,
          padding: 16,
          backgroundColor: '#ffa726',
          borderRadius: 16,
          ...style,
        }}
      >
        Houve divergência de {formattedDivergence}. Veja mais detalhes no fim
        deste card.
      </div>
    );
  };

  return (
    <React.Fragment key={`details-card-${details?.id}`}>
      <Grow in timeout={isTablet ? 500 : undefined}>
        <Paper
          style={{
            flex: 1,
            borderRadius: '1.5rem',
            display: 'flex',
            flexDirection: 'column',
            padding: '2rem',
            height: 'fit-content',
            position: 'relative',
            top: top - offsetTop,
            ...((isTablet && {
              position: 'fixed',
              right: 0,
              left: 0,
              top: 0,
              height: '100vh',
              overflow: 'auto',
              zIndex: 1300,
            }) || { position: 'relative', margin: '0 1rem 1rem 1rem' }),
          }}
        >
          <DetailsCardHeader
            refDateString={details.dateString}
            transferString={details.transferString}
            onCloseCallback={onCloseCallback}
            actionClickCallback={actionClickCallback}
            canEdit={canEdit}
            companyName={details.companyName}
          />
          <OnlineSalesDivergenceWarning />
          <DetailsCardSubtitle color="#58CDFF" text="Venda Física" />
          <DetailsCardItem
            title="Crédito"
            value={physical_store_credit?.liquidValue}
            grossValue={physical_store_credit?.bruteValue}
            tax={physical_store_credit?.commissionValue}
          />
          <DetailsCardItem
            title="Débito"
            value={physical_store_debit?.liquidValue}
            grossValue={physical_store_debit?.bruteValue}
            tax={physical_store_debit?.commissionValue}
          />
          <DetailsCardItem
            title="PIX"
            value={physical_store_pix?.liquidValue}
            grossValue={physical_store_pix?.bruteValue}
            tax={physical_store_pix?.commissionValue}
          />
          <DetailsCardItem
            title="PicPay"
            value={physical_store_pic_pay?.liquidValue}
            grossValue={physical_store_pic_pay?.bruteValue}
            tax={physical_store_pic_pay?.commissionValue}
          />
          <DetailsCardItem
            title="Voucher"
            value={physical_store_voucher?.liquidValue}
            grossValue={physical_store_voucher?.bruteValue}
            tax={physical_store_voucher?.commissionValue}
          />
          {(online_store_card || online_store_cash || online_store_online) && (
            <>
              <Divider />
              <DetailsCardSubtitle color="#F9B870" text="Venda Online" />
              <DetailsCardItem
                title="Cartão"
                value={online_store_card?.liquidValue}
                grossValue={online_store_card?.bruteValue}
                tax={online_store_card?.commissionValue}
              />
              <DetailsCardItem
                title="Dinheiro"
                value={online_store_cash?.liquidValue}
                grossValue={online_store_cash?.bruteValue}
                tax={online_store_cash?.commissionValue}
              />
              <DetailsCardItem
                title="Pagamento digital"
                value={online_store_online?.liquidValue}
                grossValue={online_store_online?.bruteValue}
                tax={online_store_online?.commissionValue}
              />
            </>
          )}
          {!!(details.values.adjusts || details.values.fixed) && (
            <>
              <Divider />
              <DetailsCardSubtitle color="#192D4D" text="Outros" />
            </>
          )}
          <DetailsCardItem title="Fixo Semanal" value={fixed?.liquidValue} />
          <DetailsCardItem title="Ajustes" value={adjusts?.liquidValue} />
          {details.observation &&
            (details.adjusts ||
              details.values.loan_installment ||
              details.values.purchase_installment) && (
              <NoteCard>
                <img
                  alt="Ícone de informações"
                  src={InfoIcon}
                  style={{
                    height: '1.25rem',
                    width: '1.25rem',
                    margin: '0.5rem',
                  }}
                />
                <span style={{ fontSize: 12, marginLeft: '0.5rem' }}>
                  Descrição de ajustes: <br />{' '}
                  <Markdown>{details.observation}</Markdown>
                </span>
              </NoteCard>
          )}
          {!!(
            details.values.loan_installment ||
            details.values.purchase_installment ||
            currentBalance < 0
          ) && (
            <>
              <Divider />
              <DetailsCardItem
                title="Subtotal"
                value={subtotal}
                fontSize="1rem"
              />
              <Divider />
              <DetailsCardSubtitle color="#EF9A91" text="Descontos" />
            </>
          )}
          <DetailsCardItem
            title="Parcela de empréstimo"
            value={loan_installment?.liquidValue}
          />
          <DetailsCardItem
            title="Parcela de compras"
            value={purchase_installment?.liquidValue}
          />
          {!!(
            details.debitsObservation &&
            (details.values.loan_installment ||
              details.values.purchase_installment)
          ) && (
            <NoteCard>
              <img
                alt="Ícone de informações"
                src={InfoIcon}
                style={{
                  height: '1.25rem',
                  width: '1.25rem',
                  margin: '0.5rem',
                }}
              />
              <span style={{ fontSize: 12, marginLeft: '0.5rem' }}>
                Descrição de descontos: <br />{' '}
                <Markdown>{details.debitsObservation}</Markdown>
              </span>
            </NoteCard>
          )}
          <DetailsCardItem
            title="Saldo anterior"
            value={currentBalance}
          />
          <Divider />
          <DetailsCardItem
            title="Total Líquido"
            value={total}
            fontSize="1rem"
          />
          <DetailsCardItem
            title="Saldo atual"
            value={finalBalance}
          />
          {haveDivergence && (
            <NoteCard style={{ border: '1px solid #ffa726' }}>
              <Icon style={{ color: '#ffa726' }}>warning</Icon>
              <span style={{ fontSize: 12, marginLeft: '0.5rem' }}>
                {divergenceMessage}
              </span>
            </NoteCard>
          )}
          <NoteCard>
            <img
              alt="Ícone de extratos"
              src={InvoiceIcon}
              style={{
                height: '1.25rem',
                width: '1.25rem',
                margin: '0.5rem',
              }}
            />
            <span style={{ fontSize: 12, marginLeft: '0.5rem' }}>
              Clique <Link to={PATHS.FINANCE_EXTRACTS}>aqui</Link> para ir para
              a tela de extratos.
            </span>
          </NoteCard>
        </Paper>
      </Grow>
    </React.Fragment>
  );
}
