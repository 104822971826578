import {
  FETCH_PAYMENT_MACHINE_REGISTERS_SUCCESS,
  FETCH_PAYMENT_MACHINE_REGISTERS_ERROR,
  GET_PAYMENT_MACHINE_REGISTERS,
  showSpinner,
  hideSpinner,
  updatePaymentMachineRegisters,
} from '../actions/paymentMachineRegisters';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'paymentMachines/registers';

export const getPaymentMachineRegistersFlow = ({ dispatch }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === GET_PAYMENT_MACHINE_REGISTERS) {
    dispatch(
      apiRequest(
        'GET',
        action.payload.companiesTab
          ? URL.replace('registers', 'companies')
          : URL,
        action.payload,
        FETCH_PAYMENT_MACHINE_REGISTERS_SUCCESS,
        FETCH_PAYMENT_MACHINE_REGISTERS_ERROR,
        {},
        true,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processPaymentMachineRegistersCollection = ({ dispatch }) => (
  next,
) => (action) => {
  next(action);
  if (action.type === FETCH_PAYMENT_MACHINE_REGISTERS_SUCCESS) {
    dispatch(updatePaymentMachineRegisters(action.payload));
    dispatch(hideSpinner());
  }
  if (action.type === FETCH_PAYMENT_MACHINE_REGISTERS_ERROR) {
    Toastify.addError(
      'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
    );
    dispatch(hideSpinner());
  }
};

export const paymentMachineRegistersMdl = [
  getPaymentMachineRegistersFlow,
  processPaymentMachineRegistersCollection,
];
