export const GET_WEEKLY_TAXES = '[weeklyTaxes] GET';
export const FETCH_WEEKLY_TAXES_SUCCESS = '[weeklyTaxes] Fetch success';
export const FETCH_WEEKLY_TAXES_ERROR = '[weeklyTaxes] Fetch Error';
export const UPDATE_WEEKLY_TAXES = '[weeklyTaxes] Update';
export const SHOW_WEEKLY_TAXES_SPINNER = '[weeklyTaxes - ui] show spinner';
export const HIDE_WEEKLY_TAXES_SPINNER = '[weeklyTaxes - ui] hide spinner';

export const getWeeklyTaxes = (filter) => ({
  type: GET_WEEKLY_TAXES,
  payload: {
    ...filter,
  },
});

export const updateWeeklyTaxes = (data) => ({
  type: UPDATE_WEEKLY_TAXES,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_WEEKLY_TAXES_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_WEEKLY_TAXES_SPINNER,
});
