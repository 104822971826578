import { withStyles, TextField } from '@material-ui/core';
import { DatePicker as MaterialDatePicker } from '@material-ui/pickers';
import styled, { css } from 'styled-components';
import { DatePickersContainerProps } from './interfaces';

export const Container = styled.div`
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

export const DatePicker = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--primary-text-color)',
        borderRadius: '1rem',
        borderWidth: 2,
      },
      '&:hover fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
    },
    color: 'var(--primary-text-color)',
    fontWeight: 500,
  },
})(MaterialDatePicker);

export const StyledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--primary-text-color)',
        borderRadius: '1rem',
        borderWidth: 2,
      },
      '&:hover fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
    },
    color: 'var(--primary-text-color)',
    fontWeight: 500,
  },
})(TextField);

export const DatePickersContainer = styled.div<DatePickersContainerProps>`
  display: flex;

  @media (max-width: 390px) {
    flex-direction: column;
  }

  ${({ isTablet }) =>
    isTablet &&
    css`
      width: 100%;
    `}

  > div {
    ${({ isMobile }) =>
      isMobile &&
      css`
        margin-top: 1rem;
      `}
    ${({ isTablet }) =>
      !isTablet &&
      css`
        max-width: 225px;
      `}
  }

  > div:first-child {
    @media (min-width: 390px) {
      margin-right: 1rem;
    }
  }
`;
