import React from 'react';
import Row from '../Row';

const TableContent = ({
  commissioningsLoading,
  loadingCompanies,
  commissioningsFormatted,
  commissioningLoading,
  companies,
  dispatch,
  handleShowEditModal,
}) => {
  if (commissioningsLoading || loadingCompanies) {
    return null;
  }
  return commissioningsFormatted.map((commissioning) => (
    <Row
      key={commissioning.companyDocument}
      row={commissioning}
      companies={companies}
      commissioningLoading={commissioningLoading}
      dispatch={dispatch}
      handleShowEditModal={handleShowEditModal}
    />
  ));
};

export default TableContent;
