import styled from 'styled-components';
import Spinner from '../Spinner';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSpinner = styled(Spinner)`
  z-index: inherit;
`;
