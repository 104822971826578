import Service from '../Service';

export const importFile = ({ file, isAdmin, update }) => {
  const api = new Service({
    route: update ? '/orderProducts/update' : '/orders/import',
    apiV2: true,
  });
  const form = new FormData();
  form.append('file', file);
  return (update
    ? api.put('', form, { isAdmin: `${isAdmin}` })
    : api.post(form, { isAdmin: `${isAdmin}` })
  )
    .then(({ data, ok, message }) => ({
      ok,
      data,
      error: message,
    }))
    .catch((e) => ({ ok: false, error: e?.response?.data?.message }));
};
