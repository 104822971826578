import React from 'react';
import Lottie from 'react-lottie';
import {
  StyledH1,
  StyledPaper,
  StyledP,
  Container,
  StyledLink,
} from './styles';
import animation from '../../assets/images/process-construction.json';
import PATHS from '../../config/routePaths';

export default function WelcomePage() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container>
      <StyledPaper elevation={0}>
        <div>
          <Lottie options={defaultOptions} height={283} width={282} />
        </div>
        <div>
          <StyledH1>Bem-vindo à Bakerytech!</StyledH1>
          <StyledP>
            Estamos configurando o seu painel e em breve você terá acesso a
            diversos gráficos e indicadores para melhor avaliar sua operação!
          </StyledP>
          <StyledP>
            Enquanto isso, pode acessar no menu lateral a página operacional de
            &nbsp;
            <StyledLink to={PATHS.FINANCE_EXTRACTS}>extratos</StyledLink>.
          </StyledP>
          <StyledP>
            Não se preocupe, você será notificado assim que seu painel estiver
            operacional! Obrigado pela compreensão.
          </StyledP>
        </div>
      </StyledPaper>
    </Container>
  );
}
