import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import HistoricalChartCard from '../../../../sharedComponents/HistoricalChartCard';
import AccumulatedChartCard from '../../../../sharedComponents/AccumulatedChartCard';
import ResumeChartCard from '../../../../sharedComponents/ResumeChartCard';
import { isEmptyObject } from '../../../../utils/globalFunctions';

export const comparisonDays = 6;

const DetailCards = ({ data, isLoading, refDate }) => {
  // Variáveis de responsividade
  const isTablet = useMediaQuery('(max-width:767px)');
  const gridSpacing = isTablet ? 2 : 3;
  const [orderedReceipts, setOrderedReceipts] = useState({});
  const [orderedCustomers, setOrderedCustomers] = useState({});

  useEffect(() => {
    if (!isEmptyObject(data)) {
      setOrderedReceipts(
        Object.keys(data.receiptsSum).sort()
          .reduce((obj, key) => ({ ...obj, [key]: data.receiptsSum[key] }), {}),
      );
      setOrderedCustomers(
        Object.keys(data.customers).sort()
          .reduce((obj, key) => ({ ...obj, [key]: data.customers[key] }), {}),
      );
    }
  }, [data]);

  const skeletonProps = {
    variant: 'rectangular',
    width: '100%',
    height: '100%',
    style: {
      minHeight: 294,
      borderRadius: 24,
    },
  };

  return (
    <div
      style={{ display: 'flex', margin: `${isTablet ? '1rem' : '1.5rem'} 0` }}
    >
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          {isLoading ? (
            <Skeleton {...skeletonProps} />
          ) : (
            <HistoricalChartCard
              values={Object.values(orderedReceipts)}
              labels={Object.keys(orderedReceipts)}
              dataLabel="Faturamento (R$)"
              title={`Faturamento de ${comparisonDays + 1} dias (${moment(refDate)
                .subtract(comparisonDays, 'days')
                .format('DD/MM/YYYY')}
                a ${moment(refDate).format('DD/MM/YYYY')})`}
              description={`com relação ao período anterior (${moment(refDate)
                .subtract(comparisonDays * 2 + 1, 'days')
                .format('DD/MM/YYYY')}
                a ${moment(refDate)
                .subtract(comparisonDays + 1, 'days')
                .format('DD/MM/YYYY')})`}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {isLoading ? (
            <Skeleton {...skeletonProps} style={{ ...skeletonProps.style, minHeight: 366 }} />
          ) : (
            <ResumeChartCard
              values={[
                data.paymentKind.bakery,
                data.paymentKind.cash,
                data.paymentKind.total - data.paymentKind.bakery - data.paymentKind.cash,
              ]}
              labels={['Bakery', 'Dinheiro', 'Outros']}
              dataLabel="Faturamento"
              title="Formas de pagamento"
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {isLoading ? (
            <Skeleton {...skeletonProps} style={{ ...skeletonProps.style, minHeight: 366 }} />
          ) : (
            <AccumulatedChartCard
              values={Object.values(orderedCustomers)}
              labels={Object.keys(orderedCustomers)}
              dataLabel="Total de cadastrados até essa data"
              title="Total de clientes cadastrados"
              description={`desde ${moment(
              Object.keys(orderedCustomers)?.[Object.keys(orderedCustomers).length - 2],
              ).format('DD/MM/YYYY')}`}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default DetailCards;
