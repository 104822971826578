export const GET_ROLES = '[roles] GET';
export const FETCH_ROLES_SUCCESS = '[roles] Fetch success';
export const FETCH_ROLES_ERROR = '[roles] Fetch Error';
export const UPDATE_ROLES = '[roles] Update';
export const SHOW_ROLES_SPINNER = '[roles - ui] show spinner';
export const HIDE_ROLES_SPINNER = '[roles - ui] hide spinner';

export const getRoles = (filter) => ({
  type: GET_ROLES,
  payload: {
    ...filter,
  },
});

export const updateRoles = (data) => ({
  type: UPDATE_ROLES,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_ROLES_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_ROLES_SPINNER,
});
