import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';
import { getCompanies } from '../../redux/actions/companies';
import { getOffers } from '../../redux/actions/offers';

import SectionContent from '../../sharedComponents/SectionContent';
import SectionHeader from '../../sharedComponents/SectionHeader';
import Input from '../../sharedComponents/Input';
import StatelessCompanySelector from '../../sharedComponents/StatelessCompanySelector';
import { userIsAdmin } from '../../utils/globalFunctions';
import ProductGrid from './components/ProductGrid';
import { Container, FilterContainer } from './styles';

const ProductListingPage = () => {
  const companies = useSelector(
    (state) => state.companies.docs || state.companies,
  );
  const loggedUser = useSelector((state) => state.loggedUser);
  const total = useSelector((state) => state.offers?.total || 0);
  const loading = useSelector((state) => state.ui.offersLoading);
  const [query, setQuery] = useState('');

  const allCompanies =
    (userIsAdmin(loggedUser) ? companies : loggedUser.companies) || [];
  const [company, setCompany] = useState(allCompanies[0]);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!company) return;
    dispatch(getOffers({ page, companyDocument: company?.document, query }));
  }, [page, company, query]);

  useEffect(() => {
    if (userIsAdmin(loggedUser)) {
      if (!companies?.length) {
        dispatch(getCompanies({ listAll: true }));
      } else {
        setCompany(companies[0]);
      }
    }
  }, [companies]);

  const handleCompanyChange = (e) => {
    const companyToSelect = allCompanies.find(
      (_company) => _company?.id === e.target.value,
    );
    setCompany(companyToSelect);
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <Container>
      <SectionHeader
        title="Comprador Digital"
        hideDatePicker
        hideCompanySelector
        defaultComponentsContainerStyle={{ marginRight: '1rem' }}
      >
        <FilterContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={8}>
              <Input
                label="Pesquisar..."
                value={query}
                onChange={handleQueryChange}
                icon="search"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <StatelessCompanySelector
                style={{ minWidth: 'auto' }}
                value={company?.id}
                companies={allCompanies}
                onChange={handleCompanyChange}
                disableClearing
                fullWidth
              />
            </Grid>
          </Grid>
        </FilterContainer>
      </SectionHeader>
      <SectionContent>
        {!total && !loading ? (
          <Typography variant="h6" align="center" style={{ marginTop: '5rem' }}>
            Neste momento, não há ofertas para esta padaria, fique atento às
            novidades.
          </Typography>
        ) : (
          <ProductGrid
            page={page}
            setPage={setPage}
            companyDocument={company?.document}
          />
        )}
      </SectionContent>
    </Container>
  );
};

export default ProductListingPage;
