import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Page } from '../../interfaces/pages';
import { RootState } from '../../interfaces/redux';
import { TransitoryValues } from '../../interfaces/audit';
import { Acquirer } from '../../interfaces/acquirers';
import SectionContent from './components/SectionContent';
import { Container } from './styles';
import SectionHeader from '../../sharedComponents/SectionHeader';
import { getTransitoryValues } from '../../redux/actions/transitoryValues';
import { RangeHandler } from '../../sharedComponents/SectionHeader/interfaces';

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const AuditTransitoryValues: React.FC<Page> = ({ loading }) => {
  const dispatch = useDispatch();
  const transitoryValuesList: TransitoryValues[] = useSelector(
    (state: RootState) => state.transitoryValues?.docs,
  );

  const [dateRange, setDateRange] = useState({
    startDate: new Date(
      moment()
        .startOf('day')
        .subtract(1, 'days')
        .toString(),
    ),
    endDate: new Date(
      moment()
        .endOf('day')
        .toString(),
    ),
  });

  const [selectedAcquirer, setSelectedAcquirer] = useState<Acquirer | null>(
    null,
  );
  const [formatedValues, setFormatedValues] = useState<TransitoryValues[]>([]);

  const handleUpdate = () => {
    dispatch(
      getTransitoryValues({
        dateGte: dateRange.startDate.toISOString(),
        dateLte: dateRange.endDate.toISOString(),
        acquirerId: selectedAcquirer?.id,
      }),
    );
  };

  const handleSelectAcquirer = (data: Acquirer | null) => {
    setSelectedAcquirer(data);
  };

  const handleDateRange: RangeHandler = (field) => (date) => {
    setDateRange((currentRange) => ({ ...currentRange, [field]: date }));
  };

  const handleFormat = () => {
    if (transitoryValuesList) {
      setFormatedValues(
        transitoryValuesList.map((item: TransitoryValues) => ({
          acquirerName: item.acquirerName,
          transferDate: new Date(item.transferDate).toLocaleDateString(),
          deposited: currencyFormatter.format(parseFloat(item.deposited)),
          entryTransitory: currencyFormatter.format(
            parseFloat(item.entryTransitory),
          ),
          outTransitory: currencyFormatter.format(
            parseFloat(item.outTransitory),
          ),
          acquirerFee: currencyFormatter.format(parseFloat(item.acquirerFee)),
          bakeryReceipt: currencyFormatter.format(
            parseFloat(item.bakeryReceipt),
          ),
        })),
      );
    }
  };

  useEffect(() => {
    handleUpdate();
  }, [dateRange, selectedAcquirer]);

  useEffect(() => {
    handleFormat();
  }, [transitoryValuesList]);

  return (
    <>
      <Container>
        <SectionHeader
          title={<span>Valores Transitórios</span>}
          hideCompanySelector
          iconButtonStyle={{ padding: '10px' }}
          refDate={dateRange}
          onSelectDateCallback={handleDateRange}
          showDateRange
          style={{ marginBottom: 0 }}
          defaultComponentsContainerStyle={{ width: '100%' }}
          subtitle=""
          hideDatePicker={false}
          subHeaderStyle={{}}
          showAcquirers
          handleSelectAcquirer={handleSelectAcquirer}
        />

        <SectionContent
          loading={loading}
          columnsMap={{
            Adquirente: 'acquirerName',
            'Data do depósito': 'transferDate',
            'Valor depoistado': 'deposited',
            'Transitório de entrada': 'entryTransitory',
            'Transitório de saída': 'outTransitory',
            'Taxa de Adquirente': 'acquirerFee',
            'Receita Bakery': 'bakeryReceipt',
          }}
          rows={formatedValues}
        />
      </Container>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.ui.transitoryValuesLoading,
});

export default connect(mapStateToProps)(AuditTransitoryValues);
