import styled from 'styled-components';
import { TextField, withStyles, Icon } from '@material-ui/core';

export const DateTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--primary-text-color)',
        borderRadius: '1rem',
        borderWidth: 2,
      },
      '&:hover fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
    },
    color: 'var(--primary-text-color)',
    fontWeight: 500,
  },
})(TextField);

export const StyledIcon = styled(Icon)`
  color: #192D4D;
  margin-right: 0.3rem;
`;

export const StyledIconAsButton = styled(StyledIcon)`
  cursor: pointer;
`;

export const StyledIconButton = styled(StyledIcon)`
  padding-left: 0
`;
