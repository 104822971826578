import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../theme';
import {
  Container,
  Card,
  Title,
  Subtitle,
  Description,
  ActionBadgeContainer,
  ActionBadge,
} from './styles';

const TextCard = ({ title, subtitle, description, badges, toLink }) => (
  <Container>
    <Link
      key={title}
      to={toLink || '/comingSoon'}
      style={{ textDecoration: 'none', color: colors.primary.main }}
    >
      <Card onClick={(event) => console.log('hey')}>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Description>{description}</Description>
        <ActionBadgeContainer>
          {badges &&
            badges.map((button, index) => (
              <ActionBadge
                backgroundColor={button.color}
                key={`action-badge-container-${index}`}
              >
                {button.label}
              </ActionBadge>
            ))}
        </ActionBadgeContainer>
      </Card>
    </Link>
  </Container>
);

export default TextCard;
