import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  TextField,
  FormGroup,
  Button,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  Switch,
  Grid,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import Alert from '../../../sharedComponents/Alert/index';
import Spinner from '../../../sharedComponents/Spinner/index';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
  CheckBoxContainer,
} from './styles';
import { getGroups } from '../../../redux/actions/groups';
import {
  getCompetitor,
  createCompetitor,
  updateCompetitor,
} from '../../../redux/actions/competitor';
import { cnpjValidation, sanitizeCNPJ } from '../../../utils/globalFunctions';
import { competitorKindMap } from '../../../config/consts';

const styles = {
  formButton: {
    margin: 2,
  },
};

const CompetitorModal = ({
  id,
  dispatch,
  competitor,
  competitorModal,
  handleClose,
  competitorSpinner,
}) => {
  const [active, setActive] = useState(true);
  const [name, setName] = useState('');
  const [infoPriceCode, setInfoPriceCode] = useState('');
  const [infoPriceCodeError, setInfoPriceCodeError] = useState(false);
  const [document, setDocument] = useState('');
  const [documentError, setDocumentError] = useState('');
  const [competitorKind, setCompetitorKind] = useState('');
  const [competitorKindError, setCompetitorKindError] = useState(false);
  const [corporateName, setCorporateName] = useState('');
  const [fantasyName, setFantasyName] = useState('');
  const [addressStreet, setAddressStreet] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [district, setDistrict] = useState('');
  const [cep, setCep] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUf] = useState('');

  const [nameError, setNameError] = useState(false);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getCompetitor(id));
      dispatch(getGroups({ listAll: true }));
    }
  }, [id]);

  useEffect(() => {
    if (competitor && id !== 'new') {
      setActive(competitor.active);
      setName(competitor.name);
      setInfoPriceCode(competitor.code || '');
      setDocument(competitor.document || '');
      setCompetitorKind(competitor.kind || '');
      setCorporateName(competitor.social_reason || '');
      setFantasyName(competitor.fantasy_name || '');
      setAddressStreet(competitor.address_street || '');
      setAddressNumber(competitor.address_number || '');
      setDistrict(competitor.district || '');
      setCep(competitor.cep || '');
      setCity(competitor.city || '');
      setUf(competitor.state || '');
    }
  }, [competitor]);

  useEffect(() => {
    if (!competitorModal) {
      handleClose();
    }
  }, [competitorModal]);

  const handleDocumentErrorMessage = document
    ? 'CNPJ inválido!'
    : 'CNPJ é obrigatório';

  const handleSave = async () => {
    let err = false;
    setNameError(!name);
    setCompetitorKindError(!competitorKind);
    setInfoPriceCodeError(!infoPriceCode);
    const cnpjWithError = !cnpjValidation(document);
    setDocumentError(cnpjWithError);
    err = !name || cnpjWithError || !competitorKind || !infoPriceCode;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(
            createCompetitor({
              active,
              name,
              document,
              kind: competitorKind,
              code: Number(infoPriceCode),
              social_reason: corporateName,
              fantasy_name: fantasyName,
              address_street: addressStreet,
              address_number: addressNumber,
              district,
              cep,
              city,
              state: uf,
            }),
          );
        } else {
          dispatch(
            updateCompetitor({
              id,
              active,
              name,
              document,
              kind: competitorKind,
              code: Number(infoPriceCode),
              social_reason: corporateName,
              fantasy_name: fantasyName,
              address_street: addressStreet,
              address_number: addressNumber,
              district,
              cep,
              city,
              state: uf,
            }),
          );
        }
      }
    }
  };

  const handleDocument = (e) => {
    const { value } = e.target;
    setDocument(sanitizeCNPJ(value));
  };

  return (
    <>
      {competitorSpinner && <Spinner overlay />}
      <Container style={{ opacity: competitorSpinner ? 0.5 : 1 }}>
        <Paper
          elevation={2}
          fullWidth
          style={{
            maxHeight: '95vh',
            overflow: 'auto',
            minWidth: '60vw',
            padding: 20,
          }}
        >
          <ModalTitle>
            {id === 'new' ? 'Adicionar' : 'Editar'} Estabelecimento
          </ModalTitle>
          <FormGroup>
            <CheckBoxContainer>
              <CheckboxContent>
                <FormControlLabel
                  control={
                    <Switch
                      checked={active}
                      onChange={(e) => setActive(e.target.checked)}
                    />
                  }
                  label="Ativo"
                  labelPlacement="start"
                />
              </CheckboxContent>
            </CheckBoxContainer>
            <InputContent>
              <TextField
                id="name-input"
                label="Nome*"
                type="text"
                error={nameError}
                helperText={nameError && 'Nome é obrigatório'}
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </InputContent>
            <InputContent>
              <TextField
                id="infoprice-code-input"
                label="Código infoprice*"
                type="text"
                variant="outlined"
                value={infoPriceCode}
                onChange={(e) => setInfoPriceCode(e.target.value)}
                fullWidth
                error={infoPriceCodeError}
                helperText={infoPriceCodeError && 'Código do estabelecimento é obrigatório'}
              />
            </InputContent>
            <InputContent>
              <InputMask
                mask="99.999.999/9999-99"
                type="text"
                value={document}
                onChange={handleDocument}
                disabled={false}
                maskChar=" "
              >
                {() => (
                  <TextField
                    id="document-input"
                    label="CNPJ*"
                    type="text"
                    variant="outlined"
                    fullWidth
                    error={documentError}
                    helperText={documentError && handleDocumentErrorMessage}
                  />
                )}
              </InputMask>
            </InputContent>
            <InputContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-label-2">
                  Tipo de Estabelecimento*
                </InputLabel>
                <Select
                  labelId="select-label-2"
                  id="select-label-component-2"
                  value={competitorKind}
                  variant="outlined"
                  onChange={(e) => {
                    setCompetitorKind(e.target.value);
                  }}
                  error={competitorKindError}
                  fullWidth
                >
                  {Object.keys(competitorKindMap).map((_competitorKind) => (
                    <MenuItem key={_competitorKind} value={_competitorKind}>
                      {competitorKindMap[_competitorKind]}
                    </MenuItem>
                  ))}

                </Select>
                {competitorKindError && (
                  <FormHelperText>
                    Tipo de Estabelecimento é obrigatório
                  </FormHelperText>
                )}
              </FormControl>
            </InputContent>
            <InputContent>
              <TextField
                id="corporate-name-input"
                label="Razão social"
                type="text"
                variant="outlined"
                value={corporateName}
                onChange={(e) => setCorporateName(e.target.value)}
                fullWidth
              />
            </InputContent>
            <InputContent>
              <TextField
                id="fantasy-name-input"
                label="Nome fantasia"
                type="text"
                variant="outlined"
                value={fantasyName}
                onChange={(e) => setFantasyName(e.target.value)}
                fullWidth
              />
            </InputContent>
            <Grid container>
              <Grid item xs={12} md={8}>
                <InputContent>
                  <TextField
                    id="address-input"
                    label="Logradouro (rua, avenida etc)"
                    type="text"
                    variant="outlined"
                    value={addressStreet}
                    onChange={(e) => setAddressStreet(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputContent>
                  <TextField
                    id="number-input"
                    label="Número"
                    type="text"
                    variant="outlined"
                    value={addressNumber}
                    onChange={(e) => setAddressNumber(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={8}>
                <InputContent>
                  <TextField
                    id="district-input"
                    label="Bairro"
                    type="text"
                    variant="outlined"
                    value={district}
                    onChange={(e) => setDistrict(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputContent>
                  <TextField
                    id="cep-input"
                    label="CEP"
                    type="text"
                    variant="outlined"
                    value={cep}
                    onChange={(e) => setCep(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={10}>
                <InputContent>
                  <TextField
                    id="city-input"
                    label="Cidade"
                    type="text"
                    variant="outlined"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={2}>
                <InputContent>
                  <TextField
                    id="uf-input"
                    label="UF"
                    type="text"
                    variant="outlined"
                    value={uf}
                    onChange={(e) => setUf(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
            </Grid>
            <ButtonContainer>
              <Button
                color="secondary"
                onClick={handleClose}
                variant="contained"
                style={styles.formButton}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={handleSave}
                variant="contained"
                style={styles.formButton}
              >
                Salvar
              </Button>
            </ButtonContainer>
          </FormGroup>
        </Paper>
      </Container>
    </>
  );
};

export default connect((state) => ({
  competitor: state.competitor,
  competitorModal: state.ui.competitorModal,
  competitorSpinner: state.ui.competitorLoading,
}))(CompetitorModal);
