import styled from 'styled-components';
import {
  IconButton,
  Button,
  Paper,
  TextField,
  withStyles,
  TableCell,
  Tab,
  Tabs,
  TablePagination,
  Table,
  TableRow,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AddRounded,
  KeyboardArrowRight,
  CheckCircleOutline,
  HighlightOffOutlined,
} from '@material-ui/icons';

export const Container = styled.div`
  margin-top: 2rem;
`;

export const StyledTab = styled(Tab)`
  width: 11.25rem;
  height: 3.438rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 1rem 2rem;
  background-color: ${({ color }) => color}33;
  border: 1px solid ${({ color }) => color}33;
  color: white;
  font-size: 0.75rem;
  font-weight: 500;
  transition: 0.4s;
  color: #192d4d;

  opacity: 1;
  &:not(.Mui-selected):hover {
    border: 1px solid ${({ color }) => color};
  }
  &.Mui-selected {
    background-color: ${({ color }) => color};
    border: 1px solid ${({ color }) => color};
    color: #fff;
  }
`;

export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    display: none;
  }
`;

export const StyledTablePagination = styled(TablePagination)`
  color: #192d4d;
`;

export const StyledPaper = styled(Paper)`
  padding: 2rem;
`;

export const FiltersTitle = styled.span`
  color: #192d4d;
  font-size: 0.75rem;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  margin-top: 0.5rem;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  width: 9rem;
  height: 3rem;
  background-color: #192d4d33;
  color: #192d4d;
  border-radius: 1.5rem;
  font-size: 0.875rem;
  float: right;
  transition: 0.4s;

  &:hover {
    background-color: #192d4d23;
  }
`;

export const StyledTextField = withStyles({
  root: {
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 18px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '1.25rem',
        borderWidth: 1,
        height: '3.5rem',
      },
    },
    color: 'var(--primary-text-color)',
    minWidth: '12rem',
    fontWeight: 500,
  },
})(TextField);

export const useStyles = makeStyles(() => ({
  row: {
    padding: 0,
    '& > *': {
      borderBottom: 'unset',
    },
  },
  cell: {
    fontSize: 12,
    padding: '0px 15px',
  },
}));

export const StyledMenu = withStyles({
  paper: {
    backgroundColor: '#ECEAF3',
    padding: 4,
  },
  list: {
    color: '#464255',
    padding: 0,
  },
})(Menu);

export const Divider = styled.div`
  height: 1px;
  background-color: #d0d6de;
`;

export const StyledMenuItem = withStyles({
  root: {
    borderRadius: 4,
    height: 38,
    '&:hover': {
      backgroundColor: '#192D4D33',
    },
  },
})(MenuItem);

export const StyledAddRounded = styled(AddRounded)`
  margin-right: 0.5rem;
`;

export const StyledTableCell = styled(TableCell)`
  text-align: center;
  color: ${({ isHeader }) => (isHeader ? '#192D4D' : '#464255')};
`;

export const StatusTableCell = styled.div`
  padding: 0.2rem 1rem;
  background-color: ${({ colors }) => colors?.background};
  color: ${({ colors }) => colors?.text};
  border-radius: 0.5rem;
  font-size: 1rem;
  width: fit-content;
  margin: auto;
`;

export const ExpandIcon = styled(KeyboardArrowRight)`
  transition: 400ms ease;
  transform: rotate(${({ open }) => (open ? 90 : 0)}deg);
`;

export const StyledTable = styled(Table)`
  background-color: #fff;
`;

export const StyledTableRow = styled(TableRow)`
  width: 100%;
`;

export const StyledCheckCircleOutline = styled(CheckCircleOutline)`
  color: #00a389;
`;

export const StyledHighlightOffOutlined = styled(HighlightOffOutlined)`
  color: #ef9a91;
`;

export const ModalContainer = styled(Paper)`
  padding: 2rem;
  height: fit-content;
  margin: auto auto;
  border-radius: 0.5rem;
  position: relative;
`;

export const CloseButton = styled(IconButton).attrs({ size: 'small' })`
  position: absolute;
  top: 2rem;
  right: 2rem;
`;
