import React from 'react';
import Container from '../../sharedComponents/Container';
import SectionContent from '../../sharedComponents/SectionContent';
import SectionHeader from '../../sharedComponents/SectionHeader';

const Wholeretail = () => (
  <Container id="section-container">
    <SectionHeader
      title="Preços Atacarejos"
      hideDatePicker
    />
    <SectionContent>
      <span>Sem dados disponíveis</span>
    </SectionContent>
  </Container>
);

export default Wholeretail;
