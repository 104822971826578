import {
  Button,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  IconButton,
  Chip,
  Icon,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { ButtonContainer, Container, InputContent, ModalTitle } from './styles';

const styles = {
  formButton: {
    margin: 2,
  },
};

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

function BulkOperationModal({
  title,
  operationText,
  companies,
  handleClose,
  handleOperation,
  children,
}) {
  const classes = useStyles();
  const [refDate, setRefDate] = useState(null);
  const [companyDocuments, setCompanyDocuments] = useState(
    companies.map((company) => company.document),
  );

  const getSelectedLabel = (value) => {
    if (companies && companies.length > 0) {
      return companies.find((item) => item.document === value)?.name;
    }
    return '';
  };

  return (
    <>
      <Paper elevation={2} style={{ height: 'fit-content' }} fullwidth>
        <Container>
          <ModalTitle>
            <span>{title}</span>
            <IconButton
              onClick={handleClose}
              style={{ marginLeft: '0.5rem', padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </ModalTitle>
          <FormGroup>
            <FormControl
              variant="outlined"
              style={{
                margin: '0.5rem 1rem',
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Padarias *
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                multiple
                value={companyDocuments}
                onChange={(event) => setCompanyDocuments(event.target.value)}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((item) => (
                      <Chip
                        key={item}
                        label={getSelectedLabel(item)}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
              >
                {companies.map((company) => (
                  <MenuItem value={company.document} key={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <InputContent>
              <DatePicker
                label={!refDate ? 'Data de Referência *' : ''}
                inputVariant="outlined"
                value={refDate}
                onChange={setRefDate}
                ampm={false}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                minDate={new Date('2021-01-01T00:00:00.000-03:00')}
                fullWidth
                InputLabelProps={{
                  hidden: true,
                  shrink: false,
                  style: {
                    marginLeft: '3rem',
                  },
                }}
                autoOk
                TextFieldComponent={(props) => (
                  <TextField
                    {...props}
                    InputProps={{
                      startAdornment: (
                        <IconButton
                          style={{ paddingLeft: 0 }}
                        >
                          <Icon className="material-icons-outlined">
                            calendar_today
                          </Icon>
                        </IconButton>
                      ),
                      endAdornment: (
                        <IconButton
                          style={{ paddingRight: 0 }}
                        >
                          <Icon>expand_more</Icon>
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </InputContent>

            {children}

            <ButtonContainer>
              <Button
                color="secondary"
                onClick={handleClose}
                variant="contained"
                style={styles.formButton}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={() => handleOperation({ companyDocuments, refDate })}
                variant="contained"
                style={styles.formButton}
                disabled={!refDate || !companyDocuments.length}
              >
                {operationText}
              </Button>
            </ButtonContainer>
          </FormGroup>
        </Container>
      </Paper>
    </>
  );
}

export default BulkOperationModal;
