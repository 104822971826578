import React, { useState } from 'react';
import { Grid, Icon, useMediaQuery } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Container from '../../sharedComponents/Container';
import {
  adminGridItems,
  auditGridItems,
  reportGridItems,
} from '../../sharedComponents/Navigation/components/MenuDrawer/menuItems';
import SectionContent from '../../sharedComponents/SectionContent';
import SectionHeader from '../../sharedComponents/SectionHeader';
import { GridContainer, GridSpacer, ListIcon, styles } from './styles';
import { hasPermission } from '../../utils/globalFunctions';

const allowedModule = (module) => hasPermission(module.path);

const AdminPanel = () => (
  <Container id="section-container">
    <SectionHeader title="Painel Admin" hideDatePicker hideCompanySelector />
    <SectionContent>
      <Grid container spacing={2}>
        {adminGridItems.filter(allowedModule).map((item) => (
          <AdminGridItem key={item.label} item={item} />
        ))}
      </Grid>
    </SectionContent>
    <GridSpacer />
    <SectionHeader title="Relatórios" hideDatePicker hideCompanySelector />
    <Grid container spacing={2}>
      {reportGridItems.filter(allowedModule).map((item) => (
        <AdminGridItem key={item.label} item={item} />
      ))}
    </Grid>
  </Container>
);

const AdminGridItem = ({ item }) => {
  const [isHovered, setIsHovered] = useState(false);
  const isTablet = useMediaQuery('(max-width:900px)');

  return (
    <Grid item xs={12} sm={6} md={3} key={item.label}>
      <Link to={item.path} style={styles.linkFont}>
        <GridContainer
          isHovered={isHovered}
          onMouseEnter={() => !isTablet && setIsHovered(true)}
          onMouseLeave={() => !isTablet && setIsHovered(false)}
        >
          <ListIcon style={styles.drawerIconStyle}>
            <Icon>{item.icon}</Icon>
          </ListIcon>
          <span>{item.label}</span>
        </GridContainer>
      </Link>
    </Grid>
  );
};

export default AdminPanel;
