export const GET_BAKERY_ADDITIONAL_INFORMATIONS =
  '[BAKERY ADDITIONAL INFORMATIONS] GET';
export const FETCH_BAKERY_ADDITIONAL_INFORMATIONS_SUCCESS =
  '[BAKERY ADDITIONAL INFORMATIONS] Fetch success';
export const FETCH_BAKERY_ADDITIONAL_INFORMATIONS_ERROR =
  '[BAKERY ADDITIONAL INFORMATIONS] Fetch Error';
export const UPDATE_BAKERY_ADDITIONAL_INFORMATIONS =
  '[BAKERY ADDITIONAL INFORMATIONS] Update';
export const SHOW_BAKERY_ADDITIONAL_INFORMATIONS_SPINNER =
  '[BAKERY ADDITIONAL INFORMATIONS - ui] show spinner';
export const HIDE_BAKERY_ADDITIONAL_INFORMATIONS_SPINNER =
  '[BAKERY ADDITIONAL INFORMATIONS - ui] hide spinner';

export const getBakeryAdditionalInformations = (filter) => ({
  type: GET_BAKERY_ADDITIONAL_INFORMATIONS,
  payload: filter,
});

export const updateBakeryAdditionalInformations = (data) => ({
  type: UPDATE_BAKERY_ADDITIONAL_INFORMATIONS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_BAKERY_ADDITIONAL_INFORMATIONS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_BAKERY_ADDITIONAL_INFORMATIONS_SPINNER,
});
