import { IconButton, TableCell } from '@material-ui/core';
import { EditRounded } from '@material-ui/icons';
import styled from 'styled-components';

export const StyledTableCell = styled(TableCell)`
  font-size: 12px;
  padding: 10px 15px;
`;

export const StyledTableCellContent = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
`;

export const StyledEditRounded = styled(EditRounded)`
  width: 20px;
  height: 20px;
`;

export const StyledInput = styled.form`
  width: 100%;
  padding: 5px 10px 10px 10px;
`;
