import React, { useState } from 'react';
import {
  Paper,
  Button,
  Icon,
  makeStyles,
  Grid,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import SubHeader from '../../sharedComponents/SubHeader';
import DropZone from '../../sharedComponents/DropZone';
import { download } from '../../services/Download';
import { Container, SubContainer } from './styles';
import { userIsAdmin } from '../../utils/globalFunctions';
import CompanySelector from '../../sharedComponents/CompanySelector';
import Input from '../../sharedComponents/Input';
import { MONTHS } from '../../config/consts';
import { checkExistence, importFile } from '../../services/ABCAnalysis';
import Toastify from '../../utils/Toastify/index';
import Alert from '../../sharedComponents/Alert';
import { updateObjectCompany } from '../../redux/actions/company';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '1rem',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '1rem',
    padding: theme.spacing(1),
  },
  button: {
    display: 'flex',
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1),
  },
  errorMsg: {
    marginTop: -16,
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
}));

function ABCAnalysesImport() {
  const classes = useStyles();
  const loggedUser = useSelector((state) => state.loggedUser);
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState([]);
  const company = useSelector((state) => state.company);
  const dispatch = useDispatch();

  const handleDownload = () => {
    download(
      'ABCAnalyses/template',
      { isAdmin: userIsAdmin(loggedUser) },
      {
        responseType: 'blob',
        filename: 'Template_Importacao_ABC_1.0.0',
        fileExtension: 'xlsx',
        forceDefaultDownloaded: true,
      },
    );
  };

  function getOverWriteConfirmation(exists) {
    if (!exists) return true;
    return Alert(
      'Deseja continuar?',
      'Um arquivo já foi importado para a combinação Padaria/Ano/Mês, se ' +
        'continuar os dados antigos serão considerados obsoletos.',
    ).then(({ confirm }) => confirm);
  }

  // eslint-disable-next-line consistent-return
  const handleImport = async () => {
    const tempErrors = [];
    if (!file) tempErrors.push('file');
    if (!company || !company.document) tempErrors.push('company');
    if (month === '' || month === undefined || month === null) {
      tempErrors.push('month');
    }
    if (!year) tempErrors.push('year');
    if (tempErrors.length) return setErrors(tempErrors);
    const isAdmin = userIsAdmin(loggedUser);
    const alreadyExists = await checkExistence({
      company: company?.document,
      month,
      year,
      isAdmin,
    });
    if (!alreadyExists.ok) {
      Toastify.addError(
        <span>
          Ocorreu um erro ao checar por importações anteriores.
          <br />
          {alreadyExists.error}
        </span>,
      );
      // eslint-disable-next-line consistent-return
      return;
    }
    const goAhead = await getOverWriteConfirmation(alreadyExists.exists);
    // eslint-disable-next-line consistent-return
    if (!goAhead) return;

    const result = await importFile({
      file,
      company: company.document,
      month,
      year,
      isAdmin,
    });

    if (!result.ok) {
      Toastify.addError(
        <span>
          Ocorreu um erro na importação dos dados.
          <br />
          {alreadyExists.error}
        </span>,
      );
      // eslint-disable-next-line consistent-return
      return;
    }

    Toastify.addSuccess(
      <span>
        Arquivo importado com sucesso.
        <br />
        Foram adicionados(s) {result.data.count} novo(s) registro(s).
        {result.data.obsoleted && (
          <>
            <br />
            {result.data.obsoleted} passaram a ser considerado(s) obsoleto(s).
          </>
        )}
      </span>,
    );
    setMonth(new Date().getMonth());
    setYear(new Date().getFullYear());
    setFile(null);
    dispatch(updateObjectCompany({}));
  };

  return (
    <Container>
      <SubHeader title="Importar Curva ABC">
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Icon>file_download</Icon>}
          onClick={handleDownload}
        >
          Baixar Template
        </Button>
      </SubHeader>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <SubContainer>
            <center>
              <DropZone file={file} onChangeFile={setFile} format="xlsx" />
              {errors.includes('file') && (
                <FormHelperText error className={classes.errorMsg}>
                  Selecione um arquivo para enviar
                </FormHelperText>
              )}
            </center>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <CompanySelector
                  fullWidth
                  disableClearing
                  error={errors.includes('company') && 'Selecione uma padaria'}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Input
                  icon="calendar_month"
                  label="Ano"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  fullWidth
                  error={
                    errors.includes('year') && 'Selecione o ano de referência'
                  }
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Input
                  icon="today"
                  label="Mês"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  disableClearing
                  select
                  fullWidth
                  error={
                    errors.includes('month') && 'Selecione o mês de referência'
                  }
                >
                  {MONTHS.map((m, i) => (
                    <MenuItem value={i} key={m}>
                      {m}
                    </MenuItem>
                  ))}
                </Input>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              onClick={handleImport}
              className={classes.button}
            >
              Importar
            </Button>
          </SubContainer>
        </Paper>
      </div>
    </Container>
  );
}

export default ABCAnalysesImport;
