import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  TextField,
  FormGroup,
  Button,
  FormControlLabel,
  Switch,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  IconButton,
} from '@material-ui/core';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import Alert from '../../../sharedComponents/Alert/index';
import Spinner from '../../../sharedComponents/Spinner/index';
import { userIsAdmin } from '../../../utils/globalFunctions';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
  UploadTitle,
  UploadSubTitle,
  InputFile,
  UploadLabel,
  UploadContainer,
  FileContainer,
  FileRemove,
  FileSelectedContainer,
} from './styles';

import {
  getProduct,
  showSpinner,
} from '../../../redux/actions/product';

import { createProductService, updateProductService } from '../../../services/Products';

import marketStructureData from './data';

const styles = {
  formButton: {
    margin: 2,
  },
  photoIcon: {
    fontSize: 48,
  },
  iconButton: {
    width: 0,
    height: 0,
    alignSelf: 'center',
  },
};

const ProductModal = ({
  id,
  dispatch,
  product,
  productModal,
  handleClose,
  productSpinner,
  loggedUser,
  handleSuccess,
  handleError,
}) => {
  const [active, setActive] = useState(true);
  const [description, setDescription] = useState('');
  const [productName, setProductName] = useState('');
  const [marketStructure, setMarketStructure] = useState('');
  const [subMarketStructure, setSubMarketStructure] = useState('');
  const [eanCode, setEanCode] = useState('');
  const [type, setType] = useState('');
  const [category, setCategory] = useState('');
  const [files, setFiles] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [picturesToDelete, setPicturesToDelete] = useState([]);

  const [productNameError, setProductNameError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [marketStructureError, setMarketStructureError] = useState(false);
  const [subMarketStructureError, setSubMarketStructureError] = useState(false);

  const handleFile = (event) => {
    setFiles(Array.from(event.target.files));
  };

  const handleFileRemove = (fileIndex) => {
    setFiles((prevFiles) => {
      const newFilesArray = [...prevFiles];
      newFilesArray.splice(fileIndex, 1);
      return newFilesArray;
    });
  };

  const handlePictureDelete = (pictureIndex) => {
    setPicturesToDelete((prevPicturesToRemove) => {
      const newPicturesToRemoveArray = [...prevPicturesToRemove];
      newPicturesToRemoveArray.push(pictures[pictureIndex].id.toString());
      return newPicturesToRemoveArray;
    });

    setPictures((prevPictures) => {
      const newPicturesArray = [...prevPictures];
      newPicturesArray.splice(pictureIndex, 1);
      return newPicturesArray;
    });
  };

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getProduct(id));
    }
  }, [id]);

  useEffect(() => {
    if (product && id !== 'new') {
      setActive(product.active);
      setDescription(product.description || '');
      setProductName(product.name || '');
      setMarketStructure(product.market_structure || '');
      setSubMarketStructure(product.sub_market_structure || '');
      setEanCode(product.ean_code || '');
      setType(product.type || '');
      setCategory(product.category || '');
      setPictures(product.pictures || []);
    }
  }, [product]);

  useEffect(() => {
    if (!productModal) {
      handleClose();
    }
  }, [productModal]);

  const handleSave = async () => {
    let err = false;
    setProductNameError(!productName);
    setTypeError(!type);
    setCategoryError(!category);
    setMarketStructureError(!marketStructure);
    setSubMarketStructureError(!subMarketStructure);

    err =
      !productName ||
      !type ||
      !category ||
      !marketStructure ||
      !subMarketStructure;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        dispatch(showSpinner());
        if (id === 'new') {
          const res = await createProductService({
            files,
            payload: {
              active,
              description,
              name: productName,
              market_structure: marketStructure,
              sub_market_structure: subMarketStructure,
              ean_code: eanCode,
              type,
              category,
            },
            isAdmin: userIsAdmin(loggedUser),
            dispatch,
          });
          if (!res.ok) {
            handleError();
          } else {
            handleSuccess();
          }
        } else {
          const res = await updateProductService({
            files,
            payload: {
              id,
              active,
              description,
              name: productName,
              market_structure: marketStructure,
              sub_market_structure: subMarketStructure,
              ean_code: eanCode,
              type,
              category,
              picturesToDelete,
            },
            isAdmin: userIsAdmin(loggedUser),
            dispatch,
          });
          if (!res.ok) {
            handleError();
          } else {
            handleSuccess();
          }
        }
      }
    }
  };

  return (
    <>
      {productSpinner && <Spinner overlay />}
      <Container style={{ opacity: productSpinner ? 0.5 : 1 }}>
        <Paper
          elevation={2}
          fullWidth
          style={{
            maxHeight: '95vh',
            overflow: 'auto',
            minWidth: '60vw',
            padding: 20,
          }}
        >
          <ModalTitle>
            {id === 'new' ? 'Adicionar' : 'Editar'} Produto
          </ModalTitle>
          <FormGroup>
            <CheckboxContent>
              <FormControlLabel
                control={
                  <Switch
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                  />
                }
                label="Ativo"
                labelPlacement="start"
              />
            </CheckboxContent>
            <InputContent>
              <TextField
                id="outlined-password-input"
                label="Nome*"
                type="text"
                error={productNameError}
                helperText={productNameError && 'Nome é obrigatório'}
                variant="outlined"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                fullWidth
              />
            </InputContent>
            <InputContent>
              <TextField
                id="outlined-password-input"
                label="EAN"
                type="text"
                variant="outlined"
                value={eanCode}
                onChange={(e) => setEanCode(e.target.value)}
                fullWidth
              />
            </InputContent>
            <InputContent>
              <TextField
                id="outlined-password-input"
                label="Descrição"
                type="text"
                variant="outlined"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
              />
            </InputContent>
            <InputContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-label-2">
                  Estrutura Mercadológica*
                </InputLabel>
                <Select
                  labelId="select-label-2"
                  id="select-label-component-2"
                  value={marketStructure}
                  variant="outlined"
                  onChange={(e) => {
                    setMarketStructure(e.target.value);
                    setSubMarketStructure('');
                  }}
                  fullWidth
                  error={marketStructureError}
                >
                  {Object.keys(marketStructureData).map((_marketStructure) => (
                    <MenuItem key={_marketStructure} value={_marketStructure}>
                      {_marketStructure}
                    </MenuItem>
                  ))}
                </Select>
                {marketStructureError && (
                  <FormHelperText>
                    Estrutura mercadológica é obrigatório
                  </FormHelperText>
                )}
              </FormControl>
            </InputContent>
            <InputContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-label-2">
                  Sub Estrutura Mercadológica*
                </InputLabel>
                <Select
                  labelId="select-label-2"
                  id="select-label-component-2"
                  value={subMarketStructure}
                  variant="outlined"
                  onChange={(e) => setSubMarketStructure(e.target.value)}
                  fullWidth
                  error={subMarketStructureError}
                >
                  {!marketStructureData[marketStructure]
                    ? null
                    : marketStructureData[marketStructure].map(
                      (_subMarketStructure) => (
                        <MenuItem
                          key={_subMarketStructure}
                          value={_subMarketStructure}
                        >
                          {_subMarketStructure}
                        </MenuItem>
                      ),
                    )}
                </Select>
                {subMarketStructureError && (
                  <FormHelperText>
                    SubEstrutura mercadológica é obrigatório
                  </FormHelperText>
                )}
              </FormControl>
            </InputContent>
            <InputContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-label-2">Tipo*</InputLabel>
                <Select
                  labelId="select-label-2"
                  id="select-label-component-2"
                  value={type}
                  variant="outlined"
                  onChange={(e) => setType(e.target.value)}
                  fullWidth
                  error={typeError}
                >
                  <MenuItem value="Notáveis">Notáveis</MenuItem>
                  <MenuItem value="Icônicos">Icônicos</MenuItem>
                  <MenuItem value="Revenda">Revenda</MenuItem>
                  <MenuItem value="Uso e consumo Padaria">
                    Uso e consumo Padaria
                  </MenuItem>
                  <MenuItem value="Kits de Produtos">Kits de Produtos</MenuItem>
                </Select>
                {typeError && (
                  <FormHelperText>Tipo é obrigatório</FormHelperText>
                )}
              </FormControl>
            </InputContent>
            <InputContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-label-2">Categoria*</InputLabel>
                <Select
                  labelId="select-label-2"
                  id="select-label-component-2"
                  value={category}
                  variant="outlined"
                  onChange={(e) => setCategory(e.target.value)}
                  fullWidth
                  error={categoryError}
                >
                  <MenuItem value="A">A</MenuItem>
                  <MenuItem value="B">B</MenuItem>
                  <MenuItem value="C">C</MenuItem>
                  <MenuItem value="D">D</MenuItem>
                  <MenuItem value="E">E</MenuItem>
                  <MenuItem value="Outros">Outros</MenuItem>
                </Select>
                {categoryError && (
                  <FormHelperText>Categoria é obrigatório</FormHelperText>
                )}
              </FormControl>
            </InputContent>
            <FormControl variant="outlined">
              <UploadContainer>
                <UploadLabel htmlFor="icon-button-file">
                  <InputFile
                    onChange={handleFile}
                    id="icon-button-file"
                    type="file"
                    multiple
                  />
                  <UploadTitle>Fotos</UploadTitle>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    style={styles.iconButton}
                  >
                    <AddPhotoAlternateIcon style={styles.photoIcon} />
                  </IconButton>
                  <UploadSubTitle>Apenas permitidos arquivos jpg, jpeg e png com tamanho menor que 0.5Mb</UploadSubTitle>
                </UploadLabel>
                <FileContainer>
                  {files.length > 0 ? <UploadSubTitle>Fotos Selecionadas ({files.length} Fotos):</UploadSubTitle> : null}
                  {files.map((file, index) =>
                    <FileSelectedContainer key={file.name}>
                      <h3>{file.name}</h3>
                      <FileRemove onClick={() => handleFileRemove(index)}>X</FileRemove>
                    </FileSelectedContainer>,
                  )}
                  {pictures.length > 0 ? <UploadSubTitle>Editar Imagens:</UploadSubTitle> : null}
                  {pictures.map((picture, index) =>
                    <FileSelectedContainer key={picture.url}>
                      <a href={picture.url}>{picture.url}</a>
                      <FileRemove onClick={() => handlePictureDelete(index)}>X</FileRemove>
                    </FileSelectedContainer>,
                  )}
                </FileContainer>
              </UploadContainer>
            </FormControl>

            <ButtonContainer>
              <Button
                color="secondary"
                onClick={handleClose}
                variant="contained"
                style={styles.formButton}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={handleSave}
                variant="contained"
                style={styles.formButton}
              >
                Salvar
              </Button>
            </ButtonContainer>
          </FormGroup>
        </Paper>
      </Container>
    </>
  );
};

export default connect((state) => ({
  product: state.product,
  productModal: state.ui.productModal,
  productSpinner: state.ui.productLoading,
  loggedUser: state.loggedUser,
}))(ProductModal);
