/* eslint-disable indent */
import {
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_ERROR,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUP_ERROR,
  GET_GROUP,
  CREATE_GROUP,
  UPDATE_GROUP,
  updateObjectGroup,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/group';
import { getGroups } from '../actions/groups';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';
import { getLoggedUser } from '../actions/loggedUser';

const URL = 'groups';

export const createGroupFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === CREATE_GROUP) {
      dispatch(
        apiRequest(
          'POST',
          URL,
          action.payload,
          UPDATE_GROUP_SUCCESS,
          UPDATE_GROUP_ERROR,
          {},
          true,
        ),
      );
      dispatch(showSpinner());
    }
  };

export const updateGroupFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === UPDATE_GROUP) {
      dispatch(
        apiRequest(
          'PUT',
          URL,
          action.payload,
          UPDATE_GROUP_SUCCESS,
          UPDATE_GROUP_ERROR,
          {},
          true,
        ),
      );
      dispatch(showSpinner());
    }
  };

export const getGroupFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GET_GROUP) {
      const { id } = action.payload;
      dispatch(
        apiRequest(
          'GET-id',
          URL,
          { id },
          FETCH_GROUP_SUCCESS,
          FETCH_GROUP_ERROR,
          {},
          true,
        ),
      );
      dispatch(showSpinner());
    }
  };

export const processGroupCollection =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === FETCH_GROUP_SUCCESS) {
      dispatch(updateObjectGroup(action.payload));
      dispatch(hideSpinner());
    } else if (action.type === UPDATE_GROUP_SUCCESS) {
      dispatch(updateObjectGroup(action.payload));
      dispatch(hideModal());
      dispatch(hideSpinner());
       dispatch(getLoggedUser());
      // To reload the page
      const { page, limit } = getState().groups;
      dispatch(getGroups({ page, limit }));
      Toastify.addSuccess('Ação realizada com sucesso.');
    } else if (action.type === UPDATE_GROUP_ERROR) {
      dispatch(hideSpinner());
      Toastify.addError(
        action.payload || 'Ocorreu um erro durante o salvamento.',
      );
    }
  };

export const groupMdl = [
  createGroupFlow,
  updateGroupFlow,
  getGroupFlow,
  processGroupCollection,
];
