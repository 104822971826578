import React from 'react';
import { Order } from '../../../../interfaces/orders';
import { OrderProductWithSupplier } from '../interfaces';
import SubOrder from '../SubOrder';
import { Container, Paper, Tabs, Tab, Content, EmptyMessage } from './styles';

const SubOrders: React.FC<{ order: Order | undefined }> = ({ order }) => {
  const [tabSelected, setTabSelected] = React.useState<number>(0);

  const handleChange = (_e: React.ChangeEvent<{}>, value: number) => {
    setTabSelected(value);
  };

  const allProducts =
    order?.supplierOrders?.reduce(
      (products: OrderProductWithSupplier[], supplierOrder) => [
        ...products,
        ...supplierOrder.orderProducts.map((product) => ({
          ...product,
          supplier: supplierOrder.supplier.corporateName,
        })),
      ],
      [],
    ) || [];

  const shortage = order?.supplierOrders
    ?.find(({ supplierDocument }) => supplierDocument === '0')
    ?.orderProducts?.map((product) => ({ ...product, supplier: 'Ruptura' }));

  return (
    <Container>
      <Tabs
        value={tabSelected}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="off"
      >
        <Tab label="Todos os produtos" id="show-all-tab" />
        {order?.supplierOrders?.map(({ supplier }) => (
          <Tab label={supplier.corporateName} />
        ))}
        <Tab label="Ruptura" className="shortage" />
      </Tabs>
      <Paper elevation={0}>
        <Content selected={tabSelected === 0}>
          <SubOrder products={allProducts} />
        </Content>
        {order?.supplierOrders
          ?.filter(({ supplierDocument }) => supplierDocument !== '0')
          ?.map((subOrder, index) => (
            <Content selected={index + 1 === tabSelected}>
              <SubOrder subOrder={subOrder} />
            </Content>
          ))}
        <Content
          selected={tabSelected === (order?.supplierOrders?.length || 0) + 1}
        >
          {shortage ? (
            <SubOrder products={shortage} />
          ) : (
            <EmptyMessage>Nenhuma ruptura por tratar registrada</EmptyMessage>
          )}
        </Content>
      </Paper>
    </Container>
  );
};

export default SubOrders;
