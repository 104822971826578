import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { Container } from './styles';
import SectionHeader from '../../sharedComponents/SectionHeader';
import SectionContent from '../../sharedComponents/SectionContent';
import StatelessCompanySelector from '../../sharedComponents/StatelessCompanySelector';
import { isEmptyObject, userIsAdmin } from '../../utils/globalFunctions';
import { getCompanies } from '../../redux/actions/companies';
import CashierClosureProvider from '../../providers/CashierClosure';
import Toastify from '../../utils/Toastify';
import CashierGrid from './components/CashierGrid';
import CustomDatePicker from './components/CustomDatePicker';
import CustomTimePicker from './components/CustomTimePicker';
import AddButton from './components/AddButton';
import CashierModal from '../Modals/CashierClosure';

const CashierClosure = ({ loggedUser, companies }) => {
  const [refDate, setRefDate] = useState(new Date());
  const [refStartTime, setRefStartTime] = useState(
    moment()
      .startOf('day')
      .toDate(),
  );
  const [refEndTime, setRefEndTime] = useState(
    moment()
      .endOf('day')
      .toDate(),
  );
  const allCompanies = userIsAdmin(loggedUser)
    ? companies
    : loggedUser.companies;
  const [selectedCompany, setSelectedCompany] = useState(allCompanies[0]);
  const [loading, setLoading] = useState(false);
  const [formattedItems, setFormattedItems] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedCashier, setSelectedCashier] = useState({});
  const [selectedPos, setSelectedPos] = useState({});

  const fetchFormattedItems = async (options) => {
    try {
      const formattedExtracts2CompanyMap = await CashierClosureProvider
        .getFormattedExtracts2CompanyMap({
          refDate,
          refStartTime,
          refEndTime,
          ...options,
        });
      setFormattedItems(formattedExtracts2CompanyMap);
    } catch (error) {
      Toastify.addError(
        'Ocorreu um erro com o carregamento dos seus dados, por favor, recarregue a página ou entre em contato com o suporte.',
        error,
      );
    }
    setLoading(false);
  };

  const reloadCashiers = async () => {
    setLoading(true);
    await fetchFormattedItems({ loadExtracts: false });
    setLoading(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCashier({});
    setSelectedPos({});
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCashierEdit = (cashierName) => {
    setSelectedCashier({
      name: cashierName,
      ...selectedItems[cashierName],
    });
    handleOpenModal();
  };

  const handlePosAdd = (posName, type) => {
    setSelectedPos({
      [type]: posName,
    });
    handleOpenModal();
  };

  const handleSaveModal = async (params) => {
    try {
      await CashierClosureProvider.upsertCashier(params);
      Toastify.addSuccess('Operação concluída com sucesso.');
      handleCloseModal();
      reloadCashiers();
    } catch (error) {
      Toastify.addError(
        'Ocorreu um erro ao salvar o caixa, por favor, verifique os dados inseridos ou entre em contato com o suporte.',
        error,
      );
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (userIsAdmin(loggedUser)) {
      if (!companies?.length) {
        dispatch(getCompanies({ listAll: true }));
      } else {
        setSelectedCompany(companies[0]);
      }
    }
  }, [companies]);

  const timeoutIds = useRef([]);

  useEffect(() => {
    // We need to send requisitions with controlled timeouts to avoid overhead
    if (timeoutIds.current.length) {
      timeoutIds.current.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
      timeoutIds.current = [];
    }
    setLoading(true);
    const timeoutID = setTimeout(() => {
      isEmptyObject(formattedItems)
        ? fetchFormattedItems()
        : fetchFormattedItems({ loadCashiers: false });
    }, 1000);
    timeoutIds.current.push(timeoutID);
  }, [refDate, refStartTime, refEndTime]);

  useEffect(() => {
    if (selectedCompany && formattedItems) {
      setSelectedItems(formattedItems[selectedCompany.document]);
    }
  }, [selectedCompany, formattedItems]);

  return (
    <>
      <Container id="section-container">
        <SectionHeader
          title="Fechamento de Caixa"
          style={{ marginBottom: 0 }}
          subHeaderStyle={{ flexDirection: 'row', alignItems: 'center' }}
          hideCompanySelector
          hideDatePicker
        >
          <AddButton handleOpenModal={handleOpenModal} />
          <CashierModal
            open={openModal}
            company={selectedCompany}
            companies={companies}
            cashier={selectedCashier}
            paymentMachine={selectedPos?.paymentMachine}
            picPayOperator={selectedPos?.picPayOperator}
            getAvailablePos={CashierClosureProvider.getAvailablePos}
            handleClose={handleCloseModal}
            handleSave={handleSaveModal}
          />
          <Grid container spacing={2} style={{ marginTop: 8 }}>
            <Grid item xs={12} sm={12} md={5}>
              <StatelessCompanySelector
                style={{ minWidth: 'auto' }}
                value={selectedCompany?.id}
                companies={allCompanies}
                onChange={(e) => {
                  const companyToSelect = allCompanies.find(
                    (company) => company.id === e.target.value,
                  );
                  setSelectedCompany(companyToSelect);
                }}
                disableClearing
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <CustomDatePicker
                refDate={refDate}
                onChangeCallback={(value) => setRefDate(value)}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <CustomTimePicker
                label="Horário inicial"
                refTime={refStartTime}
                onChangeCallback={(value) => setRefStartTime(value)}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <CustomTimePicker
                label="Horário final"
                refTime={refEndTime}
                onChangeCallback={(value) => setRefEndTime(value)}
              />
            </Grid>
          </Grid>
        </SectionHeader>
        <SectionContent isLoading={loading}>
          <CashierGrid
            items={selectedItems}
            onCashierEdit={handleCashierEdit}
            onPosAdd={handlePosAdd}
          />
        </SectionContent>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedUser: state.loggedUser,
  companies: state.companies?.docs || state.companies,
});

export default connect(mapStateToProps)(CashierClosure);
