import SweetAlert from 'sweetalert2';

const swalWithBootstrapButtons = SweetAlert.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: true,
});

const Alert = async (
  title = 'Tem certeza?',
  message = 'Esta ação não pode ser desfeita!',
  isHTML = false,
  html = '',
  confirmButtonText = 'Sim, continue.',
  cancelButtonText = 'Não, cancelar.',
) =>
  swalWithBootstrapButtons
    .fire({
      title,
      text: isHTML ? null : message,
      html: isHTML ? html : null,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        return { confirm: true };
      }
      if (result.dismiss === SweetAlert.DismissReason.cancel) {
        return { confirm: false };
      }
    });

export default Alert;
