import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Select,
  Button,
  InputLabel,
  TableCell,
  TableRow,
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  infoButton: {
    marginLeft: '1rem',
    color: 'var(--primary-text-color)',
  },
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
}));

export const SelectCompanyText = styled.span`
  font-size: 1.5rem;
  color: #a3a3a3;
  text-align: center;
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--primary-text-color);
  margin-bottom: 1rem;
`;

export const Title = styled.span`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  text-align: left;
`;

export const SubTitle = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  text-align: left;
  margin-right: 0.5rem;
`;

export const TableContainer = styled(Paper)`
  margin-top: 1rem;
  border-radius: 1.5rem;
  color: var(--primary-text-color);
  padding: 2rem;
`;

export const FilterContainerTop = styled.div`
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  gap: 1rem;
  align-items: flex-end;

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
  
  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #a3a3a333;
  align-self: center;
  margin-bottom: 0;
`;

export const FilterContainerBottom = styled.div`
  margin-top: 1rem;
  color: var(--primary-text-color);
  justify-content: space-between;
  width: 100%;
  display: flex;
  padding-right: .5rem;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

export const StyledSelect = styled(Select)`
  border-radius: 1rem;
  color: var(--primary-text-color);
  width: 9.75rem;
  height: 2.5rem;
`;

export const StyledSelect2 = styled(StyledSelect)`
  width: 12.5rem;
`;

export const SelectTitle = styled.span`
  font-size: 0.625rem;
  color: #A3A3A3;
  margin-bottom: 0.25rem;
`;

export const StyledLabel = styled(InputLabel)`
  transform: translate(14px, 12px) scale(1);
`;

export const StyledButton = styled(Button)`
  border-radius: 1rem;
  color: var(--primary-text-color);
  background-color: #192D4D33;
  height: 2.5rem;
  width: 6.875rem;
  padding: 0.5rem, 1rem;
  margin-right: 1rem;
  transition: all 0.3s;

  :hover {
    background-color: #192D4D22;
  }
`;

export const StyledTableCell = styled(TableCell)`
  color: #464255;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0;
  max-width: 150px;
`;

export const HeaderTableCell = styled(TableCell)`
  font-size: 0.625rem;
  color: var(--primary-text-color);
  font-weight: 400;
`;

export const StyledTableRow = styled(TableRow)`
  :hover {
    background-color: #F3F2F7;

    .MuiSvgIcon-root{
      color: var(--primary-text-color);
    }
  }
`;

export const BoxTitle = styled.span`
  font-size: 0.938rem;
  font-weight: 500;
  margin-bottom: .5rem;
  display: flex;
  gap: 0.5rem;

  .MuiSvgIcon-fontSizeSmall {
    font-size: 0.833rem;
  }

  .MuiSvgIcon-root {
    align-self: center;
  }
`;

export const SuggestedPrice = styled.span`
  color: #00A389;
  font-size: 2rem;
  font-weight: 600;
`;

export const LastUpdateText = styled.span`
  font-size: 0.75rem;
  color: #A3A3A3;
  margin-bottom: 1rem;
`;

export const DetailText = styled.div`
  font-size: 0.875rem;
  color: #464255;
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
  padding-right: 1rem;  
  width: 100%;
`;

export const VerticalDivider = styled.div`
  height: 7.125rem;
  width: 1px;
  background-color: #21212116;
  margin-bottom: 1rem;
`;

export const HorizontalDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #21212116;
`;

export const TopBox = styled.div`
  display: flex;
  width: 100%;
`;

export const TopLeftBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
`;

export const TopRightBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: center;
`;

export const BottomBox = styled.div`
  display: flex;
  margin-top: 1rem;
`;

export const BottomLeftBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
`;

export const BottomRightBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  padding-left: 1rem;
`;

export const Pagination = styled.div`
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  gap: 2.65rem;
  margin-top: 1rem;
`;
