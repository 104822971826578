export const GET_GROUPS = '[groups] GET';
export const FETCH_GROUPS_SUCCESS = '[groups] Fetch success';
export const FETCH_GROUPS_ERROR = '[groups] Fetch Error';
export const UPDATE_GROUPS = '[groups] Update';
export const SHOW_GROUPS_SPINNER = '[groups - ui] show spinner';
export const HIDE_GROUPS_SPINNER = '[groups - ui] hide spinner';

export const getGroups = (filter) => ({
  type: GET_GROUPS,
  payload: filter,
});

export const updateGroups = (data) => ({
  type: UPDATE_GROUPS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_GROUPS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_GROUPS_SPINNER,
});
