export const GET_SUPPLIERS = '[suppliers] GET';
export const FETCH_SUPPLIERS_SUCCESS = '[suppliers] Fetch success';
export const FETCH_SUPPLIERS_ERROR = '[suppliers] Fetch Error';
export const UPDATE_SUPPLIERS = '[suppliers] Update';
export const SHOW_SUPPLIERS_SPINNER = '[suppliers - ui] show spinner';
export const HIDE_SUPPLIERS_SPINNER = '[suppliers - ui] hide spinner';

export const getSuppliers = (filter) => ({
  type: GET_SUPPLIERS,
  payload: {
    ...filter,
  },
});

export const updateSuppliers = (data) => ({
  type: UPDATE_SUPPLIERS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_SUPPLIERS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_SUPPLIERS_SPINNER,
});
