import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableHead, TableRow, TableBody } from '@material-ui/core';
import { getFormattedRegisterItems } from '../../../providers/Registers';
import { getPaymentMachines } from '../../../redux/actions/paymentMachines';
import Spinner from '../../../sharedComponents/Spinner';
import {
  StyledTable,
  StyledTableCell,
  StyledCheckCircleOutline,
  StyledHighlightOffOutlined,
} from '../styles';

export default function CompanySubTable({ document }) {
  const dispatch = useDispatch();
  const states = useSelector((state) => {
    const machineStates =
      state.paymentMachines?.docs || state.paymentMachines || [];
    const companies = state.companies?.docs || state.companies || [];
    const formatted = getFormattedRegisterItems(machineStates, companies);
    return formatted;
  });
  const loading = useSelector((state) => state.ui.paymentMachinesLoading);

  useEffect(() => {
    if (!document) return;
    dispatch(
      getPaymentMachines({ listAll: true, companyDocuments: [document] }),
    );
  }, [document]);

  return (
    <center>
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledTableCell isHeader>Nº de Série</StyledTableCell>
            <StyledTableCell isHeader>Função</StyledTableCell>
            <StyledTableCell isHeader>Status</StyledTableCell>
            <StyledTableCell isHeader>Válido Desde</StyledTableCell>
            <StyledTableCell isHeader>Válido Até</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <StyledTableCell colSpan="7">
                <Spinner parent="Table" />
              </StyledTableCell>
            </TableRow>
          ) :
            states?.map((state) => (
              <TableRow>
                <StyledTableCell>{state.serialNumber}</StyledTableCell>
                <StyledTableCell>{state.salesFunction}</StyledTableCell>
                <StyledTableCell>
                  {state.active ? (
                    <StyledCheckCircleOutline />
                  ) : (
                    <StyledHighlightOffOutlined />
                  )}
                </StyledTableCell>
                <StyledTableCell>{state.validSinceFormatted}</StyledTableCell>
                <StyledTableCell>
                  {state.validUntilFormatted || '-'}
                </StyledTableCell>
              </TableRow>
            ))}
        </TableBody>
      </StyledTable>
    </center>
  );
}
