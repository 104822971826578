import { Paper, Typography } from '@material-ui/core';
import { Rowing } from '@material-ui/icons';
import React from 'react';
import { colors } from '../../theme';
import { arraySum } from '../../utils/globalFunctions';
import DoughnutChart from '../DoughnutChart';
import { ChartContainer, ChartLegend } from './styles';

function ResumeChartCard({ values, title, labels, dataLabel, observations }) {
  const backgroundColor = [
    colors.primary.main,
    colors.success.main,
    colors.secondary.main,
  ];
  const data = {
    labels,
    datasets: [
      {
        label: dataLabel,
        data: values,
        backgroundColor,
        borderWidth: 0,
      },
    ],
  };

  const total = arraySum(values);

  return (
    <Paper
      style={{
        flex: 1,
        borderRadius: '1.5rem',
        padding: '2rem',
        color: colors.primary.main,
        height: '100%',
      }}
    >
      <Typography style={{ fontWeight: 700 }}>{title}</Typography>
      <Typography variant="body2" style={{ marginTop: '1rem' }}>
        {observations}
      </Typography>
      <ChartContainer>
        <div style={{ paddingTop: '0.5rem' }}>
          <DoughnutChart data={data} options={{ legend: { display: false } }} />
        </div>
        <ChartLegend>
          {labels.map((label, index) => (
            <DoughnutChartLegendItem
              color={backgroundColor[index]}
              description={label}
              percent={total && (values[index] / total) * 100}
              value={values[index]}
              key={`${label}-${index}`}
            />
          ))}
        </ChartLegend>
      </ChartContainer>
    </Paper>
  );
}

function DoughnutChartLegendItem({ color, description, percent, value }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0.25rem',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            backgroundColor: color,
            width: '0.5rem',
            height: '0.5rem',
            borderRadius: 4,
            margin: '0.25rem',
          }}
        />
        <span style={{ fontSize: 12 }}>{description}</span>
      </div>
      <div style={{ fontSize: 21, fontWeight: 600 }}>
        {percent ? percent.toFixed(0) : '-'}%
      </div>
      <div style={{ fontSize: 14, fontWeight: 500 }}>
        {value.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        })}
      </div>
    </div>
  );
}

export default ResumeChartCard;
