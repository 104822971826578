export const GET_BAKERY_ADDITIONAL_INFORMATION =
  '[bakery additional information] GET';
export const UPDATE_BAKERY_ADDITIONAL_INFORMATION =
  '[bakery additional information] UPDATE';
export const CREATE_BAKERY_ADDITIONAL_INFORMATION =
  '[bakery additional information] CREATE';
export const DELETE_BAKERY_ADDITIONAL_INFORMATION =
  '[bakery additional information] DELETE';
export const UPDATE_OBJECT_BAKERY_ADDITIONAL_INFORMATION =
  '[bakery additional information] UPDATE OBJECT BAKERY_ADDITIONAL_INFORMATION';
// UI
export const SHOW_BAKERY_ADDITIONAL_INFORMATION_MODAL =
  '[bakery additional information] SHOW MODAL';
export const HIDE_BAKERY_ADDITIONAL_INFORMATION_MODAL =
  '[bakery additional information] HIDE MODAL';
// Spinner
export const UPDATE_BAKERY_ADDITIONAL_INFORMATION_SUCCESS =
  '[bakery additional information] Update success';
export const UPDATE_BAKERY_ADDITIONAL_INFORMATION_ERROR =
  '[bakery additional information] Update Error';
export const FETCH_BAKERY_ADDITIONAL_INFORMATION_SUCCESS =
  '[bakery additional information] Fetch success';
export const FETCH_BAKERY_ADDITIONAL_INFORMATION_ERROR =
  '[bakery additional information] Fetch Error';
export const SHOW_BAKERY_ADDITIONAL_INFORMATION_SPINNER =
  '[bakery additional information - ui] show spinner';
export const HIDE_BAKERY_ADDITIONAL_INFORMATION_SPINNER =
  '[bakery additional information - ui] hide spinner';

export const getBakeryAdditionalInformation = (id) => ({
  type: GET_BAKERY_ADDITIONAL_INFORMATION,
  payload: { _id: id },
});

export const createBakeryAdditionalInformation = (data) => ({
  type: CREATE_BAKERY_ADDITIONAL_INFORMATION,
  payload: data,
});

export const updateBakeryAdditionalInformation = (data) => ({
  type: UPDATE_BAKERY_ADDITIONAL_INFORMATION,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_BAKERY_ADDITIONAL_INFORMATION_MODAL,
});

export const hideModal = () => ({
  type: HIDE_BAKERY_ADDITIONAL_INFORMATION_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_BAKERY_ADDITIONAL_INFORMATION_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_BAKERY_ADDITIONAL_INFORMATION_SPINNER,
});

export const updateObjectBakeryAdditionalInformation = (data) => ({
  type: UPDATE_OBJECT_BAKERY_ADDITIONAL_INFORMATION,
  payload: data,
});
