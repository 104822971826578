export const GET_OFFER = '[offer] GET';
export const UPDATE_OFFER = '[offer] UPDATE';
export const CREATE_OFFER = '[offer] CREATE';
export const DELETE_OFFER = '[offer] DELETE';
export const UPDATE_OBJECT_OFFER = '[offer] UPDATE OBJECT OFFER';
export const UPDATE_OFFER_SUCCESS = '[offer] Update success';
export const UPDATE_OFFER_ERROR = '[offer] Update Error';
export const FETCH_OFFER_SUCCESS = '[offer] Fetch success';
export const FETCH_OFFER_ERROR = '[offer] Fetch Error';
// UI
export const SHOW_OFFER_MODAL = '[offer] SHOW MODAL';
export const HIDE_OFFER_MODAL = '[offer] HIDE MODAL';
// Spinner
export const SHOW_OFFER_SPINNER = '[offer - ui] show spinner';
export const HIDE_OFFER_SPINNER = '[offer - ui] hide spinner';

export const getOffer = (id) => ({
  type: GET_OFFER,
  payload: { _id: id },
});

export const createOffer = (data) => ({
  type: CREATE_OFFER,
  payload: data,
});

export const updateOffer = (data) => ({
  type: UPDATE_OFFER,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_OFFER_MODAL,
});

export const hideModal = () => ({
  type: HIDE_OFFER_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_OFFER_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_OFFER_SPINNER,
});

export const updateObjectOffer = (data) => ({
  type: UPDATE_OBJECT_OFFER,
  payload: data,
});
