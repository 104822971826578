import styled from 'styled-components';
import { Link } from '@material-ui/core';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  margin: auto;
  margin-top: 1rem;
  border-radius: 1.5rem;
  width: 100%;
  height: 15.6rem;
  background: linear-gradient(
      180deg,
      ${({ color }) => color} 0%,
      ${({ subColor }) => subColor} 100%
    ),
    url(${({ image }) => image});

  background-position: center;
  background-size: cover;
  @media (max-width: 720px) {
    width: 80%;
  }
`;

export const Title = styled.span`
  font-size: 2rem;
  font-weight: 600;
  text-align: left;
  color: #fff;
`;

export const SubTitle = styled.span`
  font-size: 1rem;
  text-align: left;
  margin-top: 0.5rem;
  color: #fff;
`;

export const StyledLink = styled(Link)`
  :hover {
    text-decoration: none;
    cursor: pointer;
  }
`;
