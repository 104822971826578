import { GET_SUPPLIERS, UPDATE_SUPPLIERS } from '../actions/suppliers';

const initState = {
  docs: [],
  page: 1,
  limit: 50,
  totalDocs: 0,
  searchText: '',
};

export function suppliersReducer(suppliers = initState, action) {
  switch (action.type) {
    case GET_SUPPLIERS:
      return action.payload;
    case UPDATE_SUPPLIERS:
      return action.payload;

    default:
      return suppliers;
  }
}
