import { UPDATE_OBJECT_ORDER } from '../actions/order';

export function orderReducer(order = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_ORDER:
      return action.payload;

    default:
      return order;
  }
}
