import React from 'react';
import { useHistory } from 'react-router-dom';
import PATHS from '../../../../config/routePaths';
import MenuButton from '../../../../sharedComponents/MenuButton';
import { hasPermission } from '../../../../utils/globalFunctions';
import { ButtonContainer } from '../../styles';

const ImportButton = () => {
  const history = useHistory();
  return (
    (hasPermission(PATHS.FINANCE_EXTRACTS, 'create') && (
      <ButtonContainer>
        <MenuButton
          fullWidth
          menuItems={[
            {
              onClick: () => history.push(PATHS.IMPORTS_PIC_PAY),
              label: 'PicPay',
            },
            {
              onClick: () => history.push(PATHS.IMPORTS_VOUCHER),
              label: 'Voucher',
            },
          ]}
        >
          Importar
        </MenuButton>
      </ButtonContainer>
    )) ||
    null
  );
};

export default ImportButton;
