import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  IconButton,
  Icon,
  FormControlLabel,
  Switch,
  Button,
  MenuItem,
  useMediaQuery,
  Select as MaterialSelect,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { getCompanies } from '../../redux/actions/companies';

import {
  ButtonContainer,
  CheckBoxContainer,
  CheckboxContent,
  Container,
  ReportButton,
  selectStyle,
  labelStyle,
} from './styles';
import { download } from '../../services/Download';
import { userIsAdmin } from '../../utils/globalFunctions';
import { Page } from '../../interfaces/pages';
import { RootState } from '../../interfaces/redux';
import SubHeader from '../../sharedComponents/SubHeader';
import {
  DatePicker,
  DatePickersContainer,
  StyledTextField,
} from '../../sharedComponents/SectionHeader/styles';

import { SubTitle } from '../../sharedComponents/SubHeader/styles';

import Spinner from '../../sharedComponents/Spinner';
import { DateRange, ReportResult } from '../../interfaces/reports';
import { getAcquirers } from '../../redux/actions/acquirers';
import { Acquirer } from '../../interfaces/acquirers';
import { Company } from '../../interfaces/companies';

const reportOptions: {
  type: string;
  label: string;
}[] = [
  {
    type: 'online',
    label: 'Online',
  },
  {
    type: 'fisico',
    label: 'Físico',
  },
];

const ReportGrossVsLiquidIncome: React.FC<Page> = ({ loading, loggedUser }) => {
  const isDesktop = useMediaQuery('(max-width:1080px)');
  const isTablet = useMediaQuery('(max-width:860px)');
  const isMobile = useMediaQuery('(max-width:767px)');

  const selectClasses = selectStyle();
  const labelClasses = labelStyle();

  const [reportType, setReportType] = useState<{
    type: string;
    label: string;
  }>({
    type: 'fisico',
    label: 'Físico',
  });

  const handleReportType = (data: any) => {
    setReportType(
      reportOptions.find((option) => option.type === data.target.value) ||
        reportOptions[0],
    );
  };

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: new Date(
      moment()
        .startOf('year')
        .toString(),
    ),
    endDate: new Date(
      moment()
        .endOf('month')
        .toString(),
    ),
  });

  const handleDateRange = (field: any) => (date: any) => {
    setDateRange((currentRange) => ({ ...currentRange, [field]: date }));
  };

  const handleDownload = () =>
    download(
      'reports/liquidVsGrossIncome',
      {
        dateGte: dateRange.startDate.toISOString(),
        dateLte: dateRange.endDate.toISOString(),
        reportType: reportType.type,
        isAdmin: userIsAdmin(loggedUser),
      },
      {
        filename: `incomeReport-${new Date().toISOString()}`,
        fileExtension: 'xlsx',
        callback: ({ result }: { result: ReportResult[] }) =>
          result.map((item: ReportResult) => {
            const customName = `${item.name}`;
            const final: any = {};
            final.nome = customName;
            if (item.values && item.values.length > 0) {
              item.values.map((value) => {
                const year = value.month.split('-')[0];
                const month = value.month.split('-')[1];
                final[`${month}/${year}`] = parseFloat(
                  value.value?.toString() || '0',
                );
              });
            }

            return final;
          }),
      },
    );

  return (
    <Container>
      <SubHeader
        title="Relatório Receita Bruta vs Receita Líquida Bakery"
        subtitle="Personalize os parâmetros ou gere o relatório"
        style={{}}
        breakOnSmallScreens
        children={null}
      />

      {loading && (
        <Spinner parent="CheckboxContent" color="#192D4D" style={{}} />
      )}

      {!loading && (
        <>
          <ButtonContainer>
            <ReportButton
              variant="contained"
              startIcon={<Icon>cloud_download</Icon>}
              onClick={handleDownload}
            >
              Gerar e Baixar Relatório
            </ReportButton>
          </ButtonContainer>

          <Container>
            <SubTitle>Tipo de Relatório</SubTitle>
          </Container>
          <Container>
            <MaterialSelect
              value={reportType.type}
              variant="outlined"
              onChange={handleReportType}
              fullWidth
              // InputLabelProps={{ shrink: false, classes: labelClasses }}
              style={{
                ...(isDesktop && { flex: 1 }),
                ...(isTablet
                  ? { width: '100%', marginTop: 16 }
                  : { marginLeft: 0 }),
              }}
            >
              {reportOptions.map((option) => (
                <MenuItem key={option.type} value={option.type}>
                  {option.label}
                </MenuItem>
              ))}
            </MaterialSelect>
          </Container>

          <Container>
            <SubTitle>Período do relatório</SubTitle>
          </Container>
          <Container>
            <DatePickersContainer>
              <DatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                label="Data Início"
                value={dateRange.startDate}
                onChange={handleDateRange('startDate')}
                InputLabelProps={{
                  hidden: true,
                  style: {
                    color: 'var(--primary-text-color)',
                    backgroundColor: 'transparent',
                  },
                }}
                inputVariant="outlined"
                autoOk
                TextFieldComponent={(props) => (
                  <StyledTextField
                    {...props}
                    InputProps={{
                      startAdornment: (
                        <IconButton style={{ paddingLeft: 0 }}>
                          <Icon style={{ color: '#192D4D' }}>
                            calendar_today
                          </Icon>
                        </IconButton>
                      ),
                      endAdornment: (
                        <IconButton style={{ paddingRight: 0 }}>
                          <Icon style={{ color: '#192D4D' }}>expand_more</Icon>
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />

              <DatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                label="Data Fim"
                value={dateRange.endDate}
                onChange={handleDateRange('endDate')}
                InputLabelProps={{
                  hidden: true,
                  style: {
                    color: 'var(--primary-text-color)',
                    backgroundColor: 'transparent',
                  },
                }}
                inputVariant="outlined"
                autoOk
                TextFieldComponent={(props) => (
                  <StyledTextField
                    {...props}
                    InputProps={{
                      startAdornment: (
                        <IconButton style={{ paddingLeft: 0 }}>
                          <Icon
                            style={{ color: '#192D4D' }}
                            className="material-icons-outlined"
                          >
                            calendar_today
                          </Icon>
                        </IconButton>
                      ),
                      endAdornment: (
                        <IconButton style={{ paddingRight: 0 }}>
                          <Icon style={{ color: '#192D4D' }}>expand_more</Icon>
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </DatePickersContainer>
          </Container>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  loggedUser: state.loggedUser,
  loading: state.ui.acquirersLoading,
});

export default connect(mapStateToProps)(ReportGrossVsLiquidIncome);
