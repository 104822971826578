import {
  GET_PICPAY_OPERATOR,
  UPDATE_PICPAY_OPERATOR,
  UPDATE_OBJECT_PICPAY_OPERATOR,
  CREATE_PICPAY_OPERATOR,
  DELETE_PICPAY_OPERATOR,
} from '../actions/picPayOperator';

export function picPayOperatorReducer(picPayOperator = {}, action) {
  switch (action.type) {
    case GET_PICPAY_OPERATOR:
      return action.payload;
    case UPDATE_PICPAY_OPERATOR:
      return action.payload;
    case CREATE_PICPAY_OPERATOR:
      return action.payload;
    case DELETE_PICPAY_OPERATOR:
      return action.payload;
    case UPDATE_OBJECT_PICPAY_OPERATOR:
      return action.payload;

    default:
      return picPayOperator;
  }
}
