import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;
export const SubContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: calc(100% - 32px);
`;
