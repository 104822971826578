import {
  Input as MuiInput,
  Table as MuiTable,
  TableRow,
  TableCell,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  IconButton,
  Button,
} from '@material-ui/core';
import { SearchRounded, KeyboardArrowDown } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import styled, { css } from 'styled-components';
import { SanitizedOrderStatus } from '../../../../interfaces/orders';

export const SearchInput = withStyles((theme) => ({
  root: {
    border: '1px solid #1A2D78',
    borderRadius: 8,
    borderShadow: '1px 1px 4px rgba(0, 0, 0, 0.08)',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: '#FFF',
  },
}))(MuiInput);

export const Input = withStyles((theme) => ({
  root: {
    border: '1px solid #D1D4D6',
    borderRadius: 8,
    borderShadow: '1px 1px 4px rgba(0, 0, 0, 0.08)',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: '#FFF',
  },
}))(MuiInput);

export const SearchIcon = withStyles({
  root: {
    color: '#1A2D78',
  },
})(SearchRounded);

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid #f3f2f7;
  margin: 16px 0;
`;

export const Table = withStyles({})(MuiTable);
export const Row = withStyles({
  root: {
    background: '#FFF',
    '&:nth-of-type(odd)': {
      background: '#F3F2F7',
    },
  },
})(TableRow);
export const Cell = withStyles({
  root: {
    color: '#3B3F41',
    fontSize: 12,
    borderBottom: 0,
    textAlign: 'center',
    '&.with-sub-value': {
      fontSize: 15,
      fontWeight: 600,
      textAlign: 'left',
    },
  },
})(TableCell);
export const TableBody = withStyles({})(MuiTableBody);
export const TableHead = withStyles({
  root: {
    '& th': { fontSize: 15, fontWeight: 600, textAlign: 'center' },
    '& tr': { background: '#FFF !important' },
  },
})(MuiTableHead);

export const SubValue = styled.span`
  font-size: 12px;
  font-weight: 400;
  display: block;
  text-align: left;
`;

export const DetailsBar = styled.div<{ open: boolean }>`
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: hidden;
  background-color: #f3f2f7;
  flex: 1;
  border-bottom: 0px solid #d1d4d6;
  transition: border-bottom ease-in-out 1s;
  transition: border-radius ease-in-out 1s;
  ${({ open }) =>
    open &&
    css`
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid #d1d4d6;
    `}
`;

const statusColorMap: {
  [key in SanitizedOrderStatus]: { main: string; divider: string };
} = {
  Aguardando: { main: '#D1D4D6', divider: '#9EA3A5' },
  Realizado: { main: '#AFCCE3', divider: '#2A96C1' },
  'Em entrega': { main: '#F4E18C', divider: '#EBCD0C' },
  Entregue: { main: '#AFD49E', divider: '#48B748' },
  Cancelado: { main: '#F8BCAE', divider: '#F95F52' },
};

export const DetailItem = styled.div<{ status: SanitizedOrderStatus }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-left: 1px solid #d1d4d6;
  padding: 16px 0;
  flex: 1;

  &:first-child,
  &:nth-child(3) {
    border-left: none;
  }

  &.highlight {
    background-color: ${({ status }) => statusColorMap[status].main};
    border-color: ${({ status }) => statusColorMap[status].divider};
  }
`;

export const DetailLabel = styled.span`
  display: block;
  font-weight: 600;
  font-size: 14px;
  color: #6d7173;
  text-align: center;
  .highlight & {
    color: #252729;
  }
`;

export const DetailValue = styled.span`
  font-size: 14px;
  color: #6d7173;
  text-align: center;
  .highlight & {
    color: #252729;
  }
`;

export const ExpandIcon = withStyles({
  root: {
    color: '#133DA5',
    transition: 'transform ease-in-out 500ms',
    '&.open': {
      transform: 'rotate(-180deg)',
    },
  },
})(KeyboardArrowDown);

export const ExpandButton = withStyles((theme) => ({
  root: { alignSelf: 'center', marginRight: theme.spacing(3) },
}))(IconButton);

export const SubOrderDetailsContainer = styled.div`
  background-color: #f3f2f7;
  padding: 24px;
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
`;

export const DetailsDivider = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid #d1d4d6;
  margin: 16px 0;
`;

export const Label = styled.span`
  font-size: 14px;
  line-height: 130%;
  color: #9ea3a5;
  margin-bottom: 8px;
`;

export const Field = styled.div<{ columns: number; rows?: number }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  grid-column: span ${({ columns }) => columns};
  ${({ rows }) =>
    rows &&
    css`
      grid-row: span ${rows};
    `};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-gap: 16px;
`;

export const MobileLabel = styled.span<{alignLeft?: boolean}>`
  font-size: 14px;
  font-weight: 600;
  display: flex;
  text-align: center;
  align-items: center;
  ${({ alignLeft }) => alignLeft && css`
    text-align: left;
    align-items: flex-start;
  `}
`;

export const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

export const GridItem = styled.div<{columns: number}>`
  grid-column: span ${({ columns }) => columns};
  display:flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  position: relative;
  &.align-left{
    text-align: left;
    align-items: flex-start;
  }
  &.divider-after::after {
    content: "";
    height: 85%;
    border-right: 1px solid #d1d4d6;
    position: absolute;
    right: 0;
  }

  &.divider-bottom {
    border-bottom: 1px solid #d1d4d6;
  }
`;

export const SeeMoreButton = withStyles({
  root: {
    color: '#133DA5',
    textTransform: 'none',
    gridColumnStart: 2,
  },
})(Button);

export const StatusIndicator = styled.div<{ color: string }>`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin-right: 4px;
  background-color: ${({ color }) => color};
  box-shadow: 0px 0px 4px ${({ color }) => color};
`;
