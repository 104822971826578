import {
  FETCH_COMMISSIONINGS_SUCCESS,
  FETCH_COMMISSIONINGS_ERROR,
  GET_COMMISSIONINGS,
  updateCommissionings,
  showSpinner,
  hideSpinner,
} from '../actions/commissionings';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'commissionings';

export const getCommissioningsFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_COMMISSIONINGS) {
          dispatch(
            apiRequest(
              'GET',
              URL,
              action.payload,
              FETCH_COMMISSIONINGS_SUCCESS,
              FETCH_COMMISSIONINGS_ERROR,
              {},
              true,
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processCommissioningsCollection =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_COMMISSIONINGS_SUCCESS) {
          dispatch(updateCommissionings(action.payload));
          dispatch(hideSpinner());
        } else if (action.type === FETCH_COMMISSIONINGS_ERROR) {
          dispatch(hideSpinner());
          Toastify.addError(
            'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
            action.payload,
          );
        }
      };

export const commissioningsMdl = [
  getCommissioningsFlow,
  processCommissioningsCollection,
];
