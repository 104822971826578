import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, useStyles, FilterArea } from './styles';
import { getOrders } from '../../redux/actions/orders';
import { RootState } from '../../interfaces/redux';
import Card from '../../sharedComponents/OrderCard';
import { Order } from '../../interfaces/orders';
import { Company } from '../../interfaces/companies';
import { getCompanies } from '../../redux/actions/companies';
import { formatOrders } from '../../utils/formatOrder';
import Spinner from '../../sharedComponents/Spinner';
import CompanySelector from '../../sharedComponents/CompanySelector';
import SubHeader from '../../sharedComponents/SubHeader';
import Pagination from '../../sharedComponents/Pagination';
import PATHS from '../../config/routePaths';
import { ExpandIcon } from '../../sharedComponents/OrderCard/styles';

const orderFields: { [key: string]: string } = {
  orderNumber: 'Número do Pedido',
  wordpressOrderDate: 'Data',
  total: 'Total',
  company: 'Panificador',
  paymentMethod: 'Pagamento',
};

const Orders: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState<number>(1);
  const companies: Company[] = useSelector(
    (state: RootState) => state.companies?.docs || state.companies,
  );

  const company: Company = useSelector((state: RootState) => state.company);

  const orders: Order[] = useSelector((state: RootState) =>
    formatOrders(state.orders?.docs || [], companies, true),
  );
  const {
    total,
    pages,
    limit,
  }: { total: number; pages: number; limit: number } = useSelector(
    (state: RootState) => state.orders,
  );

  const loading: boolean = useSelector(
    (state: RootState) => state.ui.ordersLoading,
  );

  useEffect(() => {
    dispatch(
      getOrders({
        page,
        limit: 20,
        'filter[companyDocument]': company.document,
      }),
    );
  }, [company, page]);

  useEffect(() => {
    if (companies && companies.length > 0) return;
    dispatch(getCompanies({ listAll: true }));
  }, [companies]);

  const handleClick = (id: number) => () =>
    history.push(`${PATHS.ORDER}/${id}`);

  return (
    <Container>
      <SubHeader
        title="Acompanhamento de pedidos"
        subtitle={
          <FilterArea>
            <CompanySelector />
          </FilterArea>
        }
      />
      {loading ? (
        <Spinner color="#133DA5" />
      ) : (
        <>
          <div className={classes.root}>
            {orders.map((order) => (
              <Card
                key={order.id}
                order={order}
                fields={orderFields}
                onClick={handleClick(order.id)}
                ActionComponent={() => <ExpandIcon>chevron_right</ExpandIcon>}
              />
            ))}
          </div>
          <Pagination
            page={page}
            total={total}
            onChangePage={setPage}
            pages={pages}
            showing={orders.length}
            limit={limit}
          />
        </>
      )}
    </Container>
  );
};

export default Orders;
