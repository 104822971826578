import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import { colors } from '../../theme';
import { analysePeriods, formatLabels } from '../../utils/globalFunctions';
import CardDescription from '../CardDescription';
import VerticalBarChart from '../VerticalBarChart';

function HistoricalChartCard({
  values,
  labels,
  title,
  description,
  dataLabel,
}) {
  const { percent, lastPeriod, lastPeriodSum } = analysePeriods(values);
  const value = lastPeriodSum;
  const backgroundColor = lastPeriod.map((value, index) => {
    if (index === lastPeriod.length - 1) {
      return colors.secondary.main;
    }
    return colors.primary.main;
  });

  const data = {
    labels: formatLabels(labels, values.length / 2),
    datasets: [
      {
        label: dataLabel,
        data: lastPeriod,
        backgroundColor,
      },
    ],
  };

  return (
    <Paper
      style={{
        flex: 1,
        borderRadius: '1.5rem',
        padding: '2rem',
        color: colors.primary.main,
        height: '100%',
      }}
    >
      <Typography style={{ fontWeight: 700 }}>{title}</Typography>
      <CardDescription
        value={value.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        })}
        comparison={percent.toFixed(1)}
        description={description}
      />
      <div style={{ paddingTop: '0.5rem' }}>
        <VerticalBarChart data={data} />
      </div>
    </Paper>
  );
}

export default HistoricalChartCard;
