/* eslint-disable indent */
import Service from '../../services/Service';
import { userIsAdmin } from '../../utils/globalFunctions';
import { API_REQUEST } from '../actions/api';
import { logout } from '../actions/loggedUser';

// TODO validate requisition with token error by status code
const tokenErrorMessages = [
  'Token invalid',
  'Token is invalid',
  'No token provided',
  'Token malformatted',
  'Token error',
];

export const api = ({ dispatch, getState }) => (next) => (action) => {
  if (action.type === API_REQUEST) {
    const {
      method,
      url,
      onSuccess,
      onError,
      params,
      apiV2,
    } = action.meta;
    const { payload = {} } = action;
    payload.isAdmin = userIsAdmin(getState().loggedUser);

    switch (method) {
      case 'POST': {
        const apiService = new Service({
          route:
            payload && (payload.id || payload._id)
              ? `${url}/${payload.id || payload._id}`
              : url,
          apiV2,
        });
        apiService.store(payload).then((response) => {
          if (response.ok) {
            dispatch({ type: onSuccess, payload: response.data });
          } else {
            dispatch({ type: onError, payload: response.message });
          }
        });
        break;
      }
      case 'PUT': {
        const apiService = new Service({ route: url, apiV2 });
        apiService.put(payload.id || payload._id, payload).then((response) => {
          if (response.ok) {
            dispatch({ type: onSuccess, payload: response.data });
          } else {
            dispatch({ type: onError, payload: response.message });
          }
        });
        break;
      }
      case 'GET-id': {
        const apiService = new Service({
          route: `${url}/${payload.id || payload._id}`,
          apiV2,
        });
        apiService
          .get(payload)
          .then((response) =>
            dispatch({ type: onSuccess, payload: response.data }),
          )
          .catch((error) => dispatch({ type: onError, payload: error }));
        break;
      }
      default: {
        const apiService = new Service({ route: url, apiV2 });
        apiService.get(payload, params).then((response) => {
          if (response.ok) {
            dispatch({ type: onSuccess, payload: response.data });
          } else if (tokenErrorMessages.includes(response.message)) {
            dispatch(logout());
          } else {
            dispatch({ type: onError, payload: response.message });
          }
        });
        break;
      }
    }
  }
  return Promise.resolve(next(action));
};
