import {
  GET_DAILY_EXPECTED_PAYMENTS,
  UPDATE_DAILY_EXPECTED_PAYMENTS,
  FETCH_DAILY_EXPECTED_PAYMENTS_SUCCESS,
} from '../actions/audit';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
  totalValue: 0,
};

export function auditReducer(dailyExpectedPayments = initState, action) {
  switch (action.type) {
    case GET_DAILY_EXPECTED_PAYMENTS: {
      return action.payload;
    }
    case UPDATE_DAILY_EXPECTED_PAYMENTS: {
      return action.payload;
    }

    case FETCH_DAILY_EXPECTED_PAYMENTS_SUCCESS: {
      return action.payload;
    }

    default:
      return dailyExpectedPayments;
  }
}
