import { Paper as MuiPaper, Tabs as MuiTabs, Tab as MuiTab } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 24px;
`;

export const Paper = withStyles((theme) => ({
  root: {
    borderRadius: 0,
    padding: theme.spacing(2),
  },
}))(MuiPaper);

export const Tabs = withStyles({
  flexContainer: { gap: 12 },
  indicator: { display: 'none' },
})(MuiTabs);

export const Tab = withStyles({
  root: {
    background: '#E7E7EA',
    borderRadius: '16px 16px 0 0',
    color: '#133DA5',
    textTransform: 'none',
    '&.shortage': {
      background: '#F8BCAE',
    },
  },
  selected: {
    background: '#FFF',
  },

})(MuiTab);

export const Content = styled.div<{selected: boolean}>`
  display: ${({ selected }) => (selected ? 'block' : 'none')};
`;

export const EmptyMessage = styled.span`
  display:block;
  margin: 32px 0;
  text-align: center;
  font-weight: 500;
`;
