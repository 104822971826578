import { Collapse, Tooltip } from '@material-ui/core';
import React from 'react';
import { SupplierOrder } from '../../../../interfaces/orders';
import Block from '../../../../sharedComponents/OrderCard/components/Block';
import {
  ActionContainer,
  ExpandIcon,
  Title,
  Value,
} from '../../../../sharedComponents/OrderCard/styles';
import {
  Container,
  Highlight,
  CommonData,
  ProductsContainer,
  ProductRow,
  ProductName,
  Column,
  SupplierName,
} from './styles';

const supplierOrderFields: { [key: string]: string } = {
  supplierOrderNumber: 'Número do pedido',
  status: 'Status',
  deliveryDate: 'Previsão de entrega',
};

const productFields: { [key: string]: string } = {
  eanCode: 'Código EAN',
  quantity: 'Quantidade',
  price: 'Preço',
  total: 'Total',
};

const SupplierCard: React.FC<{ supplierOrder: SupplierOrder }> = ({
  supplierOrder,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const toggle = () => {
    setOpen((currentState) => !currentState);
  };

  const accessibleSupplierOrder: { [key: string]: unknown } = {
    ...supplierOrder,
  };

  return (
    <>
      <Container onClick={toggle}>
        <Highlight>
          Fornecedor
          <br />
          <Tooltip title={supplierOrder.supplier.corporateName}>
            <SupplierName>{supplierOrder.supplier.corporateName}</SupplierName>
          </Tooltip>
        </Highlight>
        <CommonData>
          {Object.keys(supplierOrderFields).map((field) => (
            <Block key={field}>
              <Title>{supplierOrderFields[field]}</Title>
              <Value>{accessibleSupplierOrder[field] as string}</Value>
            </Block>
          ))}
          <ActionContainer open={open}>
            <ExpandIcon className="expandIcon">expand_more</ExpandIcon>
          </ActionContainer>
        </CommonData>
      </Container>
      <Collapse in={open}>
        <ProductsContainer>
          {supplierOrder.orderProducts.map((product) => {
            const accessibleProduct: { [key: string]: unknown } = {
              ...product,
            };
            return (
              <ProductRow key={product.id}>
                <ProductName>{product.productDescription}</ProductName>
                {Object.keys(productFields).map((field) => (
                  <Column key={field}>
                    <Title>{productFields[field]}</Title>
                    <Value>{accessibleProduct[field] as string}</Value>
                  </Column>
                ))}
              </ProductRow>
            );
          })}
        </ProductsContainer>
      </Collapse>
    </>
  );
};

export default SupplierCard;
