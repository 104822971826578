import {
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_ERROR,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_ERROR,
  GET_PRODUCT,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  updateObjectProduct,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/product';
import { getProducts } from '../actions/products';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'sellableProducts';

export const createProductFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_PRODUCT) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_PRODUCT_SUCCESS,
        UPDATE_PRODUCT_ERROR,
        {},
        true,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateProductFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_PRODUCT) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_PRODUCT_SUCCESS,
        UPDATE_PRODUCT_ERROR,
        {},
        true,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getProductFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_PRODUCT) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        `${URL}/index`,
        { _id },
        FETCH_PRODUCT_SUCCESS,
        FETCH_PRODUCT_ERROR,
        {},
        true,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processProductCollection = ({
  dispatch,
  getState,
}) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_PRODUCT_SUCCESS) {
    dispatch(updateObjectProduct(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_PRODUCT_SUCCESS) {
    dispatch(updateObjectProduct(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    // To reload the page
    const { page, limit } = getState().products;
    dispatch(
      getProducts({
        page,
        limit,
        filter: { customer: getState().customer?._id },
      }),
    );
    Toastify.addSuccess('Operação concluída com sucesso.');
  } else if (action.type === UPDATE_PRODUCT_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(
      action.payload || 'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
    );
    console.error(action.payload);
  }
};

export const productMdl = [
  createProductFlow,
  updateProductFlow,
  getProductFlow,
  processProductCollection,
];
