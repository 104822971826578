export const GET_PRODUCT = '[product] GET';
export const UPDATE_PRODUCT = '[product] UPDATE';
export const CREATE_PRODUCT = '[product] CREATE';
export const DELETE_PRODUCT = '[product] DELETE';
export const UPDATE_OBJECT_PRODUCT = '[product] UPDATE OBJECT PRODUCT';
// UI
export const SHOW_PRODUCT_MODAL = '[product] SHOW MODAL';
export const HIDE_PRODUCT_MODAL = '[product] HIDE MODAL';
// Spinner
export const UPDATE_PRODUCT_SUCCESS = '[product] Update success';
export const UPDATE_PRODUCT_ERROR = '[product] Update Error';
export const FETCH_PRODUCT_SUCCESS = '[product] Fetch success';
export const FETCH_PRODUCT_ERROR = '[product] Fetch Error';
export const SHOW_PRODUCT_SPINNER = '[product - ui] show spinner';
export const HIDE_PRODUCT_SPINNER = '[product - ui] hide spinner';

export const getProduct = (id) => ({
  type: GET_PRODUCT,
  payload: { _id: id },
});

export const createProduct = (data) => ({
  type: CREATE_PRODUCT,
  payload: data,
});

export const updateProduct = (data) => ({
  type: UPDATE_PRODUCT,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_PRODUCT_MODAL,
});

export const hideModal = () => ({
  type: HIDE_PRODUCT_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_PRODUCT_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PRODUCT_SPINNER,
});

export const updateObjectProduct = (data) => ({
  type: UPDATE_OBJECT_PRODUCT,
  payload: data,
});
