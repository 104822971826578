export const GET_PAYMENT_MACHINES = '[paymentMachines] GET';
export const FETCH_PAYMENT_MACHINES_SUCCESS = '[paymentMachines] Fetch success';
export const FETCH_PAYMENT_MACHINES_ERROR = '[paymentMachines] Fetch Error';
export const UPDATE_PAYMENT_MACHINES = '[paymentMachines] Update';
export const SHOW_PAYMENT_MACHINES_SPINNER =
  '[paymentMachines - ui] show spinner';
export const HIDE_PAYMENT_MACHINES_SPINNER =
  '[paymentMachines - ui] hide spinner';

export const getPaymentMachines = (filter) => ({
  type: GET_PAYMENT_MACHINES,
  payload: filter,
});

export const updatePaymentMachines = (data) => ({
  type: UPDATE_PAYMENT_MACHINES,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_PAYMENT_MACHINES_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PAYMENT_MACHINES_SPINNER,
});
