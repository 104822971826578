import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import { CheckCircle, CancelRounded } from '@material-ui/icons';
import { useStyles } from './styles';

const SubTable = (props) => {
  const { registers } = props;
  const classes = useStyles();

  return (
    <>
      <Box margin={1}>
        <Typography variant="h6" gutterBottom component="div">
          Histórico
        </Typography>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell} align="center">
                Ativo
              </TableCell>
              <TableCell className={classes.headerCell} align="center">
                Tipo de pagamento
              </TableCell>
              <TableCell className={classes.headerCell} align="center">
                Comissão
              </TableCell>
              <TableCell className={classes.headerCell} align="center">
                Válido desde
              </TableCell>
              <TableCell className={classes.headerCell} align="center">
                Válido até
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {registers &&
              registers.map((register) => (
                <TableRow key={register._id}>
                  <TableCell className={classes.cell} align="center">
                    {register.active ? (
                      <CheckCircle className={classes.checkButton} />
                    ) : (
                      <CancelRounded className={classes.closeButton} />
                    )}
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    {register.paymentKind}
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    {register.commissionValue}%
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    {register.validSinceFormatted}
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    {register.validUntilFormatted}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default SubTable;
