import styled from 'styled-components';

export const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 24px;
  padding-left: 24px;
  span {
    text-align: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  &::before {
    content: '';
    height: 100%;
    border: 1px solid #d1d4d6;
  }
  &:first-of-type::before {
    content: '';
    border: none;
  }
`;
