import styled from 'styled-components';

export const ListIcon = styled.div`
  display: flex;
  padding-right: 16px;
  padding-left: 8px;
  .react-icons {
    font-size: 1.6rem;
  }
  .MuiSvgIcon-root {
    color: #b9bbbd;
  }
`;
export const GridContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 16px;
  background-color: white;
  border-radius: 16px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: ${(props) =>
    props.isHovered
      ? 'rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;'
      : '0px 1px 3px 0px #00000033'};
`;

export const GridSpacer = styled.div`
  margin: 16px 0px;
`;
export const styles = {
  linkFont: {
    color: '#464255',
    textDecoration: 'none',
  },
};
