import React from 'react';
import Container from '../../sharedComponents/Container';
import SectionContent from '../../sharedComponents/SectionContent';
import SectionHeader from '../../sharedComponents/SectionHeader';

const PriceRelationships = () => (
  <Container id="section-container">
    <SectionHeader title="Análises de Relações de Preços" hideDatePicker />
    <SectionContent>
      <span>Sem dados disponíveis</span>
    </SectionContent>
  </Container>
);

export default PriceRelationships;
