/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bulkCreateTransfer } from '../../../../redux/actions/transfer';
import BulkOperationModal from '../../../../sharedComponents/BulkOperationModal';

const BulkCreateTransferModal = ({
  dispatch,
  handleClose,
  allCompanies,
  filterState = {},
}) => {
  const handleBulkCreate = async ({ companyDocuments, refDate }) => {
    dispatch(
      bulkCreateTransfer({
        payload: {
          companyDocuments,
          referenceDate: new Date(
            moment(refDate)
              .startOf('day')
              .utc(),
          ).toISOString(),
          approved: false,
        },
        metadata: { filterState },
      }),
    );
    handleClose();
  };

  return (
    <BulkOperationModal
      title="Criação massiva de Transferências"
      operationText="Gerar repasses"
      handleOperation={handleBulkCreate}
      handleClose={handleClose}
      companies={allCompanies}
    />
  );
};

export default connect((state) => ({
  allCompanies: state.companies.docs || state.companies,
}))(BulkCreateTransferModal);
