import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import OverviewCard from '../../../../sharedComponents/OverviewCard';

const physicalSaleCardNote = 'Valor referente somente a venda na loja física.';
const onlineSaleCardNote = 'Valor referente somente a venda na loja virtual.';

const OverviewCards = ({ data, isLoading }) => {
  const [todayReceiptsSum, setTodayReceiptsSum] = useState(0);
  const [onlineSalesSum, setOnlineSalesSum] = useState(0);
  const [ticketCount, setTicketCount] = useState(0);
  const [averageTicket, setAverageTicket] = useState('-');

  // Variáveis de responsividade
  const isBigger = useMediaQuery('(max-width:1287px)');
  const isMedium = useMediaQuery('(max-width:1140px)');
  const isTablet = useMediaQuery('(max-width:1023px)');
  const gridSpacing = isTablet ? 2 : 3;

  useEffect(() => {
    setTodayReceiptsSum(data.receiptsSum);
    setOnlineSalesSum(data.onlineSalesSum);
    setTicketCount(data.ticketCount);
    setAverageTicket(data.ticketCount ? data.receiptsSum / data.ticketCount : '-');
  }, [data]);

  const skeletonProps = {
    variant: 'rectangular',
    width: '100%',
    height: '100%',
    style: {
      minHeight: 150,
      borderRadius: 24,
    },
  };

  return (
    <div style={{ display: 'flex' }}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} sm={isTablet ? 6 : 3}>
          {isLoading ? (
            <Skeleton {...skeletonProps} />
          ) : (
            <OverviewCard
              value={todayReceiptsSum?.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
              })}
              colorTheme="primary"
              description={`Vendas totais${isBigger ? '' : ' do dia'}`}
              note={physicalSaleCardNote}
            />)}
        </Grid>
        <Grid item xs={12} sm={isTablet ? 6 : 3}>
          {isLoading ? (
            <Skeleton {...skeletonProps} />
          ) : (
            <OverviewCard
              value={ticketCount}
              colorTheme="success"
              description={`${
                isMedium ? 'T' : `${isBigger ? 'Nro' : 'Número'} de t`
              }ransações`}
              note={physicalSaleCardNote}
            />)}
        </Grid>
        <Grid item xs={12} sm={isTablet ? 6 : 3}>
          {isLoading ? (
            <Skeleton {...skeletonProps} />
          ) : (
            <OverviewCard
              value={averageTicket?.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
              })}
              colorTheme="secondary"
              description="Ticket médio"
              note={physicalSaleCardNote}
            />)}
        </Grid>
        <Grid item xs={12} sm={isTablet ? 6 : 3}>
          {isLoading ? (
            <Skeleton {...skeletonProps} />
          ) : (
            <OverviewCard
              value={onlineSalesSum?.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
              })}
              colorTheme="info"
              description="Venda Online"
              note={onlineSaleCardNote}
            />)}
        </Grid>
      </Grid>
    </div>
  );
};

export default OverviewCards;
