export default [
  { label: 'Consumidor', type: 'String', value: 'consumer' },
  { label: 'ID', type: 'String', value: 'transaction_code' },
  { label: 'Reference ID', type: 'String', value: 'reference_id' },
  { label: 'Tipo', type: 'String', value: 'type' },
  { label: 'Data', type: 'String', value: 'transaction_date' },
  { label: 'Status', type: 'String', value: 'status' },
  { label: 'Total', type: 'String', value: 'value' },
  { label: 'Taxa', type: 'String', value: 'fee' },
  { label: 'A receber', type: 'String', value: 'liquid_value' },
  { label: 'Operador', type: 'String', value: 'picpay_operator' },
];
