export const GET_TRANSFERS = '[transfers] GET';
export const FETCH_TRANSFERS_SUCCESS = '[transfers] Fetch success';
export const FETCH_TRANSFERS_ERROR = '[transfers] Fetch Error';
export const UPDATE_TRANSFERS = '[transfers] Update';
export const SHOW_TRANSFERS_SPINNER = '[transfers - ui] show spinner';
export const HIDE_TRANSFERS_SPINNER = '[transfers - ui] hide spinner';

export const getTransfers = (filter) => ({
  type: GET_TRANSFERS,
  payload: filter,
});

export const updateTransfers = (data) => ({
  type: UPDATE_TRANSFERS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_TRANSFERS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_TRANSFERS_SPINNER,
});
