import React from 'react';
import { Container, Title, Subtitle, CardsContainer } from './styles';
import TextCard from '../../sharedComponents/TextCard';

const PeopleManagement = () => {
  const cards = [
    {
      title: 'Operador de Caixa',
      subtitle: 'Treinamento Completo',
      description:
        'Público-alvo: Interessados e pessoas atuantes em empresas de panificação e confeitaria. Focado na atuação dos colaboradores da área de atendimento dessas empresas, mas especificamente, no caixa.',
      badges: [
        {
          label: 'Operacional',
          color: '#58CDFF',
        },
      ],
    },
    {
      title: 'Como transformar a reclamação do cliente em oportunidade',
      subtitle: '',
      description:
        'Público-alvo: Empresários e colaboradores do setor de panificação e confeitaria que são interessados em geral.',
      badges: [
        {
          label: 'Operacional',
          color: '#58CDFF',
        },
        {
          label: 'Gerencial',
          color: '#FFBB54',
        },
      ],
    },
    {
      title: 'Capacitação para Atuação Eficiente na Produção',
      subtitle: '',
      description:
        'Público-alvo: Interessados e pessoas atuantes em empresas de panificação e confeitaria. Focado na atuação dos colaboradores da área de produção dessas empresas.',
      badges: [
        {
          label: 'Operacional',
          color: '#58CDFF',
        },
      ],
    },
    {
      title: 'Como ter Preços Competitivos',
      subtitle: '',
      description:
        'Público-alvo: Empresários e colaboradores de nível gerencial de empresas do setor de panificação e confeitaria.',
      badges: [
        {
          label: 'Gerencial',
          color: '#FFBB54',
        },
      ],
    },
    {
      title: 'Gestão de Pessoas (ex de feito)',
      subtitle: 'Treinamento Completo',
      description:
        'Público-alvo: Interessados e pessoas atuantes em empresas de panificação e confeitaria. Gerentes e proprietários das empresas que querem conhecer formas apropriadas de treinar seus colaboradores para um atendimento de qualidade.',
      badges: [
        {
          label: 'Gerencial',
          color: '#FFBB54',
        },
      ],
    },
    {
      title: 'Gestão de Perdas',
      subtitle: '',
      description:
        'Público-alvo: Interessados, empresários e colaboradores do setor de panificação e confeitaria que querem saber sobre as estratégias para gerir melhor as perdas da empresa.',
      badges: [
        {
          label: 'Operacional',
          color: '#58CDFF',
        },
        {
          label: 'Gerencial',
          color: '#FFBB54',
        },
      ],
    },
    {
      title: 'Gerenciamento de Custos',
      subtitle: '',
      description:
        'Público-alvo: Interessados e pessoas atuantes em empresas de todos os portes com fabricação própria. Profissionais envolvidos na execução de tarefas as quais o conteúdo se aplica.',
      badges: [
        {
          label: 'Operacional',
          color: '#58CDFF',
        },
        {
          label: 'Gerencial',
          color: '#FFBB54',
        },
      ],
    },
  ];

  return (
    <Container>
      <Title>Gestão de Pessoas</Title>
      <Subtitle>
        Pagina de gerenciamento e treinamento dos colaboradores.
      </Subtitle>
      <CardsContainer>
        {cards && cards.map((el) => <TextCard {...el} />)}
      </CardsContainer>
    </Container>
  );
};

export default PeopleManagement;
