import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
} from '@material-ui/core';
import Alert from '../../../sharedComponents/Alert/index';
import Spinner from '../../../sharedComponents/Spinner/index';
import { adminGridItems } from '../../../sharedComponents/Navigation/components/MenuDrawer/menuItems';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
  SwitchBox,
  StyledPaper,
  FormButton,
} from './styles';

import { getRole, createRole, updateRole } from '../../../redux/actions/role';
import { sortByAlphabeticalOrder } from '../../../utils/globalFunctions';

const RoleModal = ({
  id,
  dispatch,
  role,
  roleModal,
  handleClose,
  roleSpinner,
}) => {
  const [active, setActive] = useState(true);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getRole(id));
    }
  }, [id]);

  const handleChangePermission = (value, path, field) => {
    const clone = JSON.parse(JSON.stringify(permissions));
    if (clone[path] && clone[path][field]) {
      clone[path][field] = value;
    } else {
      clone[path] = {
        ...clone[path],
        [field]: value,
      };
    }
    setPermissions(clone);
  };

  useEffect(() => {
    if (role && id !== 'new') {
      setActive(role.active);
      setName(role.name);
      setIsAdmin(role.isAdmin);
      setPermissions(role.permissions || {});
    }
  }, [role]);

  useEffect(() => {
    // Close the modal after update the roles list
    if (!roleModal) {
      handleClose();
    }
  }, [roleModal]);

  const handleSave = async () => {
    let err = false;
    setNameError(!name);
    err = !name;

    if (!err) {
      const response = await Alert();
      if (response?.confirm) {
        if (id === 'new') {
          dispatch(
            createRole({
              active,
              name,
              isAdmin,
              permissions,
            }),
          );
        } else {
          dispatch(
            updateRole({
              id,
              active,
              name,
              isAdmin,
              permissions,
            }),
          );
        }
      }
    }
  };

  return (
    <>
      {roleSpinner && <Spinner overlay />}
      <StyledPaper
        elevation={2}
        fullWidth
        style={{ maxWidth: '90vw', height: 'fit-content' }}
      >
        <Container style={{ opacity: roleSpinner ? 0.5 : 1 }}>
          <ModalTitle>
            {id === 'new' ? 'Adicionar' : 'Editar'} Perfil
          </ModalTitle>
          <FormGroup>
            <CheckboxContent>
              <FormControlLabel
                control={
                  <Switch
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                  />
                }
                label="Ativo"
                labelPlacement="start"
              />
            </CheckboxContent>
            <InputContent>
              <TextField
                id="outlined-password-input"
                label="Nome"
                type="text"
                error={nameError}
                helperText={nameError && 'Nome é obrigatório'}
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </InputContent>
            <SwitchBox>
              <InputContent>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isAdmin}
                      onChange={(e) => setIsAdmin(e.target.checked)}
                    />
                  }
                  labelPlacement="start"
                  label="Administrador"
                />
              </InputContent>
            </SwitchBox>
            {isAdmin && (
              <InputContent>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          Módulo
                        </TableCell>
                        <TableCell align="center">Leitura</TableCell>
                        <TableCell align="center">Criação</TableCell>
                        <TableCell align="center">Edição</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {adminGridItems
                        .sort(sortByAlphabeticalOrder('label'))
                        .map((module) => (
                          <TableRow key={module.path}>
                            <TableCell component="th" scope="row">
                              {module.label}
                            </TableCell>
                            <TableCell align="center">
                              <FormControlLabel
                                control={
                                  <Switch
                                    key={permissions[module.path]}
                                    checked={permissions[module.path]?.read}
                                    onChange={(e) =>
                                      handleChangePermission(
                                        e.target.checked,
                                        module.path,
                                        'read',
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                            <TableCell align="center">
                              <FormControlLabel
                                control={
                                  <Switch
                                    key={permissions[module.path]}
                                    checked={permissions[module.path]?.create}
                                    onChange={(e) =>
                                      handleChangePermission(
                                        e.target.checked,
                                        module.path,
                                        'create',
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                            <TableCell align="center">
                              <FormControlLabel
                                control={
                                  <Switch
                                    key={permissions[module.path]}
                                    checked={permissions[module.path]?.update}
                                    onChange={(e) =>
                                      handleChangePermission(
                                        e.target.checked,
                                        module.path,
                                        'update',
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </InputContent>
            )}
            <ButtonContainer>
              <FormButton
                color="secondary"
                onClick={handleClose}
                variant="contained"
              >
                Cancelar
              </FormButton>
              <FormButton
                color="primary"
                onClick={handleSave}
                variant="contained"
              >
                Salvar
              </FormButton>
            </ButtonContainer>
          </FormGroup>
        </Container>
      </StyledPaper>
    </>
  );
};

export default connect((state) => ({
  role: state.role,
  roleModal: state.ui.roleModal,
  roleSpinner: state.ui.roleLoading,
}))(RoleModal);
