import { MenuItem, Icon } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardArrowDown } from '@material-ui/icons';
import React from 'react';
import { labelStyle, Select, selectStyle } from './styles';

function StatelessCompanySelector({
  style,
  value,
  companies,
  disabled,
  onChange,
  fullWidth,
  disableClearing,
  handleClearCompany,
}) {
  const selectClasses = selectStyle();
  const labelClasses = labelStyle();
  return (
    <Select
      id="company-selector-select"
      variant="outlined"
      select
      disabled={disabled}
      value={value || ''}
      onChange={onChange}
      label={value ? '' : 'Selecione'}
      SelectProps={{
        classes: selectClasses,
        IconComponent:
          value && !disableClearing
            ? () => (
              <>
                <KeyboardArrowDown />
                <IconButton onClick={handleClearCompany}>
                  <Icon>close</Icon>
                </IconButton>
              </>
            )
            : KeyboardArrowDown,
      }}
      InputLabelProps={{ shrink: false, classes: labelClasses }}
      style={style}
      fullWidth={fullWidth}
      InputProps={{
        startAdornment: (
          <Icon
            style={{ color: '#192D4D', marginRight: '0.5rem' }}
            className="material-icons-outlined"
          >
            place
          </Icon>
        ),
      }}
    >
      {companies?.map(({ id, name }) => (
        <MenuItem value={id} key={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
}

export default StatelessCompanySelector;
