import { UPDATE_COMPETITORS } from '../actions/competitors';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function competitorsReducer(competitors = initState, action) {
  switch (action.type) {
    case UPDATE_COMPETITORS:
      return action.payload;

    default:
      return competitors;
  }
}
