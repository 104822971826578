import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, IconButton, MenuItem } from '@material-ui/core';
import Alert from '../../../sharedComponents/Alert/index';
import CompanySelector from '../../../sharedComponents/CompanySelector';
import Input from '../../../sharedComponents/Input';
import CurrencyField from '../../../sharedComponents/CurrencyField';
import Spinner from '../../../sharedComponents/Spinner/index';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  StyledPaper,
  FormButton,
  ModalHead,
  StyledIcon,
} from './styles';

import {
  getWeeklyTax,
  createWeeklyTax,
  updateWeeklyTax,
} from '../../../redux/actions/weeklyTax';
import { ISO_WEEKDAY_MAP } from '../../../config/consts';

const WeeklyTaxesModal = ({
  id,
  dispatch,
  weeklyTax,
  weeklyTaxModal,
  handleClose,
  weeklyTaxSpinner,
  setDate,
}) => {
  const [weekDay, setWeekDay] = useState('');
  const [weekDayError, setWeekDayError] = useState('');
  const [company, setCompany] = useState('');
  const [companyError, setCompanyError] = useState(false);
  const [value, setValue] = useState('');
  const [valueError, setValueError] = useState(false);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getWeeklyTax(id));
    }
  }, [id]);

  useEffect(() => {
    if (weeklyTax && id !== 'new') {
      setWeekDay(weeklyTax.dayOfWeek);
      setValue(weeklyTax.payload.value);
      setCompany(weeklyTax.company?.document);
    }
  }, [weeklyTax]);

  useEffect(() => {
    if (!weeklyTaxModal) {
      handleClose();
    }
  }, [weeklyTaxModal]);

  const handleSave = async () => {
    let err = false;
    setCompanyError(!company);
    setValueError(!value);
    setWeekDayError(!weekDay);
    err = !weekDay || !company || !value;

    if (!err) {
      const response = await Alert();
      if (response?.confirm) {
        setDate(null);
        if (id === 'new') {
          dispatch(
            createWeeklyTax({
              companyDocument: company,
              payload: { value },
              dayOfWeek: weekDay,
              kind: 'CHARGE',
            }),
          );
        } else {
          dispatch(
            updateWeeklyTax({
              id,
              companyDocument: company,
              payload: { value },
              kind: 'CHARGE',
              dayOfWeek: weekDay,
            }),
          );
        }
      }
    }
  };

  const handleCurrency = (_, val) => {
    setValue(val);
  };

  return (
    <>
      {weeklyTaxSpinner && <Spinner overlay />}
      <StyledPaper elevation={2}>
        <Container style={{ opacity: weeklyTaxSpinner ? 0.5 : 1 }}>
          <ModalHead>
            <ModalTitle>
              {id === 'new' ? 'Adicionar' : 'Editar'} Taxa Semanal
            </ModalTitle>
            <IconButton>
              <StyledIcon onClick={handleClose}>close</StyledIcon>
            </IconButton>
          </ModalHead>
          <FormGroup>
            <InputContent>
              <CompanySelector
                helperText="Informe a unidade."
                label="Unidade"
                fullWidth
                onChange={setCompany}
                value={company}
                fieldToGet="document"
              />
            </InputContent>
            <InputContent>
              <Input
                value={weekDay}
                error={weekDayError}
                name="weekDay"
                helperText={weekDayError && 'Informe o valor da taxa.'}
                label="Dia da semana"
                select
                required
                fullWidth
                icon="calendar_month"
                onChange={(e) => setWeekDay(e.target.value)}
              >
                {Object.keys(ISO_WEEKDAY_MAP).map((day) => (
                  <MenuItem value={day}>{ISO_WEEKDAY_MAP[day]}</MenuItem>
                ))}
              </Input>
            </InputContent>
            <CurrencyField
              value={value}
              error={valueError}
              name="value"
              helperText={valueError && 'Informe o valor da taxa.'}
              placeholder="Valor da Taxa*"
              required
              onChange={handleCurrency}
            />
            <ButtonContainer>
              <FormButton
                color="secondary"
                onClick={handleClose}
                variant="contained"
              >
                Cancelar
              </FormButton>
              <FormButton
                color="primary"
                onClick={handleSave}
                variant="contained"
              >
                Salvar
              </FormButton>
            </ButtonContainer>
          </FormGroup>
        </Container>
      </StyledPaper>
    </>
  );
};

export default connect((state) => ({
  weeklyTax: state.weeklyTax,
  weeklyTaxModal: state.ui.weeklyTaxModal,
  weeklyTaxSpinner: state.ui.weeklyTaxLoading,
}))(WeeklyTaxesModal);
