import {
  UPDATE_OBJECT_WEEKLY_TAX,
} from '../actions/weeklyTax';

export function weeklyTaxReducer(weeklyTax = null, action) {
  switch (action.type) {
    case UPDATE_OBJECT_WEEKLY_TAX:
      return action.payload;

    default:
      return weeklyTax;
  }
}
