import React, { useState } from 'react';
import Lottie from 'react-lottie';
import { Container } from './styles';
import animationLottie from '../../assets/animations/404Animation.json';

export default function NotFound() {
  const [animation] = useState({
    loop: true,
    autoplay: true,
    animationData: animationLottie,
  });

  const styles = {
    animation: {
      height: '90vh',
    },
  };

  return (
    <Container>
      <Lottie
        options={animation}
        isClickToPauseDisabled
        style={styles.animation}
      />
    </Container>
  );
}
