import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormGroup,
  MenuItem,
  Button,
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DatePicker } from '@material-ui/pickers';
import Alert from '../../../../sharedComponents/Alert/index';
import Spinner from '../../../../sharedComponents/Spinner/index';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  StyledInputContainer,
  StyledInputTitle,
  useStyles,
} from './styles';

import { createCommissioning } from '../../../../redux/actions/commissioning';

import { PAYMENT_KINDS } from '../../../../config/consts';

const CreateCommissioningModal = ({
  dispatch,
  commissioningModal,
  handleClose,
  commissioningSpinner,
  allCompanies,
}) => {
  const classes = useStyles();
  const [companyDocument, setCompanyDocument] = useState('');
  const [commissionValues, setCommissionValues] = useState([
    ...new Array(PAYMENT_KINDS.length),
  ]);
  const [validSince, setValidSince] = useState(null);

  const [companyDocumentError, setCompanyDocumentError] = useState(false);
  const [commissionValueError, setCommissionValueError] = useState(
    [...new Array(PAYMENT_KINDS.length)].map(() => false),
  );

  useEffect(() => {
    if (!commissioningModal) {
      handleClose();
    }
  }, [commissioningModal]);

  const handleSave = async () => {
    setCompanyDocumentError(!companyDocument);
    setCommissionValueError(
      commissionValues.map(
        (value) => (!value && value !== 0) || value > 100 || value < 0,
      ),
    );
    const haveErrors = [
      !companyDocument,
      ...commissionValues.map((value) => !value && value !== 0),
    ].some((error) => !!error);

    if (haveErrors) {
      return;
    }

    const response = await Alert();
    if (response && response.confirm) {
      const paymentKindMap = {};
      PAYMENT_KINDS.sort().forEach((kind, index) => {
        paymentKindMap[kind] = commissionValues[index];
      });
      dispatch(
        createCommissioning({
          companyDocument,
          paymentKindMap: JSON.stringify(paymentKindMap),
          validSince,
        }),
      );
    }
  };

  const handleCompanyChange = (event) => {
    const newCompanyDocument = event.target.value;
    setCompanyDocument(newCompanyDocument);
    setCompanyDocumentError(false);
  };

  const setCommissionValue = (value, index) => {
    const commissionValuesCopy = [...commissionValues];
    commissionValuesCopy[index] = value;
    setCommissionValues(commissionValuesCopy);
  };

  return (
    <>
      {commissioningSpinner && <Spinner overlay />}
      <Paper elevation={2} style={{ height: 'fit-content' }} fullWidth>
        <Container style={{ opacity: commissioningSpinner ? 0.5 : 1 }}>
          <ModalTitle>Adicionar registros de comissionamento</ModalTitle>
          <FormGroup style={{ flexWrap: 'inherit' }}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              error={companyDocumentError}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Padaria*
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={companyDocument}
                onChange={handleCompanyChange}
                label="Padaria*"
              >
                {allCompanies &&
                  allCompanies?.map(({ id, document, name }) => (
                    <MenuItem key={id} value={document}>
                      {name}
                    </MenuItem>
                  ))}
              </Select>
              {companyDocumentError && (
                <FormHelperText>O campo Padaria é obrigatório</FormHelperText>
              )}
            </FormControl>
            {PAYMENT_KINDS.sort().map((item, index) => (
              <StyledInputContainer>
                <StyledInputTitle>{item}</StyledInputTitle>
                <InputContent style={{ maxWidth: 400 }}>
                  <TextField
                    label={`Percentagem da comissão para ${item}*`}
                    type="number"
                    error={
                      commissionValueError[index] || commissionValues[index] < 0
                    }
                    helperText={
                      commissionValueError[index] &&
                      'Porcentagem de comissão inválida'
                    }
                    variant="outlined"
                    value={commissionValues[index]}
                    onChange={(event) => {
                      let { value } = event.target;
                      if (value < 0) {
                        value = 0;
                        // eslint-disable-next-line no-param-reassign
                        event.target.value = 0;
                      }
                      setCommissionValue(value, index);
                    }}
                    onBlur={(event) => {
                      const { value } = event.target;
                      const newErrors = [...commissionValueError];
                      newErrors[index] =
                        (!value && value !== 0) || value > 100 || value < 0;
                      setCommissionValueError(newErrors);
                    }}
                    fullWidth
                    autoComplete="disabled"
                  />
                </InputContent>
              </StyledInputContainer>
            ))}
            <DatePicker
              className={classes.datePickerContainer}
              label="Válido desde"
              inputVariant="outlined"
              value={validSince}
              onChange={(newDate) =>
                setValidSince(moment(newDate).startOf('day'))
              }
              format="dd/MM/yyyy"
              minDate={new Date('2021-01-01T00:00:00.000-03:00')}
              helperText="Com base no horário de Brasília (GMT-03)"
            />
            <ButtonContainer>
              <Button
                color="secondary"
                onClick={handleClose}
                variant="contained"
                className={classes.formButton}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={handleSave}
                variant="contained"
                className={classes.formButton}
              >
                Salvar
              </Button>
            </ButtonContainer>
          </FormGroup>
        </Container>
      </Paper>
    </>
  );
};

export default connect((state) => ({
  commissioningModal: state.ui.commissioningModal,
  commissioningSpinner: state.ui.commissioningLoading,
  allRoles: state.roles.docs,
  loggedUser: state.loggedUser,
  allCompanies: state.companies.docs || state.companies,
}))(CreateCommissioningModal);
