export const GET_PROVIDER = '[provider] GET';
export const UPDATE_PROVIDER = '[provider] UPDATE';
export const CREATE_PROVIDER = '[provider] CREATE';
export const DELETE_PROVIDER = '[provider] DELETE';
export const UPDATE_OBJECT_PROVIDER = '[provider] UPDATE OBJECT PROVIDER';
// UI
export const SHOW_PROVIDER_MODAL = '[provider] SHOW MODAL';
export const HIDE_PROVIDER_MODAL = '[provider] HIDE MODAL';
// Spinner
export const UPDATE_PROVIDER_SUCCESS = '[provider] Update success';
export const UPDATE_PROVIDER_ERROR = '[provider] Update Error';
export const FETCH_PROVIDER_SUCCESS = '[provider] Fetch success';
export const FETCH_PROVIDER_ERROR = '[provider] Fetch Error';
export const SHOW_PROVIDER_SPINNER = '[provider - ui] show spinner';
export const HIDE_PROVIDER_SPINNER = '[provider - ui] hide spinner';

export const getProvider = (id) => ({
  type: GET_PROVIDER,
  payload: { _id: id },
});

export const createProvider = (data) => ({
  type: CREATE_PROVIDER,
  payload: data,
});

export const updateProvider = (data) => ({
  type: UPDATE_PROVIDER,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_PROVIDER_MODAL,
});

export const hideModal = () => ({
  type: HIDE_PROVIDER_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_PROVIDER_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PROVIDER_SPINNER,
});

export const updateObjectProvider = (data) => ({
  type: UPDATE_OBJECT_PROVIDER,
  payload: data,
});
