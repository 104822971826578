import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import { useMediaQuery } from '@material-ui/core';
import { Container } from './styles';
import SectionHeader from '../../sharedComponents/SectionHeader';
import SectionContent from '../../sharedComponents/SectionContent';
import DetailCards, { comparisonDays } from './components/DetailCards';
import OverviewCards from './components/OverviewCards';
import WarningCard from '../../sharedComponents/WarningCard';
import {
  getCompanyDocumentFilter,
  arraySum,
  isEmptyObject,
  getFormattedDataPerDay,
} from '../../utils/globalFunctions';
import Toastify from '../../utils/Toastify';
import { HomeProvider } from '../../providers/Home';

const Sales = ({ loggedUser, company, companies }) => {
  const [refDate, setRefDate] = useState(new Date());
  const warning = loggedUser.group?.metadata?.warningText;

  const isTablet = useMediaQuery('(max-width:1020px)');

  const [dailyData, setDailyData] = useState([]);
  const [dailyDataLoading, setDailyDataLoading] = useState(true);
  const [historicalData, setHistoricalData] = useState([]);
  const [historicalDataLoading, setHistoricalDataLoading] = useState(true);

  const [lastUpdate, setLastUpdate] = useState();
  const [lastUpdateLoading, setLastUpdateLoading] = useState(true);
  const [overviewCardsData, setOverviewCardsData] = useState({});
  const [overviewCardsLoading, setOverviewCardsLoading] = useState(true);
  const [detailCardsData, setDetailCardsData] = useState({});
  const [detailCardsLoading, setDetailCardsLoading] = useState(true);

  const receiptsReducer = (dayData) => {
    const dayValue = arraySum(dayData.map((row) => row.receipts_sum_cents));
    return dayValue / 100;
  };

  const customersReducer = (dayData) => {
    const groupsIncluded = [];
    return arraySum(
      dayData.map((row) => {
        const rowCompanyData = companies?.docs?.find((companyData) => companyData.document === row.company_document);
        if (!groupsIncluded.includes(rowCompanyData?.group_id)) {
          groupsIncluded.push(rowCompanyData?.group_id);
          return row.accumulated_customers;
        }
        return null;
      }),
    );
  };

  useEffect(() => {
    let mounted = true;

    setLastUpdateLoading(true);
    setDailyDataLoading(true);
    setHistoricalDataLoading(true);
    setOverviewCardsLoading(true);
    setDetailCardsLoading(true);

    HomeProvider.fetchDailyData(
      getCompanyDocumentFilter({}, loggedUser),
      refDate,
    )
      .then((response) => {
        if (mounted) {
          setDailyData(response);
          setDailyDataLoading(false);
        }
      })
      .catch((error) => {
        if (error.message === 'Token invalid') {
          return;
        }
        Toastify.addError(
          'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
          error,
        );
      });

    HomeProvider.fetchHistoricalData(
      getCompanyDocumentFilter({}, loggedUser),
      refDate,
      comparisonDays,
    )
      .then((response) => {
        if (mounted) {
          setHistoricalData(response);
          setHistoricalDataLoading(false);
        }
      })
      .catch((error) => {
        if (error.message === 'Token invalid') {
          return;
        }
        Toastify.addError(
          'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
          error,
        );
      });
    return () => { mounted = false; };
  }, [refDate]);

  useEffect(() => {
    if (!dailyDataLoading) {
      setLastUpdateLoading(true);
      setOverviewCardsLoading(true);
      const filteredData = isEmptyObject(company) ?
        dailyData : dailyData.filter((e) => e.company_document === company.document);
      setLastUpdate(
        filteredData.reduce((acc, curr) => {
          const currMoment = moment(curr.last_daily_common_update).utc();
          return moment.max(acc, currMoment);
        }, moment(refDate).subtract(1, 'days').utc()),
      );
      setLastUpdateLoading(false);
      setOverviewCardsData({
        receiptsSum: arraySum(filteredData, 'receipts_sum_cents') / 100,
        ticketCount: arraySum(filteredData, 'ticket_count'),
        onlineSalesSum: arraySum(filteredData, 'online_sales_revenue_cents') / 100,
      });
      setOverviewCardsLoading(false);
    }
  }, [dailyDataLoading, company]);

  useEffect(() => {
    if (!dailyDataLoading && !historicalDataLoading && !isEmptyObject(companies)) {
      setDetailCardsLoading(true);
      const filteredData = isEmptyObject(company) ?
        historicalData : historicalData.filter((e) => e.company_document === company.document);
      const filteredDailyData = isEmptyObject(company) ?
        dailyData : dailyData.filter((e) => e.company_document === company.document);
      setDetailCardsData({
        receiptsSum: getFormattedDataPerDay(filteredData, 'reference_date', receiptsReducer),
        customers: getFormattedDataPerDay(filteredData, 'reference_date', customersReducer),
        paymentKind: {
          total: arraySum(filteredDailyData, 'receipts_sum_cents') / 100,
          cash: arraySum(filteredDailyData, 'cash_receipts_sum_cents') / 100,
          bakery: arraySum(filteredDailyData, 'bakery_revenue_cents') / 100,
        },
      });
      setDetailCardsLoading(false);
    }
  }, [dailyDataLoading, historicalDataLoading, company, companies]);

  return (
    <>
      <Container id="section-container">
        <SectionHeader
          title="Dashboard"
          subtitle={
            lastUpdateLoading ? (
              <Skeleton variant="text" />
            ) : lastUpdate ? (
              `Atualizado em ${lastUpdate.utcOffset(-3).format('DD/MM/YYYY [às] HH:mm')}`
            ) : (
              'Sem dados'
            )
          }
          refDate={refDate}
          onSelectDateCallback={setRefDate}
          defaultComponentsContainerStyle={{ width: isTablet ? '100%' : 'auto' }}
        />
        <SectionContent>
          {warning && <WarningCard warningText={warning} />}
          <OverviewCards data={overviewCardsData} isLoading={overviewCardsLoading} />
          <DetailCards data={detailCardsData} isLoading={detailCardsLoading} refDate={refDate} />
        </SectionContent>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedUser: state.loggedUser,
  company: state.company,
  companies: state.companies,
});

export default connect(mapStateToProps)(Sales);
