import React from 'react';
import { DatePicker as MaterialDatePicker } from '@material-ui/pickers';
import { Icon, IconButton } from '@material-ui/core';
import { StyledTextField } from '../../styles';

export default function CustomDatePicker({ refDate, onChangeCallback }) {
  return (
    <MaterialDatePicker
      disableToolbar
      variant="inline"
      format="dd/MM/yyyy"
      value={refDate}
      label="Data"
      onChange={onChangeCallback}
      InputLabelProps={{
        hidden: true,
        style: {
          color: 'var(--primary-text-color)',
          backgroundColor: 'transparent',
        },
      }}
      inputVariant="outlined"
      maxDate={new Date()}
      autoOk
      TextFieldComponent={(props) => (
        <StyledTextField
          {...props}
          InputProps={{
            startAdornment: (
              <IconButton
                style={{ padding: 0, marginRight: 8, marginBottom: 2 }}
              >
                <Icon
                  style={{ color: '#192D4D' }}
                  className="material-icons-outlined"
                >
                  calendar_today
                </Icon>
              </IconButton>
            ),
          }}
        />
      )}
      fullWidth
    />
  );
}
