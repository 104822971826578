import styled from 'styled-components';
import { Icon } from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

export const StyledCurrencyField = styled(CurrencyTextField)`
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: var(--primary-text-color);
      border-radius: 1rem;
      border-width: 2px;
    }
    &:hover fieldset {
      border-color: var(--primary-text-color);
    }
    &.Mui-focused fieldset {
      border-color: var(--primary-text-color);
    }
    & input::placeholder {
      color: var(--primary-text-color);
      font-weight: 500;
      opacity: 1;
    }
  }
  color: var(--primary-text-color);
  min-width: 8.313rem;
  font-weight: 500;
`;

export const StyledIcon = styled(Icon)`
  color: var(--primary-text-color);
  margin-right: 0.7rem;
`;
