export const GET_MODULE = '[module] GET';
export const UPDATE_MODULE = '[module] UPDATE';
export const CREATE_MODULE = '[module] CREATE';
export const DELETE_MODULE = '[module] DELETE';
export const UPDATE_OBJECT_MODULE = '[module] UPDATE OBJECT MODULE';
// UI
export const SHOW_MODULE_MODAL = '[module] SHOW MODAL';
export const HIDE_MODULE_MODAL = '[module] HIDE MODAL';
// Spinner
export const UPDATE_MODULE_SUCCESS = '[module] Update success';
export const UPDATE_MODULE_ERROR = '[module] Update Error';
export const FETCH_MODULE_SUCCESS = '[module] Fetch success';
export const FETCH_MODULE_ERROR = '[module] Fetch Error';
export const SHOW_MODULE_SPINNER = '[module - ui] show spinner';
export const HIDE_MODULE_SPINNER = '[module - ui] hide spinner';

export const getModule = (id) => ({
  type: GET_MODULE,
  payload: { id },
});

export const createModule = (data) => ({
  type: CREATE_MODULE,
  payload: data,
});

export const updateModule = (data) => ({
  type: UPDATE_MODULE,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_MODULE_MODAL,
});

export const hideModal = () => ({
  type: HIDE_MODULE_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_MODULE_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_MODULE_SPINNER,
});

export const updateObjectModule = (data) => ({
  type: UPDATE_OBJECT_MODULE,
  payload: data,
});
