import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ClickAwayListener from 'material-ui/internal/ClickAwayListener';
import { ListIcon, styles, useStyles } from '../../styles';
import { executeSalesIntegration } from '../../../../integrations/Sales';
import { hasPermission } from '../../../../utils/globalFunctions';
import menuItems from './menuItems';
import PATHS from '../../../../config/routePaths';

export const MenuDrawer = ({
  menuButtonRef,
  setOpen,
  drawerRef,
  handleMouseOverDrawer,
  handleMouseOutDrawer,
  open,
  loggedUser,
  handleClose,
  active,
  handleSection,
  sectionOpen,
  modules,
}) => {
  const classes = useStyles();
  const [menuItemsArray, setMenuItemsArray] = useState(menuItems);

  const handleSalesIntegration = () => {
    executeSalesIntegration(loggedUser);
  };

  const integrationActions = {
    [PATHS.INTEGRATION_SALES_INTELLIGENCE]: handleSalesIntegration,
  };

  useEffect(() => {
    const modulesActiveObject = modules.reduce((accumulator, currentValue) => {
      accumulator[currentValue.key] = currentValue.active;
      return accumulator;
    }, {});

    setMenuItemsArray((prevState) => {
      const newMenuItemsArray = [...prevState];
      newMenuItemsArray.forEach((route) => {
        route.modules.forEach((module) => {
          if (modulesActiveObject[module.path] === false) {
            module.shouldShow = false;
          }
        });
      });
      return newMenuItemsArray;
    });
  }, [modules]);
  return (
    <>
      <ClickAwayListener
        onClickAway={(e) => {
          if (e.path && e.path.some((item) => item === menuButtonRef.current)) {
            return;
          }
          setOpen(false);
        }}
      >
        <Drawer
          id="drawer"
          ref={drawerRef}
          onMouseOver={handleMouseOverDrawer}
          onMouseOut={handleMouseOutDrawer}
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
            paperAnchorLeft: classes.scrollableMenu,
          }}
        >
          {menuItemsArray.map((menuItem) => {
            if (
              !((menuItem.shouldShow ?? true) && hasPermission(menuItem.path))
            ) {
              return null;
            }
            if (menuItem.isCollapsible) {
              return (
                <CollapsibleSubMenu
                  key={menuItem.modules[0].path}
                  itemsList={menuItem.modules}
                  handleSection={handleSection}
                  handleClose={handleClose}
                  sectionOpen={sectionOpen}
                  listLabel={menuItem.label}
                  listIcon={menuItem.icon}
                  listTitle={menuItem.title}
                  active={active}
                  integrationActions={integrationActions}
                />
              );
            }
            if (menuItem.isIntegration) {
              return (
                <IntegrationsItems
                  key={menuItem.modules[0].path}
                  integrations={menuItem.modules}
                  handleClose={handleClose}
                  integrationActions={integrationActions}
                />
              );
            }
            return (
              <SimpleSubMenu
                key={menuItem.modules[0].path}
                itemsList={menuItem.modules}
                handleClose={handleClose}
              />
            );
          })}
        </Drawer>
      </ClickAwayListener>
    </>
  );
};

const validModules = (item) =>
  (item.shouldShow ?? true) && hasPermission(item.path);

const CollapsibleSubMenu = ({
  itemsList,
  handleSection,
  handleClose,
  sectionOpen,
  listLabel,
  listTitle,
  listIcon,
  active,
  integrationActions,
}) => {
  const items = itemsList.filter(validModules);
  return (
    <>
      {items.length > 0 && (
        <List>
          <ListItem onClick={handleSection(listLabel)}>
            <ListIcon style={styles.drawerIconStyle}>
              <Icon>{listIcon}</Icon>
            </ListIcon>
            <ListItemText
              style={styles.drawerTextStyle}
              secondary={listTitle}
            />
            <ListIcon className="expandIcon">
              <Icon>
                {sectionOpen === listLabel ? 'expand_less' : 'expand_more'}
              </Icon>
            </ListIcon>
          </ListItem>
          <Collapse in={sectionOpen === listLabel}>
            {items.map((item) =>
              (item.isIntegration ? (
                <IntegrationItem
                  key={item.label}
                  item={item}
                  handleClose={handleClose}
                  integrationActions={integrationActions}
                  nested
                />
              ) : (
                <Link
                  onClick={handleClose}
                  key={item.label}
                  to={item.path}
                  style={styles.linkFont}
                >
                  <ListItem
                    style={{ width: '100%' }}
                    className={`nested ${active(item) ? 'active' : ''}`}
                  >
                    <ListIcon style={styles.drawerIconStyle}>
                      <Icon>{item.icon}</Icon>
                    </ListIcon>
                    <ListItemText
                      style={styles.drawerTextStyle}
                      secondary={item.label}
                    />
                  </ListItem>
                </Link>
              )),
            )}
          </Collapse>
        </List>
      )}
    </>
  );
};

const SimpleSubMenu = ({ itemsList, handleClose }) => {
  const items = itemsList.filter(validModules);
  return (
    <>
      {items.length > 0 && (
        <List>
          {items.map((item) => (
            <Link
              onClick={handleClose}
              key={item.label}
              to={item.path}
              style={styles.linkFont}
            >
              <ListItem style={{ width: '100%' }}>
                <ListIcon style={styles.drawerIconStyle}>
                  <Icon>{item.icon}</Icon>
                </ListIcon>
                <ListItemText
                  style={styles.drawerTextStyle}
                  secondary={item.label}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      )}
    </>
  );
};

const IntegrationItem = ({ item, integrationActions, handleClose, nested }) => {
  const child = (
    <a
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...((item.integration && {
        href: item.integration,
        target: '_blank',
        rel: 'noopener noreferrer',
        onClick: handleClose,
      }) || {
        onClick: integrationActions[item.path],
      })}
      style={styles.linkFont}
    >
      <ListItem className={nested && 'nested'} style={{ width: '100%' }}>
        <ListIcon style={styles.drawerIconStyle}>
          <Icon>{item.icon}</Icon>
        </ListIcon>
        <ListItemText style={styles.drawerTextStyle} secondary={item.label} />
        <ListIcon className="externalIcon"><Icon>open_in_new</Icon></ListIcon>
      </ListItem>
    </a>
  );
  return nested ? child : <List>{child}</List>;
};

const IntegrationsItems = ({
  integrations,
  handleClose,
  integrationActions,
}) => {
  const items = integrations.filter(validModules);
  return (
    <>
      {items.length > 0 &&
        items.map((item) => (
          <IntegrationItem
            key={item.label}
            item={item}
            handleClose={handleClose}
            integrationActions={integrationActions}
          />
        ))}
    </>
  );
};
