import {
  GET_ITEM,
  UPDATE_ITEM,
  UPDATE_OBJECT_ITEM,
  CREATE_ITEM,
  DELETE_ITEM,
} from '../actions/item';

export function itemReducer(item = {}, action) {
  switch (action.type) {
    case GET_ITEM:
      return action.payload;
    case UPDATE_ITEM:
      return action.payload;
    case CREATE_ITEM:
      return action.payload;
    case DELETE_ITEM:
      return action.payload;
    case UPDATE_OBJECT_ITEM:
      return action.payload;

    default:
      return item;
  }
}
