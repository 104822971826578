import {
  FETCH_EXTRACTS_SUCCESS,
  FETCH_EXTRACTS_ERROR,
  GET_EXTRACTS,
  showSpinner,
  updateExtracts,
  hideSpinner,
} from '../actions/extracts';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'extracts';

export const getExtractsFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GET_EXTRACTS) {
      dispatch(
        apiRequest(
          'GET',
          URL,
          action.payload,
          FETCH_EXTRACTS_SUCCESS,
          FETCH_EXTRACTS_ERROR,
          {},
          true,
        ),
      );
      dispatch(showSpinner());
    }
  };

export const processExtractsCollection =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === FETCH_EXTRACTS_SUCCESS) {
      dispatch(updateExtracts(action.payload));
      dispatch(hideSpinner());
    }
    if (action.type === FETCH_EXTRACTS_ERROR) {
      Toastify.addError(
        'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
      );
      dispatch(hideSpinner());
    }
  };

export const extractsMdl = [getExtractsFlow, processExtractsCollection];
