import React from 'react';
import { Container, StyledTab, StyledTabs } from '../styles';

export default function TabBar({ handleChangeTab, selectedTab }) {
  return (
    <Container>
      <StyledTabs value={selectedTab} onChange={handleChangeTab}>
        <StyledTab color="#192D4D" label="TERMINAIS" />
        <StyledTab color="#F9B870" label="PADARIAS" />
      </StyledTabs>
    </Container>
  );
}
