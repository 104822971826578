import React from 'react';
import { TimePicker } from '../../styles';

export default function CustomTimePicker({ refTime, onChangeCallback, label }) {
  return (
    <TimePicker
      variant="inline"
      label={label}
      value={refTime}
      onChange={onChangeCallback}
      ampm={false}
      autoOk
      inputVariant="outlined"
      fullWidth
    />
  );
}
