import React from 'react';
import Container from '../Container';
import SectionContent from '../SectionContent';
import SectionHeader from '../SectionHeader';
import { EmbeddedCardsRow, IframeContainer } from './style';

export default ({ resources }) => {
  const cardsToEmbed = resources.map((resource) => resource.resource_data);
  const sectionTitle = resources[0].module.name;

  return (
    <>
      <Container id="section-container">
        <SectionHeader title={sectionTitle} hideDatePicker />
        <SectionContent>
          {cardsToEmbed.map((card, rowIndex) => {
            if (Array.isArray(card.src)) {
              return (
                <EmbeddedCardsRow index={rowIndex}>
                  {card.src.map((cardSrc, cardIndex) => (
                    <IframeContainer index={cardIndex}>
                      <iframe
                        title={`metabase-panel-${rowIndex}-${cardIndex}`}
                        src={cardSrc}
                        frameBorder="0"
                        width="100%"
                        height={card.height || 600}
                        allowtransparency
                      />
                    </IframeContainer>
                  ))}
                </EmbeddedCardsRow>
              );
            }
            return (
              <IframeContainer>
                <iframe
                  title="metabase-panel"
                  src={card.src}
                  frameBorder="0"
                  width="100%"
                  height={card.height || 600}
                  allowtransparency
                />
              </IframeContainer>
            );
          })}
        </SectionContent>
      </Container>
    </>
  );
};
