import moment from 'moment';
import { snakeToCamel } from '../../utils/globalFunctions';

export const getFormattedRegisterItems = (paymentMachines, companies) => {
  const formattedItems = paymentMachines.map((_register) => {
    // Convert snake case from api to camel case for front
    const register = {};
    Object.keys(_register).forEach((key) => {
      register[snakeToCamel(key)] = _register[key];
    });

    // Extract useful fields and process them

    const { validSince, validUntil, companyDocument, lastUpdate } = register;
    const active =
      _register.active ||
      ((!validUntil || new Date(validUntil) > new Date()) &&
        new Date(validSince) < new Date());
    const companyName =
      companies.find((company) => company.document === companyDocument)?.name ||
      '-';
    const validSinceFormatted = moment(validSince).format(
      'DD/MM/YYYY HH:mm:ss',
    );
    const validUntilFormatted =
      (register.validUntil &&
        moment(register.validUntil).format('DD/MM/YYYY HH:mm:ss')) ||
      '-';
    const lastUpdateFormatted = lastUpdate
      ? moment(lastUpdate).format('DD/MM/YYYY HH:mm:ss')
      : '---';

    return {
      ...register,
      active,
      companyName,
      validSinceFormatted,
      validUntilFormatted,
      lastUpdateFormatted,
    };
  });
  return formattedItems;
};
