import React from 'react';

import { Container } from './styles';

const ButtonSpinner = ({ parent, overlay, size = 'small', color = '#000' }) => (
  <Container parent={parent} overlay={overlay} color={color}>
    <div className={`lds-ellipsis ${size}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  </Container>
);

export default ButtonSpinner;
