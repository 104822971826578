import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const Container = styled.div`
  padding: 1rem;
  @media (max-width: 567px) {
    padding: 0.5rem;
  }
`;

export const Title = styled.p`
  color: var(--primary-text-color);
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 1rem;
  @media (max-width: 560px) {
    font-size: 1.3rem;
  }
`;

export const SummaryText = styled.p`
  min-width: 360px;
  width: fit-content;
  color: var(--primary-text-color);
  font-size: 1rem;
  font-weight: 400;
  @media (max-width: 700px) {
    font-size: 0.6rem;
  }
`;

export const Summary = styled.div`
  padding: 1rem;
`;

export const InputContent = styled.div`
  width: 100%;
  padding: 5px 10px 10px 10px;
`;

export const selectStyle = makeStyles({
  icon: {
    color: 'var(--primary-text-color)',
  },
  selectMenu: {
    '.noPadding > &': {
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    },
  },
});
export const labelStyle = makeStyles({
  root: {
    color: 'var(--primary-text-color)',
    fontWeight: 500,
    transform: 'translate(46px, 20px) scale(1)',
    '&.Mui-focused': {
      color: 'var(--primary-text-color)',
      fontWeight: 500,
    },
  },
});

export const FilterHeader = styled.div`
  width: 100%;
  padding: 5px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
`;
