import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import { AddRounded, CloudDownloadRounded } from '@material-ui/icons';
import SectionContent from './components/SectionContent';
import { Container } from './styles';
import { getReceivedPayments } from '../../redux/actions/receivedPayments';
import { download } from '../../services/Download';
import { userIsAdmin } from '../../utils/globalFunctions';
import SectionHeader from '../../sharedComponents/SectionHeader';
import AuditReceivedPaymentModal from '../Modals/AuditReceivedPaymentModal';

const numberFormatter = new Intl.NumberFormat('pt-BR');
const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const AuditReceivedPayments = ({ loading, loggedUser }) => {
  const receivedPayments = useSelector((state) => state.receivedPayments);

  const [formattedPayments, setFormattedPayments] = useState([]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [shouldUpdateItem, setShouldUpdateItem] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(
      moment()
        .startOf('day')
        .toString(),
    ),
    endDate: new Date(
      moment()
        .endOf('day')
        .toString(),
    ),
  });
  const [selectedAcquirer, setSelectedAcquirer] = useState(null);

  const dispatch = useDispatch();

  const handleFetchValues = () => {
    dispatch(
      getReceivedPayments({
        page: page + 1,
        limit,
        dateGte: dateRange.startDate.toDateString(),
        dateLte: dateRange.endDate.toDateString(),
        acquirerId: selectedAcquirer?.id || null,
      }),
    );
  };

  useEffect(() => {
    handleFetchValues();
  }, [page, limit, selectedAcquirer, dateRange]);

  const handleFormatAcquirerFee = (a, b) => {
    const result = (a / b - 1) * 100;
    if (isNaN(result)) return 0;
    return parseFloat(result).toFixed(2);
  };

  useEffect(() => {
    if (receivedPayments && receivedPayments.docs) {
      setTotalDocs(receivedPayments.total);
      setTotalValue(receivedPayments.totalValue);
      setFormattedPayments(
        receivedPayments.docs.map((item) => ({
          ...item,
          formattedValue: currencyFormatter.format(item.value),
          formattedLiquidValue: currencyFormatter.format(
            item.total_liquid_value,
          ),
          formattedGrossValue: currencyFormatter.format(item.total_value),
          acquirerFee: `${handleFormatAcquirerFee(
            item.total_value,
            item.value,
          )} %`,
          formattedTransferDate: moment(item.transfer_date).format(
            'DD/MM/YYYY',
          ),
          formattedTransferStartDate: moment(item.initial_date).format(
            'DD/MM/YYYY',
          ),
          formattedTransferEndDate: moment(item.end_date).format('DD/MM/YYYY'),
          acquirerName: item.acquirer.name,
        })),
      );
    }
  }, [receivedPayments]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDateRange = (field) => (date) => {
    setDateRange((currentRange) => ({ ...currentRange, [field]: date }));
  };

  const createCallBack = () => {
    setIsCreateModalOpen(true);
  };

  const handleUpdateItem = (id) => {
    setShouldUpdateItem(true);
    setSelectedItem(id);
    setIsCreateModalOpen(true);
  };

  const callBackCloseCreateOrEditModal = () => {
    setIsCreateModalOpen(false);
    setShouldUpdateItem(false);
    setSelectedItem(null);
    setTimeout(() => {
      handleFetchValues();
    }, 1000);
  };

  const handleSelectAcquirer = (data) => {
    setSelectedAcquirer(data);
  };
  const downloadCallback = () =>
    download(
      'audit/receivedTransfers',
      {
        dateGte: dateRange.startDate.toISOString(),
        dateLte: dateRange.endDate.toISOString(),
        isAdmin: userIsAdmin(loggedUser),
      },
      {
        filename: `receivedTransfers-${new Date().toISOString()}`,
        fileExtension: 'xlsx',
        callback: () =>
          formattedPayments.map((item) => ({
            Id: item.id,
            'Data de transferência': item.formattedTransferDate,
            Inicio: item.formattedTransferStartDate,
            Fim: item.formattedTransferEndDate,
            Adquirente: item.acquirerName,
            'Valor Recebido': item.value,
            'Líquido Transações': item.total_liquid_value,
            'Bruto Transações': item.total_value,
          })),
      },
    );

  return (
    <>
      <Container>
        <SectionHeader
          title={
            <span>
              Recebido de Adquirentes
              <IconButton
                onClick={createCallBack}
                size="small"
                style={{ marginLeft: '0.5rem' }}
              >
                <AddRounded />
              </IconButton>
              <IconButton
                onClick={downloadCallback}
                size="small"
                style={{ marginLeft: '0.5rem' }}
              >
                <CloudDownloadRounded />
              </IconButton>
            </span>
          }
          hideCompanySelector
          iconButtonStyle={{ padding: '10px' }}
          refDate={dateRange}
          onSelectDateCallback={handleDateRange}
          loggedUser={loggedUser}
          showDateRange
          style={{ marginBottom: 0 }}
          defaultComponentsContainerStyle={{ width: '100%' }}
          showAcquirers
          handleSelectAcquirer={handleSelectAcquirer}
        />

        <AuditReceivedPaymentModal
          open={isCreateModalOpen}
          handleCloseModal={callBackCloseCreateOrEditModal}
          dateGte={dateRange.startDate}
          dateLte={dateRange.endDate}
          shouldUpdateItem={shouldUpdateItem}
          selectedItem={selectedItem}
        />

        <SectionContent
          subtitle={`${numberFormatter.format(
            totalDocs,
          )} transações - Valor bruto total: ${currencyFormatter.format(
            totalValue,
          )}`}
          loading={loading}
          columnsMap={{
            Id: 'id',
            'Data Transferência': 'formattedTransferDate',
            Inicio: 'formattedTransferStartDate',
            Fim: 'formattedTransferEndDate',
            Adquirente: 'acquirerName',
            Recebido: 'formattedValue',
            'Líquido Transações': 'formattedLiquidValue',
            'Bruto Transações': 'formattedGrossValue',
            'Taxa Média': 'acquirerFee',
          }}
          rows={formattedPayments}
          totalValue={totalValue}
          total={totalDocs}
          limit={limit}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          updateCallBack={handleUpdateItem}
        />
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedUser: state.loggedUser,
  loading: state.ui.receivedPaymentsLoading,
});

export default connect(mapStateToProps)(AuditReceivedPayments);
