import { makeStyles, TextField, withStyles } from '@material-ui/core';

export const Field = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--primary-text-color)',
        borderRadius: '1rem',
        borderWidth: 2,
      },
      '&:hover fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
    },
    color: 'var(--primary-text-color)',
    minWidth: '8.313rem',
    fontWeight: 500,
  },
})(TextField);

export const selectStyle = makeStyles((theme) => ({
  root: {
    minWidth: '9.5rem',
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
    },
  },
  icon: {
    color: 'var(--primary-text-color)',
  },
}));
export const labelStyle = makeStyles({
  root: {
    color: 'var(--primary-text-color)',
    fontWeight: 500,
    transform: 'translate(46px, 20px) scale(1)',
    '&.Mui-focused': {
      color: 'var(--primary-text-color)',
      fontWeight: 500,
    },
  },
});
