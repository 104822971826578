export const GET_COMPETITORS = '[competitors] GET';
export const FETCH_COMPETITORS_SUCCESS = '[competitors] Fetch success';
export const FETCH_COMPETITORS_ERROR = '[competitors] Fetch Error';
export const UPDATE_COMPETITORS = '[competitors] Update';
export const SHOW_COMPETITORS_SPINNER = '[competitors - ui] show spinner';
export const HIDE_COMPETITORS_SPINNER = '[competitors - ui] hide spinner';

export const getCompetitors = (filter) => ({
  type: GET_COMPETITORS,
  payload: filter,
});

export const updateCompetitors = (data) => ({
  type: UPDATE_COMPETITORS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_COMPETITORS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_COMPETITORS_SPINNER,
});
