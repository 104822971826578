import React from 'react';
import { StyledCurrencyField, StyledIcon } from './styles';

function CurrencyField({
  value: controlledValue,
  onChange,
  placeholder,
  fullWidth,
  name,
  error,
  helperText,
  required,
}) {
  return (
    <StyledCurrencyField
      placeholder={placeholder}
      variant="outlined"
      value={controlledValue}
      name={name}
      onChange={onChange}
      currencySymbol={
        <StyledIcon className="material-icons-outlined">paid</StyledIcon>
      }
      outputFormat="number"
      decimalCharacter=","
      digitGroupSeparator="."
      fullWidth={fullWidth}
      error={error}
      helperText={error || helperText}
      required={required}
    />
  );
}

export default CurrencyField;
