import styled, { css } from 'styled-components';
import { withStyles, TextField } from '@material-ui/core';
import {
  DatePicker as MaterialDatePicker,
  TimePicker as MaterialTimePicker,
} from '@material-ui/pickers';

export const Container = styled.div`
  padding: 1rem;
  @media (max-width: 567px) {
    padding: 0.5rem;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #a3a3a333;
  align-self: center;
  margin: 1rem;
  margin-bottom: 0;
`;

export const NoteCard = styled.div`
  border: 1px solid black;
  padding: 0.5rem;
  display: flex;
  border-radius: 5px;
  align-items: center;
  margin-top: 0.5rem;
`;

export const DatePicker = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--primary-text-color)',
        borderRadius: '1rem',
        borderWidth: 2,
      },
      '&:hover fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
    },
    color: 'var(--primary-text-color)',
    fontWeight: 500,
  },
})(MaterialDatePicker);

export const TimePicker = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--primary-text-color)',
        borderRadius: '1rem',
        borderWidth: 2,
      },
      '&:hover fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
    },
    color: 'var(--primary-text-color)',
    fontWeight: 500,
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      background: 'transparent',
      color: 'var(--primary-text-color)',
    },
  },
})(MaterialTimePicker);

export const StyledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--primary-text-color)',
        borderRadius: '1rem',
        borderWidth: 2,
      },
      '&:hover fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
    },
    color: 'var(--primary-text-color)',
    fontWeight: 500,
  },
})(TextField);

export const DatePickersContainer = styled.div`
  display: flex;
  ${({ isTablet }) =>
    isTablet &&
    css`
      width: 100%;
    `}
  ${({ isMobile }) =>
    isMobile &&
    css`
      flex-direction: column;
    `}
  > div {
    ${({ isMobile }) =>
    isMobile &&
      css`
        margin-top: 1rem;
      `}
    ${({ isTablet }) =>
    !isTablet &&
      css`
        max-width: 225px;
      `}
  }
  > div:first-child {
    ${({ isMobile }) =>
    !isMobile &&
      css`
        margin-right: 1rem;
      `}
  }
`;
