export const GET_DAILY_EXPECTED_PAYMENTS = '[daily_expected_payments] GET';

export const FETCH_DAILY_EXPECTED_PAYMENTS_SUCCESS =
  '[daily_expected_payments] Fetch success';

export const FETCH_DAILY_EXPECTED_PAYMENTS_ERROR =
  '[daily_expected_payments] Fetch Error';

export const SHOW_DAILY_EXPECTED_PAYMENTS_SPINNER =
  '[daily_expected_payments - ui] show spinner';
export const HIDE_DAILY_EXPECTED_PAYMENTS_SPINNER =
  '[daily_expected_payments - ui] hide spinner';

export const CREATE_DAILY_EXPECTED_PAYMENTS =
  '[daily_expected_payments] create';
export const CREATE_DAILY_EXPECTED_PAYMENTS_SUCCESS =
  '[daily_expected_payments] create success';
export const CREATE_DAILY_EXPECTED_PAYMENTS_ERROR =
  '[daily_expected_payments] create error';

export const UPDATE_DAILY_EXPECTED_PAYMENTS =
  '[daily_expected_payments] Update';

export const UPDATE_DAILY_EXPECTED_PAYMENTS_SUCCESS =
  '[daily_expected_payments] update success';
export const UPDATE_DAILY_EXPECTED_PAYMENTS_ERROR =
  '[daily_expected_payments] update error';

export const getDailyExpectedPayments = (filter) => ({
  type: GET_DAILY_EXPECTED_PAYMENTS,
  payload: filter,
});

export const fetchDailyExpectedPaymentsSuccess = (data) => ({
  type: FETCH_DAILY_EXPECTED_PAYMENTS_SUCCESS,
  payload: data,
});
export const updateDailyExpectedPayments = (data) => ({
  type: UPDATE_DAILY_EXPECTED_PAYMENTS,
  payload: data,
});

export const createDailyExpectedPayment = (data) => ({
  type: CREATE_DAILY_EXPECTED_PAYMENTS,
  payload: data,
});
export const createDailyExpectedPaymentSuccess = (data) => ({
  type: CREATE_DAILY_EXPECTED_PAYMENTS_SUCCESS,
  payload: data,
});
export const createDailyExpectedPaymentError = (data) => ({
  type: CREATE_DAILY_EXPECTED_PAYMENTS_ERROR,
  payload: data,
});

export const updateDailyExpectedPayment = (data) => ({
  type: UPDATE_DAILY_EXPECTED_PAYMENTS,
  payload: data,
});
export const updateDailyExpectedPaymentSuccess = (data) => ({
  type: UPDATE_DAILY_EXPECTED_PAYMENTS_SUCCESS,
  payload: data,
});
export const updateDailyExpectedPaymentError = (data) => ({
  type: UPDATE_DAILY_EXPECTED_PAYMENTS_ERROR,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_DAILY_EXPECTED_PAYMENTS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_DAILY_EXPECTED_PAYMENTS_SPINNER,
});
