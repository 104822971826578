export const GET_ITEM = '[item] GET';
export const UPDATE_ITEM = '[item] UPDATE';
export const CREATE_ITEM = '[item] CREATE';
export const DELETE_ITEM = '[item] DELETE';
export const UPDATE_OBJECT_ITEM = '[item] UPDATE OBJECT ITEM';
// UI
export const SHOW_ITEM_MODAL = '[item] SHOW MODAL';
export const HIDE_ITEM_MODAL = '[item] HIDE MODAL';
// Spinner
export const UPDATE_ITEM_SUCCESS = '[item] Update success';
export const UPDATE_ITEM_ERROR = '[item] Update Error';
export const FETCH_ITEM_SUCCESS = '[item] Fetch success';
export const FETCH_ITEM_ERROR = '[item] Fetch Error';
export const SHOW_ITEM_SPINNER = '[item - ui] show spinner';
export const HIDE_ITEM_SPINNER = '[item - ui] hide spinner';

export const getItem = (id) => ({
  type: GET_ITEM,
  payload: { _id: id },
});

export const createItem = (data) => ({
  type: CREATE_ITEM,
  payload: data,
});

export const updateItem = (data) => ({
  type: UPDATE_ITEM,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_ITEM_MODAL,
});

export const hideModal = () => ({
  type: HIDE_ITEM_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_ITEM_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_ITEM_SPINNER,
});

export const updateObjectItem = (data) => ({
  type: UPDATE_OBJECT_ITEM,
  payload: data,
});
