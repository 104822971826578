import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Spinner from '../../../../sharedComponents/Spinner/index';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: '1rem',
  },
  title: {
    fontSize: 20,
  },
  headerRow: {
    backgroundColor: '#fff',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    // paddingTop: '1.5rem',
    // paddingBottom: '1.5rem',
    borderRadius: '1.5rem',
    '&.filter': {
      padding: '1rem',
    },
  },
  row: {
    padding: 0,
    backgroundColor: theme.palette.white.main,
  },
  firstCell: {
    fontSize: 16,
    padding: '8px 15px',
    whiteSpace: 'nowrap',
  },
  cell: {
    fontSize: 16,
    padding: '8px 15px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  headerCell: {
    textAlign: 'center',
    backgroundColor: theme.palette.white.main,
    borderTopLeftRadius: '1.5rem',
    borderTopRightRadius: '1.5rem',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  headerGridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  alertDiffValues: {
    color: theme.palette.danger.main,
  },
  rowSumary: {
    backgroundColor: theme.palette.gray2.main,
    margin: '8px 0px',
  },
  cellSumary: {
    fontSize: 20,
    padding: '12px 15px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  firstCellSumary: {
    fontSize: 20,
    padding: '12px 15px',
    fontWeight: 'bold',
  },
}));

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const SectionContent = ({ loading, columnsMap, rows, reportTotals }) => {
  const classes = useStyles();
  const headerLabels = Object.keys(columnsMap);
  const rowLabels = Object.values(columnsMap);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow className={classes.headerRow}>
                {headerLabels.map((label) => (
                  <TableCell key={label} className={classes.headerCell}>
                    {label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow className={classes.row}>
                  <TableCell
                    colSpan={headerLabels.length + 2}
                    className={classes.cell}
                  >
                    <Spinner parent="Table" />
                  </TableCell>
                </TableRow>
              )}

              {!loading && reportTotals && (
                <TableRow className={classes.rowSumary}>
                  <TableCell className={classes.firstCellSumary}>
                    Resumo dos Valores
                  </TableCell>
                  <TableCell className={classes.cellSumary}>
                    {currencyFormatter.format(
                      reportTotals.reportTotalValueSum || 0,
                    )}
                  </TableCell>

                  <TableCell className={classes.cellSumary}>
                    {currencyFormatter.format(
                      reportTotals.reportTotalCreditsSum || 0,
                    )}
                  </TableCell>
                  <TableCell className={classes.cellSumary}>
                    {currencyFormatter.format(
                      reportTotals.reportTotalPurchasesSum || 0,
                    )}
                  </TableCell>
                  <TableCell className={classes.cellSumary}>
                    {currencyFormatter.format(
                      reportTotals.reportTotalLoanSum || 0,
                    )}
                  </TableCell>
                  <TableCell className={classes.cellSumary}>
                    {currencyFormatter.format(
                      reportTotals.reportTotalAdjustmentsSum || 0,
                    )}
                  </TableCell>
                  <TableCell className={classes.cellSumary}>
                    {currencyFormatter.format(
                      reportTotals.reportTotalWeeklyRateSum || 0,
                    )}
                  </TableCell>
                  <TableCell className={classes.cellSumary} />
                </TableRow>
              )}
              {!loading &&
                rows.map((row) => (
                  <TableRow key={row.companyId} className={classes.row}>
                    {rowLabels.map((label, index) => {
                      let valueToShow;
                      if (typeof label === 'string') {
                        valueToShow = row[label];
                      } else {
                        label.forEach((labelItem) => {
                          valueToShow = valueToShow || row[labelItem];
                        });
                      }
                      return (
                        <TableCell
                          key={label}
                          className={
                            index === 0 ? classes.firstCell : classes.cell
                          }
                        >
                          {valueToShow}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}

              {!loading && rows && rows.length === 0 && (
                <TableRow className={classes.row}>
                  <TableCell
                    colSpan={headerLabels.length + 2}
                    className={classes.headerCell}
                  >
                    Nenhum resultado para exibir
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default SectionContent;
