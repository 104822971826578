export const GET_EMBEDDED_RESOURCES = '[embeddedResources] GET';
export const STORE_EMBEDDED_RESOURCES = '[embeddedResources] UPDATE';
export const FETCH_EMBEDDED_RESOURCES_SUCCESS =
  '[embeddedResources] Fetch success';
export const FETCH_EMBEDDED_RESOURCES_ERROR = '[embeddedResources] Fetch error';
export const SHOW_EMBEDDED_RESOURCES_SPINNER =
  '[embeddedResources - ui] show spinner';
export const HIDE_EMBEDDED_RESOURCES_SPINNER =
  '[embeddedResources - ui] hide spinner';

export const getEmbeddedResources = (filter) => ({
  type: GET_EMBEDDED_RESOURCES,
  payload: filter,
});

export const storeEmbeddedResources = (data) => ({
  type: STORE_EMBEDDED_RESOURCES,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_EMBEDDED_RESOURCES_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_EMBEDDED_RESOURCES_SPINNER,
});
