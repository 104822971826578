import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  @media (max-width: 767px) {
    padding: 0 1rem;
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #a3a3a333;
  align-self: stretch;
  margin: 1rem;
  margin-bottom: 0;
  &.first-divider {
    margin-top: 0;
  }
`;

export const Title = styled.p`
  color: var(--primary-text-color);
  font-size: 1.6rem;
  font-weight: 400;
  align-self: center;
  @media (max-width: 560px) {
    font-size: 1.3rem;
    margin-left: 0.5rem;
  }
`;

export const FieldTitle = styled.span`
  color: #464255;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
  }
`;
