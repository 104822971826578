import {
  FETCH_PAYMENT_MACHINES_SUCCESS,
  FETCH_PAYMENT_MACHINES_ERROR,
  GET_PAYMENT_MACHINES,
  updatePaymentMachines,
  showSpinner,
  hideSpinner,
} from '../actions/paymentMachines';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'paymentMachines';

export const getPaymentMachinesFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_PAYMENT_MACHINES) {
          dispatch(
            apiRequest(
              'GET',
              URL,
              action.payload,
              FETCH_PAYMENT_MACHINES_SUCCESS,
              FETCH_PAYMENT_MACHINES_ERROR,
              {},
              true,
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processPaymentMachinesCollection =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_PAYMENT_MACHINES_SUCCESS) {
          dispatch(updatePaymentMachines(action.payload));
          dispatch(hideSpinner());
        }
        if (action.type === FETCH_PAYMENT_MACHINES_ERROR) {
          Toastify.addError(
            'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
          );
          dispatch(hideSpinner());
        }
      };

export const paymentMachinesMdl = [
  getPaymentMachinesFlow,
  processPaymentMachinesCollection,
];
