import React from 'react';
import { Line } from 'react-chartjs-2';

const defaultOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  legend: {
    display: false,
    labels: {
      display: false,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};

const LineChart = ({ data, options }) => (
  <>
    <Line data={data} options={{ ...defaultOptions, ...options }} />
  </>
);

export default LineChart;
