import React from 'react';
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
  BoxTitle,
  VerticalDivider,
  HorizontalDivider,
  TopBox,
  TopLeftBox,
  TopRightBox,
  BottomBox,
  BottomLeftBox,
  BottomRightBox,
  DetailText,
  LastUpdateText,
  SuggestedPrice,
} from '../../styles';
import { capitalizeFirstLetter } from '../../../../utils/globalFunctions';

const formatArgs = ['pt-BR', { style: 'currency', currency: 'BRL' }];
function formatDate(date) {
  const dateObj = new Date(date);
  dateObj.setMinutes(dateObj.getMinutes() + dateObj.getTimezoneOffset());
  return format(dateObj, 'dd/MM/yyyy');
}
function Row(props) {
  const { row, supermarketRef, bakeryRef } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const percentageVsBakeryRef =
    row.bakery_reference_value && row.last_sale_value_cents
      ? (row.last_sale_value_cents /
          (Number.parseFloat(row.bakery_reference_value) * 100) -
          1) *
        100
      : null;

  function colorDecider(value) {
    if (value === null || value === undefined) return 'inherit';
    if (value > 20) {
      return '#FF5B5B';
    }
    if (value < 0) {
      return '#FFBB54';
    }
    return '#00A389';
  }

  return (
    <>
      <StyledTableRow
        style={{ backgroundColor: open ? '#F3F2F7' : null }}
        className={classes.root}
      >
        <StyledTableCell component="th" scope="row" align="center">
          {row.ean_code}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="left">
          {capitalizeFirstLetter(row.name)}
        </StyledTableCell>
        <StyledTableCell align="center">
          {row.last_sale_value_cents
            ? (row.last_sale_value_cents / 100).toLocaleString(...formatArgs)
            : '--'}
        </StyledTableCell>
        <StyledTableCell align="center">
          {row.bakery_reference_value
            ? Number.parseFloat(row.bakery_reference_value).toLocaleString(
              ...formatArgs,
            )
            : '--'}
        </StyledTableCell>
        <StyledTableCell align="center">
          {row.supermarket_mean_value_cents
            ? (row.supermarket_mean_value_cents / 100).toLocaleString(
              ...formatArgs,
            )
            : '--'}
        </StyledTableCell>
        <StyledTableCell
          align="center"
          style={{ color: colorDecider(row.percentage_vs_supermarket_mean) }}
        >
          {row.percentage_vs_supermarket_mean
            ? `${row.percentage_vs_supermarket_mean.toFixed(2)}%`
            : '--'}
        </StyledTableCell>
        <StyledTableCell align="center">
          <IconButton
            aria-label="expand row"
            size="large"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: '#192D4D' }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{ padding: '2rem' }}>
              <TopBox>
                <TopLeftBox>
                  <BoxTitle style={{ color: '#58CDFF' }}>
                    Na sua padaria
                  </BoxTitle>
                  <DetailText>
                    <span>Faturamento Último Mês</span>
                    <span>
                      {row.last_month_revenue_cents
                        ? (row.last_month_revenue_cents / 100).toLocaleString(
                          ...formatArgs,
                        )
                        : '--'}
                    </span>
                  </DetailText>
                  <DetailText>
                    <span>Preço Atual</span>
                    <span>
                      {row.last_sale_value_cents
                        ? (row.last_sale_value_cents / 100).toLocaleString(
                          ...formatArgs,
                        )
                        : '--'}
                    </span>
                  </DetailText>
                </TopLeftBox>
                <VerticalDivider />
                <TopRightBox>
                  <BoxTitle style={{ color: '#00A389' }}>
                    Preço sugerido <InfoOutlinedIcon fontSize="small" />
                  </BoxTitle>
                  <SuggestedPrice>
                    {row.recommended_value
                      ? (row.recommended_value / 100).toLocaleString(
                        ...formatArgs,
                      )
                      : '--'}
                  </SuggestedPrice>
                </TopRightBox>
              </TopBox>
              <HorizontalDivider />
              <BottomBox>
                <BottomLeftBox>
                  <BoxTitle style={{ color: '#EF9A91' }}>
                    Supermercados da região
                  </BoxTitle>
                  <DetailText>
                    <span>Média Supermercados da Região</span>
                    <span>
                      {row.supermarket_mean_value_cents
                        ? (
                          row.supermarket_mean_value_cents / 100
                        ).toLocaleString(...formatArgs)
                        : '--'}
                    </span>
                  </DetailText>
                  <DetailText>
                    <span>
                      {supermarketRef || 'Supermercado de referência'}
                    </span>
                    <span>
                      {row.supermarket_reference_value
                        ? Number.parseFloat(
                          row.supermarket_reference_value,
                        ).toLocaleString(...formatArgs)
                        : '--'}
                    </span>
                  </DetailText>
                  <DetailText>
                    <span>% vs Média Supermercados da Região</span>
                    <span
                      style={{
                        color: colorDecider(row.percentage_vs_supermarket_mean),
                      }}
                    >
                      {row.percentage_vs_supermarket_mean
                        ? `${row.percentage_vs_supermarket_mean.toFixed(2)}%`
                        : '--'}
                    </span>
                  </DetailText>
                  <LastUpdateText>
                    Última atualização{' '}
                    {row.supermarket_reference_date
                      ? formatDate(row.supermarket_reference_date)
                      : '--'}
                  </LastUpdateText>
                </BottomLeftBox>
                <VerticalDivider />
                <BottomRightBox>
                  <BoxTitle style={{ color: '#F9B870' }}>
                    Padarias da região
                  </BoxTitle>
                  <DetailText>
                    <span>Média Padarias da Região</span>
                    <span>
                      {row.bakery_mean_value_cents
                        ? (row.bakery_mean_value_cents / 100).toLocaleString(
                          ...formatArgs,
                        )
                        : '--'}
                    </span>
                  </DetailText>
                  <DetailText>
                    <span>{bakeryRef || 'Padaria de Referência'}</span>
                    <span>
                      {row.bakery_reference_value
                        ? Number.parseFloat(
                          row.bakery_reference_value,
                        ).toLocaleString(...formatArgs)
                        : '--'}
                    </span>
                  </DetailText>
                  <DetailText>
                    <span>% vs {bakeryRef || 'Padaria de Referência'}</span>
                    <span
                      style={{ color: colorDecider(percentageVsBakeryRef) }}
                    >
                      {percentageVsBakeryRef
                        ? `${percentageVsBakeryRef.toFixed(2)}%`
                        : '--'}
                    </span>
                  </DetailText>
                  <LastUpdateText>
                    Última atualização{' '}
                    {row.bakery_reference_date
                      ? formatDate(row.bakery_reference_date)
                      : '--'}
                  </LastUpdateText>
                </BottomRightBox>
              </BottomBox>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default Row;
