import { GET_MODULES, UPDATE_MODULES } from '../actions/modules';

const initState = {
  docs: [],
  page: 1,
  limit: 50,
  totalDocs: 0,
  searchText: '',
};

export function modulesReducer(modules = initState, action) {
  switch (action.type) {
    case GET_MODULES:
      return action.payload;
    case UPDATE_MODULES:
      return action.payload;

    default:
      return modules;
  }
}
