import styled from 'styled-components';
import {
  Paper,
  Button,
  TextField,
  withStyles,
  makeStyles,
} from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

export const Container = styled.div`
  min-width: 60vw;
  padding: 20px;
  height: fit-content;
`;

export const StyledPaper = styled(Paper)`
  border-radius: 1rem;
  &.MuiPaper-root {
    height: 65rem;
  }
`;

export const ModalTitle = styled.div`
  padding-bottom: 20px;
  font-size: 1.3rem;
  color: #192d4d;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #d0d6de;
  margin-bottom: 2rem;
`;

export const MiddleDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #d0d6de;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
`;

export const InputContent = styled.form`
  width: 100%;
  padding: 20px 10px 10px 10px;
`;

export const CheckboxContent = styled.form`
  width: 100%;
  padding: 12px 10px 15px 0px;
`;

export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const FormButton = styled(Button)`
  margin: 2px;
`;

export const selectStyle = makeStyles({
  icon: {
    color: 'var(--primary-text-color)',
  },
  selectMenu: {
    '.noPadding > &': {
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    },
  },
});

export const DateTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--primary-text-color)',
        borderRadius: '1rem',
        borderWidth: 2,
      },
      '&:hover fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
    },
    color: 'var(--primary-text-color)',
    fontWeight: 500,
  },
})(TextField);

export const StyledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--primary-text-color)',
        borderRadius: '1rem',
        borderWidth: 2,
      },
      '&:hover fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
    },
    color: 'var(--primary-text-color)',
    minWidth: '8.313rem',
    fontWeight: 500,
  },
})(TextField);

export const DisabledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '1rem',
        borderWidth: 2,
      },
    },
    minWidth: '8.313rem',
    fontWeight: 500,
  },
})(TextField);

export const StyledCurrencyField = styled(CurrencyTextField)`
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: var(--primary-text-color);
      border-radius: 1rem;
      border-width: 2px;
    }
    &:hover fieldset {
      border-color: var(--primary-text-color);
    }
    &.Mui-focused fieldset {
      border-color: var(--primary-text-color);
    }
    & input::placeholder {
      color: var(--primary-text-color);
      font-weight: 500;
      opacity: 1;
    }
  }
  color: var(--primary-text-color);
  min-width: 8.313rem;
  font-weight: 500;
`;

export const labelStyle = makeStyles({
  root: {
    color: 'var(--primary-text-color)',
    fontWeight: 500,
    transform: 'translate(46px, 20px) scale(1)',
    '&.Mui-focused': {
      color: 'var(--primary-text-color)',
      fontWeight: 500,
    },
  },
});
