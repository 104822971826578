import { GET_OFFERS, UPDATE_OFFERS } from '../actions/offers';

const initState = {
  docs: [],
  page: 1,
  limit: 50,
  totalDocs: 0,
  searchText: '',
};

export function offersReducer(offers = initState, action) {
  switch (action.type) {
    case GET_OFFERS:
      return action.payload;
    case UPDATE_OFFERS:
      return action.payload;

    default:
      return offers;
  }
}
