import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  TextField,
  FormGroup,
  Button,
  FormControlLabel,
  Switch,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Grid,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputMask from 'react-input-mask';
import { cpf } from 'cpf-cnpj-validator';
import Alert from '../../../sharedComponents/Alert/index';
import Spinner from '../../../sharedComponents/Spinner/index';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
  CheckBoxContainer,
} from './styles';
import { getGroups } from '../../../redux/actions/groups';
import {
  getCompany,
  createCompany,
  updateCompany,
} from '../../../redux/actions/company';
import CompaniesProvider from '../../../providers/Companies';
import { COMPANY_CATEGORIES, ICMS_CONTRIBUTION_OPTIONS } from '../../../config/consts';
import { cnpjValidation, sanitizeCNPJ, sanitizeCPF } from '../../../utils/globalFunctions';

const styles = {
  formButton: {
    margin: 2,
  },
};

const CompanyModal = ({
  id,
  dispatch,
  company,
  companyModal,
  handleClose,
  companySpinner,
  allGroups,
}) => {
  const [active, setActive] = useState(true);
  const [isPadah, setIsPadah] = useState(false);
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [document, setDocument] = useState('');
  const [documentError, setDocumentError] = useState('');
  const [epadocaAlias, setEpadocaAlias] = useState('');
  const [groupId, setGroupId] = useState('');
  const [corporateName, setCorporateName] = useState('');
  const [fantasyName, setFantasyName] = useState('');
  const [stateRegistration, setStateRegistration] = useState('');
  const [financialEmail, setFinancialEmail] = useState('');
  const [addressStreet, setAddressStreet] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [district, setDistrict] = useState('');
  const [cep, setCep] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUf] = useState('');
  const [phone, setPhone] = useState('');
  const [icmsContribution, setIcmsContribution] = useState('');
  const [bankAccount, setBankAccount] = useState('');
  const [bankBranch, setBankBranch] = useState('');
  const [bankName, setBankName] = useState('');

  const [ownerName, setOwnerName] = useState('');
  const [ownerCpf, setOwnerCpf] = useState('');

  const [cpfError, setCpfError] = useState('');

  const [totalArea, setTotalArea] = useState(0);
  const [sellingArea, setSellingArea] = useState(0);
  const [totalAreaError, setTotalAreaError] = useState(false);
  const [sellingAreaError, setSellingAreaError] = useState(false);

  const [bankList, setBankList] = useState([]);
  const [bankError, setBankError] = useState(false);
  const [nameError, setNameError] = useState(false);

  useEffect(() => {
    CompaniesProvider.getBanks()
      .then((response) => {
        setBankError(!(response.length > 0));
        setBankList(response.map((item) => `${item.code} - ${item.name}`) || []);
      })
      .catch(() => {
        setBankError(true);
      });
  }, []);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getCompany(id));
      dispatch(getGroups({ listAll: true }));
    }
  }, [id]);

  useEffect(() => {
    if (company && id !== 'new') {
      setActive(company.active);
      setName(company.name);
      setDocument(company.document || '');
      setEpadocaAlias(company.epadoca_alias || '');
      setCategory(company.category || '');
      setGroupId(company.group_id);
      setIsPadah(company.is_padah);
      setCorporateName(company.corporate_name || '');
      setFantasyName(company.fantasy_name || '');
      setStateRegistration(company.state_registration || '');
      setFinancialEmail(company.financial_email || '');
      setAddressStreet(company.address_street || '');
      setAddressNumber(company.address_number || '');
      setDistrict(company.district || '');
      setCep(company.cep || '');
      setCity(company.city || '');
      setUf(company.uf || '');
      setPhone(company.phone || '');
      setIcmsContribution(company.icms_contribution || '');
      setBankAccount(company.bank_account || '');
      setBankBranch(company.bank_branch || '');
      setBankName(company.bank_name || '');
      setTotalArea(company.total_area || 0);
      setSellingArea(company.selling_area || 0);
      setOwnerName(company.owner_name || '');
      setOwnerCpf(company.owner_cpf || '');
    }
  }, [company]);

  useEffect(() => {
    if (!companyModal) {
      handleClose();
    }
  }, [companyModal]);

  const handleTotalAreaChanged = (event) => {
    setTotalArea(parseInt(event.target.value, 10));
    setTotalAreaError(!parseInt(event.target.value, 10) && event.target.value !== '0');
  };

  const handleSellingAreaChanged = (event) => {
    setSellingArea(parseInt(event.target.value, 10));
    setSellingAreaError(!parseInt(event.target.value, 10) && event.target.value !== '0');
  };
  const handleDocumentErrorMessage = document
    ? 'CNPJ inválido!'
    : 'CNPJ é obrigatório';

  const handleSave = async () => {
    let err = false;
    setNameError(!name);
    const cnpjWithError = !cnpjValidation(document);
    setDocumentError(cnpjWithError);
    const cpfWithError = ownerCpf.length > 0 && !cpf.isValid(ownerCpf);
    setCpfError(cpfWithError);
    err = !name || cnpjWithError || totalAreaError || sellingAreaError || cpfWithError;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(
            createCompany({
              active,
              name,
              category,
              document,
              epadoca_alias: epadocaAlias,
              group_id: groupId,
              is_padah: isPadah,
              corporate_name: corporateName,
              fantasy_name: fantasyName,
              state_registration: stateRegistration,
              financial_email: financialEmail,
              address_street: addressStreet,
              address_number: addressNumber,
              district,
              cep,
              city,
              uf,
              phone,
              icms_contribution: icmsContribution,
              bank_account: bankAccount,
              bank_branch: bankBranch,
              bank_name: bankName,
              total_area: totalArea,
              selling_area: sellingArea,
              owner_name: ownerName,
              owner_cpf: ownerCpf,
            }),
          );
        } else {
          dispatch(
            updateCompany({
              id,
              active,
              name,
              category,
              document,
              epadoca_alias: epadocaAlias,
              group_id: groupId,
              is_padah: isPadah,
              corporate_name: corporateName,
              fantasy_name: fantasyName,
              state_registration: stateRegistration,
              financial_email: financialEmail,
              address_street: addressStreet,
              address_number: addressNumber,
              district,
              cep,
              city,
              uf,
              phone,
              icms_contribution: icmsContribution,
              bank_account: bankAccount,
              bank_branch: bankBranch,
              bank_name: bankName,
              total_area: totalArea,
              selling_area: sellingArea,
              owner_name: ownerName,
              owner_cpf: ownerCpf,
            }),
          );
        }
      }
    }
  };

  const handleDocument = (e) => {
    const { value } = e.target;
    setDocument(sanitizeCNPJ(value));
  };

  const handleCpf = (e) => {
    const { value } = e.target;
    setOwnerCpf(sanitizeCPF(value));
  };

  return (
    <>
      {companySpinner && <Spinner overlay />}
      <Container style={{ opacity: companySpinner ? 0.5 : 1 }}>
        <Paper
          elevation={2}
          fullWidth
          style={{
            maxHeight: '95vh',
            overflow: 'auto',
            minWidth: '60vw',
            padding: 20,
          }}
        >
          <ModalTitle>
            {id === 'new' ? 'Adicionar' : 'Editar'} Padaria
          </ModalTitle>
          <FormGroup>
            <CheckBoxContainer>
              <CheckboxContent>
                <FormControlLabel
                  control={
                    <Switch
                      checked={active}
                      onChange={(e) => setActive(e.target.checked)}
                    />
                  }
                  label="Ativo"
                  labelPlacement="start"
                />
              </CheckboxContent>
              <CheckboxContent>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isPadah}
                      onChange={(e) => setIsPadah(e.target.checked)}
                    />
                  }
                  label="Padah!"
                  labelPlacement="start"
                />
              </CheckboxContent>
            </CheckBoxContainer>
            <InputContent>
              <TextField
                id="name-input"
                label="Nome*"
                type="text"
                error={nameError}
                helperText={nameError && 'Nome é obrigatório'}
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </InputContent>
            <InputContent>
              <InputMask
                mask="99.999.999/9999-99"
                type="text"
                value={document}
                onChange={handleDocument}
                disabled={false}
                maskChar=" "
              >
                {() => (
                  <TextField
                    id="document-input"
                    label="CNPJ*"
                    type="text"
                    variant="outlined"
                    fullWidth
                    error={documentError}
                    helperText={documentError && handleDocumentErrorMessage}
                  />
                )}
              </InputMask>
            </InputContent>
            <InputContent>
              <TextField
                id="epadoca-name-input"
                label="Nome Cadastrado no Epadoca"
                type="text"
                variant="outlined"
                value={epadocaAlias}
                onChange={(e) => setEpadocaAlias(e.target.value)}
                fullWidth
              />
            </InputContent>
            <InputContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-label">Categoria</InputLabel>
                <Select
                  labelId="select-label"
                  id="category-select"
                  value={category}
                  variant="outlined"
                  onChange={(e) => setCategory(e.target.value)}
                  fullWidth
                >
                  {COMPANY_CATEGORIES.map((item) =>
                    <MenuItem value={item}>{item}</MenuItem>)}
                </Select>
              </FormControl>
            </InputContent>
            <InputContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-label-2">Grupo de Empresas</InputLabel>
                <Select
                  labelId="select-label-2"
                  id="group-select"
                  value={groupId}
                  variant="outlined"
                  onChange={(e) => setGroupId(e.target.value)}
                  fullWidth
                >
                  {allGroups &&
                    allGroups.map((r) => (
                      <MenuItem value={r.id}>{r.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </InputContent>
            <InputContent>
              <TextField
                id="corporate-name-input"
                label="Razão social"
                type="text"
                variant="outlined"
                value={corporateName}
                onChange={(e) => setCorporateName(e.target.value)}
                fullWidth
              />
            </InputContent>
            <InputContent>
              <TextField
                id="fantasy-name-input"
                label="Nome fantasia"
                type="text"
                variant="outlined"
                value={fantasyName}
                onChange={(e) => setFantasyName(e.target.value)}
                fullWidth
              />
            </InputContent>
            <Grid container>
              <Grid item xs={12} md={8}>
                <InputContent>
                  <TextField
                    id="state-regs-input"
                    label="Inscrição estadual"
                    type="text"
                    variant="outlined"
                    value={stateRegistration}
                    onChange={(e) => setStateRegistration(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputContent>
                  <TextField
                    id="icms-input"
                    label="Contribuinte ICMS?"
                    type="text"
                    select
                    variant="outlined"
                    value={icmsContribution}
                    onChange={(e) => setIcmsContribution(e.target.value)}
                    fullWidth
                  >
                    {ICMS_CONTRIBUTION_OPTIONS.map((option) =>
                      <MenuItem value={option}>{option}</MenuItem>,
                    )}
                  </TextField>
                </InputContent>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={7}>
                <InputContent>
                  <TextField
                    id="email-input"
                    label="E-mail financeiro"
                    type="text"
                    variant="outlined"
                    value={financialEmail}
                    onChange={(e) => setFinancialEmail(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={5}>
                <InputContent>
                  <TextField
                    id="phone-input"
                    label="Telefone"
                    type="text"
                    variant="outlined"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={8}>
                <InputContent>
                  <TextField
                    id="address-input"
                    label="Logradouro (rua, avenida etc)"
                    type="text"
                    variant="outlined"
                    value={addressStreet}
                    onChange={(e) => setAddressStreet(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputContent>
                  <TextField
                    id="number-input"
                    label="Número"
                    type="text"
                    variant="outlined"
                    value={addressNumber}
                    onChange={(e) => setAddressNumber(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={8}>
                <InputContent>
                  <TextField
                    id="district-input"
                    label="Bairro"
                    type="text"
                    variant="outlined"
                    value={district}
                    onChange={(e) => setDistrict(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputContent>
                  <TextField
                    id="cep-input"
                    label="CEP"
                    type="text"
                    variant="outlined"
                    value={cep}
                    onChange={(e) => setCep(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={10}>
                <InputContent>
                  <TextField
                    id="city-input"
                    label="Cidade"
                    type="text"
                    variant="outlined"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={2}>
                <InputContent>
                  <TextField
                    id="uf-input"
                    label="UF"
                    type="text"
                    variant="outlined"
                    value={uf}
                    onChange={(e) => setUf(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
            </Grid>
            <InputContent>
              <TextField
                id="owner-name-input"
                label="Responsável pela Padaria"
                type="text"
                variant="outlined"
                value={ownerName}
                onChange={(e) => setOwnerName(e.target.value)}
                fullWidth
              />
            </InputContent>
            <InputContent>
              <InputMask
                mask="999.999.999-99"
                type="text"
                value={ownerCpf}
                onChange={handleCpf}
                disabled={false}
                maskChar=" "
              >
                {() => (
                  <TextField
                    id="owner-cpf-input"
                    label="CPF do responsável"
                    type="text"
                    variant="outlined"
                    fullWidth
                    error={cpfError}
                    helperText={cpfError && 'CPF inválido'}
                  />
                )}
              </InputMask>
            </InputContent>
            <InputContent>
              {bankError
                ? <TextField
                    id="bank-input"
                    label="Banco"
                    type="text"
                    variant="outlined"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                />
                : <Autocomplete
                    id="bank-box"
                    options={bankList}
                    value={bankName}
                    onChange={(_, newValue) => setBankName(newValue)}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        label="Banco"
                        variant="outlined"
                        fullWidth
                      />}
                />
              }
            </InputContent>
            <Grid container>
              <Grid item xs={12} md={4}>
                <InputContent>
                  <TextField
                    id="branch-input"
                    label="Agência"
                    type="text"
                    variant="outlined"
                    value={bankBranch}
                    onChange={(e) => setBankBranch(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={8}>
                <InputContent>
                  <TextField
                    id="account-input"
                    label="Conta bancária"
                    type="text"
                    variant="outlined"
                    value={bankAccount}
                    onChange={(e) => setBankAccount(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={6}>
                <InputContent>
                  <TextField
                    id="total-area-input"
                    label="Área total (m²)"
                    type="number"
                    variant="outlined"
                    value={totalArea}
                    error={totalAreaError}
                    helperText={totalAreaError && 'Área deve ser um número'}
                    onChange={handleTotalAreaChanged}
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputContent>
                  <TextField
                    id="selling-area-input"
                    label="Área de vendas (m²)"
                    type="number"
                    variant="outlined"
                    value={sellingArea}
                    helperText={sellingAreaError && 'Área deve ser um número'}
                    error={sellingAreaError}
                    onChange={handleSellingAreaChanged}
                    fullWidth
                  />
                </InputContent>
              </Grid>
            </Grid>

            <ButtonContainer>
              <Button
                color="secondary"
                onClick={handleClose}
                variant="contained"
                style={styles.formButton}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={handleSave}
                variant="contained"
                style={styles.formButton}
              >
                Salvar
              </Button>
            </ButtonContainer>
          </FormGroup>
        </Paper>
      </Container>
    </>
  );
};

export default connect((state) => ({
  company: state.company,
  companyModal: state.ui.companyModal,
  companySpinner: state.ui.companyLoading,
  allGroups: state.groups.docs,
}))(CompanyModal);
