import React from 'react';
import { Card, Title, SubTitle, StyledLink } from './styles';

const HomeCard = ({ title, subtitle, link, image, color, subColor }) => (
  <StyledLink href={link}>
    <Card color={color} subColor={subColor} image={image}>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
    </Card>
  </StyledLink>
);

export default HomeCard;
