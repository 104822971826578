/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Toastify from '../../utils/Toastify/index';
import Alert from '../../sharedComponents/Alert/index';
import {
  Container,
  PaperStyled,
  Title,
  TableTitle,
  ExcelTable,
  Column,
  ColumnHeader,
  ColumnContent,
  ButtonContainer,
  AddMoreButtonContainer,
  InputContainer,
} from './styles';
import staticFields from './fields';
import excelColumns from './excelColumns';
import { apiV2 } from '../../services/Api';
import DropZone from '../../sharedComponents/DropZone';
import PATHS from '../../config/routePaths';

const PicPayImport = () => {
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [fieldsLen, setFieldsLen] = useState(staticFields.length);
  const [fields, setFields] = useState([
    'consumer',
    'transaction_code',
    'reference_id',
    'type',
    'transaction_date',
    'status',
    'value',
    'fee',
    'liquid_value',
    'picpay_operator',
  ]);
  const [inputs, setInputs] = useState([]);
  const [skipHeader, setSkipHeader] = useState(true);

  const fieldsOrder = () => {
    setInputs(
      staticFields.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
    );
  };

  useEffect(() => {
    fieldsOrder();
  }, []);

  useEffect(() => {
    if (fieldsLen <= fields.length) return;
    const diff = fieldsLen - fields.length;
    setFields([...fields, ...new Array(diff)]);
  }, [fieldsLen]);

  const setValueOfField = (index, newValue) => {
    const newItems = [...fields];
    newItems[index] = newValue;
    setFields(newItems);
  };

  const handleImport = async () => {
    let err = false;

    if (!file) {
      err = true;
      Toastify.addError(
        'Nenhum arquivo encontrado. Por favor, selecione um arquivo válido.',
      );
      return;
    }

    if (file.invalid) {
      err = true;
      Toastify.addError(
        'Tipo de arquivo não suportado. Por favor, selecione um arquivo válido.',
      );
    }

    let savedDocuments = 0;
    fields.forEach((field) => {
      if (field) {
        savedDocuments += 1;
      }
    });

    if (savedDocuments === 0) {
      err = true;
      Toastify.addError('Indique as colunas do arquivo.');
    }

    const operatorField = fields.some((field) => field === 'picpay_operator');
    if (!operatorField) {
      err = true;
      Toastify.addError('Indique a coluna do operador.');
    }

    if (!err) {
      const form = new FormData();
      form.append('file', file);
      form.append('skipHeader', skipHeader);

      form.append('fields', fields);

      const confirmResponse = await Alert();
      if (confirmResponse && confirmResponse.confirm) {
        apiV2
          .post('/extracts/import/picPay?isAdmin=true', form)
          .then((response) => {
            if (response.status === 200 || response.status === 204) {
              Toastify.addSuccess(
                `Arquivo importado com sucesso. Foram adicionado(s) ${response.data.count} novo(s) registro(s).`,
              );
              history.push(PATHS.FINANCE_EXTRACTS);
            } else {
              Toastify.addError('Erro ao tentar importar dados. ');
            }
          })
          .catch((error) => {
            Toastify.addError(
              `Erro ao tentar importar dados.\n${error?.response?.data?.message}`,
            );
          });
      }
    }
  };

  return (
    <Container>
      <PaperStyled elevation={1}>
        <Grid container>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <Title>Importar Transações PicPay</Title>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} align="center">
            <DropZone file={file} onChangeFile={setFile} format="csv" />
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={skipHeader}
                  color="primary"
                  onChange={(event) => setSkipHeader(event.target.checked)}
                />
              }
              label="Ignorar primera linha?"
            />
          </Grid>
        </Grid>
        <TableTitle>Colunas do arquivo CSV</TableTitle>
        <ExcelTable>
          <AddMoreButtonContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setFieldsLen(fieldsLen + 1)}
            >
              Adicionar +1 coluna
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 5 }}
              onClick={() => setFieldsLen(fieldsLen + 5)}
            >
              Adicionar +5 colunas
            </Button>
          </AddMoreButtonContainer>
          {fields &&
            fields.map(
              (column, count) =>
                count < fieldsLen && (
                  <Column>
                    <ColumnHeader>{excelColumns[count]}</ColumnHeader>
                    <ColumnContent>
                      <InputContainer>
                        <Autocomplete
                          freeSolo
                          autoHighlight
                          size="small"
                          options={inputs.filter((input) =>
                            fields.every((field) => field !== input.value),
                          )}
                          getOptionLabel={(option) => option?.label}
                          value={
                            inputs.find(
                              ({ value }) => value === fields[count],
                            ) || null
                          }
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setValueOfField(count, newValue.value);
                            } else {
                              setValueOfField(count, null);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={params.label}
                              placeholder={params.label}
                            />
                          )}
                        />
                      </InputContainer>
                    </ColumnContent>
                  </Column>
                ),
            )}
        </ExcelTable>
        <ButtonContainer>
          <Button
            color="secondary"
            onClick={() => history.push(PATHS.SETTINGS_PIC_PAY_OPERATORS)}
            variant="contained"
            style={{ marginRight: 5 }}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={handleImport}
            variant="contained"
            style={{ marginleft: 5 }}
          >
            Importar
          </Button>
        </ButtonContainer>
      </PaperStyled>
    </Container>
  );
};

export default PicPayImport;
