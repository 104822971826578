import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormGroup,
  MenuItem,
  Button,
  FormControlLabel,
  Switch,
  Grid,
} from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Alert from '../../../sharedComponents/Alert/index';
import Spinner from '../../../sharedComponents/Spinner/index';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
} from './styles';

import { getItem, createItem, updateItem } from '../../../redux/actions/item';
import useDebounce from '../../../hooks/useDebounce';
import { api } from '../../../services/Api';

const styles = {
  formButton: {
    margin: 2,
  },
};

const ItemModal = ({
  id,
  dispatch,
  item,
  itemModal,
  handleClose,
  itemSpinner,
  company,
}) => {
  const [data, setData] = useState({
    description: '',
    qty: '',
    unit_value: 0,
    status: '',
    total_paid_percentage: '',
    type: '',
    provider_code: '',
    observation: '',
  });

  const [providerError, setProviderError] = useState(false);

  const types = [
    'Uniformes',
    'Comunicação Visual Interna',
    'comunicação Visual Externa',
    'Iluminação',
    'Infraestrutura',
    'Serviços Civis',
    'Inauguração',
    'Marcenaria e Layout',
    'Treinamento',
    'Paisagismo/Decoração',
  ];
  const statusType = ['Aprovado', 'Aguardando', 'Reprovado'];
  const statusColor = {
    Aprovado: '#c5e1a5',
    Aguardando: undefined,
    Reprovado: '#ef9a9a',
  };

  const [descriptionError, setDescriptionError] = useState(false);

  const {
    description,
    qty,
    unit_value,
    status,
    total_paid_percentage,
    type,
    provider_code,
    observation,
  } = data;
  const debouncedProviderCode = useDebounce(provider_code, 800);

  useEffect(() => {
    if (!debouncedProviderCode) return;
    api
      .get(`providers/code/${debouncedProviderCode}`)
      .then((response) => {
        if (response.status !== 200 || !response.data._id)
          return setProviderError(true);
        setProviderError(false);
        setData((currentData) => ({
          ...currentData,
          type: response.data.type,
        }));
      })
      .catch(() => setProviderError(true));
  }, [debouncedProviderCode]);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getItem(id));
    }
  }, [id]);

  useEffect(() => {
    if (item && id !== 'new') {
      setData({ ...data, ...item, provider_code: item.provider?.code });
    }
  }, [item]);

  useEffect(() => {
    if (!itemModal) {
      handleClose();
    }
  }, [itemModal]);

  const handleSave = async () => {
    let err = false;
    setDescriptionError(!description);
    err = !description || providerError;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(createItem({ ...data, customer: company }));
        } else {
          dispatch(updateItem(data));
        }
      }
    }
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  const handleCurrency = (e, value) => {
    const { name } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  function calculatePaidValue(item) {
    const paidValue =
      (item.total_paid_percentage * item.unit_value * item.qty) / 100;

    return Number.isNaN(paidValue) ? 0 : paidValue;
  }

  return (
    <>
      {itemSpinner && <Spinner overlay />}
      <Paper elevation={2} fullWidth style={{ height: 'fit-content' }}>
        <Container style={{ opacity: itemSpinner ? 0.5 : 1 }}>
          <ModalTitle>{id === 'new' ? 'Adicionar' : 'Editar'} Item</ModalTitle>
          <FormGroup>
            <InputContent>
              <TextField
                id="outlined-password-input"
                label="Descrição"
                required
                type="text"
                error={descriptionError}
                helperText={descriptionError && 'Descrição é obrigatório'}
                variant="outlined"
                value={description}
                name="description"
                onChange={handleEdit}
                fullWidth
              />
            </InputContent>
            <InputContent>
              <TextField
                id="outlined-password-input"
                label="Padaria"
                disabled
                type="text"
                variant="outlined"
                value={company.name}
                fullWidth
              />
            </InputContent>
            <InputContent>
              <TextField
                label="Quantidade"
                type="number"
                variant="outlined"
                value={qty}
                name="qty"
                onChange={handleEdit}
                fullWidth
              />
            </InputContent>
            <Grid container>
              <Grid item xs={6}>
                <InputContent>
                  <CurrencyTextField
                    label="Valor Unitário"
                    variant="outlined"
                    value={unit_value}
                    name="unit_value"
                    onChange={handleCurrency}
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={6}>
                <InputContent>
                  <CurrencyTextField
                    label="Valor Total"
                    disabled
                    variant="outlined"
                    value={unit_value * qty}
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    fullWidth
                  />
                </InputContent>
              </Grid>
            </Grid>
            <InputContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-label">Status</InputLabel>
                <Select
                  value={status}
                  name="status"
                  variant="outlined"
                  onChange={handleEdit}
                  fullWidth
                  style={{ backgroundColor: statusColor[status] }}
                >
                  {statusType.map((status_type) => (
                    <MenuItem value={status_type}>{status_type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </InputContent>
            <Grid container>
              <Grid item xs={6}>
                <InputContent>
                  <TextField
                    label="% Total Pago"
                    variant="outlined"
                    value={total_paid_percentage}
                    name="total_paid_percentage"
                    onChange={handleEdit}
                    fullWidth
                    type="number"
                    InputProps={{
                      endAdornment: <span style={{ fontSize: 20 }}>%</span>,
                    }}
                    inputProps={{
                      min: 0,
                      max: 100,
                      style: { textAlign: 'right' },
                    }}
                    inputMode=""
                  />
                </InputContent>
              </Grid>
              <Grid item xs={6}>
                <InputContent>
                  <CurrencyTextField
                    label="Valor Pago"
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    disabled
                    variant="outlined"
                    value={calculatePaidValue(data)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
            </Grid>
            <InputContent>
              <TextField
                label="Código do Fornecedor"
                type="text"
                variant="outlined"
                required
                value={provider_code}
                name="provider_code"
                onChange={handleEdit}
                error={providerError}
                helperText={
                  providerError && 'Não foi possível localizar o fornecedor.'
                }
                fullWidth
              />
            </InputContent>
            <InputContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-label">Tipo</InputLabel>
                <Select
                  value={type}
                  name="type"
                  variant="outlined"
                  onChange={handleEdit}
                  fullWidth
                  disabled
                >
                  {types.map((r) => (
                    <MenuItem value={r}>{r}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </InputContent>
            <InputContent>
              <TextField
                id="outlined-multiline-static"
                label="Observações"
                multiline
                rows={6}
                defaultValue={observation}
                value={observation}
                name="observation"
                onChange={handleEdit}
                variant="outlined"
                fullWidth
              />
            </InputContent>
            <ButtonContainer>
              <Button
                color="secondary"
                onClick={handleClose}
                variant="contained"
                style={styles.formButton}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={handleSave}
                variant="contained"
                style={styles.formButton}
              >
                Salvar
              </Button>
            </ButtonContainer>
          </FormGroup>
        </Container>
      </Paper>
    </>
  );
};

export default connect((state) => ({
  item: state.item,
  itemModal: state.ui.itemModal,
  itemSpinner: state.ui.itemLoading,
  company: state.company,
}))(ItemModal);
