import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
  @media (max-width: 567px) {
    padding: 0.5rem;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #a3a3a333;
  align-self: center;
  margin: 1rem;
  margin-bottom: 0;
`;

export const NoteCard = styled.div`
  border: 1px solid black;
  padding: 0.5rem;
  display: flex;
  border-radius: 5px;
  align-items: center;
  margin-top: 0.5rem;
`;
