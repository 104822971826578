import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { getCommissionings } from '../../redux/actions/commissionings';
import { showModal, hideModal, updateCommissionValue } from '../../redux/actions/commissioning';
import ModalContainer from '../../sharedComponents/ModalContainer/index';
import CreateCommissioningModal from '../Modals/Commissionings/CreateModal';
import EditModal from '../Modals/Commissionings/EditModal';
import { Container } from './styles';
import { getFormattedRegisterItems } from '../../providers/Registers';
import { getFormattedCommissionings } from '../../providers/Commissionings';
import SectionHeader from './components/SectionHeader';
import SectionContent from './components/SectionContent';
import { getCompanies } from '../../redux/actions/companies';

const Commissionings = ({
  dispatch,
  commissionings,
  commissioningModal,
  commissioningsLoading,
  commissioningLoading,
  companies,
  loadingCompanies,
}) => {
  const [commissioningsFormatted, setCommissioningsFormatted] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(1000);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [editCommissionData, setEditCommssionData] = useState({});
  const [commissionData, setCommissionData] = useState({});

  const isTablet = useMediaQuery('(max-width:812px)');

  useEffect(() => {
    dispatch(getCompanies({ listAll: true }));
  }, []);

  useEffect(() => {
    dispatch(getCommissionings({ page: page + 1, limit, searchText }));
  }, [page, limit]);

  useEffect(() => {
    if (commissionings.docs && companies.docs) {
      const formattedRegisters = getFormattedRegisterItems(
        commissionings.docs,
        companies.docs,
      );
      const formattedCommissionings = getFormattedCommissionings(
        formattedRegisters,
      );
      setCommissioningsFormatted(formattedCommissionings);
    }
    setTotal(commissionings?.total || 0);
  }, [commissionings, companies]);

  const handleOpenModal = () => {
    dispatch(showModal());
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleShowEditModal = (newCommissionData, oldCommissionData) => {
    setEditCommssionData(newCommissionData);
    setCommissionData(oldCommissionData);
    setShowEditModal(true);
  };

  const saveEditCommission = (data, validSince) => {
    const salesFunction = data.paymentKind.split(' ')[0].replaceAll('[', '').replaceAll(']', '');
    setShowEditModal(false);
    dispatch(
      updateCommissionValue({
        ...data,
        salesFunction,
        validSince,
      }),
    );
  };

  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <ModalContainer open={commissioningModal} handleClose={handleCloseModal}>
        <CreateCommissioningModal
          id={modalContentId}
          handleClose={handleCloseModal}
        />
      </ModalContainer>
      <ModalContainer
        open={showEditModal}
        handleClose={() => setShowEditModal(false)}
      >
        <EditModal
          newCommissionData={editCommissionData}
          commissionData={commissionData}
          handleClose={() => setShowEditModal(false)}
          handleSave={saveEditCommission}
        />
      </ModalContainer>
      <Container>
        <SectionHeader
          onSubmitCallback={setSearchText}
          searchText={searchText}
          dispatch={dispatch}
          setPage={setPage}
          setTotal={setTotal}
          limit={limit}
          isTablet={isTablet}
          handleClick={handleClick}
        />
        <SectionContent
          commissioningsLoading={commissioningsLoading}
          loadingCompanies={loadingCompanies}
          commissioningsFormatted={commissioningsFormatted}
          commissioningLoading={commissioningLoading}
          companies={companies}
          dispatch={dispatch}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          total={total}
          limit={limit}
          page={page}
          handleShowEditModal={handleShowEditModal}
        />
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedUser: state.loggedUser,
  commissioningsLoading: state.ui.commissioningsLoading,
  commissioningLoading: state.ui.commissioningLoading,
  commissionings: state.commissionings,
  companies: state.companies,
  loadingCompanies: state.ui.loadingCompanies,
  commissioningModal: state.ui.commissioningModal,
});

export default connect(mapStateToProps)(Commissionings);
