import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { AddRounded } from '@material-ui/icons';
import SubHeader from '../../../../sharedComponents/SubHeader';
import { getCommissionings } from '../../../../redux/actions/commissionings';
import { useStyles } from './styles';
import {
  hasPermission,
} from '../../../../utils/globalFunctions';
import PATHS from '../../../../config/routePaths';

const SectionHeader = ({ onSubmitCallback, searchText, dispatch, setPage, setTotal, limit, isTablet, handleClick }) => {
  const [text, setText] = useState(searchText);
  const classes = useStyles();

  const handleTextChange = (e) => {
    const { value } = e.target;
    setText(value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getCommissionings({ page: 1, limit, searchText: text }));
    setPage(0);
    setTotal(0);
  };

  const submit = (e) => {
    const { value } = e.target;
    onSubmitCallback(value);
    handleSearch(e);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      submit(e);
    }
  };

  return (
    <SubHeader
      title={
        <span>
          {isTablet ? 'C' : 'Registro de c'}
          omissionamentos
          <IconButton
            className={classes.addButton}
            onClick={() => handleClick('new')}
            disabled={!hasPermission(PATHS.SETTINGS_COMMISSIONING, 'create')}
            size="small"
          >
            <AddRounded />
          </IconButton>
        </span>
      }
    >
      <span
        style={{
          paddingTop: 4,
          width: '100%',
          maxWidth: isTablet ? undefined : 300,
        }}
      >
        <TextField
          label={!text ? 'Pesquisar por tipo ou função' : ''}
          type="text"
          size="small"
          variant="outlined"
          value={text}
          onChange={handleTextChange}
          onKeyPress={handleKeyPress}
          onBlur={submit}
          fullWidth
          InputProps={{
            style: {
              borderRadius: '1.5rem',
              border: '1px solid var(--primary-text-color)',
              color: 'var(--primary-text-color)',
            },
          }}
          InputLabelProps={{
            shrink: false,
            style: { color: 'var(--primary-text-color)' },
          }}
        />
      </span>
    </SubHeader>
  );
};

export default SectionHeader;
