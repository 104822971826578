import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100%;
  padding: 10px;
`;

export const Picture = styled.img`
  margin-top: 10px;
  margin-bottom: 20px;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  cursor: pointer;
`;

export const InputForm = styled.div`
  margin: 15px;
`;
