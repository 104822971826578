import { GET_PRODUCTS, UPDATE_PRODUCTS } from '../actions/products';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
  total: 0,
  totalSold: 0,
  recommendedTotal: 0,
  percentageOverRevenue: 0,
};

export function productsReducer(products = initState, action) {
  switch (action.type) {
    case GET_PRODUCTS:
      return action.payload;
    case UPDATE_PRODUCTS:
      return action.payload;

    default:
      return products;
  }
}
