import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import {
  CheckCircle,
  CancelRounded,
  EditRounded,
  AddRounded,
} from '@material-ui/icons';
import { useMediaQuery } from '@material-ui/core';
import { getSuppliers } from '../../redux/actions/suppliers';
import { showModal, hideModal } from '../../redux/actions/supplier';
import ModalContainer from '../../sharedComponents/ModalContainer/index';
import EditModal from '../Modals/Suppliers';
import Spinner from '../../sharedComponents/Spinner/index';
import { Container, useStyles } from './styles';
import SubHeader from '../../sharedComponents/SubHeader';
import { hasPermission, maskCNPJ } from '../../utils/globalFunctions';
import PATHS from '../../config/routePaths';

const Suppliers = ({ dispatch, suppliers, supplierModal, suppliersLoading }) => {
  const classes = useStyles();
  const [suppliersFormatted, setSuppliersFormatted] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');

  const isTablet = useMediaQuery('(max-width:812px)');
  const isMobile = useMediaQuery('(max-width:480px)');

  useEffect(() => {
    dispatch(getSuppliers({ page: page + 1, limit, query: searchText }));
  }, [page, limit]);

  useEffect(() => {
    if (suppliers.docs) {
      setSuppliersFormatted(suppliers.docs);
      setTotal(suppliers.total);
    }
  }, [suppliers]);

  const handleOpenModal = () => {
    dispatch(showModal());
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getSuppliers({ page: 1, limit, query: searchText }));
  };

  return (
    <>
      <ModalContainer open={supplierModal} handleClose={handleCloseModal}>
        <EditModal id={modalContentId} handleClose={handleCloseModal} />
      </ModalContainer>
      <Container>
        <SubHeader
          title={
            <span>
              Fornecedores
              <IconButton
                className={classes.addButton}
                onClick={() => handleClick('new')}
                disabled={!hasPermission(PATHS.SETTINGS_SUPPLIERS, 'create')}
                size="small"
              >
                <AddRounded />
              </IconButton>
            </span>
          }
        >
          <span
            style={{
              paddingTop: 4,
              width: isMobile ? '100%' : undefined,
            }}
          >
            <TextField
              style={{
                width: isTablet ? '100%' : 300,
              }}
              label={searchText ? '' : 'Pesquisar'}
              type="text"
              size="small"
              variant="outlined"
              value={searchText}
              name="supplier_name"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(e) => (e.key === 'Enter' ? handleSearch(e) : null)}
              fullWidth
              InputProps={{
                style: {
                  borderRadius: '1.5rem',
                  border: '1px solid var(--primary-text-color)',
                  color: 'var(--primary-text-color)',
                },
              }}
              InputLabelProps={{
                shrink: false,
                style: { color: 'var(--primary-text-color)' },
              }}
            />
          </span>
        </SubHeader>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Ativo</TableCell>
                    <TableCell colSpan={2}>Razão Social</TableCell>
                    <TableCell>CNPJ</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {suppliersLoading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="5" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!suppliersLoading &&
                    suppliersFormatted.map((supplier) => (
                      <TableRow key={supplier.id} className={classes.row}>
                        <TableCell className={classes.cell}>
                          {supplier.active ? (
                            <CheckCircle className={classes.checkButton} />
                          ) : (
                            <CancelRounded className={classes.closeButton} />
                          )}
                        </TableCell>
                        <TableCell colSpan="2" className={classes.cell}>
                          <b>{supplier.corporate_name}</b>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <b>{maskCNPJ(supplier.document)}</b>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <b>{supplier.email}</b>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <IconButton
                            onClick={() => handleClick(supplier.id)}
                            disabled={
                              !hasPermission(PATHS.SETTINGS_SUPPLIERS, 'update')
                            }
                          >
                            <EditRounded />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 100, 200, 500, 1000]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={suppliers.page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Resultados por página"
            />
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  suppliersLoading: state.ui.suppliersLoading,
  suppliers: state.suppliers,
  supplierModal: state.ui.supplierModal,
});

export default connect(mapStateToProps)(Suppliers);
