import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import {
  CheckCircle,
  CancelRounded,
  EditRounded,
  AddRounded,
  Publish as UploadIcon,
} from '@material-ui/icons';
import { useMediaQuery } from '@material-ui/core';
import { getPicPayOperators } from '../../redux/actions/picPayOperators';
import { showModal, hideModal } from '../../redux/actions/picPayOperator';
import ModalContainer from '../../sharedComponents/ModalContainer/index';
import EditModal from '../Modals/PicPayOperators';
import Spinner from '../../sharedComponents/Spinner/index';
import { Container } from './styles';
import { getFormattedRegisterItems } from '../../providers/Registers';
import SubHeader from '../../sharedComponents/SubHeader';
import { getCompanies } from '../../redux/actions/companies';
import PATHS from '../../config/routePaths';
import { hasPermission } from '../../utils/globalFunctions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: '1rem',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '1rem',
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 15px',
    textAlign: 'center',
  },
  headerCell: {
    textAlign: 'center',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
  },
  addButton: {
    border: '2px solid var(--primary-text-color)',
    marginLeft: '1rem',
    color: 'var(--primary-text-color)',
    '&.secondary': {
      color: '#f4b169',
      border: '2px solid #f4b169',
    },
  },
}));

const PicPayOperators = ({
  picPayOperators,
  picPayOperatorModal,
  picPayOperatorsLoading,
  companies,
  loadingCompanies,
}) => {
  const classes = useStyles();
  const [picPayOperatorsFormatted, setPicPayOperatorsFormatted] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');

  const isTablet = useMediaQuery('(max-width:836px)');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanies({ listAll: true }));
  }, []);

  useEffect(() => {
    dispatch(getPicPayOperators({ page: page + 1, limit }));
  }, [page, limit]);

  useEffect(() => {
    if (picPayOperators.docs && companies.docs) {
      const formattedItems = getFormattedRegisterItems(
        picPayOperators.docs,
        companies.docs,
      );
      setPicPayOperatorsFormatted(formattedItems);
      setTotal(picPayOperators.total);
    }
  }, [picPayOperators, companies]);

  const handleOpenModal = () => {
    dispatch(showModal());
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getPicPayOperators({ page: 1, limit, searchText }));
  };

  return (
    <>
      <ModalContainer open={picPayOperatorModal} handleClose={handleCloseModal}>
        <EditModal id={modalContentId} handleClose={handleCloseModal} />
      </ModalContainer>
      <Container>
        <SubHeader
          title={
            <span>
              Operação PicPay
              <IconButton
                size="small"
                className={classes.addButton}
                onClick={() => handleClick('new')}
                disabled={
                  !hasPermission(PATHS.SETTINGS_PIC_PAY_OPERATORS, 'create')
                }
              >
                <AddRounded />
              </IconButton>
              <Tooltip title="Importar transações PicPay">
                <Link to={PATHS.IMPORTS_PIC_PAY}>
                  <IconButton
                    size="small"
                    className={`${classes.addButton} secondary`}
                    disabled={!hasPermission(PATHS.FINANCE_EXTRACTS, 'create')}
                  >
                    <UploadIcon />
                  </IconButton>
                </Link>
              </Tooltip>
            </span>
          }
        >
          <span
            style={{
              paddingTop: 4,
              width: '100%',
              maxWidth: isTablet ? undefined : 300,
            }}
          >
            <TextField
              label={searchText ? '' : 'Pesquisar'}
              type="text"
              size="small"
              variant="outlined"
              value={searchText}
              name="vendor_name"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(e) => (e.key === 'Enter' ? handleSearch(e) : null)}
              fullWidth
              InputProps={{
                style: {
                  borderRadius: '1.5rem',
                  border: '1px solid var(--primary-text-color)',
                  color: 'var(--primary-text-color)',
                },
              }}
              InputLabelProps={{
                shrink: false,
                style: { color: 'var(--primary-text-color)' },
              }}
            />
          </span>
        </SubHeader>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerCell}>Ativa</TableCell>
                    <TableCell className={classes.headerCell}>
                      Padaria
                    </TableCell>
                    <TableCell className={classes.headerCell}>
                      Operador
                    </TableCell>
                    <TableCell className={classes.headerCell} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(picPayOperatorsLoading || loadingCompanies) && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="7" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!(picPayOperatorsLoading || loadingCompanies) &&
                    picPayOperatorsFormatted.map((picPayOperator) => (
                      <TableRow className={classes.row}>
                        <TableCell className={classes.cell}>
                          {picPayOperator.active ? (
                            <CheckCircle className={classes.checkButton} />
                          ) : (
                            <CancelRounded className={classes.closeButton} />
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {picPayOperator.companyName}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {picPayOperator.operator}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <IconButton
                            onClick={() => handleClick(picPayOperator.id)}
                            disabled={
                              !hasPermission(
                                PATHS.SETTINGS_PIC_PAY_OPERATORS,
                                'update',
                              )
                            }
                          >
                            <EditRounded />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Resultados por página"
            />
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  picPayOperatorsLoading: state.ui.picPayOperatorsLoading,
  picPayOperators: state.picPayOperators,
  companies: state.companies,
  loadingCompanies: state.ui.loadingCompanies,
  picPayOperatorModal: state.ui.picPayOperatorModal,
});

export default connect(mapStateToProps)(PicPayOperators);
