import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { MoreHorizOutlined } from '@material-ui/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PAYMENT_KINDS,
  statusColorMap,
  statusMap,
} from '../../../config/consts';
import PATHS from '../../../config/routePaths';
import { getPaymentMachineRegisters } from '../../../redux/actions/paymentMachineRegisters';
import { updateRegister } from '../../../services/PaymentMachines';
import Alert from '../../../sharedComponents/Alert';
import { hasPermission } from '../../../utils/globalFunctions';
import Toastify from '../../../utils/Toastify';
import {
  Divider,
  ExpandIcon,
  StatusTableCell,
  StyledMenu,
  StyledMenuItem,
  StyledTableCell,
} from '../styles';
import SubTable from './TerminalSubTable';

export default function TerminalRow({
  paymentMachine,
  companies,
  open,
  onClick,
  onOpenModal,
}) {
  const [anchor, setAnchor] = React.useState(null);
  const dispatch = useDispatch();
  const page = useSelector((state) => state.paymentMachineRegisters?.page);
  const limit = useSelector((state) => state.paymentMachineRegisters?.limit);

  const handleOpenActions = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchor(null);
  };

  const onFinish = (result) => {
    result.then(({ ok, data }) => {
      if (!ok) {
        Toastify.addError(
          data?.message ||
            'Ocorreu um erro durante a operação, por favor tente novamente.',
        );
        return;
      }
      Toastify.addSuccess('POS atualizado com sucesso.');
      dispatch(getPaymentMachineRegisters({ page, limit }));
    });
  };

  const handleStatusChange = (label, status) => async () => {
    const confirmation = await Alert(
      'Tem certeza?',
      `Ao confirmar, este POS será marcado como "${label}".'`,
    );
    if (!confirmation?.confirm) return;

    onFinish(
      updateRegister(paymentMachine.id, {
        status,
      }),
    );
  };

  const actionsMap = {
    RESERVED: [
      { label: 'Ativar', action: onOpenModal(paymentMachine.id, 'ACTIVATE') },
      {
        label: 'POS com defeito',
        action: handleStatusChange('COM DEFEITO', 'FAULTY'),
      },
    ],
    ACTIVE: [
      { label: 'Transferir', action: onOpenModal(paymentMachine.id, 'TRANSFER') },
      {
        label: 'Alterar função de venda ',
        action: onOpenModal(paymentMachine.id, 'FUNCTION'),
      },
      {
        label: 'Transferir para Reserva ',
        action: handleStatusChange('RESERVA', 'RESERVED'),
      },
      {
        label: 'POS com defeito',
        action: handleStatusChange('COM DEFEITO', 'FAULTY'),
      },
    ],
    FAULTY: [
      {
        label: 'Desativar',
        action: handleStatusChange('DESATIVADO', 'DISABLED'),
      },
      {
        label: 'Transferir para Reserva',
        action: handleStatusChange('RESERVA', 'RESERVED'),
      },
    ],
    DISABLED: [],
  };

  return (
    <>
      <TableRow style={{ backgroundColor: open ? '#F3F2F7' : undefined }}>
        <StyledTableCell>{paymentMachine.serialNumber}</StyledTableCell>
        <StyledTableCell>
          <StatusTableCell colors={statusColorMap[paymentMachine.status]}>
            {statusMap[paymentMachine.status]}
          </StatusTableCell>
        </StyledTableCell>
        <StyledTableCell>{paymentMachine.companyName}</StyledTableCell>
        <StyledTableCell>{paymentMachine.salesFunction}</StyledTableCell>
        <StyledTableCell>{paymentMachine.lastUpdateFormatted}</StyledTableCell>
        <StyledTableCell>
          <Tooltip title="Ações">
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleOpenActions}
              disabled={
                !hasPermission(PATHS.SETTINGS_PAYMENT_MACHINES, 'update') ||
                !actionsMap[paymentMachine.status]?.length
              }
            >
              <MoreHorizOutlined />
            </IconButton>
          </Tooltip>
          <StyledMenu
            anchorEl={anchor}
            keepMounted
            open={Boolean(anchor)}
            onClose={handleCloseActions}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            elevation={1}
          >
            {actionsMap[paymentMachine.status]?.map((item, index) => (
              <React.Fragment key={item.label}>
                {!!index && <Divider />}
                <StyledMenuItem onClick={item.action}>
                  {item.label}
                </StyledMenuItem>
              </React.Fragment>
            ))}
          </StyledMenu>
        </StyledTableCell>
        <StyledTableCell>
          <Tooltip title={open ? 'Esconder' : 'Expandir'}>
            <IconButton
              aria-label="expand row"
              onClick={onClick(paymentMachine.serialNumber)}
            >
              <ExpandIcon open={open} />
            </IconButton>
          </Tooltip>
        </StyledTableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: open ? '#F3F2F7' : undefined }}>
        <TableCell
          style={{ padding: open ? '1rem' : 0 }}
          colSpan={PAYMENT_KINDS.length + 3}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <SubTable
              serialNumber={paymentMachine.serialNumber}
              companies={companies}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
