import { api, apiV2 } from './Api';

class Service {
  constructor({ route, apiV2: useApiV2 }) {
    this.route = route;
    this.useApiV2 = useApiV2;
  }

  async post(data, params) {
    return this.store(data, params);
  }

  async store(data, params) {
    try {
      const response = await (this.useApiV2 ? apiV2 : api).post(
        this.route,
        data,
        { params },
      );
      return {
        data: response.data,
        ok: true,
        status: response.data.status,
      };
    } catch (error) {
      return {
        ok: false,
        status: error.response?.status || error.response?.data?.status,
        statusCode:
          error.response?.statusCode || error.response?.data?.statusCode,
        message:
          error.response?.data?.message ||
          error.response?.data?.error ||
          error.message ||
          'Route not found',
      };
    }
  }

  async delete(query = {}, params = [], options = {}) {
    const queryString = [];
    const _query = { ...query };
    if (params.length) {
      params.forEach((paramKey) => {
        _query[paramKey] = _query.filter[paramKey];
      });
      delete _query.filter;
    }
    Object.keys(_query).forEach((paramKey) => {
      let paramToAdd = _query[paramKey];
      if (!paramToAdd || paramToAdd.length === 0) {
        return;
      }
      if (typeof paramToAdd === 'object') {
        paramToAdd = JSON.stringify(paramToAdd);
      }
      queryString.push(`${paramKey}=${paramToAdd}`);
    });

    const url = queryString.length
      ? `${this.route}?${queryString.join('&')}`
      : `${this.route}`;

    try {
      const response = await (this.useApiV2 ? apiV2 : api).delete(url, options);
      return {
        data: response.data,
        ok: true,
        status: response.data.status,
      };
    } catch (error) {
      return {
        ok: false,
        status: error.response?.status || error.response?.data?.status,
        statusCode:
          error.response?.statusCode || error.response?.data?.statusCode,
        message:
          error.response?.data?.message ||
          error.response?.data?.error ||
          error.message ||
          'Route not found',
      };
    }
  }

  async get(query = {}, params = [], options = {}) {
    const queryString = [];
    const _query = { ...query };
    if (params.length) {
      params.forEach((paramKey) => {
        _query[paramKey] = _query.filter[paramKey];
      });
      delete _query.filter;
    }
    Object.keys(_query).forEach((paramKey) => {
      let paramToAdd = _query[paramKey];
      if (!paramToAdd || paramToAdd.length === 0) {
        return;
      }
      if (typeof paramToAdd === 'object') {
        paramToAdd = JSON.stringify(paramToAdd);
      }
      queryString.push(`${paramKey}=${paramToAdd}`);
    });

    const url = queryString.length
      ? `${this.route}?${queryString.join('&')}`
      : `${this.route}`;

    try {
      const response = await (this.useApiV2 ? apiV2 : api).get(url, options);
      return {
        data: response.data,
        ok: true,
        status: response.data.status,
      };
    } catch (error) {
      return {
        ok: false,
        status: error.response?.status || error.response?.data?.status,
        statusCode:
          error.response?.statusCode || error.response?.data?.statusCode,
        message:
          error.response?.data?.message ||
          error.response?.data?.error ||
          error.message ||
          'Route not found',
      };
    }
  }

  async put(id, data, params) {
    try {
      const fullRoute = id ? `${this.route}/${id}` : `${this.route}`;
      const response = await (this.useApiV2 ? apiV2 : api).put(
        fullRoute,
        data,
        { params },
      );
      return {
        data: response.data.docs || response.data,
        ok: true,
        status: response.data.status,
      };
    } catch (error) {
      return {
        ok: false,
        status: error.response?.status || error.response?.data?.status,
        statusCode:
          error.response?.statusCode || error.response?.data?.statusCode,
        message:
          error.response?.data?.message ||
          error.response?.data?.error ||
          error.message ||
          'Route not found',
      };
    }
  }
}

export default Service;
