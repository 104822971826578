import { toast, ToastContent } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const addSuccess = (
  message: ToastContent = 'A operação foi realizada com êxito',
) => {
  console.log(message);
  toast.success(message);
};
const addError = (
  message: ToastContent = 'Ocorreu um erro. Por favor, tente novamente ou contate o suporte.',
  error?: any,
) => {
  // TODO log on db with useful info as UserAgent, ip, href, etc
  console.log({
    message,
    userAgent: navigator.userAgent,
    href: window.location.href,
    error,
  });
  toast.error(message);
};
const addWarning = (
  message = 'Aviso! Algo pode ter dado errado. Por favor, verifique se está tudo certo.',
) => {
  console.log(message);
  toast.warning(message);
};
const addInfo = (message = 'Info') => {
  console.log(message);
  toast.info(message);
};

export default {
  addSuccess,
  addError,
  addWarning,
  addInfo,
};
