import { store } from '../../redux/store';
import { obj2QueryString, userIsAdmin } from '../../utils/globalFunctions';
import Service from '../Service';

export const getDailySummary = async (data) => {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const queryString = obj2QueryString({ ...data, isAdmin });
  const apiService = new Service({
    route: `/home/dailySummary?${queryString}`,
    apiV2: true,
  });
  const response = await apiService.get({});
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: response.data.status,
  };
};

export const getHistoricalSummary = async (data) => {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const queryString = obj2QueryString({ ...data, isAdmin });
  const apiService = new Service({
    route: `/home/historicalSummary?${queryString}`,
    apiV2: true,
  });
  const response = await apiService.get({});
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: response.data.status,
  };
};
