import {
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_ERROR,
  FETCH_ITEM_SUCCESS,
  FETCH_ITEM_ERROR,
  GET_ITEM,
  CREATE_ITEM,
  UPDATE_ITEM,
  updateObjectItem,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/item';
import { getItems } from '../actions/items';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'items';

export const createItemFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === CREATE_ITEM) {
      dispatch(
        apiRequest(
          'POST',
          URL,
          action.payload,
          UPDATE_ITEM_SUCCESS,
          UPDATE_ITEM_ERROR,
        ),
      );
      dispatch(showSpinner());
    }
  };

export const updateItemFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === UPDATE_ITEM) {
      dispatch(
        apiRequest(
          'PUT',
          URL,
          action.payload,
          UPDATE_ITEM_SUCCESS,
          UPDATE_ITEM_ERROR,
        ),
      );
      dispatch(showSpinner());
    }
  };

export const getItemFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GET_ITEM) {
      const { _id } = action.payload;
      dispatch(
        apiRequest(
          'GET-id',
          URL,
          { _id },
          FETCH_ITEM_SUCCESS,
          FETCH_ITEM_ERROR,
        ),
      );
      dispatch(showSpinner());
    }
  };

export const processItemCollection =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === FETCH_ITEM_SUCCESS) {
      dispatch(updateObjectItem(action.payload));
      dispatch(hideSpinner());
    } else if (action.type === UPDATE_ITEM_SUCCESS) {
      dispatch(updateObjectItem(action.payload));
      dispatch(hideModal());
      dispatch(hideSpinner());
      // To reload the page
      const { page, limit } = getState().items;
      dispatch(
        getItems({
          page,
          limit,
          filter: { customer: getState().customer?._id },
        }),
      );
      Toastify.addSuccess('Operação concluída com sucesso.');
    } else if (action.type === UPDATE_ITEM_ERROR) {
      dispatch(hideSpinner());
      Toastify.addError(
        'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
      );
      console.error(action.payload);
    }
  };

export const itemMdl = [
  createItemFlow,
  updateItemFlow,
  getItemFlow,
  processItemCollection,
];
