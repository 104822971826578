import { SHOW_USERS_SPINNER, HIDE_USERS_SPINNER } from '../actions/users';
import {
  SHOW_USER_MODAL,
  HIDE_USER_MODAL,
  SHOW_USER_SPINNER,
  HIDE_USER_SPINNER,
} from '../actions/user';
import {
  SHOW_PROVIDERS_SPINNER,
  HIDE_PROVIDERS_SPINNER,
} from '../actions/providers';
import {
  SHOW_PROVIDER_MODAL,
  HIDE_PROVIDER_MODAL,
  SHOW_PROVIDER_SPINNER,
  HIDE_PROVIDER_SPINNER,
} from '../actions/provider';
import { SHOW_ITEMS_SPINNER, HIDE_ITEMS_SPINNER } from '../actions/items';
import {
  SHOW_ITEM_MODAL,
  HIDE_ITEM_MODAL,
  SHOW_ITEM_SPINNER,
  HIDE_ITEM_SPINNER,
} from '../actions/item';
import {
  SHOW_BAKERY_ADDITIONAL_INFORMATIONS_SPINNER,
  HIDE_BAKERY_ADDITIONAL_INFORMATIONS_SPINNER,
} from '../actions/bakeryAdditionalInformations';
import {
  SHOW_BAKERY_ADDITIONAL_INFORMATION_MODAL,
  HIDE_BAKERY_ADDITIONAL_INFORMATION_MODAL,
  SHOW_BAKERY_ADDITIONAL_INFORMATION_SPINNER,
  HIDE_BAKERY_ADDITIONAL_INFORMATION_SPINNER,
} from '../actions/bakeryAdditionalInformation';
import { SHOW_LOGIN_SPINNER, HIDE_LOGIN_SPINNER } from '../actions/loggedUser';
import { SHOW_ROLES_SPINNER, HIDE_ROLES_SPINNER } from '../actions/roles';
import {
  SHOW_ROLE_MODAL,
  HIDE_ROLE_MODAL,
  SHOW_ROLE_SPINNER,
  HIDE_ROLE_SPINNER,
} from '../actions/role';
import {
  SHOW_WEEKLY_TAXES_SPINNER,
  HIDE_WEEKLY_TAXES_SPINNER,
} from '../actions/weeklyTaxes';
import {
  SHOW_WEEKLY_TAX_MODAL,
  HIDE_WEEKLY_TAX_MODAL,
  SHOW_WEEKLY_TAX_SPINNER,
  HIDE_WEEKLY_TAX_SPINNER,
} from '../actions/weeklyTax';
import {
  SHOW_SUPPLIERS_SPINNER,
  HIDE_SUPPLIERS_SPINNER,
} from '../actions/suppliers';
import {
  SHOW_SUPPLIER_MODAL,
  HIDE_SUPPLIER_MODAL,
  SHOW_SUPPLIER_SPINNER,
  HIDE_SUPPLIER_SPINNER,
} from '../actions/supplier';
import { SHOW_OFFERS_SPINNER, HIDE_OFFERS_SPINNER } from '../actions/offers';
import {
  SHOW_OFFER_MODAL,
  HIDE_OFFER_MODAL,
  SHOW_OFFER_SPINNER,
  HIDE_OFFER_SPINNER,
} from '../actions/offer';
import { SHOW_MODULES_SPINNER, HIDE_MODULES_SPINNER } from '../actions/modules';
import {
  SHOW_MODULE_MODAL,
  HIDE_MODULE_MODAL,
  SHOW_MODULE_SPINNER,
  HIDE_MODULE_SPINNER,
} from '../actions/module';
import {
  SHOW_COMPANIES_SPINNER,
  HIDE_COMPANIES_SPINNER,
} from '../actions/companies';
import {
  SHOW_COMPANY_MODAL,
  HIDE_COMPANY_MODAL,
  SHOW_COMPANY_SPINNER,
  HIDE_COMPANY_SPINNER,
} from '../actions/company';
import {
  SHOW_COMPETITORS_SPINNER,
  HIDE_COMPETITORS_SPINNER,
} from '../actions/competitors';
import {
  SHOW_COMPETITOR_MODAL,
  HIDE_COMPETITOR_MODAL,
  SHOW_COMPETITOR_SPINNER,
  HIDE_COMPETITOR_SPINNER,
} from '../actions/competitor';
import { SHOW_GROUPS_SPINNER, HIDE_GROUPS_SPINNER } from '../actions/groups';
import {
  SHOW_GROUP_MODAL,
  HIDE_GROUP_MODAL,
  SHOW_GROUP_SPINNER,
  HIDE_GROUP_SPINNER,
} from '../actions/group';
import {
  SHOW_PASSWORD_MODAL,
  HIDE_PASSWORD_MODAL,
  SHOW_PASSWORD_SPINNER,
  HIDE_PASSWORD_SPINNER,
} from '../actions/password';
import {
  HIDE_PAYMENT_MACHINE_REGISTERS_SPINNER,
  SHOW_PAYMENT_MACHINE_REGISTERS_SPINNER,
} from '../actions/paymentMachineRegisters';
import {
  HIDE_PAYMENT_MACHINES_SPINNER,
  SHOW_PAYMENT_MACHINES_SPINNER,
} from '../actions/paymentMachines';
import {
  HIDE_PAYMENT_MACHINE_MODAL,
  HIDE_PAYMENT_MACHINE_SPINNER,
  SHOW_PAYMENT_MACHINE_MODAL,
  SHOW_PAYMENT_MACHINE_SPINNER,
} from '../actions/paymentMachine';
import {
  HIDE_PICPAY_OPERATORS_SPINNER,
  SHOW_PICPAY_OPERATORS_SPINNER,
} from '../actions/picPayOperators';
import {
  HIDE_PICPAY_OPERATOR_MODAL,
  HIDE_PICPAY_OPERATOR_SPINNER,
  SHOW_PICPAY_OPERATOR_MODAL,
  SHOW_PICPAY_OPERATOR_SPINNER,
} from '../actions/picPayOperator';
import {
  HIDE_EXTRACTS_SPINNER,
  SHOW_EXTRACTS_SPINNER,
} from '../actions/extracts';
import {
  HIDE_COMMISSIONINGS_SPINNER,
  SHOW_COMMISSIONINGS_SPINNER,
} from '../actions/commissionings';
import {
  HIDE_COMMISSIONING_MODAL,
  HIDE_COMMISSIONING_SPINNER,
  SHOW_COMMISSIONING_MODAL,
  SHOW_COMMISSIONING_SPINNER,
} from '../actions/commissioning';
import {
  HIDE_TRANSFERS_SPINNER,
  SHOW_TRANSFERS_SPINNER,
} from '../actions/transfers';
import {
  HIDE_TRANSFER_MODAL,
  HIDE_TRANSFER_SPINNER,
  SHOW_TRANSFER_MODAL,
  SHOW_TRANSFER_SPINNER,
} from '../actions/transfer';
import {
  HIDE_EMBEDDED_RESOURCES_SPINNER,
  SHOW_EMBEDDED_RESOURCES_SPINNER,
} from '../actions/embeddedResources';
import {
  SHOW_PRODUCT_MODAL,
  HIDE_PRODUCT_MODAL,
  SHOW_PRODUCT_SPINNER,
  HIDE_PRODUCT_SPINNER,
} from '../actions/product';
import {
  SHOW_PRODUCTS_SPINNER,
  HIDE_PRODUCTS_SPINNER,
  SHOW_MARKET_STRUCTURE_SPINNER,
  HIDE_MARKET_STRUCTURE_SPINNER,
} from '../actions/products';
import {
  HIDE_DAILY_EXPECTED_PAYMENTS_SPINNER,
  SHOW_DAILY_EXPECTED_PAYMENTS_SPINNER,
} from '../actions/audit';

import {
  HIDE_REPORT_SPLIT_VALUES_SPINNER,
  SHOW_REPORT_SPLIT_VALUES_SPINNER,
} from '../actions/reportSplitValues';

import {
  HIDE_ACQUIRERS_SPINNER,
  SHOW_ACQUIRERS_SPINNER,
} from '../actions/acquirers';

import {
  HIDE_RECEIVED_PAYMENTS_SPINNER,
  SHOW_RECEIVED_PAYMENTS_SPINNER,
} from '../actions/receivedPayments';

import {
  SHOW_TRANSITORY_VALUES_SPINNER,
  HIDE_TRANSITORY_VALUES_SPINNER,
} from '../actions/transitoryValues';
import { SHOW_ORDERS_SPINNER, HIDE_ORDERS_SPINNER } from '../actions/orders';
import {
  SHOW_ORDER_MODAL,
  HIDE_ORDER_MODAL,
  SHOW_ORDER_SPINNER,
  HIDE_ORDER_SPINNER,
} from '../actions/order';

const initUi = {
  roleModal: false,
  supplierModal: false,
  userModal: false,
  userLoading: false,
  usersLoading: false,
  providerModal: false,
  providerLoading: false,
  providersLoading: false,
  itemModal: false,
  itemLoading: false,
  itemsLoading: false,
  bakeryAdditionalInformationModal: false,
  bakeryAdditionalInformationLoading: false,
  bakeryAdditionalInformationsLoading: false,
  loginLoading: false,
  supplierLoading: false,
  suppliersLoading: false,
  offerLoading: false,
  offersLoading: false,
  roleLoading: false,
  rolesLoading: false,
  moduleLoading: false,
  modulesLoading: false,
  companyModal: false,
  companyLoading: false,
  companiesLoading: false,
  competitorModal: false,
  competitorLoading: false,
  competitorsLoading: false,
  groupModal: false,
  groupLoading: false,
  groupsLoading: false,
  passwordLoading: false,
  passwordModal: false,
  paymentMachinesLoading: false,
  paymentMachinesModal: false,
  paymentMachineLoading: false,
  paymentMachineModal: false,
  picPayOperatorsLoading: false,
  picPayOperatorsModal: false,
  picPayOperatorLoading: false,
  picPayOperatorModal: false,
  extractsLoading: false,
  extractsModal: false,
  commissioningsLoading: false,
  commissioningsModal: false,
  commissioningLoading: false,
  commissioningModal: false,
  transfersLoading: false,
  transfersModal: false,
  transferLoading: false,
  transferModal: false,
  transitoryValuesLoading: false,
  resourcesLoading: true,
  productModal: false,
  productLoading: false,
  productsLoading: false,
  weeklyTaxModal: false,
  weeklyTaxLoading: false,
  weeklyTaxesLoading: false,
  dailyExpectedPaymentsLoading: false,
  reportSplitValuesLoading: false,
  receivedPaymentsLoading: false,
  acquirersLoading: false,
  ordersLoading: false,
  orderModal: false,
  orderLoading: false,
};

export function uiReducer(state = initUi, action) {
  switch (action.type) {
    // Users
    case SHOW_USERS_SPINNER:
      return { ...state, usersLoading: true };

    case HIDE_USERS_SPINNER:
      return { ...state, usersLoading: false };

    case SHOW_USER_SPINNER:
      return { ...state, userLoading: true };

    case HIDE_USER_SPINNER:
      return { ...state, userLoading: false };

    case SHOW_USER_MODAL:
      return { ...state, userModal: true };

    case HIDE_USER_MODAL:
      return { ...state, userModal: false };

    // Providers
    case SHOW_PROVIDERS_SPINNER:
      return { ...state, providersLoading: true };

    case HIDE_PROVIDERS_SPINNER:
      return { ...state, providersLoading: false };

    case SHOW_PROVIDER_SPINNER:
      return { ...state, providerLoading: true };

    case HIDE_PROVIDER_SPINNER:
      return { ...state, providerLoading: false };

    case SHOW_PROVIDER_MODAL:
      return { ...state, providerModal: true };

    case HIDE_PROVIDER_MODAL:
      return { ...state, providerModal: false };

    // Items
    case SHOW_ITEMS_SPINNER:
      return { ...state, itemsLoading: true };

    case HIDE_ITEMS_SPINNER:
      return { ...state, itemsLoading: false };

    case SHOW_ITEM_SPINNER:
      return { ...state, itemLoading: true };

    case HIDE_ITEM_SPINNER:
      return { ...state, itemLoading: false };

    case SHOW_ITEM_MODAL:
      return { ...state, itemModal: true };

    case HIDE_ITEM_MODAL:
      return { ...state, itemModal: false };

    // Bakery Additional Informations
    case SHOW_BAKERY_ADDITIONAL_INFORMATIONS_SPINNER:
      return { ...state, bakeryAdditionalInformationsLoading: true };

    case HIDE_BAKERY_ADDITIONAL_INFORMATIONS_SPINNER:
      return { ...state, bakeryAdditionalInformationsLoading: false };

    case SHOW_BAKERY_ADDITIONAL_INFORMATION_SPINNER:
      return { ...state, bakeryAdditionalInformationLoading: true };

    case HIDE_BAKERY_ADDITIONAL_INFORMATION_SPINNER:
      return { ...state, bakeryAdditionalInformationLoading: false };

    case SHOW_BAKERY_ADDITIONAL_INFORMATION_MODAL:
      return { ...state, bakeryAdditionalInformationModal: true };

    case HIDE_BAKERY_ADDITIONAL_INFORMATION_MODAL:
      return { ...state, bakeryAdditionalInformationModal: false };

    // Groups
    case SHOW_GROUPS_SPINNER:
      return { ...state, groupsLoading: true };

    case HIDE_GROUPS_SPINNER:
      return { ...state, groupsLoading: false };

    case SHOW_GROUP_SPINNER:
      return { ...state, groupLoading: true };

    case HIDE_GROUP_SPINNER:
      return { ...state, groupLoading: false };

    case SHOW_GROUP_MODAL:
      return { ...state, groupModal: true };

    case HIDE_GROUP_MODAL:
      return { ...state, groupModal: false };

    // Companies
    case SHOW_COMPANIES_SPINNER:
      return { ...state, companiesLoading: true };

    case HIDE_COMPANIES_SPINNER:
      return { ...state, companiesLoading: false };

    case SHOW_COMPANY_SPINNER:
      return { ...state, companyLoading: true };

    case HIDE_COMPANY_SPINNER:
      return { ...state, companyLoading: false };

    case SHOW_COMPANY_MODAL:
      return { ...state, companyModal: true };

    case HIDE_COMPANY_MODAL:
      return { ...state, companyModal: false };

    // Competitors
    case SHOW_COMPETITORS_SPINNER:
      return { ...state, competitorsLoading: true };

    case HIDE_COMPETITORS_SPINNER:
      return { ...state, competitorsLoading: false };

    case SHOW_COMPETITOR_SPINNER:
      return { ...state, competitorLoading: true };

    case HIDE_COMPETITOR_SPINNER:
      return { ...state, competitorLoading: false };

    case SHOW_COMPETITOR_MODAL:
      return { ...state, competitorModal: true };

    case HIDE_COMPETITOR_MODAL:
      return { ...state, competitorModal: false };

    // Login
    case SHOW_LOGIN_SPINNER:
      return { ...state, loginLoading: true };

    case HIDE_LOGIN_SPINNER:
      return { ...state, loginLoading: false };

    // Roles
    case SHOW_ROLES_SPINNER:
      return { ...state, rolesLoading: true };

    case HIDE_ROLES_SPINNER:
      return { ...state, rolesLoading: false };

    case SHOW_ROLE_SPINNER:
      return { ...state, roleLoading: true };

    case HIDE_ROLE_SPINNER:
      return { ...state, roleLoading: false };

    case SHOW_ROLE_MODAL:
      return { ...state, roleModal: true };

    case HIDE_ROLE_MODAL:
      return { ...state, roleModal: false };

    // Weekly Taxes
    case SHOW_WEEKLY_TAXES_SPINNER:
      return { ...state, weeklyTaxesLoading: true };

    case HIDE_WEEKLY_TAXES_SPINNER:
      return { ...state, weeklyTaxesLoading: false };

    case SHOW_WEEKLY_TAX_SPINNER:
      return { ...state, weeklyTaxLoading: true };

    case HIDE_WEEKLY_TAX_SPINNER:
      return { ...state, weeklyTaxLoading: false };

    case SHOW_WEEKLY_TAX_MODAL:
      return { ...state, weeklyTaxModal: true };

    case HIDE_WEEKLY_TAX_MODAL:
      return { ...state, weeklyTaxModal: false };

    // suppliers
    case SHOW_SUPPLIERS_SPINNER:
      return { ...state, suppliersLoading: true };

    case HIDE_SUPPLIERS_SPINNER:
      return { ...state, suppliersLoading: false };

    case SHOW_SUPPLIER_SPINNER:
      return { ...state, supplierLoading: true };

    case HIDE_SUPPLIER_SPINNER:
      return { ...state, supplierLoading: false };

    case SHOW_SUPPLIER_MODAL:
      return { ...state, supplierModal: true };

    case HIDE_SUPPLIER_MODAL:
      return { ...state, supplierModal: false };

    // offers
    case SHOW_OFFERS_SPINNER:
      return { ...state, offersLoading: true };

    case HIDE_OFFERS_SPINNER:
      return { ...state, offersLoading: false };

    case SHOW_OFFER_SPINNER:
      return { ...state, offerLoading: true };

    case HIDE_OFFER_SPINNER:
      return { ...state, offerLoading: false };

    case SHOW_OFFER_MODAL:
      return { ...state, offerModal: true };

    case HIDE_OFFER_MODAL:
      return { ...state, offerModal: false };

    // Modules
    case SHOW_MODULES_SPINNER:
      return { ...state, modulesLoading: true };

    case HIDE_MODULES_SPINNER:
      return { ...state, modulesLoading: false };

    case SHOW_MODULE_SPINNER:
      return { ...state, moduleLoading: true };

    case HIDE_MODULE_SPINNER:
      return { ...state, moduleLoading: false };

    case SHOW_MODULE_MODAL:
      return { ...state, moduleModal: true };

    case HIDE_MODULE_MODAL:
      return { ...state, moduleModal: false };

    // Password
    case SHOW_PASSWORD_SPINNER:
      return { ...state, passwordLoading: true };
    case HIDE_PASSWORD_SPINNER:
      return { ...state, passwordLoading: false };
    case SHOW_PASSWORD_MODAL:
      return { ...state, passwordModal: true };
    case HIDE_PASSWORD_MODAL:
      return { ...state, passwordModal: false };

    // Payment Machines
    case SHOW_PAYMENT_MACHINES_SPINNER:
      return { ...state, paymentMachinesLoading: true };

    case HIDE_PAYMENT_MACHINES_SPINNER:
      return { ...state, paymentMachinesLoading: false };

    case SHOW_PAYMENT_MACHINE_SPINNER:
      return { ...state, paymentMachineLoading: true };

    case HIDE_PAYMENT_MACHINE_SPINNER:
      return { ...state, paymentMachineLoading: false };

    case SHOW_PAYMENT_MACHINE_MODAL:
      return { ...state, paymentMachineModal: true };

    case HIDE_PAYMENT_MACHINE_MODAL:
      return { ...state, paymentMachineModal: false };

    // Payment Machine Registers
    case SHOW_PAYMENT_MACHINE_REGISTERS_SPINNER:
      return { ...state, paymentMachineRegistersLoading: true };

    case HIDE_PAYMENT_MACHINE_REGISTERS_SPINNER:
      return { ...state, paymentMachineRegistersLoading: false };

    // Pic Pay Operators
    case SHOW_PICPAY_OPERATORS_SPINNER:
      return { ...state, picPayOperatorsLoading: true };

    case HIDE_PICPAY_OPERATORS_SPINNER:
      return { ...state, picPayOperatorsLoading: false };

    case SHOW_PICPAY_OPERATOR_SPINNER:
      return { ...state, picPayOperatorLoading: true };

    case HIDE_PICPAY_OPERATOR_SPINNER:
      return { ...state, picPayOperatorLoading: false };

    case SHOW_PICPAY_OPERATOR_MODAL:
      return { ...state, picPayOperatorModal: true };

    case HIDE_PICPAY_OPERATOR_MODAL:
      return { ...state, picPayOperatorModal: false };

    // Extracts
    case SHOW_EXTRACTS_SPINNER:
      return { ...state, extractsLoading: true };

    case HIDE_EXTRACTS_SPINNER:
      return { ...state, extractsLoading: false };

    // Audit
    case SHOW_DAILY_EXPECTED_PAYMENTS_SPINNER:
      return { ...state, dailyExpectedPaymentsLoading: true };

    case HIDE_DAILY_EXPECTED_PAYMENTS_SPINNER:
      return { ...state, dailyExpectedPaymentsLoading: false };

    // Commissionings
    case SHOW_COMMISSIONINGS_SPINNER:
      return { ...state, commissioningsLoading: true };

    case HIDE_COMMISSIONINGS_SPINNER:
      return { ...state, commissioningsLoading: false };

    case SHOW_COMMISSIONING_SPINNER:
      return { ...state, commissioningLoading: true };

    case HIDE_COMMISSIONING_SPINNER:
      return { ...state, commissioningLoading: false };

    case SHOW_COMMISSIONING_MODAL:
      return { ...state, commissioningModal: true };

    case HIDE_COMMISSIONING_MODAL:
      return { ...state, commissioningModal: false };

    // Transfers
    case SHOW_TRANSFERS_SPINNER:
      return { ...state, transfersLoading: true };

    case HIDE_TRANSFERS_SPINNER:
      return { ...state, transfersLoading: false };

    case SHOW_TRANSFER_SPINNER:
      return { ...state, transferLoading: true };

    case HIDE_TRANSFER_SPINNER:
      return { ...state, transferLoading: false };

    case SHOW_TRANSFER_MODAL:
      return { ...state, transferModal: true };

    case HIDE_TRANSFER_MODAL:
      return { ...state, transferModal: false };

    // Products
    case SHOW_PRODUCTS_SPINNER:
      return { ...state, productsLoading: true };

    case HIDE_PRODUCTS_SPINNER:
      return { ...state, productsLoading: false };

    case SHOW_PRODUCT_SPINNER:
      return { ...state, productLoading: true };

    case HIDE_PRODUCT_SPINNER:
      return { ...state, productLoading: false };

    case SHOW_PRODUCT_MODAL:
      return { ...state, productModal: true };

    case HIDE_PRODUCT_MODAL:
      return { ...state, productModal: false };

    // Embedded resources
    case SHOW_EMBEDDED_RESOURCES_SPINNER:
      return { ...state, resourcesLoading: true };

    case HIDE_EMBEDDED_RESOURCES_SPINNER:
      return { ...state, resourcesLoading: false };

    // Market structure
    case SHOW_MARKET_STRUCTURE_SPINNER:
      return { ...state, marketResourcesLoading: true };

    case HIDE_MARKET_STRUCTURE_SPINNER:
      return { ...state, marketResourcesLoading: false };

    // Report split Values
    case SHOW_REPORT_SPLIT_VALUES_SPINNER:
      return { ...state, reportSplitValuesLoading: true };

    case HIDE_REPORT_SPLIT_VALUES_SPINNER:
      return { ...state, reportSplitValuesLoading: false };

    // Acquirers
    case SHOW_ACQUIRERS_SPINNER:
      return { ...state, acquirersLoading: true };

    case HIDE_ACQUIRERS_SPINNER:
      return { ...state, acquirersLoading: false };

    // Acquirers
    case SHOW_RECEIVED_PAYMENTS_SPINNER:
      return { ...state, receivedPaymentsLoading: true };

    case HIDE_RECEIVED_PAYMENTS_SPINNER:
      return { ...state, receivedPaymentsLoading: false };

    // Transitory Values
    case SHOW_TRANSITORY_VALUES_SPINNER:
      return { ...state, transitoryValuesLoading: true };

    case HIDE_TRANSITORY_VALUES_SPINNER:
      return { ...state, transitoryValuesLoading: false };

    // Orders
    case SHOW_ORDERS_SPINNER:
      return { ...state, ordersLoading: true };
    case HIDE_ORDERS_SPINNER:
      return { ...state, ordersLoading: false };
    case SHOW_ORDER_SPINNER:
      return { ...state, orderLoading: true };

    case HIDE_ORDER_SPINNER:
      return { ...state, orderLoading: false };

    case SHOW_ORDER_MODAL:
      return { ...state, orderModal: true };

    case HIDE_ORDER_MODAL:
      return { ...state, orderModal: false };

    default:
      return state;
  }
}
