import { UPDATE_PICPAY_OPERATORS } from '../actions/picPayOperators';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function picPayOperatorsReducer(picPayOperators = initState, action) {
  switch (action.type) {
    case UPDATE_PICPAY_OPERATORS:
      return action.payload;

    default:
      return picPayOperators;
  }
}
