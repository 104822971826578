export const GET_RECEIVED_PAYMENTS = '[received_payments] GET';

export const FETCH_RECEIVED_PAYMENTS_SUCCESS =
  '[received_payments] Fetch success';

export const FETCH_RECEIVED_PAYMENTS_ERROR = '[received_payments] Fetch Error';

export const SHOW_RECEIVED_PAYMENTS_SPINNER =
  '[received_payments - ui] show spinner';
export const HIDE_RECEIVED_PAYMENTS_SPINNER =
  '[received_payments - ui] hide spinner';

export const CREATE_RECEIVED_PAYMENTS = '[received_payments] create';
export const CREATE_RECEIVED_PAYMENTS_SUCCESS =
  '[received_payments] create success';
export const CREATE_RECEIVED_PAYMENTS_ERROR =
  '[received_payments] create error';

export const UPDATE_RECEIVED_PAYMENTS = '[received_payments] Update';

export const UPDATE_RECEIVED_PAYMENTS_SUCCESS =
  '[received_payments] update success';
export const UPDATE_RECEIVED_PAYMENTS_ERROR =
  '[received_payments] update error';

export const getReceivedPayments = (filter) => ({
  type: GET_RECEIVED_PAYMENTS,
  payload: filter,
});

export const fetchReceivedPaymentsSuccess = (data) => ({
  type: FETCH_RECEIVED_PAYMENTS_SUCCESS,
  payload: data,
});
export const updateReceivedPayments = (data) => ({
  type: UPDATE_RECEIVED_PAYMENTS,
  payload: data,
});

export const createReceivedPayment = (data) => ({
  type: CREATE_RECEIVED_PAYMENTS,
  payload: data,
});
export const createReceivedPaymentSuccess = (data) => ({
  type: CREATE_RECEIVED_PAYMENTS_SUCCESS,
  payload: data,
});
export const createReceivedPaymentError = (data) => ({
  type: CREATE_RECEIVED_PAYMENTS_ERROR,
  payload: data,
});

export const updateReceivedPayment = (data) => ({
  type: UPDATE_RECEIVED_PAYMENTS,
  payload: data,
});
export const updateReceivedPaymentSuccess = (data) => ({
  type: UPDATE_RECEIVED_PAYMENTS_SUCCESS,
  payload: data,
});
export const updateReceivedPaymentError = (data) => ({
  type: UPDATE_RECEIVED_PAYMENTS_ERROR,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_RECEIVED_PAYMENTS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_RECEIVED_PAYMENTS_SPINNER,
});
