import styled, { css } from 'styled-components';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { GiFactory } from 'react-icons/gi';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core';

export const StyledAppBar = styled(AppBar)`
  height: 65px;
`;

export const StyledToolbar = styled(Toolbar)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 1rem 0;
  padding-left: 1.5rem;
  @media (max-width: 583px) {
    margin: 1rem;
    padding: 0;
  }
`;

export const Container = styled.div`
  position: relative;
  top: 0;
  left: 0;
  background-color: #16161e;
  width: 100%;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 50px 10px 20px;
  width: 10%;
  @media only screen and (max-width: 1116px) {
    width: 100%;
  }
`;

export const Logo = styled.img`
  cursor: pointer;
  height: 1.875rem;
  margin: 1rem;
  @media only screen and (max-width: 1116px) {
    text-align: center;
  }
`;

export const StyledIcon = styled.img`
  cursor: pointer;
  height: 1.8rem;
  margin-left: 1rem;
  @media only screen and (max-width: 1116px) {
    text-align: center;
  }
  @media (max-width: 583px) {
    margin-left: 0;
  }
`;

export const HeaderContent = styled.div`
  position: relative;
  margin: 15px 20px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 40%;
  @media only screen and (max-width: 1116px) {
    display: none;
  }
`;

export const Search = styled.input`
  height: 36px;
  width: 92%;
  background-color: #2c2c32;
  border: 1px solid #2c2c32;
  font-size: 15px;
  color: white;
  border-radius: 20px;
  padding-left: 20px;

  /* @media only screen and (max-width: 1116px) {
    display: none;
  } */
`;

export const SearchIcon = styled.img`
  position: absolute;
  height: 36px;
  width: 36px;
  top: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0);
  transform: rotateY(180deg);
  cursor: pointer;
`;

export const ItemForm = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 20px;
`;

export const Item = styled.div`
  font-size: 1.6rem;
  color: white;
  margin: 20px;
  cursor: pointer;
`;

export const PanelContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  font-size: 1rem;
  color: white;
  white-space: nowrap;
`;

export const Hideable = styled.span`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  width: auto;
  font-size: 1rem;
  color: white;
  white-space: nowrap;
  ${({ sm }) =>
    sm &&
    css`
      display: none;
    `}
`;

export const PanelPicture = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 0px;
`;

export const PanelItem = styled.div`
  width: 60px;
  height: 60px;
`;

export const LoginButton = styled.button`
  margin-left: 20px;
  height: 35px;
  width: 150px;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  a {
    text-decoration: none;
    color: white;
  }
`;

export const UserName = styled.div`
  font-size: 1rem;
  margin-left: 10px;
  color: white;
`;

export const HeaderButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  font-size: 14px;
  color: black;
  width: 160px;
  height: 100%;
  margin-top: -20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-right: 5px;
`;

export const ListIcon = styled(ListItemIcon)`
  padding-left: 8px;
  .react-icons {
    font-size: 1.6rem;
  }
  .MuiSvgIcon-root {
    color: #b9bbbd;
  }
`;

export const MenuButton = styled.span`
  cursor: pointer;

  .MuiIcon-root {
    font-size: 100px;
  }
`;
export const SearchBox = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-left: 20px;
`;

export const Title = styled.span`
  display: flex;
  flex: 1;
  padding: 10px;
  font-size: 20px;
  font-weight: 100;
  ${({ sm }) =>
    sm &&
    css`
      visibility: hidden;
      overflow: hidden;
    `}
`;

export const BlankSpace = styled.div`
  display: flex;
  flex: 1;
`;

export const HeaderUserName = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: #464255;
  margin-left: 0.5rem;
`;

export const FactoryIcon = styled(GiFactory)`
  font-size: 1.4rem;
  color: #b9bbbd;
`;

export const styles = {
  iconStyle: {
    marginLeft: 25,
    cursor: 'pointer',
    color: '#717478',
  },
  dropdownIconStyle: {
    color: '#fff',
    position: 'absolute',
    top: 20,
    left: 20,
    cursor: 'pointer',
  },
  drawerIconStyle: {
    color: '#B9BBBD',
    height: '2.5rem',
    borderRadius: '0.69rem',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    minWidth: 0,
  },
  drawerTextStyle: {
    marginLeft: '1rem',
  },
  logoutIconStyle: {
    marginLeft: 10,
    height: 25,
    width: 25,
  },
  linkFont: {
    color: '#464255',
    textDecoration: 'none',
  },
};

const drawerWidth = '24.313rem';

export const useStyles = makeStyles((_theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    zIndex: _theme.zIndex.drawer + 1,
    transition: _theme.transitions.create(['width', 'margin'], {
      easing: _theme.transitions.easing.sharp,
      duration: _theme.transitions.duration.leavingScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    '& .MuiDrawer-paper': {
      position: 'absolute',
      height: 'auto',
    },
    zIndex: 10,
    '& .MuiList-padding': {
      padding: '.5rem',
    },
    '& .active': {
      backgroundColor: '#192D4D26',
      borderRadius: '1.125rem',
      color: '#192D4D',
    },
    '& .active .MuiIcon-root': {
      color: '#192D4D',
    },
    '& .MuiIcon-root': {
      padding: 0,
    },

    '& .MuiListItem-root:hover': {
      backgroundColor: '#192D4D16',
      borderRadius: '1.125rem',
      color: '#192D4D',
    },

    '& .MuiTypography-colorTextSecondary': {
      color: '#464255',
    },
    '& .active .MuiTypography-colorTextSecondary': {
      color: '#192D4D',
    },
  },
  drawerOpen: {
    backgroundColor: '#FFFFFF',
    color: '#464255',
    '& .MuiListItemText-root': {
      cursor: 'pointer',
      color: '#464255',
    },
    width: '100%',
    zIndex: 100,
    overflowX: 'hidden',
    transition: _theme.transitions.create('width', {
      easing: _theme.transitions.easing.sharp,
      duration: _theme.transitions.duration.enteringScreen,
    }),
    height: 'auto',
    [_theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      '& .nested': {
        paddingLeft: _theme.spacing(8),
      },
      margin: '5rem 0 1rem 1.5rem',
      borderRadius: '1.352rem',
    },
    [_theme.breakpoints.down(540)]: {
      height: '100%',
    },
    marginTop: '5rem',
    '& .externalIcon': {
      color: '#B9BBBD',
      height: '2.5rem',
      borderRadius: '0.69rem',
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      minWidth: 0,
    },
  },
  drawerClose: {
    backgroundColor: '#FFFFFF',
    color: 'white',
    transition: _theme.transitions.create('width', {
      easing: _theme.transitions.easing.sharp,
      duration: _theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    zIndex: 100,
    border: 0,
    width: 0,
    marginTop: '5rem',
    [_theme.breakpoints.up('sm')]: {
      width: _theme.spacing(9),
      margin: '5rem 0 1rem 1.5rem',
    },
    borderRadius: '1.352rem',
    height: 'auto',
    '& .MuiListItemText-root': {
      display: 'none',
    },
    '& .expandIcon': {
      display: 'none',
    },
    '& .externalIcon': {
      display: 'none',
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: _theme.spacing(0, 1),
    // necessary for content to be below app bar
    ..._theme.mixins.toolbar,
  },
  toolbarIcon: {
    color: '#fff',
  },
  content: {
    minHeight: 'calc(100vh - 65px - 4rem)',
    overflow: 'auto',
    transition: _theme.transitions.create('width', {
      easing: _theme.transitions.easing.sharp,
      duration: _theme.transitions.duration.leavingScreen,
    }),
    [_theme.breakpoints.up('sm')]: {
      marginLeft: `calc(1.5rem + ${_theme.spacing(9)}px + 0.5rem)`,
    },
  },
  tooltip: {
    fontSize: 12,
  },
  scrollableMenu: {
    scrollbarColor: '#192D4D55 #192D4D26',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 3,
      background: '#192D4D26',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 3,
      background: '#192D4D55',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#192D4Daa',
    },
  },
  companyInput: {
    minWidth: 200,
    '& .MuiInput-underline::before': {
      borderBottomColor: '#717478',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
      borderBottomColor: '#ddd',
    },
    '& .MuiInput-underline::after': {
      borderBottomColor: '#fff',
    },
    '& .MuiInput-root .MuiSelect-icon': {
      color: '#717478',
    },
    '& .MuiInput-root:hover:not(.Mui-disabled) .MuiSelect-icon': {
      color: '#ddd',
    },
    '& .MuiInputLabel-root': {
      color: '#717478',
    },
    '&:hover .MuiInputLabel-root': {
      color: '#ddd',
    },
    '& .MuiInput-root .MuiSelect-root': {
      color: '#717478',
    },
    '& .MuiInput-root:hover .MuiSelect-root': {
      color: '#ddd',
    },
    '& .MuiInput-root.Mui-focused .MuiSelect-root': {
      color: '#FFF',
    },
  },
  menuButton: {
    [_theme.breakpoints.up('sm')]: {
      padding: '0.4rem',
      height: '2.5rem',
      borderRadius: '0.69rem',
      color: '#464255',
      backgroundColor: '#fff',
      width: _theme.spacing(9),
      marginRight: 36,
    },
  },
  popover: {
    marginTop: '0.875rem',
    '& .MuiPopover-paper': {
      borderRadius: '0.5rem',
      padding: '0.625rem',
      overflow: 'visible',
      marginLeft: '0.25rem',
      '&::before': {
        content: '""',
        width: 0,
        height: 0,
        borderLeft: '0.438rem solid transparent',
        borderRight: '0.438rem solid transparent',
        borderBottom: '0.875rem solid #fff',
        position: 'absolute',
        top: '-0.875rem',
        right: '0.5rem',
      },
    },
  },
}));
