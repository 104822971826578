import {
  FETCH_PROVIDERS_SUCCESS,
  FETCH_PROVIDERS_ERROR,
  GET_PROVIDERS,
  updateProviders,
  showSpinner,
  hideSpinner,
} from '../actions/providers';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'providers';

export const getProvidersFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GET_PROVIDERS) {
      dispatch(
        apiRequest(
          'GET',
          URL,
          action.payload,
          FETCH_PROVIDERS_SUCCESS,
          FETCH_PROVIDERS_ERROR,
        ),
      );
      dispatch(showSpinner());
    }
  };

export const processProvidersCollection =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === FETCH_PROVIDERS_SUCCESS) {
      dispatch(updateProviders(action.payload));
      dispatch(hideSpinner());
    }
    if (action.type === FETCH_PROVIDERS_ERROR) {
      Toastify.addError(
        'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
      );
      dispatch(hideSpinner());
    }
  };

export const providersMdl = [getProvidersFlow, processProvidersCollection];
