import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormGroup,
  MenuItem,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from '../../../sharedComponents/Alert/index';
import Spinner from '../../../sharedComponents/Spinner/index';
import { Container, InputContent, ModalTitle, ButtonContainer } from './styles';

import {
  getPicPayOperator,
  createPicPayOperator,
  updatePicPayOperator,
} from '../../../redux/actions/picPayOperator';
import { getFormattedRegisterItems } from '../../../providers/Registers';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  datePickerContainer: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formButton: {
    margin: 2,
  },
}));

const PicPayOperatorModal = ({
  id,
  dispatch,
  picPayOperator,
  picPayOperatorModal,
  handleClose,
  picPayOperatorSpinner,
  allCompanies,
}) => {
  const classes = useStyles();
  const [companyDocument, setCompanyDocument] = useState('');
  const [operator, setOperator] = useState('');

  const [companyDocumentError, setCompanyDocumentError] = useState(false);
  const [operatorError, setOperatorError] = useState(false);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getPicPayOperator(id));
    }
  }, [id]);

  useEffect(() => {
    if (picPayOperator && allCompanies && id !== 'new') {
      const formattedPicPayOperator = getFormattedRegisterItems(
        [picPayOperator],
        allCompanies,
      )?.[0];
      setCompanyDocument(formattedPicPayOperator.companyDocument);
      setOperator(formattedPicPayOperator.operator);
    }
  }, [picPayOperator, allCompanies]);

  useEffect(() => {
    if (!picPayOperatorModal) {
      handleClose();
    }
  }, [picPayOperatorModal]);

  const handleSave = async () => {
    setCompanyDocumentError(!companyDocument);
    setOperatorError(!operator);

    const haveErrors = [!companyDocument, !operator].some((error) => !!error);

    if (haveErrors) {
      return;
    }

    const response = await Alert();
    if (response && response.confirm) {
      if (id === 'new') {
        dispatch(
          createPicPayOperator({
            companyDocument,
            operator,
          }),
        );
      } else {
        dispatch(
          updatePicPayOperator({
            id,
            operator,
          }),
        );
      }
    }
  };

  const handleCompanyChange = (event) => {
    const newCompanyId = event.target.value;
    setCompanyDocument(newCompanyId);
  };

  return (
    <>
      {picPayOperatorSpinner && <Spinner overlay />}
      <Container>
        <Paper
          elevation={2}
          style={{
            opacity: picPayOperatorSpinner ? 0.5 : 1,
            minWidth: '60vw',
            padding: 20,
          }}
          fullWidth
        >
          <ModalTitle>
            {id === 'new' ? 'Adicionar' : 'Editar'} operador PicPay
          </ModalTitle>
          <FormGroup>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              error={companyDocumentError}
            >
              <InputLabel id="companyLabel">Padaria*</InputLabel>
              <Select
                labelId="companyLabel"
                value={companyDocument}
                onChange={handleCompanyChange}
                label="Padaria*"
                disabled={id !== 'new'}
              >
                {allCompanies &&
                  allCompanies?.map(({ document, name }) => (
                    <MenuItem key={id} value={document}>
                      {name}
                    </MenuItem>
                  ))}
              </Select>
              {companyDocumentError && (
                <FormHelperText>O campo Padaria é obrigatório</FormHelperText>
              )}
            </FormControl>
            <InputContent>
              <TextField
                label="Operador*"
                type="text"
                error={operatorError}
                helperText={operatorError && 'Operador é obrigatório'}
                variant="outlined"
                value={operator}
                onChange={(e) => setOperator(e.target.value)}
                fullWidth
              />
            </InputContent>

            <ButtonContainer>
              <Button
                color="secondary"
                onClick={handleClose}
                variant="contained"
                className={classes.formButton}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={handleSave}
                variant="contained"
                className={classes.formButton}
                disabled={id !== 'new' && picPayOperator.validUntil}
              >
                Salvar
              </Button>
            </ButtonContainer>
          </FormGroup>
        </Paper>
      </Container>
    </>
  );
};

export default connect((state) => ({
  picPayOperator: state.picPayOperator,
  picPayOperatorModal: state.ui.picPayOperatorModal,
  picPayOperatorSpinner: state.ui.picPayOperatorLoading,
  allRoles: state.roles.docs,
  loggedUser: state.loggedUser,
  allCompanies: state.companies.docs || state.companies,
}))(PicPayOperatorModal);
