import { UPDATE_PAYMENT_MACHINES } from '../actions/paymentMachines';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function paymentMachinesReducer(paymentMachines = initState, action) {
  switch (action.type) {
    case UPDATE_PAYMENT_MACHINES:
      return action.payload;

    default:
      return paymentMachines;
  }
}
