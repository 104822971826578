import styled, { css } from 'styled-components';
import MuiPaper from '@material-ui/core/Paper';
import MuiInput from '@material-ui/core/Input';
import { withStyles } from '@material-ui/styles';
import { KeyboardArrowDown } from '@material-ui/icons';

export const Paper = withStyles((theme) => ({
  root: {
    borderRadius: 8,
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
}))(MuiPaper);

export const Title = styled.h2<{ isMobile?: boolean }>`
  font-weight: 600;
  font-size: 18px;
  color: #252729;
  ${({ isMobile }) =>
    !isMobile &&
    css`
      margin-bottom: 24px;
    `}
  display: flex;
  justify-content: space-between;
`;

export const Divider = styled.div<{isMobile?: boolean}>`
  border: 1px solid #d1d4d6;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  ${({ isMobile }) => isMobile && css`
    margin-top: 16px;
  `}
`;

export const Text = styled.span`
  font-size: 14px;
  line-height: 130%;
  color: #252729;
`;

export const Label = styled(Text)`
  color: #9ea3a5;
  margin-bottom: 8px;
`;

export const Field = styled.div<{ columns: number; rows?: number }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  grid-column: span ${({ columns }) => columns};
  ${({ rows }) =>
    rows &&
    css`
      grid-row: span ${rows};
    `};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(4, auto);
  grid-gap: 16px;
`;

export const Input = withStyles((theme) => ({
  root: {
    border: '1px solid #D1D4D6',
    borderRadius: 8,
    borderShadow: '1px 1px 4px rgba(0, 0, 0, 0.08)',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: '#FFF',
  },
  multiline: {
    height: '100%',
  },
}))(MuiInput);

export const ExpandIcon = withStyles({
  root: {
    color: '#133DA5',
    transition: 'transform ease-in-out 500ms',
    '&.open': {
      transform: 'rotate(-180deg)',
    },
  },
})(KeyboardArrowDown);
