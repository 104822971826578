import { store } from '../../redux/store';
import { userIsAdmin } from '../../utils/globalFunctions';
import Service from '../Service';

export async function getCompetitors(data) {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const apiService = new Service({
    route: '/competitors',
    apiV2: true,
  });
  const response = await apiService.get({ ...data, isAdmin });
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: response.data.status,
  };
}
