import { GET_EXTRACTS, UPDATE_EXTRACTS } from '../actions/extracts';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
  totalValue: 0,
};

export function extractsReducer(extracts = initState, action) {
  switch (action.type) {
    case GET_EXTRACTS:
      return action.payload;
    case UPDATE_EXTRACTS:
      return action.payload;

    default:
      return extracts;
  }
}
