import axios from 'axios';
import { apiUrl, apiUrlV2, sessionStorageKey } from '../config/consts';

export const api = axios.create({
  baseURL: apiUrl,
});

export const apiV2 = axios.create({
  baseURL: apiUrlV2,
});

const getToken = () => {
  const { token } = localStorage.getItem(sessionStorageKey)
    ? JSON.parse(localStorage.getItem(sessionStorageKey))
    : {};
  return token;
};

export const setupAuthentication = (previousToken) => {
  const token = previousToken || getToken();
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
  apiV2.defaults.headers.common.Authorization = `Bearer ${token}`;
};

setupAuthentication();
