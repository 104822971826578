import {
  Collapse,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { Order } from '../../../../interfaces/orders';
import { FieldType } from './interfaces';
import {
  Divider,
  Field,
  Grid,
  Input,
  Label,
  Paper,
  Text,
  Title,
  ExpandIcon,
} from './styles';

const fields: FieldType[] = [
  { key: 'company', label: 'Padaria:', columns: 3 },
  {
    key: 'discountCoupon',
    label: 'Cupom de desconto',
    columns: 3,
    input: true,
  },
  { key: 'subTotal', label: 'Valor do pedido', columns: 2 },
  { key: 'discount', label: 'Desconto', columns: 2, input: true },
  { key: 'total', label: 'Valor final', columns: 2 },
  { key: 'wordpressOrderDate', label: 'Data do pedido', columns: 2 },
  { key: 'deliveryExpectedDate', label: 'Previsão de entrega', columns: 2 },
  { key: 'deliveryDate', label: 'Data de entrega', columns: 2 },
  {
    key: 'observation',
    label: 'Observações',
    columns: 6,
    rows: 3,
    multiline: true,
    input: true,
  },
  {
    key: 'paymentMethod',
    label: 'Forma de recebimento',
    columns: 3,
    input: true,
  },
  {
    key: 'paymentCondition',
    label: 'Prazo de recebimento',
    columns: 3,
    input: true,
  },
  { key: 'status', label: 'Status do pedido', columns: 3 },
];

const OrderDetails: React.FC<{ order: Order | undefined }> = ({ order }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggle = () => setOpen((currentState) => !currentState);

  return (
    <Paper elevation={0}>
      <Title isMobile={isMobile}>
        Detalhes do pedido
        {isMobile && (
          <IconButton onClick={toggle} size="small">
            <ExpandIcon className={open ? 'open' : ''} />
          </IconButton>
        )}
      </Title>
      <Collapse in={isMobile ? open : true}>
        <Divider isMobile={isMobile} />
        <Grid>
          {fields.map(({ key, label, columns, rows, input, multiline }) => (
            <Field columns={isMobile ? 12 : columns} rows={rows}>
              <Label>{label}</Label>
              {input ? (
                <Input
                  multiline={multiline}
                  disableUnderline
                  fullWidth
                  value={order?.[key]}
                  disabled
                />
              ) : (
                <Text>{(order?.[key] as string) || '---'}</Text>
              )}
            </Field>
          ))}
        </Grid>
      </Collapse>
    </Paper>
  );
};

export default OrderDetails;
