import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import ProductCard from '../ProductCard';
import Pagination from '../../../../sharedComponents/Pagination';
import Spinner from '../../../../sharedComponents/Spinner';

const ProductGrid = ({ page, setPage, companyDocument }) => {
  const total = useSelector((state) => state.offers?.total || 0);
  const pages = useSelector((state) => state.offers?.pages || 0);
  const loading = useSelector((state) => state.ui.offersLoading);
  const offers = useSelector((state) => state.offers?.docs || []);

  return loading ? (
    <Spinner color="#192d4d" />
  ) : (
    <>
      <Grid container spacing={2}>
        {offers.map((offer) => (
          <ProductCard
            key={offer.id}
            offer={offer}
            companyDocument={companyDocument}
          />
        ))}
      </Grid>
      <Pagination
        page={page}
        onChangePage={setPage}
        showing={offers.length}
        pages={pages}
        total={total}
      />
    </>
  );
};

ProductGrid.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func.isRequired,
  companyDocument: PropTypes.string.isRequired,
};

ProductGrid.defaultProps = {
  page: 1,
};

export default ProductGrid;
