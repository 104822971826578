export const GET_OFFERS = '[offers] GET';
export const FETCH_OFFERS_SUCCESS = '[offers] Fetch success';
export const FETCH_OFFERS_ERROR = '[offers] Fetch Error';
export const UPDATE_OFFERS = '[offers] Update';
export const SHOW_OFFERS_SPINNER = '[offers - ui] show spinner';
export const HIDE_OFFERS_SPINNER = '[offers - ui] hide spinner';

export const getOffers = (filter) => ({
  type: GET_OFFERS,
  payload: {
    ...filter,
  },
});

export const updateOffers = (data) => ({
  type: UPDATE_OFFERS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_OFFERS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_OFFERS_SPINNER,
});
