export const GET_PAYMENT_MACHINE = '[paymentMachine] GET';
export const UPDATE_PAYMENT_MACHINE = '[paymentMachine] UPDATE';
export const CREATE_PAYMENT_MACHINE = '[paymentMachine] CREATE';
export const DELETE_PAYMENT_MACHINE = '[paymentMachine] DELETE';
export const UPDATE_OBJECT_PAYMENT_MACHINE =
  '[paymentMachine] UPDATE OBJECT PAYMENT_MACHINE';
// UI
export const SHOW_PAYMENT_MACHINE_MODAL = '[paymentMachine] SHOW MODAL';
export const HIDE_PAYMENT_MACHINE_MODAL = '[paymentMachine] HIDE MODAL';
// Spinner
export const UPDATE_PAYMENT_MACHINE_SUCCESS = '[paymentMachine] Update success';
export const UPDATE_PAYMENT_MACHINE_ERROR = '[paymentMachine] Update Error';
export const FETCH_PAYMENT_MACHINE_SUCCESS = '[paymentMachine] Fetch success';
export const FETCH_PAYMENT_MACHINE_ERROR = '[paymentMachine] Fetch Error';
export const SHOW_PAYMENT_MACHINE_SPINNER =
  '[paymentMachine - ui] show spinner';
export const HIDE_PAYMENT_MACHINE_SPINNER =
  '[paymentMachine - ui] hide spinner';

export const getPaymentMachine = (id) => ({
  type: GET_PAYMENT_MACHINE,
  payload: { _id: id },
});

export const createPaymentMachine = (data) => ({
  type: CREATE_PAYMENT_MACHINE,
  payload: data,
});

export const updatePaymentMachine = (data) => ({
  type: UPDATE_PAYMENT_MACHINE,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_PAYMENT_MACHINE_MODAL,
});

export const hideModal = () => ({
  type: HIDE_PAYMENT_MACHINE_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_PAYMENT_MACHINE_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PAYMENT_MACHINE_SPINNER,
});

export const updateObjectPaymentMachine = (data) => ({
  type: UPDATE_OBJECT_PAYMENT_MACHINE,
  payload: data,
});
