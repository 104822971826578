import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Spinner from '../../../../sharedComponents/Spinner/index';
import { useStyles } from './styles';

const TableLoading = ({ commissioningsLoading, loadingCompanies }) => {
  const classes = useStyles();
  return commissioningsLoading || loadingCompanies ? (
    <TableRow className={classes.row}>
      <TableCell colSpan="10" className={classes.cell}>
        <Spinner parent="Table" />
      </TableCell>
    </TableRow>
  ) : null;
};

export default TableLoading;
