export const GET_MODULES = '[modules] GET';
export const FETCH_MODULES_SUCCESS = '[modules] Fetch success';
export const FETCH_MODULES_ERROR = '[modules] Fetch Error';
export const UPDATE_MODULES = '[modules] Update';
export const SHOW_MODULES_SPINNER = '[modules - ui] show spinner';
export const HIDE_MODULES_SPINNER = '[modules - ui] hide spinner';

export const getModules = (filter) => ({
  type: GET_MODULES,
  payload: {
    ...filter,
  },
});

export const updateModules = (data) => ({
  type: UPDATE_MODULES,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_MODULES_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_MODULES_SPINNER,
});
