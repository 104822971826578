import styled from 'styled-components';

import { Button, makeStyles, TextField, withStyles } from '@material-ui/core';

export const Container = styled.div`
  padding: 1rem;
  @media (max-width: 567px) {
    padding: 0.5rem;
  }
`;

export const Title = styled.p`
  color: var(--primary-text-color);
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 1rem;
  @media (max-width: 560px) {
    font-size: 1.3rem;
  }
`;

export const SummaryText = styled.p`
  min-width: 360px;
  width: fit-content;
  color: var(--primary-text-color);
  font-size: 1rem;
  font-weight: 400;
  @media (max-width: 700px) {
    font-size: 0.6rem;
  }
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: space-between;
`;

export const CheckboxContent = styled.form`
  width: 100%;
  padding: 12px 10px 15px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const ReportButton = withStyles({
  root: {
    '& .MuiButton-root': {
      backgroundColor: '#00A389',
      color: '#fff',
    },
    backgroundColor: '#00A389',
    color: '#fff',
  },
})(Button);

export const selectStyle = makeStyles((theme) => ({
  root: {
    minWidth: '9.5rem',
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
    },
  },
  icon: {
    color: 'var(--primary-text-color)',
  },
}));
export const labelStyle = makeStyles({
  root: {
    color: 'var(--primary-text-color)',
    fontWeight: 500,
    transform: 'translate(46px, 20px) scale(1)',
    '&.Mui-focused': {
      color: 'var(--primary-text-color)',
      fontWeight: 500,
    },
  },
});

export const Select = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--primary-text-color)',
        borderRadius: '1rem',
        borderWidth: 2,
      },
      '&:hover fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
    },
    color: 'var(--primary-text-color)',
    minWidth: '8.313rem',
    fontWeight: 500,
  },
})(TextField);
