import React from 'react';
import { BlockContent, Wrapper } from './styles';

const Block: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Wrapper>
    <BlockContent>{children}</BlockContent>
  </Wrapper>
);

export default Block;
