import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import {
  CheckCircle,
  CancelRounded,
  EditRounded,
  AddRounded,
  WarningRounded,
} from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import Spinner from '../../sharedComponents/Spinner/index';
import { SrcContainer, useStyles } from './styles';
import {
  getCompanyDocumentFilter,
  userIsAdmin,
} from '../../utils/globalFunctions';
import ModalContainer from '../../sharedComponents/ModalContainer';
import Modal from '../Modals/EmbeddedResources';
import { getEmbeddedResources } from '../../redux/actions/embeddedResources';
import SectionHeader from '../../sharedComponents/SectionHeader';
import SectionContent from '../../sharedComponents/SectionContent';
import Container from '../../sharedComponents/Container';

const EmbeddedResources = ({
  dispatch,
  embeddedResources,
  loggedUser,
  company,
  companies,
  loading,
}) => {
  const classes = useStyles();
  const [filteredResources, setFilteredResources] = useState([]);
  const [showingModal, setShowingModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    let resourcesToFilter = [];
    if (embeddedResources?.length) {
      const isAdmin = userIsAdmin(loggedUser);
      const relatedCompanies = getCompanyDocumentFilter(company, loggedUser);
      resourcesToFilter = embeddedResources.filter(
        (resource) =>
          relatedCompanies.indexOf(resource.company_document) >= 0 ||
          (company && Object.keys(company).length === 0 && isAdmin),
      );
    }
    setFilteredResources(resourcesToFilter);
  }, [embeddedResources, company]);

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setShowingModal(true);
  };

  const handleCloseModal = (shouldReload) => {
    if (shouldReload) {
      dispatch(getEmbeddedResources());
    }
    setSelectedItem(null);
    setShowingModal(false);
  };

  return (
    <>
      <ModalContainer
        open={showingModal}
        handleClose={() => handleCloseModal(false)}
      >
        <Modal resource={selectedItem} handleClose={handleCloseModal} />
      </ModalContainer>
      <Container id="section-container">
        <SectionHeader
          title={
            <span>
              Recursos embedáveis
              <IconButton
                className={classes.addButton}
                onClick={() => handleOpenModal(null)}
                size="small"
              >
                <AddRounded />
              </IconButton>
            </span>
          }
          hideDatePicker
        />
        <SectionContent>
          <Paper className={classes.paper}>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Ativo</TableCell>
                    <TableCell>Padaria</TableCell>
                    <TableCell>Módulo</TableCell>
                    <TableCell>Altura (px)</TableCell>
                    <TableCell>Links</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="8" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    filteredResources.map((resource) => (
                      <TableRow
                        className={classes.row}
                        key={`table-row-${resource.id}`}
                      >
                        <TableCell className={classes.cell}>
                          {resource.active ? (
                            resource.module.active ? (
                              <CheckCircle className={classes.checkButton} />
                            ) : (
                              <Tooltip
                                title="Módulo correspondente está desativado"
                                aria-label="warning - module unactive"
                              >
                                <WarningRounded className={classes.warningButton} />
                              </Tooltip>
                            )
                          ) : (
                            <CancelRounded className={classes.closeButton} />
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {
                            companies?.find(
                              (_company) =>
                                _company.document === resource.company_document,
                            )?.name
                          }
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {resource.module_key}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {resource.resource_data.height || 600}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {(Array.isArray(resource.resource_data.src) &&
                            resource.resource_data.src.map((src) => (
                              <Tooltip
                                title={src}
                                aria-label="link to embedded resource"
                                key={`${resource.id}-${src}`}
                              >
                                <SrcContainer>&#8226; {src}</SrcContainer>
                              </Tooltip>
                            ))) || (
                            <Tooltip
                              title={resource.resource_data.src}
                              aria-label="link to embedded resource"
                            >
                              <SrcContainer>
                                &#8226; {resource.resource_data.src}
                              </SrcContainer>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <IconButton
                            onClick={() => handleOpenModal(resource)}
                            size="small"
                          >
                            <EditRounded />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </SectionContent>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedUser: state.loggedUser,
  company: state.company,
  companies: state.companies?.docs || state.companies,
  loading: state.ui.resourcesLoading,
  embeddedResources: state.embeddedResources,
  userModal: state.ui.userModal,
});

export default connect(mapStateToProps)(EmbeddedResources);
