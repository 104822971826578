import React from 'react';
import { Typography } from '@material-ui/core';
import { colors } from '../../theme';

function CardDescription({ value, comparison, hidePercent, description }) {
  return (
    <div
      style={{ display: 'flex', alignItems: 'flex-end', margin: '0.5rem 0' }}
    >
      <Typography variant="h5" style={{ fontWeight: 500 }}>
        {value}
      </Typography>
      <ComparisonLabel
        comparison={comparison}
        hidePercent={hidePercent}
        description={description}
      />
    </div>
  );
}

function ComparisonLabel({ comparison, hidePercent, description }) {
  return (
    <span
      style={{
        marginBottom: '0.25rem',
        marginLeft: '0.25rem',
      }}
    >
      <Typography
        variant="caption"
        style={{
          color: colors[comparison > 0 ? 'success' : 'warning'].main,
          fontWeight: 700,
        }}
      >
        {comparison >= 0 ? '+' : ''}
        {comparison}
        {hidePercent ? '' : '%'}{' '}
      </Typography>
      <Typography variant="caption">
        {description || 'com relação ao período anterior'}
      </Typography>
    </span>
  );
}

export default CardDescription;
