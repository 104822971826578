export const GET_PAYMENT_MACHINE_REGISTERS = '[paymentMachineRegisters] GET';
export const FETCH_PAYMENT_MACHINE_REGISTERS_SUCCESS =
  '[paymentMachineRegisters] Fetch success';
export const FETCH_PAYMENT_MACHINE_REGISTERS_ERROR =
  '[paymentMachineRegisters] Fetch Error';
export const UPDATE_PAYMENT_MACHINE_REGISTERS =
  '[paymentMachineRegisters] Update';
export const SHOW_PAYMENT_MACHINE_REGISTERS_SPINNER =
  '[paymentMachineRegisters - ui] show spinner';
export const HIDE_PAYMENT_MACHINE_REGISTERS_SPINNER =
  '[paymentMachineRegisters - ui] hide spinner';

export const getPaymentMachineRegisters = (filter) => ({
  type: GET_PAYMENT_MACHINE_REGISTERS,
  payload: filter,
});
export const updatePaymentMachineRegisters = (data) => ({
  type: UPDATE_PAYMENT_MACHINE_REGISTERS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_PAYMENT_MACHINE_REGISTERS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PAYMENT_MACHINE_REGISTERS_SPINNER,
});
