import {
  GET_ACQUIRERS,
  UPDATE_ACQUIRERS,
  FETCH_ACQUIRERS_SUCCESS,
  FETCH_ACQUIRERS_ERROR,
} from '../actions/acquirers';

const initState = {
  docs: [],
};

export function acquirersReducer(acquirers = initState, action) {
  switch (action.type) {
    case GET_ACQUIRERS: {
      return action.payload;
    }
    case UPDATE_ACQUIRERS: {
      return action.payload;
    }

    case FETCH_ACQUIRERS_SUCCESS: {
      return action.payload;
    }
    case FETCH_ACQUIRERS_ERROR: {
      return action.payload;
    }

    default:
      return acquirers;
  }
}
