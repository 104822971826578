import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Paper, TextField, FormGroup, Button } from '@material-ui/core';
import Alert from '../../../sharedComponents/Alert/index';
import Spinner from '../../../sharedComponents/Spinner/index';
import { Container, InputContent, ModalTitle, ButtonContainer } from './styles';

import { updatePassword } from '../../../redux/actions/password';

const styles = {
  formButton: {
    margin: 2,
  },
};

const UserModal = ({ id, dispatch, handleClose, passwordLoading }) => {
  const [password, setPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [passwordConfirmationError, setPasswordConfirmationError] =
    useState(false);

  const handleSave = async (e) => {
    e.preventDefault();
    let err = false;
    setPasswordError(!password || currentPassword === password);
    setCurrentPasswordError(!currentPassword);
    setPasswordConfirmationError(password !== passwordConfirmation);
    err =
      !password ||
      !currentPassword ||
      password !== passwordConfirmation ||
      currentPassword === password;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        dispatch(updatePassword(id, password, currentPassword));
      }
    }
  };

  return (
    <>
      {passwordLoading && <Spinner overlay />}
      <form onSubmit={handleSave}>
        <Paper elevation={2} style={{ height: 'fit-content' }} fullWidth>
          <Container style={{ opacity: passwordLoading ? 0.5 : 1 }}>
            <ModalTitle>Alterar senha</ModalTitle>
            <FormGroup>
              <InputContent>
                <TextField
                  id="outlined-password-input"
                  label="Senha Atual*"
                  type="password"
                  helperText={currentPasswordError && 'Informe sua senha atual'}
                  error={currentPasswordError}
                  variant="outlined"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  fullWidth
                />
              </InputContent>
              <InputContent>
                <TextField
                  id="outlined-password-input"
                  label="Nova Senha*"
                  type="password"
                  helperText={
                    passwordError &&
                    (!password
                      ? 'Informe uma nova senha'
                      : 'Nova senha deve ser diferente da senha atual')
                  }
                  error={passwordError}
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                />
              </InputContent>
              <InputContent>
                <TextField
                  id="outlined-password-input"
                  label="Confirmação de Nova Senha*"
                  type="password"
                  helperText={
                    passwordConfirmationError &&
                    'Confirmação deve ser idêntica a nova senha'
                  }
                  error={passwordConfirmationError}
                  variant="outlined"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  fullWidth
                />
              </InputContent>
              <ButtonContainer>
                <Button
                  color="secondary"
                  onClick={handleClose}
                  variant="contained"
                  style={styles.formButton}
                >
                  Cancelar
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  style={styles.formButton}
                >
                  Salvar
                </Button>
              </ButtonContainer>
            </FormGroup>
          </Container>
        </Paper>
      </form>
    </>
  );
};

export default connect((state) => ({
  passwordLoading: state.ui.passwordLoading,
}))(UserModal);
