import {
  GET_RECEIVED_PAYMENTS,
  UPDATE_RECEIVED_PAYMENTS,
  FETCH_RECEIVED_PAYMENTS_SUCCESS,
} from '../actions/receivedPayments';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
  totalValue: 0,
};

export function receivedPaymentsReducer(receivedPayemnts = initState, action) {
  switch (action.type) {
    case GET_RECEIVED_PAYMENTS: {
      return action.payload;
    }
    case UPDATE_RECEIVED_PAYMENTS: {
      return action.payload;
    }

    case FETCH_RECEIVED_PAYMENTS_SUCCESS: {
      return action.payload;
    }

    default:
      return receivedPayemnts;
  }
}
