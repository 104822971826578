import {
  FETCH_ITEMS_SUCCESS,
  FETCH_ITEMS_ERROR,
  GET_ITEMS,
  updateItems,
  showSpinner,
  hideSpinner,
} from '../actions/items';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'items';

export const getItemsFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GET_ITEMS) {
      dispatch(
        apiRequest(
          'GET',
          URL,
          {
            ...action.payload,
            filter: { active: true, ...action.payload?.filter },
          },
          FETCH_ITEMS_SUCCESS,
          FETCH_ITEMS_ERROR,
          ['customer', 'active'],
        ),
      );
      dispatch(showSpinner());
    }
  };

export const processItemsCollection =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === FETCH_ITEMS_SUCCESS) {
      dispatch(updateItems(action.payload));
      dispatch(hideSpinner());
    }
    if (action.type === FETCH_ITEMS_ERROR) {
      Toastify.addError(
        'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
      );
      dispatch(hideSpinner());
    }
  };

export const itemsMdl = [getItemsFlow, processItemsCollection];
