import {
  GET_REPORT_SPLIT_VALUES,
  UPDATE_REPORT_SPLIT_VALUES,
  FETCH_REPORT_SPLIT_VALUES_SUCCESS,
  FETCH_REPORT_SPLIT_VALUES_ERROR,
} from '../actions/reportSplitValues';

const initState = {
  docs: [],
};

export function reportSplitValuesReducer(
  reportSplitValues = initState,
  action,
) {
  switch (action.type) {
    case GET_REPORT_SPLIT_VALUES: {
      return action.payload;
    }
    case UPDATE_REPORT_SPLIT_VALUES: {
      return action.payload;
    }

    case FETCH_REPORT_SPLIT_VALUES_SUCCESS: {
      return action.payload;
    }
    case FETCH_REPORT_SPLIT_VALUES_ERROR: {
      return action.payload;
    }

    default:
      return reportSplitValues;
  }
}
