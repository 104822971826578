import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableHead, TableRow, TableBody } from '@material-ui/core';
import { getFormattedRegisterItems } from '../../../providers/Registers';
import { getPaymentMachines } from '../../../redux/actions/paymentMachines';
import Spinner from '../../../sharedComponents/Spinner';
import {
  StyledTable,
  StyledTableCell,
  StyledCheckCircleOutline,
  StyledHighlightOffOutlined,
} from '../styles';

export default function TerminalSubTable({
  serialNumber,
  companies,
}) {
  const dispatch = useDispatch();
  const states = useSelector((state) => {
    const machineStates =
      state.paymentMachines?.docs || state.paymentMachines || [];
    const formatted = getFormattedRegisterItems(machineStates, companies);
    return formatted;
  });
  const loading = useSelector((state) => state.ui.paymentMachinesLoading);

  useEffect(() => {
    if (!serialNumber) return;
    dispatch(getPaymentMachines({ listAll: true, serialNumber }));
  }, [serialNumber]);

  return (
    <center>
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledTableCell isHeader>Início</StyledTableCell>
            <StyledTableCell isHeader>Padaria</StyledTableCell>
            <StyledTableCell isHeader>Status</StyledTableCell>
            <StyledTableCell isHeader>Função de Venda</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <StyledTableCell colSpan="7">
                <Spinner parent="Table" />
              </StyledTableCell>
            </TableRow>
          ) :
            states.map((state) => (
              <TableRow>
                <StyledTableCell>{state.validSinceFormatted}</StyledTableCell>
                <StyledTableCell>{state.companyName}</StyledTableCell>
                <StyledTableCell>
                  {state.active ? (
                    <StyledCheckCircleOutline />
                  ) : (
                    <StyledHighlightOffOutlined />
                  )}
                </StyledTableCell>
                <StyledTableCell>{state.salesFunction}</StyledTableCell>
              </TableRow>
            ))}
        </TableBody>
      </StyledTable>
    </center>
  );
}
