import CompaniesService from '../../services/Companies';

async function getBanks() {
  const { data } = await CompaniesService.fetchBanks();
  return data
    .map(({ name, code }) => ({ name, code }))
    .filter(({ code }) => !!code);
}

export default {
  getBanks,
};
