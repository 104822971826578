export const GET_PICPAY_OPERATORS = '[picPayOperators] GET';
export const FETCH_PICPAY_OPERATORS_SUCCESS = '[picPayOperators] Fetch success';
export const FETCH_PICPAY_OPERATORS_ERROR = '[picPayOperators] Fetch Error';
export const UPDATE_PICPAY_OPERATORS = '[picPayOperators] Update';
export const SHOW_PICPAY_OPERATORS_SPINNER =
  '[picPayOperators - ui] show spinner';
export const HIDE_PICPAY_OPERATORS_SPINNER =
  '[picPayOperators - ui] hide spinner';

export const getPicPayOperators = (filter) => ({
  type: GET_PICPAY_OPERATORS,
  payload: filter,
});

export const updatePicPayOperators = (data) => ({
  type: UPDATE_PICPAY_OPERATORS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_PICPAY_OPERATORS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PICPAY_OPERATORS_SPINNER,
});
