import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { HoverButtonContainer, StyledButton } from './styles';

function HoverButton({
  OnHoverComponent,
  NoHoverComponent,
  onClickCallback,
  buttonStyle,
  style,
  options,
}) {
  const [isShown, setIsShown] = useState(false);
  const { exclusiveAppearing } = options;

  return (
    <HoverButtonContainer style={{ ...style }}>
      <StyledButton
        title=""
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        onClick={() => onClickCallback && onClickCallback()}
        style={{ ...buttonStyle }}
      >
        {exclusiveAppearing
          ? !isShown && NoHoverComponent && <NoHoverComponent />
          : NoHoverComponent && <NoHoverComponent />}
        {isShown && OnHoverComponent && <OnHoverComponent />}
      </StyledButton>
    </HoverButtonContainer>
  );
}

HoverButton.propTypes = {
  OnHoverComponent: PropTypes.element.isRequired,
  NoHoverComponent: PropTypes.element.isRequired,
  onClickCallback: PropTypes.func.isRequired,
  buttonStyle: PropTypes.instanceOf(React.CSSProperties),
  style: PropTypes.instanceOf(React.CSSProperties),
  options: PropTypes.shape({
    exclusiveAppearing: PropTypes.bool,
  }),
};

HoverButton.defaultProps = {
  buttonStyle: {},
  style: {},
  options: {},
};

export default HoverButton;
