import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
  @media (max-width: 567px) {
    padding: 0.5rem;
  }
`;

export const FilterContainer = styled.div`
  @media (min-width: 960px) {
    display: flex;
    flex: 1;
  }
`;
