import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Poppins', sans-serif;
  padding: 16px 32px;
`;

export const Title = styled.h1`
  font-size: 32px;
  line-height: 48px;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 26px;
  padding-bottom: 8px;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 16px;
  row-rap: 16px;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 33% 33% 33%;
  }
`;
