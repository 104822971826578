import React, { useState } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowUp';
import { arraySum } from '../../../../utils/globalFunctions';
import {
  ContentContainer,
  Divider,
  GridContainer,
  HeaderContainer,
  HeaderTitle,
  MainRowContent,
  RowContainer,
  RowListContainer,
  SubRowListContainer,
  SubRowContainer,
  TotalRowContainer,
  SubRowText,
  GreenText,
  MembersRowContainer,
  CustomChip,
} from './styles';

export default function ValuesCard({
  title,
  headerColor,
  aggregated,
  nonAggregated,
  total,
  members,
  buttonComponent,
}) {
  if (!total) {
    return null;
  }
  return (
    <Grid item xs={12} sm={6} md={3}>
      <GridContainer>
        <CardHeader
          title={title}
          button={buttonComponent}
          color={headerColor}
        />
        <CardContent
          aggregated={aggregated}
          nonAggregated={nonAggregated}
          members={members}
          total={total}
        />
      </GridContainer>
    </Grid>
  );
}

function CardHeader({ title, button, color }) {
  return (
    <HeaderContainer color={color}>
      <HeaderTitle>{title}</HeaderTitle>
      <>
        {button}
      </>
    </HeaderContainer>
  );
}

function CardContent({ aggregated, nonAggregated, members, total }) {
  return (
    <ContentContainer>
      <CardRowList aggregated={aggregated} nonAggregated={nonAggregated} />
      <Divider />
      <CardTotal total={total} />
      <CardMembers members={members} />
    </ContentContainer>
  );
}

function CardRowList({ aggregated, nonAggregated }) {
  return (
    <RowListContainer>
      {aggregated.map((list) => (
        list.length > 1 ?
          <CardRowWithSubRows subRows={list} />
          : <CardRow title={list[0]?.title} value={list[0]?.value} />
      ))}
      {nonAggregated.map(({ title, value }) => (
        <CardRow title={title} value={value} />
      ))}
    </RowListContainer>
  );
}

const formatter = Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

function CardRowWithSubRows({ subRows }) {
  const rowValue = arraySum(subRows.map((subRow) => subRow.value));
  const rowTitle = subRows.map((subRow) => subRow.title).join(' / ');
  const [open, setOpen] = useState(false);
  if (!rowValue) {
    return null;
  }
  return (
    <RowContainer>
      <MainRowContent>
        <div>
          {rowTitle}
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </div>

        <div>
          <GreenText>{formatter.format(rowValue)}</GreenText>
        </div>
      </MainRowContent>
      <SubRowListContainer>
        {open &&
          subRows.map(({ title, value }) => (
            <CardSubRow title={title} value={value} />
          ))}
      </SubRowListContainer>
    </RowContainer>
  );
}

function CardRow({ title, value }) {
  if (!value) {
    return null;
  }
  return (
    <RowContainer>
      <MainRowContent>
        <div>{title}</div>
        <div>
          <GreenText>{formatter.format(value)}</GreenText>
        </div>
      </MainRowContent>
    </RowContainer>
  );
}

function CardSubRow({ title, value }) {
  return (
    <SubRowContainer>
      <SubRowText>{title}</SubRowText>
      <SubRowText>{formatter.format(value)}</SubRowText>
    </SubRowContainer>
  );
}

function CardTotal({ total }) {
  return (
    <TotalRowContainer>
      <div style={{ fontSize: 16 }}>Total</div>
      <div style={{ fontSize: 16 }}>
        <GreenText>{formatter.format(total)}</GreenText>
      </div>
    </TotalRowContainer>
  );
}

function CardMembers({ members }) {
  return (
    members &&
    <MembersRowContainer>
      {(members.paymentMachines || []).map((machine) =>
        <CustomChip size="small">
          <span>
            <b>POS</b>{' '}{machine}
          </span>
        </CustomChip>,
      )}
      {(members.picPayOperators || []).map((operator) =>
        <CustomChip size="small">
          <span>
            <b>PicPay</b>{' '}{operator}
          </span>
        </CustomChip>,
      )}
    </MembersRowContainer>
  );
}
