import React from 'react';
import { useMediaQuery, IconButton, Icon } from '@material-ui/core';
import { connect } from 'react-redux';
import CompanySelector from '../CompanySelector';
import SubHeader from '../SubHeader';
import {
  DatePicker,
  StyledTextField,
  DatePickersContainer,
  Container,
} from './styles';
import AcquirersSelector from '../AcquirersSelector';
import {
  DateHandler,
  RangeHandler,
  RangeType,
  SectionHeaderProps,
} from './interfaces';

const SectionHeader: React.FC<React.PropsWithChildren<SectionHeaderProps>> = ({
  title,
  subtitle,
  refDate,
  onSelectDateCallback,
  loggedUser,
  hideDatePicker,
  hideCompanySelector,
  showDateRange,
  style,
  subHeaderStyle,
  iconButtonStyle,
  defaultComponentsContainerStyle,
  showAcquirers,
  handleSelectAcquirer,
  children = undefined,
}) => {
  const isDesktop = useMediaQuery('(max-width:1080px)');
  const isTablet = useMediaQuery('(max-width:860px)');
  const isMobile = useMediaQuery('(max-width:767px)');
  return (
    <Container id="section-header" style={{ ...style }}>
      <SubHeader title={title} subtitle={subtitle} style={subHeaderStyle}>
        <div
          style={{
            display: 'flex',
            marginTop: 8,
            flexWrap: 'wrap',
            ...defaultComponentsContainerStyle,
          }}
        >
          {!hideDatePicker && !showDateRange && (
            <DatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              label={!refDate ? 'Filtrar data' : ''}
              value={refDate}
              onChange={onSelectDateCallback as DateHandler}
              InputLabelProps={{
                hidden: true,
                shrink: false,
                style: { color: 'var(--primary-text-color)' },
              }}
              inputVariant="outlined"
              maxDate={new Date()}
              autoOk
              style={{
                backgroundColor: '#fff',
                borderRadius: '1rem',
                ...(isTablet && { width: '100%', marginTop: '1rem' }),
              }}
            />
          )}
          {!hideDatePicker && showDateRange && (
            <DatePickersContainer isMobile={isMobile} isTablet={isTablet}>
              <DatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                label="De"
                value={(refDate as RangeType)?.startDate}
                onChange={(onSelectDateCallback as RangeHandler)('startDate')}
                InputLabelProps={{
                  hidden: true,
                  style: {
                    color: 'var(--primary-text-color)',
                    backgroundColor: 'transparent',
                  },
                }}
                inputVariant="outlined"
                maxDate={new Date()}
                autoOk
                TextFieldComponent={(props) => (
                  <StyledTextField
                    {...props}
                    InputProps={{
                      startAdornment: (
                        <IconButton
                          style={{ paddingLeft: 0, ...iconButtonStyle }}
                        >
                          <Icon
                            style={{ color: '#192D4D' }}
                            className="material-icons-outlined"
                          >
                            calendar_today
                          </Icon>
                        </IconButton>
                      ),
                      endAdornment: (
                        <IconButton
                          style={{ paddingRight: 0, ...iconButtonStyle }}
                        >
                          <Icon style={{ color: '#192D4D' }}>expand_more</Icon>
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
              <DatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                label="Até"
                value={(refDate as RangeType)?.endDate}
                onChange={(onSelectDateCallback as RangeHandler)('endDate')}
                InputLabelProps={{
                  hidden: true,
                  style: {
                    color: 'var(--primary-text-color)',
                    backgroundColor: 'transparent',
                  },
                }}
                inputVariant="outlined"
                maxDate={new Date()}
                autoOk
                TextFieldComponent={(props) => (
                  <StyledTextField
                    {...props}
                    InputProps={{
                      startAdornment: (
                        <IconButton
                          style={{ paddingLeft: 0, ...iconButtonStyle }}
                        >
                          <Icon
                            style={{ color: '#192D4D' }}
                            className="material-icons-outlined"
                          >
                            calendar_today
                          </Icon>
                        </IconButton>
                      ),
                      endAdornment: (
                        <IconButton
                          style={{ paddingRight: 0, ...iconButtonStyle }}
                        >
                          <Icon style={{ color: '#192D4D' }}>expand_more</Icon>
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </DatePickersContainer>
          )}
          {!hideCompanySelector && (
            <CompanySelector
              style={{
                ...(isDesktop && { flex: 1 }),
                ...(isTablet
                  ? { width: '100%', marginTop: 16 }
                  : { marginLeft: 16 }),
              }}
              loggedUser={loggedUser}
            />
          )}
          {showAcquirers && handleSelectAcquirer && (
            <AcquirersSelector
              style={{
                ...(isDesktop && { flex: 1 }),
                ...(isTablet
                  ? { width: '100%', marginTop: 16 }
                  : { marginLeft: 16 }),
              }}
              callBack={handleSelectAcquirer}
            />
          )}
        </div>
        {children}
      </SubHeader>
    </Container>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => ({
  loggedUser: state.loggedUser,
});

export default connect(mapStateToProps)(SectionHeader);
