import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useStyles } from './styles';
import { PAYMENT_KINDS } from '../../../../config/consts';

const TableHeader = () => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell rowSpan={2} className={classes.headerCell} />
        <TableCell rowSpan={2} className={classes.headerCell} align="center">
          Padaria
        </TableCell>
        <TableCell
          colSpan={PAYMENT_KINDS.length}
          className={classes.headerCell}
          align="center"
        >
          Tipo de pagamento
        </TableCell>
      </TableRow>
      <TableRow>
        {PAYMENT_KINDS.map(
          (
            kind, // TODO map these keys to make it more presentable
          ) => (
            <TableCell
              key={kind}
              className={classes.headerCell}
              align="center"
            >
              {kind}
            </TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
