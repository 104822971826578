export const GET_PROVIDERS = '[providers] GET';
export const FETCH_PROVIDERS_SUCCESS = '[providers] Fetch success';
export const FETCH_PROVIDERS_ERROR = '[providers] Fetch Error';
export const UPDATE_PROVIDERS = '[providers] Update';
export const SHOW_PROVIDERS_SPINNER = '[providers - ui] show spinner';
export const HIDE_PROVIDERS_SPINNER = '[providers - ui] hide spinner';

export const getProviders = (filter) => ({
  type: GET_PROVIDERS,
  payload: filter,
});

export const updateProviders = (data) => ({
  type: UPDATE_PROVIDERS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_PROVIDERS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PROVIDERS_SPINNER,
});
