import { useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { EditRounded } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowUp';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ModalContainer from '../../sharedComponents/ModalContainer/index';
import Spinner from '../../sharedComponents/Spinner/index';
import SubHeader from '../../sharedComponents/SubHeader';
import {
  hideModal,
  showModal,
} from '../../redux/actions/bakeryAdditionalInformation';
import { getBakeryAdditionalInformations } from '../../redux/actions/bakeryAdditionalInformations';
import EditModal from '../Modals/BakeryAdditionalInformation';
import { Container } from './styles';
import { hasPermission } from '../../utils/globalFunctions';
import PATHS from '../../config/routePaths';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: '1rem',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '1rem',
  },
  row: {
    padding: 0,
    '& > *': {
      borderBottom: 'unset',
    },
  },
  cell: {
    fontSize: 12,
    padding: '0px 15px',
  },
  headerCell: {
    padding: '5px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
  },
  addButton: {
    border: '2px solid var(--primary-text-color)',
    marginLeft: '1rem',
    color: 'var(--primary-text-color)',
  },
}));

const AdditionalInformationHistory = ({
  dispatch,
  bakeryAdditionalInformations,
  bakeryAdditionalInformationsLoading,
  bakeryAdditionalInformationModal,
  companies,
  loadingCompanies,
}) => {
  const classes = useStyles();
  const [
    bakeryAdditionalInformationsFormatted,
    setBakeryAdditionalInformationsFormatted,
  ] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');

  const isTablet = useMediaQuery('(max-width:812px)');

  useEffect(() => {
    dispatch(
      getBakeryAdditionalInformations({ page: page + 1, limit, searchText }),
    );
  }, [page, limit]);

  useEffect(() => {
    if (bakeryAdditionalInformations.docs && companies.docs) {
      const formattedData = companies.docs.map(({ name, idV1 }) => ({
        customerName: name,
        additionalInfo: bakeryAdditionalInformations.docs.filter(
          ({ customer }) => customer === idV1 || customer.idV1 === idV1,
        ),
      }));
      setBakeryAdditionalInformationsFormatted(formattedData);
    }
    setTotal(bakeryAdditionalInformations?.totalDocs || 0);
  }, [bakeryAdditionalInformations, companies]);

  const handleOpenModal = () => {
    dispatch(showModal());
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  function SectionHeader({ onSubmitCallback }) {
    const [text, setText] = useState(searchText);

    const handleTextChange = (e) => {
      const { value } = e.target;
      setText(value);
    };

    const handleSearch = (e) => {
      e.preventDefault();
      dispatch(
        getBakeryAdditionalInformations({ page: 1, limit, searchText: text }),
      );
      setPage(0);
      setTotal(0);
    };

    const submit = (e) => {
      const { value } = e.target;
      onSubmitCallback(value);
      handleSearch(e);
    };

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        submit(e);
      }
    };

    return (
      <SubHeader title="informações Extras">
        <span
          style={{
            paddingTop: 4,
            width: '100%',
            maxWidth: isTablet ? undefined : 300,
          }}
        >
          <TextField
            label={text ? '' : 'Pesquisar por mês ou ano'}
            type="text"
            size="small"
            variant="outlined"
            value={text}
            onChange={handleTextChange}
            onKeyPress={handleKeyPress}
            onBlur={submit}
            fullWidth
            InputProps={{
              style: {
                borderRadius: '1.5rem',
                border: '1px solid var(--primary-text-color)',
                color: 'var(--primary-text-color)',
              },
            }}
            InputLabelProps={{
              shrink: false,
              style: { color: 'var(--primary-text-color)' },
            }}
          />
        </span>
      </SubHeader>
    );
  }

  function TableLoading() {
    return bakeryAdditionalInformationsLoading || loadingCompanies ? (
      <TableRow className={classes.row}>
        <TableCell colSpan="10" className={classes.cell}>
          <Spinner parent="Table" />
        </TableCell>
      </TableRow>
    ) : null;
  }

  function TableHeader() {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            style={{ maxWidth: '10rem' }}
            className={classes.headerCell}
          />
          <TableCell className={classes.headerCell} align="center">
            Padaria
          </TableCell>
          <TableCell className={classes.headerCell} align="center">
            Registros
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function Row(props) {
    const { row } = props;
    const { additionalInfo, customerName } = row;
    const [open, setOpen] = React.useState(false);

    return row ? (
      <>
        <TableRow className={classes.row}>
          <TableCell className={classes.cell} align="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell>
          <TableCell className={classes.cell} align="center">
            {customerName}
          </TableCell>
          <TableCell className={classes.cell} align="center">
            {additionalInfo.length}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <SubTable registers={additionalInfo} />
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    ) : null;
  }

  function SubTable(props) {
    const { registers } = props;

    const formatter = Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return (
      <>
        <Box margin={1}>
          <Typography variant="h6" gutterBottom component="div">
            Histórico
          </Typography>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell} align="center">
                  Mês/Ano
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  Área para produção
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  Área para atendimento
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  Funcionários para a produção
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  Funcionários para atendimento
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  Funcionários no administrativo
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  Despesas com folha de pagamento
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  Despesas com energia/gás
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  Despesas com água
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  Despesas com aluguel
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  Despesas administrativas
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  Outras despesas
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {registers &&
                registers.map((register) => (
                  <TableRow key={register.idV1}>
                    <TableCell className={classes.cell} align="center">
                      {register.month}/{register.year}
                    </TableCell>
                    <TableCell className={classes.cell} align="center">
                      {register.production_area}
                      m²
                    </TableCell>
                    <TableCell className={classes.cell} align="center">
                      {register.attendance_area}
                      m²
                    </TableCell>
                    <TableCell className={classes.cell} align="center">
                      {register.production_employees_number}
                    </TableCell>
                    <TableCell className={classes.cell} align="center">
                      {register.attendance_employees_number}
                    </TableCell>
                    <TableCell className={classes.cell} align="center">
                      {register.management_employees_number}
                    </TableCell>
                    <TableCell className={classes.cell} align="center">
                      {formatter.format(register.paycheck_expenses)}
                    </TableCell>
                    <TableCell className={classes.cell} align="center">
                      {formatter.format(register.energy_gas_expenses)}
                    </TableCell>
                    <TableCell className={classes.cell} align="center">
                      {formatter.format(register.water_expense)}
                    </TableCell>
                    <TableCell className={classes.cell} align="center">
                      {formatter.format(register.rent_expense)}
                    </TableCell>
                    <TableCell className={classes.cell} align="center">
                      {formatter.format(register.management_expenses)}
                    </TableCell>
                    <TableCell className={classes.cell} align="center">
                      {formatter.format(register.other_expenses)}
                    </TableCell>
                    <TableCell className={classes.cell} align="center">
                      <IconButton
                        onClick={() => handleClick(register._id)}
                        disabled={
                          !hasPermission(PATHS.SETTINGS_ADD_INFO, 'update')
                        }
                      >
                        <EditRounded />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </>
    );
  }

  function TableContent() {
    return !(bakeryAdditionalInformationsLoading || loadingCompanies)
      ? bakeryAdditionalInformationsFormatted.map((commissioning) => (
        <Row key={commissioning.idV1} row={commissioning} />
      ))
      : null;
  }

  return (
    <>
      <ModalContainer
        open={bakeryAdditionalInformationModal}
        handleClose={handleCloseModal}
      >
        <EditModal id={modalContentId} handleClose={handleCloseModal} />
      </ModalContainer>
      <Container>
        <SectionHeader onSubmitCallback={setSearchText} />
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer className={classes.table}>
              <Table aria-label="collapsible table">
                <TableHeader />
                <TableBody>
                  <TableLoading />
                  <TableContent />
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Resultados por página"
            />
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedUser: state.loggedUser,
  bakeryAdditionalInformationsLoading:
    state.ui.bakeryAdditionalInformationsLoading,
  bakeryAdditionalInformationLoading:
    state.ui.bakeryAdditionalInformationLoading,
  bakeryAdditionalInformations: state.bakeryAdditionalInformations,
  companies: state.companies,
  loadingCompanies: state.ui.loadingCompanies,
  bakeryAdditionalInformationModal: state.ui.bakeryAdditionalInformationModal,
});

export default connect(mapStateToProps)(AdditionalInformationHistory);
