import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Animated } from 'react-animated-css';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Spinner from '../../sharedComponents/ButtonSpinner/index';
import {
  Container,
  Form,
  Logo,
  Input,
  LoginButton,
  Text,
  SubText,
  InputGroup,
  EyeIcon,
} from './styles';
import logoImage from '../../assets/images/bt_logo_dark.svg';

import { apiV2, setupAuthentication } from '../../services/Api';
import Toastify from '../../utils/Toastify';
import PATHS from '../../config/routePaths';

const ResetPassword = ({ authenticated, loginSpinner }) => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const data = { password, passwordConfirmation };
      const schema = Yup.object().shape({
        password: Yup.string().required('Senha obrigatória'),
        passwordConfirmation: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'As senhas não coincidem',
        ),
      });

      await schema.validate(data);

      const queryString = location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get('token');
      const isAdmin = urlParams.get('isAdmin');

      if (!token) {
        throw new Error('Token not provided');
      }
      setupAuthentication(token);

      await apiV2.post('/users/resetPassword', {
        password,
        isAdmin,
      });

      Toastify.addSuccess('Sua senha foi modificada com sucesso!');
      history.push(PATHS.LOGIN);
    } catch (error) {
      if (error.response?.data.message === 'Token invalid' || error.message === 'Token not provided') {
        Toastify.addError(
          'O link expirou ou é inválido, por favor faça um novo pedido de redefinição de senha.',
        );
      } else {
        Toastify.addError(
          'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
        );
      }
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!authenticated) return;
    history.replace('/');
  }, [authenticated]);

  function togglePasswordVisibility() {
    setShowPassword((show) => !show);
  }
  function togglePasswordConfirmationVisibility() {
    setShowPasswordConfirmation((show) => !show);
  }

  return (
    <>
      <Container>
        <Animated
          animationIn="fadeInDown"
          animationOut="fadeOut"
          animationInDuration={2000}
          animationOutDuration={1000}
          style={{ display: 'flex' }}
        >
          <Form onSubmit={handleResetPassword}>
            <Grid container style={{ maxWidth: 440 }} spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Logo src={logoImage} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Text>Redefina sua senha</Text>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <SubText>
                  Digite uma nova senha abaixo para redefinir sua senha.
                </SubText>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <InputGroup>
                  <EyeIcon onClick={togglePasswordVisibility}>
                    {showPassword ? 'visibility' : 'visibility_off'}
                  </EyeIcon>
                  <Input
                    placeholder="Senha"
                    value={password}
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <InputGroup>
                  <EyeIcon onClick={togglePasswordConfirmationVisibility}>
                    {showPasswordConfirmation ? 'visibility' : 'visibility_off'}
                  </EyeIcon>
                  <Input
                    placeholder="Confirmação de Senha"
                    value={passwordConfirmation}
                    type={showPasswordConfirmation ? 'text' : 'password'}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                  />
                </InputGroup>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <LoginButton disabled={loginSpinner || loading} type="submit">
                  {loginSpinner || loading ? <Spinner /> : 'Salvar'}
                </LoginButton>
              </Grid>
            </Grid>
          </Form>
        </Animated>
      </Container>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    authenticated: state.loggedUser.authenticated,
    loginSpinner: state.ui.loginLoading,
  };
}

export default connect(mapStateToProps)(ResetPassword);
