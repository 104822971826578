import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHeader from '../TableHeader';
import TableLoading from '../TableLoading';
import TableContent from '../TableContent';
import { useStyles } from './styles';

const SectionContent = ({
  commissioningsLoading,
  loadingCompanies,
  commissioningsFormatted,
  commissioningLoading,
  companies,
  dispatch,
  total,
  limit,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  handleShowEditModal,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.table}>
          <Table aria-label="collapsible table">
            <TableHeader />
            <TableBody>
              <TableLoading
                commissioningsLoading={commissioningsLoading}
                loadingCompanies={loadingCompanies}
              />
              <TableContent
                commissioningsLoading={commissioningsLoading}
                loadingCompanies={loadingCompanies}
                commissioningsFormatted={commissioningsFormatted}
                commissioningLoading={commissioningLoading}
                companies={companies}
                dispatch={dispatch}
                handleShowEditModal={handleShowEditModal}
              />
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[1000, 2000, 5000, 10000]}
          component="div"
          count={total}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Resultados por página"
        />
      </Paper>
    </div>
  );
};

export default SectionContent;
