import {
  GET_MODULE,
  UPDATE_MODULE,
  UPDATE_OBJECT_MODULE,
  CREATE_MODULE,
  DELETE_MODULE,
} from '../actions/module';

export function moduleReducer(module = {}, action) {
  switch (action.type) {
    case GET_MODULE:
      return action.payload;
    case UPDATE_MODULE:
      return action.payload;
    case CREATE_MODULE:
      return action.payload;
    case DELETE_MODULE:
      return action.payload;
    case UPDATE_OBJECT_MODULE:
      return action.payload;

    default:
      return module;
  }
}
