export const GET_PICPAY_OPERATOR = '[picPayOperator] GET';
export const UPDATE_PICPAY_OPERATOR = '[picPayOperator] UPDATE';
export const CREATE_PICPAY_OPERATOR = '[picPayOperator] CREATE';
export const DELETE_PICPAY_OPERATOR = '[picPayOperator] DELETE';
export const UPDATE_OBJECT_PICPAY_OPERATOR =
  '[picPayOperator] UPDATE OBJECT PICPAY_OPERATOR';
// UI
export const SHOW_PICPAY_OPERATOR_MODAL = '[picPayOperator] SHOW MODAL';
export const HIDE_PICPAY_OPERATOR_MODAL = '[picPayOperator] HIDE MODAL';
// Spinner
export const UPDATE_PICPAY_OPERATOR_SUCCESS = '[picPayOperator] Update success';
export const UPDATE_PICPAY_OPERATOR_ERROR = '[picPayOperator] Update Error';
export const FETCH_PICPAY_OPERATOR_SUCCESS = '[picPayOperator] Fetch success';
export const FETCH_PICPAY_OPERATOR_ERROR = '[picPayOperator] Fetch Error';
export const SHOW_PICPAY_OPERATOR_SPINNER =
  '[picPayOperator - ui] show spinner';
export const HIDE_PICPAY_OPERATOR_SPINNER =
  '[picPayOperator - ui] hide spinner';

export const getPicPayOperator = (id) => ({
  type: GET_PICPAY_OPERATOR,
  payload: { _id: id },
});

export const createPicPayOperator = (data) => ({
  type: CREATE_PICPAY_OPERATOR,
  payload: data,
});

export const updatePicPayOperator = (data) => ({
  type: UPDATE_PICPAY_OPERATOR,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_PICPAY_OPERATOR_MODAL,
});

export const hideModal = () => ({
  type: HIDE_PICPAY_OPERATOR_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_PICPAY_OPERATOR_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PICPAY_OPERATOR_SPINNER,
});

export const updateObjectPicPayOperator = (data) => ({
  type: UPDATE_OBJECT_PICPAY_OPERATOR,
  payload: data,
});
