import React from 'react';
import { ButtonsContainer } from '../../styles';
import ImportButton from '../ImportButton';
import DownloadButton from '../DownloadButton';
import FiltersButton from '../FiltersButton';

const ActionButtons = ({
  loggedUser,
  refDate,
  downloadCallback,
  handleOpenModal,
}) => (
  <ButtonsContainer>
    <ImportButton loggedUser={loggedUser} />
    <DownloadButton refDate={refDate} downloadCallback={downloadCallback} />
    <FiltersButton refDate={refDate} handleOpenModal={handleOpenModal} />
  </ButtonsContainer>
);

export default ActionButtons;
