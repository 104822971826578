import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormGroup,
  MenuItem,
  Button,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import Alert from '../../../sharedComponents/Alert/index';
import Spinner from '../../../sharedComponents/Spinner/index';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
} from './styles';

import {
  getProvider,
  createProvider,
  updateProvider,
} from '../../../redux/actions/provider';
import { userIsAdmin } from '../../../utils/globalFunctions';

const styles = {
  formButton: {
    margin: 2,
  },
};

const ProviderModal = ({
  id,
  dispatch,
  provider,
  providerModal,
  handleClose,
  providerSpinner,
  disableEdit,
  loggedUser,
}) => {
  const [active, setActive] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [document, setDocument] = useState('');
  const [phone, setPhone] = useState('');
  const [contact, setContact] = useState('');
  const [type, setType] = useState('');
  const [recommended, setRecommended] = useState(true);
  const [observations, setObservations] = useState('');
  const [internalObservations, setInternalObservations] = useState('');
  const types = [
    'Uniformes',
    'Comunicação Visual Interna',
    'Comunicação Visual Externa',
    'Iluminação',
    'Infraestrutura',
    'Serviços Civis',
    'Inauguração',
    'Marcenaria e Layout',
    'Treinamento',
    'Paisagismo/Decoração',
  ];

  const [nameError, setNameError] = useState(false);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getProvider(id));
    }
  }, [id]);

  useEffect(() => {
    if (provider && id !== 'new') {
      setActive(provider.active);
      setName(provider.name);
      setEmail(provider.email);
      setDocument(provider.document);
      setPhone(provider.phone);
      setContact(provider.contact);
      setType(provider.type);
      setRecommended(provider.recommended);
      setObservations(provider.observations);
      setInternalObservations(provider.internal_observations);
    }
  }, [provider]);

  useEffect(() => {
    if (!providerModal) {
      handleClose();
    }
  }, [providerModal]);

  const handleSave = async () => {
    let err = false;
    setNameError(!name);
    err = !name;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(
            createProvider({
              active,
              name,
              email,
              document,
              contact,
              phone,
              type,
              recommended,
              observations,
              internal_observations: internalObservations,
            }),
          );
        } else {
          dispatch(
            updateProvider({
              _id: id,
              active,
              name,
              email,
              document,
              contact,
              phone,
              type,
              recommended,
              observations,
              internal_observations: internalObservations,
            }),
          );
        }
      }
    }
  };

  return (
    <>
      {providerSpinner && <Spinner overlay />}
      <Container style={{ opacity: providerSpinner ? 0.5 : 1 }}>
        <Paper
          elevation={2}
          style={{ height: 'fit-content', padding: 20 }}
          fullWidth
        >
          <ModalTitle>
            {id === 'new' ? 'Adicionar' : 'Editar'} Fornecedor
          </ModalTitle>
          <FormGroup>
            <CheckboxContent>
              <FormControlLabel
                control={
                  <Switch
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                    disabled={disableEdit}
                  />
                }
                label="Ativo"
                labelPlacement="start"
              />
            </CheckboxContent>
            <InputContent>
              <TextField
                id="outlined-password-input"
                label="Nome*"
                type="text"
                error={nameError}
                helperText={nameError && 'Nome é obrigatório'}
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                disabled={disableEdit}
              />
            </InputContent>
            <InputContent>
              <TextField
                label="CPF/CNPJ"
                type="text"
                variant="outlined"
                value={document}
                onChange={(e) => setDocument(e.target.value)}
                fullWidth
                disabled={disableEdit}
              />
            </InputContent>
            <InputContent>
              <TextField
                label="Número de Telefone"
                type="text"
                variant="outlined"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
                disabled={disableEdit}
              />
            </InputContent>
            <InputContent>
              <TextField
                id="outlined-password-input"
                label="E-mail"
                type="text"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                disabled={disableEdit}
              />
            </InputContent>
            <InputContent>
              <TextField
                id="outlined-password-input"
                label="Contato Comercial"
                type="text"
                variant="outlined"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                fullWidth
                disabled={disableEdit}
              />
            </InputContent>
            <InputContent>
              <FormControl variant="outlined" fullWidth disabled={disableEdit}>
                <InputLabel id="select-label">Tipo</InputLabel>
                <Select
                  labelId="select-label"
                  id="select-label-component"
                  value={type}
                  variant="outlined"
                  onChange={(e) => setType(e.target.value)}
                  fullWidth
                  disabled={disableEdit}
                >
                  {types.map((r) => (
                    <MenuItem value={r}>{r}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </InputContent>
            <InputContent>
              <TextField
                id="outlined-multiline-static"
                label="Observações da Padaria"
                multiline
                rows={6}
                defaultValue={observations}
                value={observations}
                onChange={(e) => setObservations(e.target.value)}
                variant="outlined"
                fullWidth
                disabled={disableEdit}
              />
            </InputContent>
            {userIsAdmin(loggedUser) && (
              <CheckboxContent>
                <FormControlLabel
                  control={
                    <Switch
                      checked={recommended}
                      onChange={(e) => setRecommended(e.target.checked)}
                      disabled={disableEdit}
                    />
                  }
                  label="Recomendado"
                  labelPlacement="start"
                />
              </CheckboxContent>
            )}
            {userIsAdmin(loggedUser) && (
              <InputContent>
                <TextField
                  id="outlined-multiline-static"
                  label="Observações Internas"
                  multiline
                  rows={6}
                  defaultValue={internalObservations}
                  value={internalObservations}
                  onChange={(e) => setInternalObservations(e.target.value)}
                  variant="outlined"
                  fullWidth
                  disabled={disableEdit}
                />
              </InputContent>
            )}
            <ButtonContainer>
              <Button
                color="secondary"
                onClick={handleClose}
                variant="contained"
                style={styles.formButton}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={disableEdit ? () => {} : handleSave}
                variant="contained"
                style={styles.formButton}
                disabled={disableEdit}
              >
                Salvar
              </Button>
            </ButtonContainer>
          </FormGroup>
        </Paper>
      </Container>
    </>
  );
};

export default connect((state) => ({
  provider: state.provider,
  providerModal: state.ui.providerModal,
  providerSpinner: state.ui.providerLoading,
  loggedUser: state.loggedUser,
}))(ProviderModal);
