import axios from 'axios';
import { store } from '../../redux/store';
import { obj2QueryString, userIsAdmin } from '../../utils/globalFunctions';
import Service from '../Service';

async function fetchAllAcquirers() {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const queryString = obj2QueryString({
    listAll: true,
    isAdmin,
  });
  const apiService = new Service({
    route: `/audit/acquirers?${queryString}`,
    apiV2: true,
  });
  const response = await apiService.get({});
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: 200,
  };
}

async function createExpectedPayment(acquirerId, dateGte, value) {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const queryString = obj2QueryString({
    isAdmin,
  });
  const apiService = new Service({
    route: `/audit/dailyExpectedPayments?${queryString}`,
    apiV2: true,
  });

  const response = await apiService.post({
    acquirerId,
    initial_date: dateGte,
    value,
  });

  if (!response.ok) {
    return Promise.reject(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: 200,
  };
}

async function deleteExpectedDailyPayment(id) {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const queryString = obj2QueryString({
    listAll: true,
    isAdmin,
  });
  const apiService = new Service({
    route: `/audit/dailyExpectedPayments/${id}?${queryString}`,
    apiV2: true,
  });
  const response = await apiService.delete({});
  if (!response.ok) {
    return Promise.reject(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: 200,
  };
}

async function getExpectedDailyPaymentById(id) {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const queryString = obj2QueryString({
    listAll: true,
    isAdmin,
  });
  const apiService = new Service({
    route: `/audit/dailyExpectedPayments/${id}?${queryString}`,
    apiV2: true,
  });
  const response = await apiService.get();
  if (!response.ok) {
    return Promise.reject(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: 200,
  };
}

async function getReceivedPaymentById(id) {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const queryString = obj2QueryString({
    listAll: true,
    isAdmin,
  });
  const apiService = new Service({
    route: `/audit/receivedTransfers/${id}?${queryString}`,
    apiV2: true,
  });
  const response = await apiService.get();
  if (!response.ok) {
    return Promise.reject(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: 200,
  };
}

async function updateExpectedDailyPaymentById(id, acquirerId, dateGte, value) {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const queryString = obj2QueryString({
    isAdmin,
  });
  const apiService = new Service({
    route: '/audit/dailyExpectedPayments',
    apiV2: true,
  });

  const response = await apiService.put(id, {
    acquirerId,
    initial_date: dateGte,
    value,
    isAdmin,
  });

  if (!response.ok) {
    return Promise.reject(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: 200,
  };
}

export default {
  fetchAllAcquirers,
  createExpectedPayment,
  deleteExpectedDailyPayment,
  getExpectedDailyPaymentById,
  updateExpectedDailyPaymentById,
  getReceivedPaymentById,
};
