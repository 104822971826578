import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { analysePeriods, formatLabels } from '../../utils/globalFunctions';
import CardDescription from '../CardDescription';
import LineChart from '../LineChart';
import { colors } from '../../theme';

function AccumulatedChartCard({
  values,
  labels,
  title,
  description,
  dataLabel,
}) {
  // Getting only lasts two items for comparison
  const { absolute, lastPeriodSum } = analysePeriods([
    values[values.length - 2],
    values[values.length - 1],
  ]);

  // Getting last period data to show
  const { lastPeriod } = analysePeriods(values);

  const value = lastPeriodSum;
  const data = {
    labels: formatLabels(labels, values.length / 2),
    datasets: [
      {
        label: dataLabel,
        data: lastPeriod,
        borderColor: colors.primary.main,
        backgroundColor: `${colors.primary.main}55`,
      },
    ],
  };

  return (
    <Paper
      style={{
        flex: 1,
        borderRadius: '1.5rem',
        padding: '2rem',
        color: colors.primary.main,
        height: '100%',
      }}
    >
      <Typography style={{ fontWeight: 700 }}>{title}</Typography>
      {values?.length > 0 ? (
        <CardDescription
          value={value}
          comparison={absolute}
          hidePercent
          description={description || 'desde o dia anterior'}
        />
      ) : (
        'sem dados registrados'
      )}
      <div style={{ paddingTop: '0.5rem' }}>
        <LineChart data={data} />
      </div>
    </Paper>
  );
}

export default AccumulatedChartCard;
