import { Icon, Typography } from '@material-ui/core';
import React from 'react';
import { Container, IconContainer, Card } from './styles';

function WarningCard({ warningText }) {
  return (
    <Card>
      <IconContainer>
        <Icon>error_outline</Icon>
      </IconContainer>
      <Container>
        <Typography style={{ fontSize: 14 }}>
          {warningText}
        </Typography>
      </Container>
    </Card>
  );
}

export default WarningCard;
