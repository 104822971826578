export const GET_GROUP = '[group] GET';
export const UPDATE_GROUP = '[group] UPDATE';
export const CREATE_GROUP = '[group] CREATE';
export const DELETE_GROUP = '[group] DELETE';
export const UPDATE_OBJECT_GROUP = '[group] UPDATE OBJECT GROUP';
// UI
export const SHOW_GROUP_MODAL = '[group] SHOW MODAL';
export const HIDE_GROUP_MODAL = '[group] HIDE MODAL';
// Spinner
export const UPDATE_GROUP_SUCCESS = '[group] Update success';
export const UPDATE_GROUP_ERROR = '[group] Update Error';
export const FETCH_GROUP_SUCCESS = '[group] Fetch success';
export const FETCH_GROUP_ERROR = '[group] Fetch Error';
export const SHOW_GROUP_SPINNER = '[group - ui] show spinner';
export const HIDE_GROUP_SPINNER = '[group - ui] hide spinner';

export const getGroup = (id) => ({
  type: GET_GROUP,
  payload: { id },
});

export const createGroup = (data) => ({
  type: CREATE_GROUP,
  payload: data,
});

export const updateGroup = (data) => ({
  type: UPDATE_GROUP,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_GROUP_MODAL,
});

export const hideModal = () => ({
  type: HIDE_GROUP_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_GROUP_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_GROUP_SPINNER,
});

export const updateObjectGroup = (data) => ({
  type: UPDATE_OBJECT_GROUP,
  payload: data,
});
