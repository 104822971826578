import {
  UPDATE_BAKERY_ADDITIONAL_INFORMATION_SUCCESS,
  UPDATE_BAKERY_ADDITIONAL_INFORMATION_ERROR,
  FETCH_BAKERY_ADDITIONAL_INFORMATION_SUCCESS,
  FETCH_BAKERY_ADDITIONAL_INFORMATION_ERROR,
  GET_BAKERY_ADDITIONAL_INFORMATION,
  CREATE_BAKERY_ADDITIONAL_INFORMATION,
  UPDATE_BAKERY_ADDITIONAL_INFORMATION,
  updateObjectBakeryAdditionalInformation,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/bakeryAdditionalInformation';
import { getBakeryAdditionalInformations } from '../actions/bakeryAdditionalInformations';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'additionalInformation';

export const createBakeryAdditionalInformationFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === CREATE_BAKERY_ADDITIONAL_INFORMATION) {
      dispatch(
        apiRequest(
          'POST',
          URL,
          action.payload,
          UPDATE_BAKERY_ADDITIONAL_INFORMATION_SUCCESS,
          UPDATE_BAKERY_ADDITIONAL_INFORMATION_ERROR,
        ),
      );
      dispatch(showSpinner());
    }
  };

export const updateBakeryAdditionalInformationFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === UPDATE_BAKERY_ADDITIONAL_INFORMATION) {
      dispatch(
        apiRequest(
          'PUT',
          URL,
          action.payload,
          UPDATE_BAKERY_ADDITIONAL_INFORMATION_SUCCESS,
          UPDATE_BAKERY_ADDITIONAL_INFORMATION_ERROR,
        ),
      );
      dispatch(showSpinner());
    }
  };

export const getBakeryAdditionalInformationFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GET_BAKERY_ADDITIONAL_INFORMATION) {
      const { _id } = action.payload;
      dispatch(
        apiRequest(
          'GET-id',
          URL,
          { _id },
          FETCH_BAKERY_ADDITIONAL_INFORMATION_SUCCESS,
          FETCH_BAKERY_ADDITIONAL_INFORMATION_ERROR,
        ),
      );
      dispatch(showSpinner());
    }
  };

export const processBakeryAdditionalInformationCollection =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === FETCH_BAKERY_ADDITIONAL_INFORMATION_SUCCESS) {
      dispatch(updateObjectBakeryAdditionalInformation(action.payload));
      dispatch(hideSpinner());
    } else if (action.type === UPDATE_BAKERY_ADDITIONAL_INFORMATION_SUCCESS) {
      dispatch(updateObjectBakeryAdditionalInformation(action.payload));
      dispatch(hideModal());
      dispatch(hideSpinner());
      // To reload the page
      const { page, limit } = getState().bakeryAdditionalInformations;
      dispatch(
        getBakeryAdditionalInformations({
          page,
          limit,
          filter: { customer: getState().customer?._id },
        }),
      );
      Toastify.addSuccess('Operação concluída com sucesso.');
    } else if (action.type === UPDATE_BAKERY_ADDITIONAL_INFORMATION_ERROR) {
      dispatch(hideSpinner());
      Toastify.addError(
        'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
      );
      console.error(action.payload);
    }
  };

export const bakeryAdditionalInformationMdl = [
  createBakeryAdditionalInformationFlow,
  updateBakeryAdditionalInformationFlow,
  getBakeryAdditionalInformationFlow,
  processBakeryAdditionalInformationCollection,
];
