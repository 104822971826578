import { UPDATE_PAYMENT_MACHINE_REGISTERS } from '../actions/paymentMachineRegisters';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function paymentMachineRegistersReducer(paymentMachineRegisters = initState, action) {
  switch (action.type) {
    case UPDATE_PAYMENT_MACHINE_REGISTERS:
      return action.payload;

    default:
      return paymentMachineRegisters;
  }
}
