import React from 'react';
import { Button } from '@material-ui/core';
import { ButtonContainer } from '../../../../Extracts/styles';

const ClearFiltersButton = ({ refDate, handleSubmit, shouldUpdateItem }) => (
  <ButtonContainer>
    <Button
      style={{
        height: 40,
        borderRadius: '1.5rem',
        padding: '16px',
      }}
      color="primary"
      variant="contained"
      size="small"
      onClick={handleSubmit}
      fullWidth
    >
      {shouldUpdateItem ? 'Atualizar Valor' : 'Cadastrar Valor'}
    </Button>
  </ButtonContainer>
);

export default ClearFiltersButton;
