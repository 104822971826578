import React from 'react';
import { IconButton } from '@material-ui/core';
import { KeyboardArrowDown, Close } from '@material-ui/icons';

const Clearable = ({ onClear, ...props }) => (
  <>
    <IconButton
      onClick={onClear}
      style={{ position: 'absolute', right: '2rem' }}
      size="small"
    >
      <Close />
    </IconButton>
    <KeyboardArrowDown {...props} />
  </>
);

export default Clearable;
