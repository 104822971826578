export const GET_COMPANIES = '[companies] GET';
export const FETCH_COMPANIES_SUCCESS = '[companies] Fetch success';
export const FETCH_COMPANIES_ERROR = '[companies] Fetch Error';
export const UPDATE_COMPANIES = '[companies] Update';
export const SHOW_COMPANIES_SPINNER = '[companies - ui] show spinner';
export const HIDE_COMPANIES_SPINNER = '[companies - ui] hide spinner';

export const getCompanies = (filter) => ({
  type: GET_COMPANIES,
  payload: filter,
});

export const updateCompanies = (data) => ({
  type: UPDATE_COMPANIES,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_COMPANIES_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_COMPANIES_SPINNER,
});
