import {
  GET_COMMISSIONING,
  UPDATE_COMMISSIONING,
  UPDATE_OBJECT_COMMISSIONING,
  CREATE_COMMISSIONING,
  DELETE_COMMISSIONING,
} from '../actions/commissioning';

export function commissioningReducer(commissioning = {}, action) {
  switch (action.type) {
    case GET_COMMISSIONING:
      return action.payload;
    case UPDATE_COMMISSIONING:
      return action.payload;
    case CREATE_COMMISSIONING:
      return action.payload;
    case DELETE_COMMISSIONING:
      return action.payload;
    case UPDATE_OBJECT_COMMISSIONING:
      return action.payload;

    default:
      return commissioning;
  }
}
