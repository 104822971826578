import {
  UPDATE_MODULE_SUCCESS,
  UPDATE_MODULE_ERROR,
  FETCH_MODULE_SUCCESS,
  FETCH_MODULE_ERROR,
  GET_MODULE,
  CREATE_MODULE,
  UPDATE_MODULE,
  updateObjectModule,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/module';
import { getModules } from '../actions/modules';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'modules';

export const createModuleFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === CREATE_MODULE) {
      dispatch(
        apiRequest(
          'POST',
          URL,
          action.payload,
          UPDATE_MODULE_SUCCESS,
          UPDATE_MODULE_ERROR,
          {},
          true,
        ),
      );
      dispatch(showSpinner());
    }
  };

export const updateModuleFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === UPDATE_MODULE) {
      dispatch(
        apiRequest(
          'PUT',
          URL,
          action.payload,
          UPDATE_MODULE_SUCCESS,
          UPDATE_MODULE_ERROR,
          {},
          true,
        ),
      );
      dispatch(showSpinner());
    }
  };

export const getModuleFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GET_MODULE) {
      const { id } = action.payload;
      dispatch(
        apiRequest(
          'GET-id',
          URL,
          { id },
          FETCH_MODULE_SUCCESS,
          FETCH_MODULE_ERROR,
          {},
          true,
        ),
      );
      dispatch(showSpinner());
    }
  };

export const processModuleCollection =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === FETCH_MODULE_SUCCESS) {
      dispatch(updateObjectModule(action.payload));
      dispatch(hideSpinner());
    } else if (action.type === UPDATE_MODULE_SUCCESS) {
      dispatch(updateObjectModule(action.payload));
      dispatch(hideModal());
      dispatch(hideSpinner());
      const { page, limit } = getState().modules;
      dispatch(getModules({ page, limit }));
      Toastify.addSuccess('Module saved.');
    } else if (action.type === UPDATE_MODULE_ERROR) {
      dispatch(hideSpinner());
      Toastify.addError(action.payload || 'Error to save the module');
    }
  };

export const moduleMdl = [
  createModuleFlow,
  updateModuleFlow,
  getModuleFlow,
  processModuleCollection,
];
