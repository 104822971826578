import { userIsAdmin } from '../../utils/globalFunctions';
import { store } from '../../redux/store';
import Service from '../Service';

async function checkForEan(eanCode) {
  try {
    const isAdmin = userIsAdmin(store.getState().loggedUser);
    const service = new Service({ route: '/sellableProducts', apiV2: true });
    const response = await service.get({ eanCode, isAdmin });
    if (response.ok && response.data?.docs?.length > 0) return true;
    return false;
  } catch (e) {
    return false;
  }
}

async function importFile(file) {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const form = new FormData();
  form.append('file', file);
  const apiService = new Service({
    route: '/offers/import',
    apiV2: true,
  });
  const response = await apiService.post(form, { isAdmin });
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: response.data.status,
  };
}

export default {
  checkForEan,
  importFile,
};
