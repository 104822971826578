import React from 'react';
import { PropTypes } from './interfaces';
import { Container, Title, SubTitle, TitleContainer } from './styles';

const SubHeader: React.FC<React.PropsWithChildren<PropTypes>> = ({
  title,
  subtitle,
  children,
  style,
  breakOnSmallScreens,
}) => (
  <Container style={style} breakOnSmallScreens={breakOnSmallScreens}>
    <TitleContainer>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
    </TitleContainer>
    {children}
  </Container>
);

export default SubHeader;
