import styled, { css } from 'styled-components';
import { FormHelperText, Icon } from '@material-ui/core';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  margin: 0 auto !important;
  width: 90%;
  zoom: 1.1;
`;

export const Logo = styled.img`
  height: 2.688rem;
  width: 16.75rem;
`;

export const Text = styled.span`
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.688rem;
  text-align: center;
  color: #3f4254;
`;

export const SubText = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.688rem;
  text-align: center;
  color: #626679;
`;

export const Input = styled.input`
  font-size: 0.8rem !important;
  box-shadow: none;
  border: solid 1px #eceaf3;
  border-radius: 1.125rem;
  background-color: white;
  height: 3.875rem;
  width: 26.25rem;
  padding: 18px 24px;
  color: #192d4d;
  transition: all 500ms;
  &:focus {
    border: solid 2px #192d4d;
  }
  &.error {
    border: solid 1px #ff3a3a;
    padding-left: 3.875rem;
    &:focus {
      border: solid 2px #ff3a3a;
    }
  }
  @media (max-width: 700px) {
    width: 18.25rem;
  }
`;

export const LoginButton = styled.button`
  background-color: #192d4d;
  color: white;
  font-weight: 500;
  font-size: 0.875rem;
  border-radius: 20px;
  height: 3.313rem;
  min-width: 6.5rem;
  border: 2px solid #192d4d;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  padding: 16px 32px 16px 32px;
  line-height: 0;
  transition: background 0.3s;
  ${({ disabled }) =>
    disabled
      ? null
      : css`
          &:hover {
            background-color: #192d41;
          }
        `};
`;

export const RegisterButton = styled.button`
  background-color: rgba(0, 0, 0, 0);
  color: white;
  font-weight: bold;
  border-radius: 20px;
  height: 35px;
  width: 100%;
  border: 2px solid white;
  cursor: pointer;
  transition: background 0.3s;
  &:hover {
    background-color: white;
    color: black;
  }
`;

export const ForgotPasswordButton = styled.button`
  background: transparent;
  border: none;
  color: #2d4161;
  cursor: pointer;
  font-family: Poppins;
  font-size: 0.688rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.063rem;
  text-align: center;
  text-decoration: underline #2d4161;
`;

export const VerificationCodeText = styled.span`
  font-size: 16px;
  color: white;
  font-weight: 400;
`;

export const ErrorIcon = styled(Icon)`
  position: absolute;
  left: 1.644rem;
  font-size: 1.456rem;
  z-index: 10;
  color: #ff3a3a;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 26.25rem;
  position: relative;
  @media (max-width: 700px) {
    width: 18.25rem;
  }
`;

export const HelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    margin-bottom: 1rem;
    font-size: 0.75rem;
    font-weight: 500;
  }
  &.MuiFormHelperText-root.Mui-error {
    color: #ff3a3a;
  }
`;
