import {
  UPDATE_COMMISSIONING_SUCCESS,
  UPDATE_COMMISSIONING_ERROR,
  FETCH_COMMISSIONING_SUCCESS,
  FETCH_COMMISSIONING_ERROR,
  GET_COMMISSIONING,
  CREATE_COMMISSIONING,
  UPDATE_COMMISSIONING,
  UPDATE_COMMISSION_VALUE,
  updateObjectCommissioning,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/commissioning';
import { getCommissionings } from '../actions/commissionings';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'commissionings';

export const createCommissioningFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === CREATE_COMMISSIONING) {
          dispatch(
            apiRequest(
              'POST',
              URL,
              action.payload,
              UPDATE_COMMISSIONING_SUCCESS,
              UPDATE_COMMISSIONING_ERROR,
              {},
              true,
            ),
          );
          dispatch(showSpinner());
        }
      };

export const updateCommissioningFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === UPDATE_COMMISSIONING) {
          dispatch(
            apiRequest(
              'PUT',
              URL,
              action.payload,
              UPDATE_COMMISSIONING_SUCCESS,
              UPDATE_COMMISSIONING_ERROR,
              {},
              true,
            ),
          );
          dispatch(showSpinner());
        }
      };

export const updateCommissionValueFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === UPDATE_COMMISSION_VALUE) {
          dispatch(
            apiRequest(
              'POST',
              `${URL}/updateValue`,
              action.payload,
              UPDATE_COMMISSIONING_SUCCESS,
              UPDATE_COMMISSIONING_ERROR,
              {},
              true,
            ),
          );
          dispatch(showSpinner());
        }
      };

export const getCommissioningFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_COMMISSIONING) {
          const { _id } = action.payload;
          dispatch(
            apiRequest(
              'GET-id',
              URL,
              { _id },
              FETCH_COMMISSIONING_SUCCESS,
              FETCH_COMMISSIONING_ERROR,
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processCommissioningCollection =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_COMMISSIONING_SUCCESS) {
          dispatch(updateObjectCommissioning(action.payload));
          dispatch(hideSpinner());
        } else if (action.type === UPDATE_COMMISSIONING_SUCCESS) {
          dispatch(updateObjectCommissioning(action.payload));
          dispatch(hideModal());
          dispatch(hideSpinner());
          // To reload the page
          const { page, limit } = getState().commissionings;
          dispatch(getCommissionings({ page, limit }));
          Toastify.addSuccess('Operação concluída com sucesso.');
        } else if (action.type === UPDATE_COMMISSIONING_ERROR) {
          dispatch(hideSpinner());
          Toastify.addError(
            'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
          );
          console.error(action.payload);
        }
      };

export const commissioningMdl = [
  createCommissioningFlow,
  updateCommissioningFlow,
  updateCommissionValueFlow,
  getCommissioningFlow,
  processCommissioningCollection,
];
