const sellableProductsMarketData = {
  Bebidas: [
    'Águas',
    'Cerveja Lata',
    'Cerveja Long Neck',
    'Cerveja Outros',
    'Energéticos/Isotônicos',
    'Outras Bebidas',
    'Refrigerante Lata',
    'Refrigerante Pet Colas',
    'Refrigerante Pet Guaraná',
    'Refrigerantes Garrafa',
    'Refrigerantes Outros',
    'Sucos Integrais',
    'Sucos Prontos',
    'Vinhos/Destilados',
  ],
  Bomboniere: [
    'Balas/Pirulitos/Chicletes',
    'Chocolates e Bombons',
    'Chocolates Gourmets',
    'Doces Caseiros/Compotas',
    'Outros Bomboniere',
  ],
  'Congelados/Sorvetes': [
    'Carnes/Aves/Pescados',
    'Legumes',
    'Massas/Pratos Prontos',
    'Outros Congelados/Sorvetes',
    'Pão de queijo',
    'Polpa de Frutas',
    'Sorvetes/Picoles',
  ],
  'Frios/Embutidos': [
    'Apresuntados',
    'Mortadelas',
    'Mussarelas',
    'Outros Frios/Embutidos',
    'Pates/Antepastos',
    'Peito de Peru',
    'Presuntos',
    'Salames',
  ],
  'Higiene/Limpeza': [
    'Desinfetantes',
    'Detergentes/Esponjas',
    'Higiene Pessoal',
    'Outros Higiene/Limpeza',
    'Sabao em Po/Barra/Amaciantes',
  ],
  Hortifruti: [
    'Frutas',
    'Legumes',
    'Outros Hortifruti',
    'Ovos',
    'Verduras',
  ],
  Laticinios: [
    'Creme de Leite',
    'Iogurtes Bandejas/Copos',
    'Iogurtes Garrafas/Funcionais',
    'Leite Condensado',
    'Leite Fermentado/Bebida láctea',
    'Leite Longa Vida UHT',
    'Outros Laticinios',
    'Queijo Ralado',
    'Requeijão',
  ],
  Mercearia: [
    'Açúcares/Adoçantes',
    'Biscoitos/Bolachas Doces',
    'Biscoitos/Bolachas Salgados',
    'Cereais',
    'Descartáveis',
    'Enlatados/Conservas',
    'Farináceos/Amidos',
    'Macarrões',
    'Manteigas/Margarinas',
    'Matinais',
    'Molhos prontos/Caldos/Extratos',
    'Óleos/Azeites',
    'Outros Mercearia',
    'Pães Industrializados',
    'Sais',
    'Salgadinhos/Snacks',
    'Temperos em pó',
    'Temperos/Vinagres outros',
  ],
  'Produção Própria': [
    'Confeitaria',
    'Lanchonete',
    'Outros Produção Propria',
    'Padaria',
    'Restaurante',
  ],
};

export default sellableProductsMarketData;
