/* eslint-disable react/jsx-props-no-spreading */
import {
  Chip,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { DateTimePicker } from '@material-ui/pickers';
import { useTheme } from '@material-ui/styles';
import { endOfDay, startOfDay } from 'date-fns';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { paymentKindMap } from '../../../providers/Extracts';
import StatelessCompanySelector from '../../../sharedComponents/StatelessCompanySelector';
import ModalContainer from '../../../sharedComponents/ModalContainer';
import {
  FilterHeader,
  labelStyle,
  selectStyle,
  StyledCurrencyField,
  StyledFormControl,
  StyledTextField,
} from '../../Extracts/styles';
import ActionButtons from './Components/ActionButtons';
import { Divider, FieldTitle, Title } from './styles';
import { getSerialNumbers } from '../../../services/PaymentMachines';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '60vw',
    paddingTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: 0,
      minHeight: '100vh',
    },
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '1.5rem',
    '&.filter': {
      padding: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    height: 50,
    padding: '0px 15px',
    textAlign: 'center',
  },
  buttons: {
    color: 'blue',
  },
  headerGridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  headerGridContainer: {
    marginLeft: '6px',
    marginRight: '6px',
    marginTop: '1rem',
    width: '100%',
    '&:first-child': {
      marginTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 16px)',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const Filters = ({
  dateGte,
  dateLte,
  paymentKind,
  searchValue,
  handleSearch,
  serialNumber,
  salesFunction,
  handleCloseModal,
  company,
  open,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const modalStyle = {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  };

  const isMobile = useMediaQuery('(max-width:480px)');

  return (
    <ModalContainer
      open={open}
      handleClose={handleCloseModal}
      modalStyle={modalStyle}
    >
      <div className={classes.root}>
        <Paper className={`${classes.paper} filter`}>
          <FilterHeader>
            <Title>Filtros</Title>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon style={{ color: '#192D4D' }} />
            </IconButton>
          </FilterHeader>
          {isMobile && <Divider className="first-divider" />}
          <div>
            <FilterGrid
              dateGte={dateGte}
              dateLte={dateLte}
              paymentKind={paymentKind}
              searchValue={searchValue}
              handleSearch={handleSearch}
              serialNumber={serialNumber}
              salesFunction={salesFunction}
              company={company}
            />
          </div>
        </Paper>
      </div>
    </ModalContainer>
  );
};

const FilterGrid = connect((state) => ({
  companies: state.companies?.docs || state.companies || [],
  picPayOperators: state.picPayOperators?.docs || state.picPayOperators,
}))(
  ({
    companies,
    dateGte,
    dateLte,
    paymentKind,
    searchValue,
    handleSearch,
    serialNumber,
    salesFunction,
    company,
    picPayOperators,
  }) => {
    const classes = useStyles();
    const selectClasses = selectStyle();
    const labelClasses = labelStyle();
    const [innerDateGte, setInnerDateGte] = useState(dateGte);
    const [innerDateLte, setInnerDateLte] = useState(dateLte);
    const [innerPaymentKind, setInnerPaymentKind] = useState(paymentKind);
    const [innerSearchValue, setInnerSearchValue] = useState(searchValue);
    const [innerSerialNumber, setInnerSerialNumber] = useState(serialNumber);
    const [innerSalesFunction, setInnerSalesFunction] = useState(salesFunction);
    const [innerCompany, setInnerCompany] = useState(company?.id);
    const [paymentMachines, setPaymentMachines] = useState([]);
    const [posInputValue, setPosInputValue] = useState('');
    const fromDateRef = useRef();
    const toDateRef = useRef();

    useEffect(() => {
      getSerialNumbers().then((result) => {
        if (result.ok) {
          setPaymentMachines(result.data);
        }
      });
    }, []);

    const getPOSOptions = useCallback(
      () => [
        ...(paymentMachines || []),
        ...(picPayOperators.map(({ operator }) => operator) || []),
      ],
      [picPayOperators, paymentMachines],
    );

    const handleClearFilters = () => {
      setInnerDateGte(startOfDay(new Date()));
      setInnerDateLte(endOfDay(new Date()));
      setInnerPaymentKind([]);
      setInnerSearchValue('');
      setInnerSerialNumber('');
      setInnerSalesFunction('');
      setInnerCompany('');
      handleSearch({
        dateGte: startOfDay(new Date()),
        dateLte: endOfDay(new Date()),
        paymentKind: [],
        searchValue: '',
        salesFunction: '',
        serialNumber: '',
        company: '',
      });
    };

    const handleApplyFilters = (e) => {
      e.preventDefault();
      handleSearch({
        dateGte: innerDateGte,
        dateLte: innerDateLte,
        paymentKind: innerPaymentKind,
        searchValue: innerSearchValue,
        salesFunction: innerSalesFunction,
        serialNumber: innerSerialNumber,
        company: companies.find(({ id }) => id === innerCompany),
      });
    };

    const handlePOSInput = (_, value) => setPosInputValue(value);
    const handlePOSValue = (_, newValue) => setInnerSerialNumber(newValue);

    return (
      <>
        <Grid container spacing={2} className={classes.headerGridContainer}>
          <Grid item xs={12} md={3}>
            <FieldTitle ref={fromDateRef}>Data e Hora</FieldTitle>
          </Grid>
          <Grid item xs={12} md={4}>
            <DateTimePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy HH:mm"
              ampm={false}
              label="De"
              value={innerDateGte}
              onChange={setInnerDateGte}
              InputLabelProps={{
                hidden: true,
                style: {
                  color: 'var(--primary-text-color)',
                  backgroundColor: 'transparent',
                },
              }}
              PopoverProps={{ anchorEl: () => fromDateRef.current }}
              inputVariant="outlined"
              maxDate={new Date()}
              fullWidth
              autoOk
              TextFieldComponent={(props) => (
                <StyledTextField
                  {...props}
                  ref={fromDateRef}
                  InputProps={{
                    startAdornment: (
                      <IconButton style={{ paddingLeft: 0 }}>
                        <Icon
                          style={{ color: '#192D4D' }}
                          className="material-icons-outlined"
                        >
                          calendar_today
                        </Icon>
                      </IconButton>
                    ),
                    endAdornment: (
                      <IconButton style={{ paddingRight: 0 }}>
                        <Icon style={{ color: '#192D4D' }}>expand_more</Icon>
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DateTimePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy HH:mm"
              ampm={false}
              label="Até"
              value={innerDateLte}
              onChange={setInnerDateLte}
              InputLabelProps={{
                hidden: true,
                style: {
                  color: 'var(--primary-text-color)',
                  backgroundColor: 'transparent',
                },
              }}
              inputVariant="outlined"
              maxDate={new Date()}
              fullWidth
              PopoverProps={{ anchorEl: () => toDateRef.current }}
              TextFieldComponent={(props) => (
                <StyledTextField
                  {...props}
                  ref={toDateRef}
                  InputProps={{
                    startAdornment: (
                      <IconButton style={{ paddingLeft: 0 }}>
                        <Icon
                          style={{ color: '#192D4D' }}
                          className="material-icons-outlined"
                        >
                          calendar_today
                        </Icon>
                      </IconButton>
                    ),
                    endAdornment: (
                      <IconButton style={{ paddingRight: 0 }}>
                        <Icon style={{ color: '#192D4D' }}>expand_more</Icon>
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2} className={classes.headerGridContainer}>
          <Grid item xs={12} md={3}>
            <FieldTitle>Meio de Pagamento</FieldTitle>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledFormControl
              className={classes.formControl}
              fullWidth
              variant="outlined"
            >
              <InputLabel
                shrink={false}
                classes={labelClasses}
                id="payment-kind-label"
              >
                {!innerPaymentKind?.length ? 'Selecione' : ''}
              </InputLabel>
              <Select
                labelId="payment-kind-label"
                multiple
                value={innerPaymentKind || []}
                name="vendor_name"
                onChange={(e) => setInnerPaymentKind(e.target.value)}
                className={innerPaymentKind?.length && 'noPadding'}
                SelectDisplayProps
                classes={selectClasses}
                IconComponent={KeyboardArrowDown}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={paymentKindMap[value]}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                startAdornment={
                  <Icon
                    style={{ color: '#192D4D', marginRight: '0.7rem' }}
                    className="material-icons-outlined"
                  >
                    account_balance_wallet
                  </Icon>
                }
              >
                {Object.keys(paymentKindMap).map((val) => (
                  <MenuItem value={val}>{paymentKindMap[val]}</MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2} className={classes.headerGridContainer}>
          <Grid item xs={12} md={3}>
            <FieldTitle>Valor Aproximado</FieldTitle>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledCurrencyField
              placeholder={!innerSearchValue ? 'Digite' : ''}
              variant="outlined"
              value={innerSearchValue || ''}
              name="value"
              currencySymbol={
                <Icon
                  style={{ color: '#192D4D', marginRight: '0.7rem' }}
                  className="material-icons-outlined"
                >
                  paid
                </Icon>
              }
              outputFormat="number"
              decimalCharacter=","
              digitGroupSeparator="."
              fullWidth
              onChange={(e, value) => setInnerSearchValue(value)}
              onKeyPress={(e) => (e.key === 'Enter' ? handleSearch(e) : null)}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2} className={classes.headerGridContainer}>
          <Grid item xs={12} md={3}>
            <FieldTitle>Unidade</FieldTitle>
          </Grid>
          <Grid item xs={12} md={4}>
            <StatelessCompanySelector
              value={innerCompany}
              companies={companies}
              onChange={(e) => setInnerCompany(e.target.value)}
              fullWidth
              disableClearing
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2} className={classes.headerGridContainer}>
          <Grid item xs={12} md={3}>
            <FieldTitle>POS</FieldTitle>
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              options={getPOSOptions()}
              value={innerSerialNumber}
              onChange={handlePOSValue}
              popupIcon={<KeyboardArrowDown htmlColor="#192D4D" />}
              autoHighlight
              onInputChange={handlePOSInput}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  label={innerSerialNumber || posInputValue ? '' : 'Digite'}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    shrink: false,
                    style: { color: '#192D4D', marginLeft: 32 },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <Icon
                        style={{
                          color: '#192D4D',
                          marginRight: '0.7rem',
                        }}
                        className="material-icons-outlined"
                      >
                        print
                      </Icon>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2} className={classes.headerGridContainer}>
          <Grid item xs={12} md={3}>
            <FieldTitle>Função de Vendas</FieldTitle>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledTextField
              label={!innerSalesFunction ? 'Selecione' : ''}
              type="text"
              value={innerSalesFunction || ''}
              variant="outlined"
              name="salesFunction"
              onChange={(e) => setInnerSalesFunction(e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: false, classes: labelClasses }}
              select
              SelectProps={{
                IconComponent: KeyboardArrowDown,
                classes: selectClasses,
              }}
              InputProps={{
                startAdornment: (
                  <Icon
                    style={{ color: '#192D4D', marginRight: '0.7rem' }}
                    className="material-icons-outlined"
                  >
                    shopping_cart
                  </Icon>
                ),
              }}
            >
              <MenuItem value="ONLINE">Venda online</MenuItem>
              <MenuItem value="OFFLINE">Loja física</MenuItem>
            </StyledTextField>
          </Grid>
        </Grid>
        <Divider />
        <ActionButtons
          refDate={dateGte && dateLte}
          handleClear={handleClearFilters}
          handleApplyFilters={handleApplyFilters}
        />
      </>
    );
  },
);

export default Filters;
