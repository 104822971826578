import {
  GET_PROVIDER,
  UPDATE_PROVIDER,
  UPDATE_OBJECT_PROVIDER,
  CREATE_PROVIDER,
  DELETE_PROVIDER,
} from '../actions/provider';

export function providerReducer(provider = {}, action) {
  switch (action.type) {
    case GET_PROVIDER:
      return action.payload;
    case UPDATE_PROVIDER:
      return action.payload;
    case CREATE_PROVIDER:
      return action.payload;
    case DELETE_PROVIDER:
      return action.payload;
    case UPDATE_OBJECT_PROVIDER:
      return action.payload;

    default:
      return provider;
  }
}
