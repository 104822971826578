import { aggregateArrayOfDicts } from '../../utils/globalFunctions';

const _formatValues = (registers) => {
  const valuesMap = {};
  const activeRegisters = registers.filter((register) => register.active);
  activeRegisters.forEach((register) => {
    valuesMap[register.paymentKind] =
      Math.round(register.commissionValue * 100) / 100;
  });
  return valuesMap;
};

const _formatAggregatedRegisters = (aggregatedRegisters) => {
  const formattedItems = Object.keys(aggregatedRegisters).map(
    (companyDocument) => {
      const registers = aggregatedRegisters[companyDocument];
      const valuesMap = _formatValues(registers);
      return {
        companyDocument,
        registers,
        ...valuesMap,
      };
    },
  );
  return formattedItems;
};

export const getFormattedCommissionings = (registers) => {
  const aggregatedRegisters = aggregateArrayOfDicts(
    registers,
    'companyDocument',
  );
  const formattedItems = _formatAggregatedRegisters(aggregatedRegisters);

  return formattedItems
    .sort((a, b) => (a.salesFunction < b.salesFunction ? -1 : 1))
    .sort((a, b) => (a.companyName < b.companyName ? -1 : 1));
};
