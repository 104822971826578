import styled, { css } from 'styled-components';
import { ContainerPropTypes } from './interfaces';

export const Container = styled.div<ContainerPropTypes>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 400px) {
    justify-content: center;
  }
  ${({ breakOnSmallScreens }) =>
    breakOnSmallScreens &&
    css`
      @media (max-width: 400px) {
        justify-content: center;
        flex-direction: column;
        align-items: stretch;
      }
    `}
`;

export const TitleContainer = styled.div`
  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const Title = styled.p`
  color: var(--primary-text-color);
  font-size: 2rem;
  font-weight: 600;
  @media (max-width: 700px) {
    text-align: center;
    font-size: 1.3rem;
  }
`;

export const SubTitle = styled.p`
  color: var(--secondary-text-color);
  font-size: 1rem;
  @media (max-width: 700px) {
    text-align: center;
  }
`;
