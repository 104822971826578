import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import EditIcon from '../../../../assets/images/edit.svg';
import { ButtonContainer } from '../../styles';

const useStyles = makeStyles((theme) => ({
  button: {
    height: 40,
    borderRadius: '1.5rem',
    padding: '1rem',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 20,
    },
  },
}));

const FiltersButton = ({ refDate, handleOpenModal }) => {
  const classes = useStyles();
  return (
    <ButtonContainer>
      <Button
        className={classes.button}
        color="secondary"
        variant="contained"
        size="small"
        onClick={handleOpenModal}
        disabled={!refDate}
        fullWidth
        endIcon={<img
          alt="Ícone de Filtros"
          src={EditIcon}
          style={{
            height: '1rem',
            width: '1rem',
          }}
        />}
      >
        Filtros
      </Button>
    </ButtonContainer>
  );
};

export default FiltersButton;
