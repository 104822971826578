import {
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_ERROR,
  GET_COMPANIES,
  updateCompanies,
  showSpinner,
  hideSpinner,
} from '../actions/companies';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'companies';

export const getCompaniesFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_COMPANIES) {
          dispatch(
            apiRequest(
              'GET',
              URL,
              action.payload,
              FETCH_COMPANIES_SUCCESS,
              FETCH_COMPANIES_ERROR,
              {},
              true, // To call the apiV2
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processCompaniesCollection =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_COMPANIES_SUCCESS) {
          dispatch(
            updateCompanies(
              action.payload.docs ? action.payload : { docs: action.payload },
            ),
          );
          dispatch(hideSpinner());
        } else if (action.type === FETCH_COMPANIES_ERROR) {
          dispatch(hideSpinner());
          Toastify.addError(
            'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
          );
        }
      };

export const companiesMdl = [getCompaniesFlow, processCompaniesCollection];
