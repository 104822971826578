import currency from 'currency.js';
import { addHours, format } from 'date-fns';
import { Company } from '../interfaces/companies';
import {
  Order,
  OrderProduct,
  OrderStatus,
  SanitizedOrderStatus,
  SupplierOrder,
} from '../interfaces/orders';

const realCurrencyConfiguration = {
  fromCents: true,
  separator: '.',
  decimal: ',',
  symbol: 'R$',
};

const statusMap: { [key in OrderStatus]: SanitizedOrderStatus } = {
  WAITING: 'Aguardando',
  REQUESTED: 'Realizado',
  DELIVERED: 'Entregue',
  CANCELED: 'Cancelado',
  PARTIALLY_DELIVERED: 'Em entrega',
};

const getPaymentMethodFromConditions = (conditions?: string) => {
  if (!conditions) return '---';
  const map: { [key: string]: string } = {
    split: 'Split',
    boleto: 'Boleto',
    pix: 'PIX',
  };

  const selectedMethod = Object.keys(map).find((method) =>
    conditions.includes(method),
  );
  if (selectedMethod) return map[selectedMethod];
  return '---';
};

const getCalculatedFinancialValues = (product: OrderProduct) => {
  const price = currency(
    product?.unitNegotiatedPrice || product.unitPlatformPrice,
    realCurrencyConfiguration,
  );

  const quantity = currency(product.quantity, {
    ...realCurrencyConfiguration,
    symbol: '',
    precision: 3,
  });

  const total = price.multiply(quantity);

  const unitTypedPrice = product.unitTypedPrice
    ? currency(product.unitTypedPrice, realCurrencyConfiguration)
    : undefined;
  const unitCostPrice = product.unitCostPrice
    ? currency(product.unitCostPrice, realCurrencyConfiguration)
    : undefined;

  return { price, quantity, total, unitCostPrice, unitTypedPrice };
};

export const formatOrderProducts = (product: OrderProduct) => {
  const unitPlatformPrice = currency(
    product.unitPlatformPrice,
    realCurrencyConfiguration,
  ).format();
  const unitNegotiatedPrice = product.unitNegotiatedPrice
    ? currency(product.unitNegotiatedPrice, realCurrencyConfiguration).format()
    : undefined;

  const {
    price: priceCurrency,
    total: totalCurrency,
    quantity: quantityCurrency,
    unitCostPrice: unitCostPriceCurrency,
    unitTypedPrice: unitTypedPriceCurrency,
  } = getCalculatedFinancialValues(product);

  const price = priceCurrency.format();

  const quantity = quantityCurrency.format();

  const total = totalCurrency.format();

  const unitCostPrice = unitCostPriceCurrency?.format();

  const unitTypedPrice = unitTypedPriceCurrency?.format();

  return {
    ...product,
    total,
    unitPlatformPrice,
    quantity,
    unitNegotiatedPrice,
    price,
    totalCurrency,
    unitCostPrice,
    unitTypedPrice,
  } as OrderProduct;
};

export const formatSupplierOrder = (
  order: Order,
  orderDate: string,
  hideCanceled?: boolean,
) => (supplierOrder: SupplierOrder) => {
  const supplierOrderStatus = statusMap[supplierOrder.status as OrderStatus];
  const totalCurrency = supplierOrder.orderProducts.reduce(
    (total, product) =>
      (product.status !== 'CANCELED'
        ? total.add(getCalculatedFinancialValues(product).total || 0)
        : total),
    currency(0, realCurrencyConfiguration),
  );

  const originalTotalCurrency = supplierOrder.orderProducts.reduce(
    (total, product) =>
      (product.status !== 'CANCELED'
        ? total.add(
          currency(
            product.unitPlatformPrice,
            realCurrencyConfiguration,
          ).multiply(
            currency(product.quantity, {
              ...realCurrencyConfiguration,
              precision: 3,
            }),
          ) || 0,
        )
        : total),
    currency(0, realCurrencyConfiguration),
  );

  const typedTotalCurrency = supplierOrder.orderProducts.reduce(
    (total, product) =>
      (product.status !== 'CANCELED'
        ? total.add(
          currency(
            product.unitTypedPrice || 0,
            realCurrencyConfiguration,
          ).multiply(
            currency(product.quantity, {
              ...realCurrencyConfiguration,
              precision: 3,
            }),
          ) || 0,
        )
        : total),
    currency(0, realCurrencyConfiguration),
  );

  const supplierOrderNumber = `${order.wordpressOrderId || order.id}-${
    supplierOrder.code
  }`;

  const deliveryDate = format(
    supplierOrder.deliveryDate
      ? new Date(supplierOrder.deliveryDate)
      : addHours(new Date(order.wordpressOrderDate), 72),
    "dd/MM/yyyy 'às' HH:mm",
  );

  const filteredOrderProducts = hideCanceled
    ? supplierOrder.orderProducts.filter(({ status }) => status !== 'CANCELED')
    : supplierOrder.orderProducts;

  const orderProducts = filteredOrderProducts.map(formatOrderProducts);

  const deliveryExpectedDate = format(
    addHours(new Date(orderDate), 72),
    'dd/MM/yyyy',
  );

  return {
    ...supplierOrder,
    status: supplierOrderStatus,
    total: totalCurrency.format(),
    totalCurrency,
    supplierOrderNumber,
    deliveryDate,
    orderProducts,
    originalTotal: originalTotalCurrency.format(),
    typedTotal: typedTotalCurrency.format(),
    deliveryExpectedDate,
  };
};

export const formatOrder = (
  order: Order,
  companies: Company[],
  hideCanceled?: boolean,
) => {
  const company = companies.find(
    ({ document }) => document === order.companyDocument,
  )?.name;

  const status = statusMap[order.status as OrderStatus];

  const filteredSupplierOrders = hideCanceled
    ? order.supplierOrders?.filter((supOrder) => supOrder.status !== 'CANCELED')
    : order.supplierOrders;

  const supplierOrders = filteredSupplierOrders?.map(
    formatSupplierOrder(
      order,
      (order.wordpressOrderDate || order.createdAt) as string,
      hideCanceled,
    ),
  );

  const subTotalCurrency = supplierOrders?.reduce(
    (currentTotal, supplierOrder) =>
      supplierOrder.totalCurrency.add(currentTotal),
    currency(0),
  );
  const subTotal = subTotalCurrency.format();
  const total = subTotalCurrency.subtract(order.discount || 0).format();
  const deliveryDate =
    order.deliveryDate && format(new Date(order.deliveryDate), 'dd/MM/yyyy');

  const deliveryExpectedDate = format(
    addHours(new Date(order.wordpressOrderDate || order.createdAt), 48),
    'dd/MM/yyyy',
  );

  const wordpressOrderDate = format(
    new Date(order.wordpressOrderDate || order.createdAt),
    "dd/MM/yyyy 'às' HH:mm",
  );

  const discount =
    order.discount &&
    currency(order.discount, realCurrencyConfiguration).format();

  const paymentMethod =
    order.paymentMethod ||
    getPaymentMethodFromConditions(order.paymentCondition);

  return {
    ...order,
    company,
    wordpressOrderDate,
    status,
    supplierOrders,
    total,
    subTotal,
    orderNumber: order.wordpressOrderId || order.id,
    deliveryDate,
    paymentMethod,
    discount,
    deliveryExpectedDate,
  };
};

export const formatOrders = (
  orders: Order[],
  companies: Company[],
  hideCanceled?: boolean,
) => {
  const filteredOrders = hideCanceled
    ? orders.filter(({ status }) => status !== 'CANCELED')
    : orders;
  return filteredOrders.map((order) =>
    formatOrder(order, companies, hideCanceled),
  );
};
