import styled from 'styled-components';

export const HoverButtonContainer = styled.div`
  width: 20px;
  height: 20px;
  margin: 5px;
`;

export const StyledButton = styled.button`
  width: 100%;
  height: 100%;
  border: 0;
  background: none;
`;
