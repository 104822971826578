import Service from '../Service';

const api = new Service({ route: '/sellableProducts', apiV2: true });

export const createProductService = ({ files, payload, isAdmin }) => {
  const form = new FormData();

  files.forEach((file) => form.append('pictures', file, file.name));

  const keys = Object.keys(payload);
  keys.forEach((key) => {
    form.append(key, payload[key]);
  });

  return api
    .post(form, { isAdmin: `${isAdmin}` })
    .then(({ data, ok, message }) => ({
      ok,
      data,
      error: message,
    }))
    .catch((e) => ({ ok: false, error: e?.response?.data?.message }));
};

export const updateProductService = ({ files, payload, isAdmin }) => {
  const form = new FormData();

  files.forEach((file) => form.append('pictures', file, file.name));

  const { id, picturesToDelete, ...data } = payload;
  const keys = Object.keys(data);
  keys.forEach((key) => {
    form.append(key, data[key]);
  });

  picturesToDelete.forEach((pictureId) =>
    form.append('picturesToDelete[]', pictureId),
  );

  return api
    .put(id, form, { isAdmin: `${isAdmin}` })
    .then(({ res, ok, message }) => ({
      ok,
      res,
      error: message,
    }))
    .catch((e) => ({ ok: false, error: e?.response?.data?.message }));
};
