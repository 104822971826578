export const GET_USERS = '[users] GET';
export const FETCH_USERS_SUCCESS = '[users] Fetch success';
export const FETCH_USERS_ERROR = '[users] Fetch Error';
export const UPDATE_USERS = '[users] Update';
export const SHOW_USERS_SPINNER = '[users - ui] show spinner';
export const HIDE_USERS_SPINNER = '[users - ui] hide spinner';

export const getUsers = (filter) => ({
  type: GET_USERS,
  payload: filter,
});

export const updateUsers = (data) => ({
  type: UPDATE_USERS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_USERS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_USERS_SPINNER,
});
