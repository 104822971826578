import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import { AddRounded, AddToPhotos, LibraryAddCheck } from '@material-ui/icons';
import {
  userIsAdmin,
  getCompanyDocumentFilter,
  hasPermission,
} from '../../utils/globalFunctions';
import { Container } from './styles';
import SectionHeader from '../../sharedComponents/SectionHeader';
import ModalContainer from '../../sharedComponents/ModalContainer';
import EditModal from '../Modals/Transfers/CreateTransferModal';
import BulkCreateModal from '../Modals/Transfers/BulkCreateTransferModal';
import BulkApproveModal from '../Modals/Transfers/BulkApproveTransferModal';
import { hideModal, showModal } from '../../redux/actions/transfer';
import TransferProvider from '../../providers/Transfers';
import { getTransfers } from '../../redux/actions/transfers';
import { getCommissionings } from '../../redux/actions/commissionings';
import { SectionContent } from './components/SectionContent.js';
import PATHS from '../../config/routePaths';

const Transfers = ({
  dispatch,
  transfers,
  transfersLoading,
  loggedUser,
  companies,
  company,
  loadingCompanies,
  transferModal,
  commissionings,
}) => {
  const [formattedTransfers, setFormattedTransfers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(0); // TODO implement pagination
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(1000); // TODO implement pagination
  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState(0);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(
      moment()
        .subtract(7, 'days')
        .startOf('day'),
    ),
    endDate: new Date(moment().endOf('day')),
  });
  const [modalContentId, setModalContentId] = useState('new');
  const [selectedItem, setSelectedItem] = useState();
  const [showingBulkCreateModal, setShowingBulkCreateModal] = useState(false);
  const [showingBulkApproveModal, setShowingBulkApproveModal] = useState(false);

  useEffect(() => {
    const { startDate, endDate } = dateRange;
    if (!transferModal) {
      dispatch(
        getTransfers({
          page: page + 1,
          limit,
          dayGte: startDate.toISOString(),
          dayLte: endDate.toISOString(),
          companyDocumentsIn: getCompanyDocumentFilter(company, loggedUser),
          ignoreApproval: userIsAdmin(loggedUser),
        }),
      );
    }
  }, [page, limit, dateRange, company, transferModal]);

  useEffect(() => {
    dispatch(
      getCommissionings({
        listAll: true,
        companyDocumentIn: getCompanyDocumentFilter(company, loggedUser),
      }),
    );
  }, [company]);

  useEffect(() => {
    if (transfers.docs && companies.docs && commissionings) {
      const transferProvider = TransferProvider({
        transfers: transfers.docs,
        companies: companies.docs,
        commissionings,
      });
      const _formattedTransfers = transferProvider.getFormattedTransfers();
      setFormattedTransfers(_formattedTransfers);
    }
    setTotal(transfers?.total || 0);
  }, [transfers, companies, commissionings]);

  const handleOpenModal = () => {
    dispatch(showModal());
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleClick = (id, item) => {
    setModalContentId(id);
    setSelectedItem(item);
    handleOpenModal();
  };

  const handleDateRange = (field) => (date) => {
    setDateRange((currentRange) => ({ ...currentRange, [field]: date }));
  };

  const isLoading = transfersLoading || loadingCompanies;
  return (
    <>
      <ModalContainer open={transferModal} handleClose={handleCloseModal}>
        <EditModal
          id={modalContentId}
          handleClose={handleCloseModal}
          totalValue={selectedItem?.totalValue}
        />
      </ModalContainer>
      <ModalContainer
        open={showingBulkCreateModal}
        handleClose={() => {
          setShowingBulkCreateModal(false);
        }}
      >
        <BulkCreateModal
          handleClose={() => {
            setShowingBulkCreateModal(false);
          }}
          filterState={{
            page: page + 1,
            limit,
            dayGte: dateRange.startDate.toISOString(),
            dayLte: dateRange.endDate.toISOString(),
            companyDocumentsIn: getCompanyDocumentFilter(company, loggedUser),
            ignoreApproval: userIsAdmin(loggedUser),
          }}
        />
      </ModalContainer>
      <ModalContainer
        open={showingBulkApproveModal}
        handleClose={() => {
          setShowingBulkApproveModal(false);
        }}
      >
        <BulkApproveModal
          handleClose={() => {
            const { startDate, endDate } = dateRange;

            dispatch(
              getTransfers({
                page: page + 1,
                limit,
                dayGte: startDate.toISOString(),
                dayLte: endDate.toISOString(),
                companyDocumentsIn: getCompanyDocumentFilter(
                  company,
                  loggedUser,
                ),
                ignoreApproval: userIsAdmin(loggedUser),
              }),
            );
            setShowingBulkApproveModal(false);
          }}
        />
      </ModalContainer>
      <Container id="section-container">
        <SectionHeader
          title={
            <span>
              Resumo financeiro
              {hasPermission(PATHS.FINANCE_TRANSFERS, 'create') && (
                <IconButton
                  onClick={() => handleClick('new')}
                  size="small"
                  style={{ marginLeft: '0.5rem' }}
                >
                  <AddRounded />
                </IconButton>
              )}
              {hasPermission(PATHS.FINANCE_TRANSFERS, 'create') && (
                <IconButton
                  onClick={() => {
                    setShowingBulkCreateModal(true);
                  }}
                  size="small"
                  style={{ marginLeft: '0.5rem' }}
                >
                  <AddToPhotos />
                </IconButton>
              )}
              {hasPermission(PATHS.FINANCE_TRANSFERS, 'create') && (
                <IconButton
                  onClick={() => {
                    setShowingBulkApproveModal(true);
                  }}
                  size="small"
                  style={{ marginLeft: '0.5rem' }}
                >
                  <LibraryAddCheck />
                </IconButton>
              )}
            </span>
          }
          iconButtonStyle={{ padding: '10px' }}
          refDate={dateRange}
          onSelectDateCallback={handleDateRange}
          loggedUser={loggedUser}
          showDateRange
          style={{ marginBottom: 0 }}
          defaultComponentsContainerStyle={{ width: '100%' }}
        />
        <SectionContent
          data={formattedTransfers}
          isLoading={isLoading}
          actionClickCallback={handleClick}
          canEdit={hasPermission(PATHS.FINANCE_TRANSFERS, 'update')}
          loggedUser={loggedUser}
        />
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedUser: state.loggedUser,
  transfersLoading: state.ui.transfersLoading,
  transfers: state.transfers,
  companies: state.companies,
  commissionings: state.commissionings.length ? state.commissionings : [],
  company: state.company,
  loadingCompanies: state.ui.loadingCompanies,
  transferModal: state.ui.transferModal,
});

export default connect(mapStateToProps)(Transfers);
