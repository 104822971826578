export const GET_LOGGED_USER = '[loggedUser] GET';
export const UPDATE_LOGGED_USER = '[loggedUser] UPDATE';
export const LOGIN = '[loggedUser] LOGIN';
export const LOGOUT = '[loggedUser] LOGOUT';

export const FETCH_LOGIN_SUCCESS = '[loggedUser] Fetch success';
export const REFRESH_LOGIN_SUCCESS = '[loggedUser] Refresh success';
export const FETCH_LOGIN_ERROR = '[loggedUser] Fetch Error';
export const SHOW_LOGIN_SPINNER = '[loggedUser - ui] show spinner';
export const HIDE_LOGIN_SPINNER = '[loggedUser - ui] hide spinner';

export const getLoggedUser = () => ({
  type: GET_LOGGED_USER,
  payload: {},
});

export const updateLoggedUser = (data) => ({
  type: UPDATE_LOGGED_USER,
  payload: data,
});

export const login = (data) => ({
  type: LOGIN,
  payload: data,
});

export const logout = (data) => ({
  type: LOGOUT,
  payload: data,
});

export const showLoginSpinner = () => ({
  type: SHOW_LOGIN_SPINNER,
});

export const hideLoginSpinner = () => ({
  type: HIDE_LOGIN_SPINNER,
});
