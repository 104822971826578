import styled from 'styled-components';

export const HeaderContainer = styled.div`
  background-color: ${(props) => props.color};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  white-space: nowrap;
  overflow-x: clip;
`;

export const HeaderTitle = styled.span`
  margin: 8px 8px 4px;
  font-size: 16px;
  font-weight: 600;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #21212114;
  width: 100%;
`;

export const GridContainer = styled.div`
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 1px 3px 0px #00000033;
`;

export const ContentContainer = styled.div`
  padding: 16px;
`;

export const RowListContainer = styled.div`
  min-height: 150px;
  margin-bottom: 16px;
`;

export const RowContainer = styled.div`
  margin-bottom: 16px;
`;

export const MainRowContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SubRowListContainer = styled.span``;

export const SubRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 8px;
  margin-top: 8px;
`;

export const SubRowText = styled.span`
  font-size: 12px;
  color: #A3A3A3;
`;

export const TotalRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
`;

export const MembersRowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 16px;
  gap: 8px;
`;

export const CustomChip = styled.div`
  display: flex;
  justify-content: center;
  background: #ECEAF3;
  border-radius: 16px;
  padding: 8px 16px;
`;

export const GreenText = styled.span`
  color: #00A389;
`;
