import styled from 'styled-components';
import { colors } from '../../theme';

export const Container = styled.div`
  margin: auto auto 0px 12px;
`;

export const IconContainer = styled.div`
  margin: auto 0px;
  height: 24px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  position: relative;
  padding: 12px;
  margin: 0px 0px 16px;
  background: ${colors.warning.light};
  border: 1px solid #192D4D;
  box-sizing: border-box;
  border-radius: 24px;
`;
