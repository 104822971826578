import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import {
  ChevronLeft as PrevIcon,
  ChevronRight as NextIcon,
} from '@material-ui/icons';
import { Container } from './styles';

const Pagination = ({
  onChangePage,
  page,
  total,
  showing,
  pages,
  limit = 10,
}) => {
  const handleChangePage = (action) => () =>
    onChangePage(action === 'prev' ? page - 1 : page + 1);
  return (
    <Container>
      <IconButton disabled={page === 1} onClick={handleChangePage('prev')}>
        <PrevIcon />
      </IconButton>
      <span>
        {(page - 1) * limit + 1} - {(page - 1) * limit + showing} de {total}
      </span>
      <IconButton disabled={page >= pages} onClick={handleChangePage('next')}>
        <NextIcon />
      </IconButton>
    </Container>
  );
};

Pagination.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number,
  total: PropTypes.number,
  showing: PropTypes.number,
  pages: PropTypes.number,
};

Pagination.defaultProps = {
  page: 1,
  total: 0,
  showing: 0,
  pages: 0,
};

export default Pagination;
