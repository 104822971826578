import React from 'react';
import { ButtonsContainer } from '../../styles';
import ClearFiltersButton from '../ClearFiltersButton';
import ApplyFiltersButton from '../ApplyFiltersButton';

const ActionButtons = ({
  refDate,
  handleClear,
  handleApplyFilters,
}) => (
  <ButtonsContainer>
    <ClearFiltersButton refDate={refDate} handleClear={handleClear} />
    <ApplyFiltersButton refDate={refDate} handleApplyFilters={handleApplyFilters} />
  </ButtonsContainer>
);

export default ActionButtons;
