export const GET_TRANSITORY_VALUES = '[transit values] GET';
export const FETCH_TRANSITORY_VALUES_SUCCESS = '[transit values] Fetch success';
export const FETCH_TRANSITORY_VALUES_ERROR = '[transit values] Fetch Error';
export const UPDATE_TRANSITORY_VALUES = '[transit values] Update';
export const SHOW_TRANSITORY_VALUES_SPINNER = '[transit values - ui] show spinner';
export const HIDE_TRANSITORY_VALUES_SPINNER = '[transit values - ui] hide spinner';

export const getTransitoryValues = (filter) => ({
  type: GET_TRANSITORY_VALUES,
  payload: filter,
});

export const updateTransitoryValues = (data) => ({
  type: UPDATE_TRANSITORY_VALUES,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_TRANSITORY_VALUES_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_TRANSITORY_VALUES_SPINNER,
});
