/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem, Icon } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardArrowDown } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAcquirers } from '../../redux/actions/acquirers';
import { AcquirerSelectorProps } from './interfaces';
import { labelStyle, Select, selectStyle } from './styles';

const AcquirersSelector: React.FC<AcquirerSelectorProps> = ({
  style,
  value: controlledValue,
  fullWidth,
  disableClearing,
  error,
  label,
  callBack,
}) => {
  const selectClasses = selectStyle();
  const labelClasses = labelStyle();
  const dispatch = useDispatch();
  const [acquirer, setAcquirer] = useState<any>(null);
  const acquirers = useSelector((state: any) => state.acquirers?.docs);

  React.useEffect(() => {
    dispatch(getAcquirers({ listAll: true }));
  }, []);

  const handleChange = (event: any) => {
    const { value } = event.target;

    const filtered = acquirers.find(({ id }: any) => id === value);
    setAcquirer(filtered);
  };

  const handleClearCompany = () => {
    setAcquirer(null);
  };

  useEffect(() => {
    if (callBack) callBack(acquirer);
  }, [acquirer]);

  return (
    <Select
      id="acquirer-selector-select"
      variant="outlined"
      select
      value={acquirer?.id || ''}
      onChange={handleChange}
      label={acquirer?.id ? '' : label || 'Selecione'}
      SelectProps={{
        classes: selectClasses,
        IconComponent:
          (controlledValue || acquirer?.id) && !disableClearing
            ? () => (
                <>
                  <KeyboardArrowDown />
                  <IconButton onClick={handleClearCompany}>
                    <Icon>close</Icon>
                  </IconButton>
                </>
              )
            : KeyboardArrowDown,
      }}
      InputLabelProps={{ shrink: false, classes: labelClasses }}
      style={style}
      fullWidth={fullWidth}
      InputProps={{
        startAdornment: (
          <Icon
            style={{ color: '#192D4D', marginRight: '0.7rem' }}
            className="material-icons-outlined"
          >
            store
          </Icon>
        ),
      }}
      error={!!error}
      helperText={error}
    >
      {acquirers?.map(({ id, name }: any) => (
        <MenuItem value={id} key={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default AcquirersSelector;
