import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;

export const StatusCircle = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${({ color }) => color};
`;

export const FakeLink = styled.a`
  cursor: pointer;
  color: blue;
  text-decoration: underline;
`;
