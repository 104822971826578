import { GET_COMPANIES, UPDATE_COMPANIES } from '../actions/companies';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function companiesReducer(companies = initState, action) {
  switch (action.type) {
    case UPDATE_COMPANIES:
      return action.payload;

    default:
      return companies;
  }
}
