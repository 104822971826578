import { GET_GROUPS, UPDATE_GROUPS } from '../actions/groups';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function groupsReducer(groups = initState, action) {
  switch (action.type) {
    case UPDATE_GROUPS:
      return action.payload;

    default:
      return groups;
  }
}
