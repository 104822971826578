export const GET_EXTRACTS = '[extracts] GET';
export const UPDATE_EXTRACTS = '[extracts] Update';
export const FETCH_EXTRACTS_SUCCESS = '[extracts] Fetch success';
export const FETCH_EXTRACTS_ERROR = '[extracts] Fetch Error';
export const SHOW_EXTRACTS_SPINNER = '[extracts - ui] show spinner';
export const HIDE_EXTRACTS_SPINNER = '[extracts - ui] hide spinner';

export const getExtracts = (filter) => ({
  type: GET_EXTRACTS,
  payload: filter,
});

export const updateExtracts = (data) => ({
  type: UPDATE_EXTRACTS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_EXTRACTS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_EXTRACTS_SPINNER,
});
