import {
  FETCH_BAKERY_ADDITIONAL_INFORMATIONS_SUCCESS,
  FETCH_BAKERY_ADDITIONAL_INFORMATIONS_ERROR,
  GET_BAKERY_ADDITIONAL_INFORMATIONS,
  updateBakeryAdditionalInformations,
  showSpinner,
  hideSpinner,
} from '../actions/bakeryAdditionalInformations';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'additionalInformation';

export const getBakeryAdditionalInformationsFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GET_BAKERY_ADDITIONAL_INFORMATIONS) {
      dispatch(
        apiRequest(
          'GET',
          URL,
          action.payload,
          FETCH_BAKERY_ADDITIONAL_INFORMATIONS_SUCCESS,
          FETCH_BAKERY_ADDITIONAL_INFORMATIONS_ERROR,
          [],
        ),
      );
      dispatch(showSpinner());
    }
  };

export const processBakeryAdditionalInformationsCollection =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === FETCH_BAKERY_ADDITIONAL_INFORMATIONS_SUCCESS) {
      dispatch(updateBakeryAdditionalInformations(action.payload));
      dispatch(hideSpinner());
    } else if (action.type === FETCH_BAKERY_ADDITIONAL_INFORMATIONS_ERROR) {
      dispatch(hideSpinner());
      Toastify.addError(
        'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
      );
      console.error(action.payload);
    }
  };

export const bakeryAdditionalInformationsMdl = [
  getBakeryAdditionalInformationsFlow,
  processBakeryAdditionalInformationsCollection,
];
