import styled from 'styled-components';
import {
  makeStyles,
  TextField,
  withStyles,
  FormControl,
} from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

export const Container = styled.div`
  padding: 1rem;
  @media (max-width: 567px) {
    padding: 0.5rem;
  }
`;

export const Title = styled.p`
  color: var(--primary-text-color);
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 1rem;
  @media (max-width: 560px) {
    font-size: 1.3rem;
  }
`;

export const SummaryText = styled.p`
  min-width: 360px;
  width: fit-content;
  color: var(--primary-text-color);
  font-size: 1rem;
  font-weight: 400;
  @media (max-width: 700px) {
    font-size: 0.6rem;
  }
`;

export const Summary = styled.div`
  padding: 1rem;
`;

export const InputContent = styled.div`
  width: 100%;
  padding: 5px 10px 10px 10px;
`;

export const StyledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--primary-text-color)',
        borderRadius: '1rem',
        borderWidth: 2,
      },
      '&:hover fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
    },
    color: 'var(--primary-text-color)',
    minWidth: '8.313rem',
    fontWeight: 500,
  },
})(TextField);
export const StyledFormControl = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--primary-text-color)',
        borderRadius: '1rem',
        borderWidth: 2,
      },
      '&:hover fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
    },
    color: 'var(--primary-text-color)',
    minWidth: '8.313rem',
    fontWeight: 500,
  },
})(FormControl);

export const StyledCurrencyField = styled(CurrencyTextField)`
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: var(--primary-text-color);
      border-radius: 1rem;
      border-width: 2px;
    }
    &:hover fieldset {
      border-color: var(--primary-text-color);
    }
    &.Mui-focused fieldset {
      border-color: var(--primary-text-color);
    }
    & input::placeholder {
      color: var(--primary-text-color);
      font-weight: 500;
      opacity: 1;
    }
  }
  color: var(--primary-text-color);
  min-width: 8.313rem;
  font-weight: 500;
`;

export const selectStyle = makeStyles({
  icon: {
    color: 'var(--primary-text-color)',
  },
  selectMenu: {
    '.noPadding > &': {
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    },
  },
});
export const labelStyle = makeStyles({
  root: {
    color: 'var(--primary-text-color)',
    fontWeight: 500,
    transform: 'translate(46px, 20px) scale(1)',
    '&.Mui-focused': {
      color: 'var(--primary-text-color)',
      fontWeight: 500,
    },
  },
});

export const FilterHeader = styled.div`
  width: 100%;
  padding: 5px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  @media (max-width: 400px) {
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  @media (max-width: 700px) {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

export const ButtonContainer = styled.span`
  margin-left: 1rem;

  @media (max-width: 400px) {
    margin-top: 0.5rem;
    margin-left: 0.25rem;
  }
`;
