import React, { useEffect, useRef, useState } from 'react';
import { DetailsCard } from '../DetailsCard';
import { SectionList } from '../SectionList';

export function SectionContent({
  data,
  isLoading,
  actionClickCallback,
  canEdit,
  loggedUser,
}) {
  const [showingDetails, setShowingDetails] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [top, setTop] = useState(0);
  const [offsetTop, setOffsetTop] = useState(0);
  const listRef = useRef(null);
  const itemRef = useRef(null);

  useEffect(() => {
    if (!listRef.current) return;
    setOffsetTop(listRef.current.offsetTop);
  }, [listRef.current]);

  useEffect(() => {
    if (!itemRef.current || selectedItem === undefined) return;
    setTop(itemRef.current.offsetTop);
  }, [itemRef.current, selectedItem]);

  useEffect(() => {
    if (isLoading && showingDetails) {
      setShowingDetails(false);
      setSelectedItem();
    }
  }, [isLoading]);

  return (
    <div style={{ display: 'flex' }} id="section-content">
      <SectionList
        data={data}
        isLoading={isLoading}
        onItemClick={(item) => {
          setShowingDetails(true);
          setSelectedItem(item);
        }}
        selectedItemId={selectedItem?.id}
        ref={listRef}
        itemRef={itemRef}
      />
      <DetailsCard
        isShowing={showingDetails && !isLoading}
        onCloseCallback={() => {
          setShowingDetails(false);
          setSelectedItem(-1);
        }}
        actionClickCallback={() =>
          actionClickCallback(selectedItem.id, selectedItem)
        }
        details={selectedItem}
        canEdit={canEdit}
        offsetTop={offsetTop}
        top={top}
        loggedUser={loggedUser}
      />
    </div>
  );
}
