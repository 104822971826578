import React from 'react';
import { Button } from '@material-ui/core';
import { ButtonContainer } from './styles';

const TextButton = ({
  text,
  disabled,
  outlined,
  onClick,
  color = 'primary',
  fullWidth = true,
  noMargins,
  type,
}) => (
  <ButtonContainer fullWidth={fullWidth} noMargins={noMargins}>
    <Button
      style={{
        height: 40,
        borderRadius: '1.5rem',
        padding: '16px',
      }}
      color={color}
      variant={outlined ? 'outlined' : 'contained'}
      size="small"
      onClick={onClick}
      disabled={disabled}
      fullWidth={fullWidth}
      type={type}
    >
      {text}
    </Button>
  </ButtonContainer>
);

export default TextButton;
