import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormGroup,
  MenuItem,
  Button,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import Spinner from '../../../sharedComponents/Spinner/index';
import DynamicList from '../../../sharedComponents/DynamicList';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
} from './styles';

import { getModules } from '../../../redux/actions/modules';
import { isNumber } from '../../../utils/globalFunctions';
import EmbeddedResourcesService from '../../../services/EmbeddedResources';
import Toastify from '../../../utils/Toastify';

const styles = {
  formButton: {
    margin: 2,
  },
  errorSpan: {
    position: 'absolute',
    bottom: -22,
    left: 10,
    color: 'red',
    fontSize: '0.75rem',
  },
};

const EmbeddedResourceModal = ({
  dispatch,
  handleClose,
  allCompanies,
  allModules,
  resource,
  loading,
}) => {
  const [active, setActive] = useState(true);
  const [companyDocument, setCompanyDocument] = useState('');
  const [moduleKey, setModuleKey] = useState('');
  const [height, setHeight] = useState('');
  const [links, setLinks] = useState([]);

  const [companyDocumentError, setCompanyError] = useState(false);
  const [moduleKeyError, setModuleError] = useState(false);
  const [heightError, setHeightError] = useState(false);
  const [linksError, setLinksError] = useState(false);

  useEffect(() => {
    if (resource) {
      setActive(resource.active);
      setCompanyDocument(resource.company_document);
      setModuleKey(resource.module_key);
      setHeight(resource.resource_data.height);
      setLinks(
        Array.isArray(resource.resource_data.src)
          ? resource.resource_data.src
          : [resource.resource_data.src],
      );
    }
  }, [resource]);

  useEffect(() => {
    if (!allModules?.length) {
      dispatch(getModules({ listAll: true }));
    }
  }, []);

  const handleSave = async () => {
    const errorList = [
      !companyDocument,
      !moduleKey,
      !!height && !isNumber(height),
      !links?.length,
    ];
    setCompanyError(errorList[0]);
    setModuleError(errorList[1]);
    setHeightError(errorList[2]);
    setLinksError(errorList[3]);
    const err = errorList.some(Boolean);

    if (!err) {
      const modifiedResource = {
        active,
        company_document: companyDocument,
        module_key: moduleKey,
        resource_data: {
          src: links,
          height,
        },
      };
      if (resource) {
        await EmbeddedResourcesService.update(resource.id, modifiedResource);
      } else {
        await EmbeddedResourcesService.create(modifiedResource);
      }
      Toastify.addSuccess('Operação realizada com sucesso!');
      handleClose(true);
    }
  };

  return (
    <>
      {loading && <Spinner overlay />}
      <Paper elevation={2} style={{ height: 'fit-content' }} fullWidth>
        <Container style={{ opacity: loading ? 0.5 : 1 }}>
          <ModalTitle>
            {resource ? 'Editar' : 'Adicionar'} Recurso Embedável
          </ModalTitle>
          <FormGroup>
            <CheckboxContent>
              <FormControlLabel
                control={
                  <Switch
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                  />
                }
                label="Ativo"
                labelPlacement="start"
              />
            </CheckboxContent>
            <InputContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-label-company">Padaria*</InputLabel>
                <Select
                  labelId="select-label-company"
                  id="select-label-component-company"
                  value={companyDocument}
                  error={companyDocumentError}
                  variant="outlined"
                  onChange={(e) => setCompanyDocument(e.target.value)}
                  fullWidth
                >
                  {allCompanies?.length &&
                    allCompanies.map((company) => (
                      <MenuItem key={company.id} value={company.document}>
                        {company.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </InputContent>
            <InputContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-label-module">Módulo*</InputLabel>
                <Select
                  labelId="select-label-module"
                  id="select-label-component-module"
                  value={moduleKey}
                  error={moduleKeyError}
                  variant="outlined"
                  onChange={(e) => setModuleKey(e.target.value)}
                  fullWidth
                >
                  {allModules?.length &&
                    allModules.map((module) => (
                      module.active &&
                      <MenuItem key={module.key} value={module.key}>{module.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </InputContent>
            <InputContent>
              <TextField
                id="outlined-height-input"
                label="Altura (px)"
                type="number"
                error={heightError}
                helperText={heightError && 'Altura deve ser um número'}
                variant="outlined"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                fullWidth
              />
            </InputContent>
            <DynamicList
              items={links}
              setItemsCallback={setLinks}
              error={linksError}
              title="Links"
            />
            <ButtonContainer>
              <Button
                color="secondary"
                onClick={() => handleClose(false)}
                variant="contained"
                style={styles.formButton}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={handleSave}
                variant="contained"
                style={styles.formButton}
              >
                Salvar
              </Button>
            </ButtonContainer>
          </FormGroup>
        </Container>
      </Paper>
    </>
  );
};

export default connect((state) => ({
  loggedUser: state.loggedUser,
  allCompanies: state.companies?.docs || state.companies || [],
  allModules: state.modules?.docs || state.modules || [],
  loading:
    state.ui.resourcesLoading ||
    state.ui.modulesLoading ||
    state.ui.companiesLoading,
}))(EmbeddedResourceModal);
