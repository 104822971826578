import moment from 'moment';
import currency from 'currency.js';
import { transferKey2CommissioningKind } from '../../config/consts';
import {
  getFlatRates,
  getOnlineValuesByPaymentKind,
  getSumByKeyBySalesFunction,
  updateTransfers,
} from '../../services/Transfers';
import {
  aggregateArrayOfDicts,
  api2FrontInterface,
  arraySum,
  snakeToCamel,
} from '../../utils/globalFunctions';
import Toastify from '../../utils/Toastify';

export const TRANSFER_KINDS = Object.keys(transferKey2CommissioningKind);

const TransferProvider = ({ transfers, companies, commissionings }) => {
  // Setup
  function _formatCommissionings(_commissionings) {
    return [..._commissionings].map((commissioning) => {
      const _commissioning = {};
      Object.keys(commissioning).forEach((key) => {
        _commissioning[snakeToCamel(key)] = commissioning[key];
      });
      const { validUntil } = _commissioning;
      return {
        active: !validUntil || new Date(validUntil) > new Date(),
        ..._commissioning,
      };
    });
  }

  function _elaborateCommissionMap(_commissionings) {
    const companyDocument2Commissions = aggregateArrayOfDicts(
      _commissionings,
      'companyDocument',
    );
    const _commissionMap = {};
    Object.keys(companyDocument2Commissions).forEach((companyDocument) => {
      const salesFunction2Commissions = aggregateArrayOfDicts(
        companyDocument2Commissions[companyDocument],
        'salesFunction',
      );
      _commissionMap[companyDocument] = {};
      Object.keys(salesFunction2Commissions).forEach((salesFunction) => {
        _commissionMap[companyDocument][salesFunction] = aggregateArrayOfDicts(
          salesFunction2Commissions[salesFunction],
          'paymentKind',
        );
      });
    });
    return _commissionMap;
  }

  function _elaborateCompanyNameMap(_companies) {
    const _companyNameMap = {};
    _companies &&
      _companies.forEach((company) => {
        _companyNameMap[company.document] = company.name;
      });
    return _companyNameMap;
  }

  // Convert snake case from api to camel case for front and filter by active
  const formattedCommissionings = _formatCommissionings(commissionings);

  // Elaborate commission map from sales function and payment kind
  const commissionMap = _elaborateCommissionMap(formattedCommissionings);

  const companyNameMap = _elaborateCompanyNameMap(companies);

  // Private methods
  function _getCompanyName(companyDocument) {
    return companyNameMap[companyDocument] || '';
  }

  function _getTransferDateString(transferDate) {
    if (transferDate) {
      const transferMoment = moment(transferDate).utc();
      return transferMoment.format('DD/MM/YYYY');
    }
    return 'Sem data de repasse';
  }

  function _getReferenceDateString(referenceDate) {
    if (referenceDate) {
      const referenceMoment = moment(referenceDate).utc();
      return referenceMoment.format('DD/MM/YYYY');
    }
    return '';
  }

  const nominalCalculation = (bruteValue, commissionValue) =>
    currency(bruteValue).multiply(1 - commissionValue / 100).value;
  const bakeryRetention = (bruteValue, commissionValue) =>
    currency(bruteValue).multiply(-1 * (commissionValue / 100)).value;
  const noCalculation = (bruteValue) => currency(bruteValue).value;

  function _calculateLiquidValue(key, bruteValue, commissionValue) {
    const calculationMap = {
      online_store_cash: bakeryRetention,
      fixed: noCalculation,
      adjusts: noCalculation,
      purchase_installment: noCalculation,
      loan_installment: noCalculation,
      onlineSalesDivergence: () => 0,
    };
    return (calculationMap[key] || nominalCalculation)(
      bruteValue,
      commissionValue,
    );
  }

  function _processRelatedValues(_values) {
    const values = { ..._values };

    // This logic is intrinsic to online operation with partners Epadoca and PagSeguro
    const {
      online_store_card: epadoca,
      online_store_payment_machine: pagseguro,
    } = values;
    const diff = epadoca - pagseguro;
    values.onlineSalesDivergence = diff;
    values.online_store_card = Math.min(epadoca, pagseguro);

    if (diff > 0) {
      // If epadoca > pagseguro, send diff to online store
      values.online_store_cash += Math.abs(diff);
    } else if (diff < 0) {
      // If epadoca < pagseguro, send diff to physical store
      values.physical_store_credit += Math.abs(diff);
    }

    // After calculations this fields it's not necessary anymore
    delete values.online_store_payment_machine;

    return values;
  }

  function _findRegisterByMoment(register, referenceMoment) {
    const startMoment = moment(register.validSince);
    const endMoment = register.validUntil && moment(register.validUntil);
    return (
      startMoment <= referenceMoment &&
      (endMoment ? referenceMoment < endMoment : true)
    );
  }

  function _getCommissionFromReferenceDate(possibleCommissions, referenceDate) {
    if (!possibleCommissions) {
      return undefined;
    }
    const referenceMoment = moment(referenceDate);
    return possibleCommissions.find((register) =>
      _findRegisterByMoment(register, referenceMoment),
    );
  }

  function _getCommissionFromTransferKey(
    transferKey,
    companyDocument,
    referenceDate,
  ) {
    const salesFunction = transferKey.toLowerCase().includes('online')
      ? 'ONLINE'
      : 'OFFLINE';
    const commissionKind = transferKey2CommissioningKind[transferKey];
    const commissionKind2Commission =
      commissionMap[companyDocument]?.[salesFunction];
    const possibleCommissions = commissionKind2Commission?.[commissionKind];
    const commission = _getCommissionFromReferenceDate(
      possibleCommissions,
      referenceDate,
    );
    return commission;
  }

  function _formatValues(_values, companyDocument, referenceMoment) {
    const values = { ..._values };
    const formattedValues = {};
    Object.keys(values).forEach((key) => {
      const bruteValue = values?.[key];
      if (!bruteValue) {
        return;
      }
      const commission = _getCommissionFromTransferKey(
        key,
        companyDocument,
        referenceMoment,
      );
      const commissionValue = parseFloat(commission?.commissionValue || 0);
      const liquidValue = _calculateLiquidValue(
        key,
        bruteValue,
        commissionValue,
      );
      formattedValues[key] = {
        bruteValue,
        liquidValue,
        commissionValue,
      };
    });
    return formattedValues;
  }

  function _sanitizeCurrencyObj(obj) {
    const sanitizedObj = {};
    Object.keys(obj).forEach((key) => {
      sanitizedObj[key] = currency(obj[key]).value || 0;
    });
    return sanitizedObj;
  }
  function _calculateTotalValue(_values) {
    const sum = arraySum(
      Object.values(_values).map(_sanitizeCurrencyObj),
      'liquidValue',
    );
    return currency(sum).value || 0;
  }

  function _calculateSubtotalValue(_values) {
    const values = { ..._values };
    delete values.loan_installment;
    delete values.purchase_installment;
    return arraySum(
      Object.values(values).map(_sanitizeCurrencyObj),
      'liquidValue',
    );
  }

  function _formatTransfer(transfer) {
    // Convert snake case from api to camel case for front
    const formattedItem = api2FrontInterface(transfer);

    const { current_balance: currentBalance, ...values } = formattedItem.values;

    // Process redundant data to decide where to commission each value
    formattedItem.values = _processRelatedValues(values);

    // Bring data from json to fields and calculation of total
    const formattedValues = _formatValues(
      formattedItem.values,
      formattedItem.companyDocument,
      formattedItem.referenceDate,
    );

    const subtotalValue = _calculateSubtotalValue(formattedValues);
    let totalValue = _calculateTotalValue(formattedValues);
    let finalBalance = currentBalance;
    if (totalValue + currentBalance >= 0 && currentBalance < 0) {
      totalValue += currentBalance;
      finalBalance = 0;
    } else if (currentBalance < 0 || totalValue < 0) {
      finalBalance = currentBalance + totalValue;
      totalValue = 0;
    }

    const transferString = _getTransferDateString(formattedItem.transferDate);

    const dateString = _getReferenceDateString(formattedItem.referenceDate);

    const companyName = _getCompanyName(formattedItem.companyDocument);

    return {
      ...formattedItem,
      ...formattedValues,
      dateString,
      transferString,
      companyName,
      totalValue,
      subtotalValue,
      currentBalance,
      finalBalance,
    };
  }

  // Public methods
  let formattedItems;

  function getFormattedTransfers() {
    formattedItems = (transfers || []).map(_formatTransfer);
    return formattedItems;
  }

  const factory = { getFormattedTransfers };

  return factory;
};

export class TransferModalProvider {
  static fetchAutoCalculatedFields = (
    companyDocument,
    refDate,
    onUpdateCallback,
  ) => {
    const promise = new Promise((resolve, reject) => {
      try {
        const promises = Promise.all([
          getSumByKeyBySalesFunction({
            companyDocuments: [companyDocument],
            dateGte: new Date(
              moment(refDate)
                .startOf('day')
                .format(),
            ).toISOString(),
            dateLte: new Date(
              moment(refDate)
                .endOf('day')
                .format(),
            ).toISOString(),
          }),
          getOnlineValuesByPaymentKind({
            companyDocuments: [companyDocument],
            dateGte: new Date(
              moment(refDate)
                .startOf('day')
                .format(),
            ).toISOString(),
            dateLte: new Date(
              moment(refDate)
                .endOf('day')
                .format(),
            ).toISOString(),
          }),
          getFlatRates({
            companyDocuments: [companyDocument],
            referenceISOString: new Date(moment(refDate)).toISOString(),
            kind: 'CHARGE',
          }),
        ]);
        promises.then((responses) => {
          const valuesToUpdate = this.updateAutoCalculatedFields(
            responses.map((response) => response.data),
            onUpdateCallback,
          );
          resolve(valuesToUpdate);
        });
      } catch (error) {
        reject(error);
        Toastify.addError(
          'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
          error,
        );
      }
    });
    return promise;
  };

  static processExtractsValues = (extractSumByKey) => {
    const valuesToUpdate = {};

    // Offline values
    const paymentKind2TransferKey = {
      3: 'physical_store_credit',
      8: 'physical_store_debit',
      11: 'physical_store_pix',
      98: 'physical_store_voucher',
      99: 'physical_store_pic_pay',
    };
    const offlineRows =
      extractSumByKey?.filter((row) => row.sales_function !== 'ONLINE') || [];
    offlineRows.forEach((row) => {
      const transferLabel = paymentKind2TransferKey[row.payment_kind_code];
      valuesToUpdate[transferLabel] = row.sum;
    });

    // Online values
    const onlineRows =
      extractSumByKey?.filter((row) => row.sales_function === 'ONLINE') || [];
    const onlineSum = onlineRows.length ? arraySum(onlineRows, 'sum') : 0;
    valuesToUpdate.online_store_payment_machine = onlineSum;

    const allPaymentKindToUpdate = [
      ...new Set([
        ...Object.values(paymentKind2TransferKey),
        ...Object.keys(valuesToUpdate),
      ]),
    ];

    allPaymentKindToUpdate.forEach((paymentKind) => {
      if (!valuesToUpdate[paymentKind]) {
        valuesToUpdate[paymentKind] = 0;
      }
    });

    return valuesToUpdate;
  };

  static processOnlineSalesValues = (onlineSalesSumByKey) => {
    const valuesToUpdate = {};

    // Cash/online values
    const paymentKind2TransferKey = {
      1: 'online_store_cash',
      36: 'online_store_online',
      54: 'online_store_online',
    };
    const nonCardRows =
      onlineSalesSumByKey?.filter(
        (row) => !!paymentKind2TransferKey[row.payment_kind_code],
      ) || [];
    nonCardRows.forEach((row) => {
      const transferLabel = paymentKind2TransferKey[row.payment_kind_code];
      if (!valuesToUpdate[transferLabel]) {
        valuesToUpdate[transferLabel] = 0;
      }
      valuesToUpdate[transferLabel] += row._sum;
    });

    // Card values (and all others)
    const cardRows = onlineSalesSumByKey?.filter(
      (row) => !paymentKind2TransferKey[row.payment_kind_code],
    );
    const cardSum = cardRows?.length ? arraySum(cardRows, '_sum') : 0;
    valuesToUpdate.online_store_card = cardSum;

    const allPaymentKindToUpdate = [
      ...new Set([
        ...Object.values(paymentKind2TransferKey),
        ...Object.keys(valuesToUpdate),
      ]),
    ];

    allPaymentKindToUpdate.forEach((paymentKind) => {
      if (!valuesToUpdate[paymentKind]) {
        valuesToUpdate[paymentKind] = 0;
      }
    });

    return valuesToUpdate;
  };

  static processFlatRate = (flatRates) => ({
    fixed: arraySum(flatRates, 'value'),
  });

  static updateAutoCalculatedFields = (
    [extractSumByKey, onlineSalesSumByKey, flatRates],
    callback,
  ) => {
    const valuesToUpdate = {
      ...this.processExtractsValues(extractSumByKey),
      ...this.processOnlineSalesValues(onlineSalesSumByKey),
      ...this.processFlatRate(flatRates),
    };
    Object.keys(valuesToUpdate).forEach((valueKey) => {
      callback &&
        callback((currentData) => ({
          ...currentData,
          [valueKey]: valuesToUpdate[valueKey] || 0,
        }));
    });
    return valuesToUpdate;
  };

  static bulkTransferApproval = async ({
    companyDocuments,
    refDate,
    transferDate,
  }) => {
    const { data } = await updateTransfers(
      {
        companyDocuments,
        referenceISOString: new Date(
          moment(refDate)
            .startOf('day')
            .format(),
        ).toISOString(),
      },
      {
        valuesToUpdate: {
          approved: true,
          transfer_date: transferDate,
        },
      },
    );
    return data;
  };
}

export default TransferProvider;
