import {
  GET_PRODUCT,
  UPDATE_PRODUCT,
  UPDATE_OBJECT_PRODUCT,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
} from '../actions/product';

export function productReducer(product = {}, action) {
  switch (action.type) {
    case GET_PRODUCT:
      return action.payload;
    case UPDATE_PRODUCT:
      return action.payload;
    case CREATE_PRODUCT:
      return action.payload;
    case DELETE_PRODUCT:
      return action.payload;
    case UPDATE_OBJECT_PRODUCT:
      return action.payload;

    default:
      return product;
  }
}
