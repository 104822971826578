import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TabBar from './components/TabBar';
import TerminalsContent from './components/TerminalsContent';
import CompaniesContent from './components/CompaniesContent';
import ModalContainer from '../../sharedComponents/ModalContainer/index';
import EditModal from '../Modals/PaymentMachines';
import SubHeader from '../../sharedComponents/SubHeader';
import { showModal, hideModal } from '../../redux/actions/paymentMachine';
import Container from '../../sharedComponents/Container';
import { getCompanies } from '../../redux/actions/companies';
import { getFormattedRegisterItems } from '../../providers/Registers';
import CompaniesService from '../../services/Companies';
import Toastify from '../../utils/Toastify';

const PaymentMachines = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [
    paymentMachineRegistersFormatted,
    setPaymentMachineRegistersFormatted,
  ] = useState([]);
  const [total, setTotal] = useState();
  const paymentMachineModal = useSelector(
    (state) => state.ui.paymentMachineModal,
  );
  const dispatch = useDispatch();
  const paymentMachineRegisters = useSelector(
    (state) => state.paymentMachineRegisters,
  );
  const [allCompanies, setAllCompanies] = useState([]);

  useEffect(() => {
    dispatch(getCompanies({ listAll: true }));
    CompaniesService.fetchAllCompanies()
      .then((response) => {
        setAllCompanies(response.data);
      })
      .catch((error) => {
        Toastify.addError(
          'Ocorreu um erro durante o carregamento dos dados, por favor recarregue a página e tente novamente.',
          error,
        );
      });
  }, []);

  useEffect(() => {
    if (paymentMachineRegisters.docs && allCompanies) {
      const formattedItems = getFormattedRegisterItems(
        paymentMachineRegisters.docs,
        allCompanies,
      );
      setPaymentMachineRegistersFormatted(formattedItems);
      setTotal(paymentMachineRegisters.total);
    }
  }, [paymentMachineRegisters, allCompanies]);

  const handleOpenModal = () => {
    dispatch(showModal());
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleClick = () => {
    handleOpenModal();
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <ModalContainer open={paymentMachineModal} handleClose={handleCloseModal}>
        <EditModal handleClose={handleCloseModal} />
      </ModalContainer>
      <Container>
        <SubHeader title={<span>Máquinas</span>} />
        <TabBar selectedTab={selectedTab} handleChangeTab={handleChangeTab} />
        {selectedTab === 0 && (
          <TerminalsContent
            handleClick={handleClick}
            allCompanies={allCompanies}
            paymentMachineRegistersFormatted={paymentMachineRegistersFormatted}
            total={total}
          />
        )}
        {selectedTab === 1 && (
          <CompaniesContent
            paymentMachineRegistersFormatted={paymentMachineRegistersFormatted}
            total={total}
          />
        )}
      </Container>
    </>
  );
};

export default PaymentMachines;
