import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import React from 'react';
import {
  PAYMENT_KINDS,
  statusColorMap,
  statusMap,
} from '../../../config/consts';
import PATHS from '../../../config/routePaths';
import { hasPermission } from '../../../utils/globalFunctions';
import { ExpandIcon, StatusTableCell, StyledTableCell } from '../styles';
import SubTable from './CompanySubTable';

export default function CompanyRow({ company, open, onClick }) {
  return (
    <>
      <TableRow style={{ backgroundColor: open ? '#F3F2F7' : undefined }}>
        <StyledTableCell>{company.name}</StyledTableCell>
        <StyledTableCell>{company.activeRegisters}</StyledTableCell>
        <StyledTableCell>{company.totalRegisters}</StyledTableCell>
        <StyledTableCell>{company.lastUpdateFormatted}</StyledTableCell>
        <StyledTableCell>
          <Tooltip title={open ? 'Esconder' : 'Expandir'}>
            <IconButton
              aria-label="expand row"
              onClick={onClick(company.document)}
            >
              <ExpandIcon open={open} />
            </IconButton>
          </Tooltip>
        </StyledTableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: open ? '#F3F2F7' : undefined }}>
        <TableCell
          style={{ padding: open ? '1rem' : 0 }}
          colSpan={PAYMENT_KINDS.length + 3}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <SubTable document={company.document} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
