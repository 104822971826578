export const GET_ACQUIRERS = '[acquirers] GET';
export const FETCH_ACQUIRERS_SUCCESS = '[acquirers] Fetch success';
export const FETCH_ACQUIRERS_ERROR = '[acquirers] Fetch Error';
export const UPDATE_ACQUIRERS = '[acquirers] Update';
export const SHOW_ACQUIRERS_SPINNER = '[acquirers - ui] show spinner';
export const HIDE_ACQUIRERS_SPINNER = '[acquirers - ui] hide spinner';

export const getAcquirers = (filter) => ({
  type: GET_ACQUIRERS,
  payload: filter,
});

export const updateAcquirers = (data) => ({
  type: UPDATE_ACQUIRERS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_ACQUIRERS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_ACQUIRERS_SPINNER,
});
