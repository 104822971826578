import { Button, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';

export const Container = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    overflow: 'hidden',
  },
}))(Paper);

export const OutlinedButton = withStyles({
  root: {
    backgroundColor: '#F3F2F7',
    borderRadius: 8,
    border: '1px solid #133DA5',
  },
  label: {
    textTransform: 'none',
    color: '#133DA5',
  },
})(Button);

export const Highlight = styled.div`
  display: flex;
  height: 100%;
  background-color: #133da5;
  padding: 24px 16px 24px 16px;
  color: #fff;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 200px;
  flex-direction: column;
`;

export const CommonData = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  padding: 24px 32px 24px 8px;
`;

export const ProductsContainer = withStyles({
  root: {
    marginTop: -32,
    borderRadius: 16,
    marginRight: 8,
    marginLeft: 8,
    paddingTop: 40,
    paddingBottom: 8,
    position: 'relative',
    zIndex: -10,
  },
})(Paper);

export const ProductRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 16px;
`;

export const ProductName = styled.span`
  font-weight: 600;
  font-size: 15px;
  width: 312px;
  margin: auto 0;
`;

export const Column = styled.span`
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`;

export const SupplierName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;
