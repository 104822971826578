import React, { forwardRef, useState } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grow from '@material-ui/core/Grow';
import { useMediaQuery } from '@material-ui/core';
import { colors } from '../../../../theme';

export const ListItem = forwardRef(
  ({ item, index, onItemClick, selectedItemId }, ref) => {
    const [isHovering, setIsHovering] = useState(false);
    const isSelected = selectedItemId === item.id;
    const isTablet = useMediaQuery('(max-width:900px)');

    return (
      <>
        <Grow in timeout={(index + 1) * 500}>
          <Card
            ref={ref}
            style={{
              margin: '1rem 0',
              borderRadius: '1.5rem',
              backgroundColor: item.approved
                ? undefined
                : `${colors.warning.main}26`,
            }}
            elevation={isHovering || isSelected ? 4 : 1}
          >
            <CardActionArea
              style={{
                padding: '2rem',
                display: 'flex',
                justifyContent: !isTablet && 'space-between',
                alignItems: 'center',
                fontSize: '1.125rem',
                fontWeight: isSelected ? 500 : undefined,
                flexDirection: isTablet && 'column',
              }}
              onClick={onItemClick}
              onMouseEnter={() => !isTablet && setIsHovering(true)}
              onMouseLeave={() => !isTablet && setIsHovering(false)}
            >
              <div
                style={{
                  color: item.transferString
                    ? colors.dark.main
                    : colors.gray4.main,
                  fontSize: 15,
                }}
              >
                {item.transferString}
              </div>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginLeft: !isTablet && '1rem',
                  flexDirection: isTablet && 'column',
                }}
              >
                <span
                  style={{
                    ...(isTablet && {
                      marginTop: '0.5rem',
                      textAlign: 'center',
                    }),
                  }}
                >
                  {item.companyName}
                  <span
                    style={{
                      color: colors.gray4.main,
                      fontSize: 12,
                      marginLeft: '0.5rem',
                    }}
                  >
                    {item.dateString ? `Referente à ${item.dateString}` : ''}
                  </span>
                  <span
                    style={{
                      color: colors.gray4.main,
                      fontSize: 12,
                      marginLeft: '0.5rem',
                    }}
                  >
                    {item.approved ? '' : '(não aprovado)'}
                  </span>
                </span>
                <span
                  style={{
                    display: 'flex',
                    marginTop: isTablet && '0.5rem',
                    flex: 1,
                    justifyContent: isTablet ? 'center' : 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      color: item.approved
                        ? colors.success.main
                        : colors.warning.main,
                      fontWeight: 500,
                      marginRight: !isTablet && '1rem',
                    }}
                  >
                    {item.totalValue.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                    })}
                  </span>
                  {!isTablet &&
                    (isSelected ? (
                      <ArrowBackIcon style={{ color: colors.primary.main }} />
                    ) : (
                      <ArrowForwardIcon style={{ color: colors.gray4.main }} />
                    ))}
                </span>
              </div>
            </CardActionArea>
          </Card>
        </Grow>
      </>
    );
  },
);
