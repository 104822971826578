import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '1rem',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '1rem',
    padding: theme.spacing(1),
  },
  button: {
    display: 'flex',
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1),
  },
  errorMsg: {
    marginTop: -16,
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
}));

export const SubContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: calc(100% - 32px);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const SubTitle = styled.p`
  color: var(--primary-text-color);
  font-size: 1.15rem;
  text-align: left;
  margin: auto;
  margin-left: 0;
`;
