import { withStyles } from '@material-ui/styles';
import { CardMedia, CardHeader, Button as MuiButton } from '@material-ui/core';

export const Thumbnail = withStyles({
  img: {
    maxHeight: 140,
    objectFit: 'cover',
    objectPosition: 'center',
  },
})(CardMedia);

export const Header = withStyles({
  title: {
    fontSize: '1rem',
  },
  subheader: {
    fontSize: '.8rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  content: { overflow: 'hidden' },
})(CardHeader);

export const Button = withStyles({
  root: {
    borderRadius: 16,
    margin: '0 1rem 1rem 1rem',
    float: 'right',
  },
})(MuiButton);
