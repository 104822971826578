export const GET_WEEKLY_TAX = '[weeklyTax] GET';
export const UPDATE_WEEKLY_TAX = '[weeklyTax] UPDATE';
export const CREATE_WEEKLY_TAX = '[weeklyTax] CREATE';
export const DELETE_WEEKLY_TAX = '[weeklyTax] DELETE';
export const UPDATE_OBJECT_WEEKLY_TAX = '[weeklyTax] UPDATE OBJECT WEEKLY_TAX';
// UI
export const SHOW_WEEKLY_TAX_MODAL = '[weeklyTax] SHOW MODAL';
export const HIDE_WEEKLY_TAX_MODAL = '[weeklyTax] HIDE MODAL';
// Spinner
export const UPDATE_WEEKLY_TAX_SUCCESS = '[weeklyTax] Update success';
export const UPDATE_WEEKLY_TAX_ERROR = '[weeklyTax] Update Error';
export const FETCH_WEEKLY_TAX_SUCCESS = '[weeklyTax] Fetch success';
export const FETCH_WEEKLY_TAX_ERROR = '[weeklyTax] Fetch Error';
export const SHOW_WEEKLY_TAX_SPINNER = '[weeklyTax - ui] show spinner';
export const HIDE_WEEKLY_TAX_SPINNER = '[weeklyTax - ui] hide spinner';

export const getWeeklyTax = (id) => ({
  type: GET_WEEKLY_TAX,
  payload: { _id: id },
});

export const createWeeklyTax = (data) => ({
  type: CREATE_WEEKLY_TAX,
  payload: data,
});

export const updateWeeklyTax = (data) => ({
  type: UPDATE_WEEKLY_TAX,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_WEEKLY_TAX_MODAL,
});

export const hideModal = () => ({
  type: HIDE_WEEKLY_TAX_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_WEEKLY_TAX_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_WEEKLY_TAX_SPINNER,
});

export const updateObjectWeeklyTax = (data) => ({
  type: UPDATE_OBJECT_WEEKLY_TAX,
  payload: data,
});
