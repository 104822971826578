import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Order } from '../../interfaces/orders';
import { RootState } from '../../interfaces/redux';
import { getCompanies } from '../../redux/actions/companies';
import { getOrder } from '../../redux/actions/order';
import Spinner from '../../sharedComponents/Spinner';
import SubHeader from '../../sharedComponents/SubHeader';
import { formatOrder } from '../../utils/formatOrder';
import { OrderDetails, SubOrders } from './components';
import { Container } from './styles';

const OrderPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const companies = useSelector(
    (state: RootState) => state.companies?.docs || state.companies,
  );
  const order: Order | undefined = useSelector((state: RootState) =>
    (state.order.id ? formatOrder(state.order, companies) : undefined),
  );

  const loading: boolean = useSelector(
    (state: RootState) => state.ui.orderLoading,
  );

  React.useEffect(() => {
    if (!dispatch) return;
    dispatch(getOrder(params.id));
  }, [dispatch]);

  React.useEffect(() => {
    if ((companies && companies.length) || !dispatch) return;
    dispatch(getCompanies({ listAll: true }));
  }, [dispatch, companies]);

  return (
    <Container>
      <SubHeader
        title={`Pedido${order?.status === 'Cancelado' ? 'Cancelado ' : ''} ${
          order?.orderNumber ? `- #${order.orderNumber}` : ''
        }`}
      />
      {loading ? (
        <Spinner color="#133DA5" />
      ) : (
        <>
          <OrderDetails order={order} />
          <SubOrders order={order} />
        </>
      )}
    </Container>
  );
};

export default OrderPage;
