import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { Icon, IconButton, Grid, MenuItem, FormHelperText } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { KeyboardArrowDown } from '@material-ui/icons';
import Alert from '../../../sharedComponents/Alert';
import Spinner from '../../../sharedComponents/Spinner';
import { getSuppliers } from '../../../redux/actions/suppliers';
import OffersService from '../../../services/Offers';
import {
  Container,
  ModalTitle,
  ButtonContainer,
  StyledPaper,
  FormButton,
  StyledTextField,
  DateTextField,
  Divider,
  MiddleDivider,
  StyledCurrencyField,
  selectStyle,
  labelStyle,
} from './styles';
import {
  getOffer,
  createOffer,
  updateOffer,
} from '../../../redux/actions/offer';
import CompanyOffersImport from './components/CompanyOffersImport';

const OfferModal = ({
  id,
  dispatch,
  offer,
  offerModal,
  handleClose,
  offerSpinner,
}) => {
  const selectClasses = selectStyle();
  const labelClasses = labelStyle();
  const [eanCodeError, setEanCodeError] = useState(false);
  const [supplierError, setSupplierError] = useState(false);
  const [deliveryTimeError, setDeliveryTimeError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [missingFile, setMissingFile] = useState(false);
  const [showFileHelperText, setShowFileHelperText] = useState(false);

  const [data, setData] = useState({
    validSince: new Date(),
    validUntil: null,
    eanCode: '',
    supplierId: '',
    minOrder: '',
    costPrice: '',
    deliveryTime: '',
    deliveryValue: '',
    companyOffers: [],
  });

  const {
    validSince,
    validUntil,
    eanCode,
    supplierId,
    minOrder,
    costPrice,
    deliveryTime,
    deliveryValue,
    companyOffers,
  } = data;

  const fromDateRef = useRef();
  const toDateRef = useRef();

  const suppliers = useSelector((state) => state.suppliers?.docs || []);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getOffer(id));
    }
  }, [id]);

  useEffect(() => {
    if (offer && id !== 'new') {
      setData(offer);
    }
  }, [offer]);

  useEffect(() => {
    if (!offerModal) {
      handleClose();
    }
  }, [offerModal]);

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  const handleDate = (field) => (value) =>
    setData((currentData) => ({ ...currentData, [field]: value }));

  const handleCurrency = (e, value) => {
    const { name } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  const handleFile = (docs) => {
    setData((currentData) => ({ ...currentData, companyOffers: docs }));
  };

  useEffect(() => {
    dispatch(getSuppliers({ listAll: true, active: true }));
  }, []);

  const handleSave = async () => {
    let err = false;
    setEanCodeError(!data.eanCode);
    setSupplierError(!data.supplierId);
    setDeliveryTimeError(!data.deliveryTime);
    setDateError(data.validUntil && data.validUntil < data.validSince);
    const isEanValid = await OffersService.checkForEan(data.eanCode);
    if (!isEanValid) setEanCodeError(true);
    const fileError = missingFile && id === 'new';
    if (fileError) setShowFileHelperText(true);
    err =
      fileError ||
      !isEanValid ||
      !data.eanCode ||
      !data.supplierId ||
      !data.deliveryTime ||
      (data.validUntil && data.validUntil < data.validSince);

    if (!err) {
      const response = await Alert();
      if (response?.confirm) {
        if (id === 'new') {
          dispatch(
            createOffer({
              validSince,
              validUntil,
              eanCode,
              supplierId,
              minOrder: parseInt(minOrder, 10) || undefined,
              costPrice: parseFloat(costPrice) || undefined,
              deliveryTime: parseInt(deliveryTime, 10),
              deliveryValue: parseFloat(deliveryValue) || undefined,
              companyOffers,
            }),
          );
        } else {
          dispatch(
            updateOffer({
              id,
              ...data,
              minOrder: parseInt(minOrder, 10) || undefined,
              costPrice: parseFloat(costPrice) || undefined,
              deliveryTime: parseInt(deliveryTime, 10),
              deliveryValue: parseFloat(deliveryValue) || undefined,
            }),
          );
        }
      }
    }
  };

  return (
    <>
      {offerSpinner && <Spinner overlay />}
      <StyledPaper
        elevation={2}
        style={{ maxWidth: '90vw', height: 'fit-content' }}
      >
        <Container style={{ opacity: offerSpinner ? 0.5 : 1, maxWidth: 300 }}>
          <ModalTitle>
            {id === 'new' ? 'Adicionar' : 'Editar'} Oferta
          </ModalTitle>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <DateTimePicker
                ref={fromDateRef}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy HH:mm"
                ampm={false}
                label="Válido desde"
                value={validSince}
                name="validSince"
                onChange={handleDate('validSince')}
                InputLabelProps={{
                  hidden: true,
                  style: {
                    color: 'var(--primary-text-color)',
                    backgroundColor: 'transparent',
                  },
                }}
                fullWidth
                PopoverProps={{ anchorEl: () => fromDateRef.current }}
                inputVariant="outlined"
                autoOk
                TextFieldComponent={(props) => (
                  <DateTextField
                    {...props}
                    ref={fromDateRef}
                    InputProps={{
                      startAdornment: (
                        <IconButton style={{ paddingLeft: 0 }}>
                          <Icon
                            style={{ color: '#192D4D' }}
                            className="material-icons-outlined"
                          >
                            calendar_today
                          </Icon>
                        </IconButton>
                      ),
                      endAdornment: (
                        <IconButton style={{ paddingRight: 0 }}>
                          <Icon style={{ color: '#192D6D' }}>expand_more</Icon>
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateTimePicker
                ref={toDateRef}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy HH:mm"
                ampm={false}
                label="Válido até"
                error={dateError}
                helperText={
                  dateError && 'A data final não pode ser anterior à inicial.'
                }
                value={validUntil}
                name="validUntil"
                emptyLabel="dd/mm/yyyy"
                onChange={handleDate('validUntil')}
                InputLabelProps={{
                  hidden: true,
                  style: {
                    color: 'var(--primary-text-color)',
                    backgroundColor: 'transparent',
                  },
                }}
                fullWidth
                PopoverProps={{ anchorEl: () => toDateRef.current }}
                inputVariant="outlined"
                autoOk
                TextFieldComponent={(props) => (
                  <DateTextField
                    {...props}
                    ref={toDateRef}
                    InputProps={{
                      startAdornment: (
                        <IconButton style={{ paddingLeft: 0 }}>
                          <Icon
                            style={{ color: '#192D6D' }}
                            className="material-icons-outlined"
                          >
                            calendar_today
                          </Icon>
                        </IconButton>
                      ),
                      endAdornment: (
                        <IconButton style={{ paddingRight: 0 }}>
                          <Icon style={{ color: '#192D6D' }}>expand_more</Icon>
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                label={!eanCode ? 'Código EAN' : ''}
                variant="outlined"
                value={eanCode || ''}
                name="eanCode"
                error={eanCodeError}
                helperText={eanCodeError && 'Informe um Código EAN válido'}
                InputLabelProps={{ shrink: false, classes: labelClasses }}
                InputProps={{
                  startAdornment: (
                    <Icon
                      style={{ color: '#192D4D', marginRight: '0.7rem' }}
                      className="material-icons-outlined"
                    >
                      qr_code_2
                    </Icon>
                  ),
                }}
                fullWidth
                onChange={handleEdit}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                label={!supplierId ? 'Fornecedor' : ''}
                variant="outlined"
                value={supplierId || ''}
                name="supplierId"
                error={supplierError}
                helperText={supplierError && 'Fornecedor é obrigatório!'}
                select
                SelectProps={{
                  IconComponent: KeyboardArrowDown,
                  classes: selectClasses,
                }}
                InputLabelProps={{ shrink: false, classes: labelClasses }}
                InputProps={{
                  startAdornment: (
                    <Icon
                      style={{ color: '#192D4D', marginRight: '0.7rem' }}
                      className="material-icons-outlined"
                    >
                      local_shipping
                    </Icon>
                  ),
                }}
                fullWidth
                onChange={handleEdit}
              >
                {suppliers.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.corporate_name}
                  </MenuItem>
                ))}
              </StyledTextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                label={!minOrder ? 'Pedido mínimo' : ''}
                variant="outlined"
                value={minOrder || ''}
                name="minOrder"
                InputProps={{
                  startAdornment: (
                    <Icon
                      style={{ color: '#192D4D', marginRight: '0.7rem' }}
                      className="material-icons-outlined"
                    >
                      assignment
                    </Icon>
                  ),
                }}
                InputLabelProps={{ shrink: false, classes: labelClasses }}
                outputFormat="string"
                onChange={handleEdit}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                label={!deliveryTime ? 'Prazo de entrega (em dias)' : ''}
                variant="outlined"
                value={deliveryTime || ''}
                name="deliveryTime"
                error={deliveryTimeError}
                helperText={
                  deliveryTimeError && 'Prazo de entrega é obrigatório!'
                }
                InputLabelProps={{ shrink: false, classes: labelClasses }}
                InputProps={{
                  startAdornment: (
                    <Icon
                      style={{ color: '#192D4D', marginRight: '0.7rem' }}
                      className="material-icons-outlined"
                    >
                      schedule
                    </Icon>
                  ),
                }}
                outputFormat="string"
                onChange={handleEdit}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledCurrencyField
                placeholder={!costPrice ? 'Preço de custo' : ''}
                variant="outlined"
                value={costPrice || ''}
                name="costPrice"
                currencySymbol={
                  <Icon
                    style={{ color: '#192D4D', marginRight: '0.7rem' }}
                    className="material-icons-outlined"
                  >
                    paid
                  </Icon>
                }
                outputFormat="number"
                decimalCharacter=","
                digitGroupSeparator="."
                onChange={handleCurrency}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledCurrencyField
                placeholder={!deliveryValue ? 'Valor da entrega' : ''}
                variant="outlined"
                value={deliveryValue || ''}
                name="deliveryValue"
                currencySymbol={
                  <Icon
                    style={{ color: '#192D4D', marginRight: '0.7rem' }}
                    className="material-icons-outlined"
                  >
                    paid
                  </Icon>
                }
                outputFormat="number"
                decimalCharacter=","
                digitGroupSeparator="."
                onChange={handleCurrency}
                fullWidth
              />
            </Grid>
          </Grid>
          <MiddleDivider />
          <CompanyOffersImport
            setCompanyOffers={handleFile}
            setMissingFile={setMissingFile}
          />
          <FormHelperText error={showFileHelperText}>
            {showFileHelperText && 'A planilha é obrigatória.'}
          </FormHelperText>
          <ButtonContainer>
            <FormButton
              color="secondary"
              onClick={handleClose}
              variant="contained"
            >
              Cancelar
            </FormButton>
            <FormButton
              color="primary"
              onClick={handleSave}
              variant="contained"
            >
              Salvar
            </FormButton>
          </ButtonContainer>
        </Container>
      </StyledPaper>
    </>
  );
};

export default connect((state) => ({
  offer: state.offer,
  modules: state.modules,
  offerModal: state.ui.offerModal,
  offerSpinner: state.ui.offerLoading,
}))(OfferModal);
