import {
  UPDATE_PASSWORD,
  SUCCESS_UPDATE_PASSWORD,
  ERROR_UPDATE_PASSWORD,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/password';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify/index';

const URL = (id) => `users/${id}/update-password`;

export function updatePasswordFlow({ dispatch }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === UPDATE_PASSWORD) {
        const { id, password, currentPassword } = action.payload;
        dispatch(
          apiRequest(
            'POST',
            URL(id),
            { password, currentPassword },
            SUCCESS_UPDATE_PASSWORD,
            ERROR_UPDATE_PASSWORD,
          ),
        );
        dispatch(showSpinner());
      }
    };
  };
}

export function processUpdatePassword({ dispatch }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === SUCCESS_UPDATE_PASSWORD) {
        dispatch(hideModal());
        dispatch(hideSpinner());
        Toastify.addSuccess('Senha atualizada com sucesso');
      } else if (action.type === ERROR_UPDATE_PASSWORD) {
        Toastify.addError('Erro ao atualizar senha');
        console.error(action.payload);
        dispatch(hideSpinner());
      }
    };
  };
}

export const passwordMdl = [updatePasswordFlow, processUpdatePassword];
