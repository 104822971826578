import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  row: {
    padding: 0,
    '& > *': {
      borderBottom: 'unset',
    },
  },
  cell: {
    fontSize: 12,
    padding: '0px 15px',
  },
}));
