import { store } from '../../redux/store';
import { obj2QueryString, userIsAdmin } from '../../utils/globalFunctions';
import Service from '../Service';

async function fetchAggregatedExtracts(data) {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const queryString = obj2QueryString({ ...data, isAdmin });
  const apiService = new Service({
    route: `/extracts/aggregated?${queryString}`,
    apiV2: true,
  });
  const response = await apiService.store({});
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: response.data.status,
  };
}

async function fetchCashiers(data) {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const queryString = obj2QueryString({ ...data, isAdmin });
  const apiService = new Service({
    route: `/cashiers?${queryString}`,
    apiV2: true,
  });
  const response = await apiService.get({});
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: 200,
  };
}

async function fetchAvailablePos(data) {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const queryString = obj2QueryString({ ...data, isAdmin });
  const apiService = new Service({
    route: `/paymentMachines/actives?${queryString}`,
    apiV2: true,
  });
  const response = await apiService.get({});
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: 200,
  };
}

async function updateCashier(id, data) {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const apiService = new Service({
    route: '/cashiers',
    apiV2: true,
  });
  const response = await apiService.put(id, { data, isAdmin });
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: response.data.status,
  };
}

async function createCashier(data) {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const apiService = new Service({
    route: '/cashiers',
    apiV2: true,
  });
  const response = await apiService.post({ ...data, isAdmin });
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: response.data.status,
  };
}

export default {
  fetchAggregatedExtracts,
  fetchCashiers,
  fetchAvailablePos,
  updateCashier,
  createCashier,
};
