import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  TextField,
  FormGroup,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '../../../sharedComponents/Alert/index';
import Spinner from '../../../sharedComponents/Spinner/index';
import { Container, InputContent, ModalTitle, ButtonContainer } from './styles';

import {
  createPaymentMachine,
} from '../../../redux/actions/paymentMachine';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  datePickerContainer: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formButton: {
    margin: 2,
  },
}));

const PaymentMachineModal = ({
  dispatch,
  paymentMachineModal,
  handleClose,
  paymentMachineSpinner,
}) => {
  const classes = useStyles();
  const [serialNumber, setSerialNumber] = useState('');
  const [serialNumberError, setSerialNumberError] = useState(false);

  useEffect(() => {
    if (!paymentMachineModal) {
      handleClose();
    }
  }, [paymentMachineModal]);

  const handleSave = async () => {
    setSerialNumberError(!serialNumber);
    if (!serialNumber) {
      return;
    }

    const response = await Alert();
    if (response && response.confirm) {
      dispatch(
        createPaymentMachine({
          serialNumber,
        }),
      );
    }
  };

  return (
    <>
      {paymentMachineSpinner && <Spinner overlay />}
      <Container>
        <Paper
          elevation={2}
          style={{
            opacity: paymentMachineSpinner ? 0.5 : 1,
            minWidth: '60vw',
            padding: 20,
          }}
          fullWidth
        >
          <ModalTitle>
            Adicionar registro de máquina de cartão
          </ModalTitle>
          <FormGroup>
            <InputContent>
              <TextField
                id="outlined-password-input"
                label="Número de série*"
                type="text"
                error={serialNumberError}
                helperText={
                  serialNumberError && 'Número de série é obrigatório'
                }
                variant="outlined"
                value={serialNumber}
                onChange={(e) => setSerialNumber(e.target.value)}
                fullWidth
              />
            </InputContent>
            <ButtonContainer>
              <Button
                color="secondary"
                onClick={handleClose}
                variant="contained"
                className={classes.formButton}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={handleSave}
                variant="contained"
                className={classes.formButton}
              >
                Salvar
              </Button>
            </ButtonContainer>
          </FormGroup>
        </Paper>
      </Container>
    </>
  );
};

export default connect((state) => ({
  paymentMachineModal: state.ui.paymentMachineModal,
  paymentMachineSpinner: state.ui.paymentMachineLoading,
  allRoles: state.roles.docs,
  loggedUser: state.loggedUser,
}))(PaymentMachineModal);
