import { format } from 'date-fns';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import ExtractProvider from '../../providers/Extracts';
import { getCompanies } from '../../redux/actions/companies';
import { getExtracts } from '../../redux/actions/extracts';
import { getPaymentMachines } from '../../redux/actions/paymentMachines';
import { getPicPayOperators } from '../../redux/actions/picPayOperators';
import { download } from '../../services/Download';
import CompaniesService from '../../services/Companies';
import Toastify from '../../utils/Toastify';
import SubHeader from '../../sharedComponents/SubHeader';
import {
  getCompanyDocumentFilter,
  userIsAdmin,
} from '../../utils/globalFunctions';
import FiltersModal from '../Modals/FiltersModal';
import ActionButtons from './components/ActionButtons';
import FilterChip from './components/FilterChip';
import SectionContent from './components/SectionContent';
import { Container } from './styles';

const numberFormatter = new Intl.NumberFormat('pt-BR');
const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const Extracts = ({
  extracts,
  totalDocs,
  totalValue,
  extractsLoading,
  paymentMachines,
  picPayOperators,
  loggedUser,
}) => {
  const [extractsFormatted, setExtractsFormatted] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [paymentKind, setPaymentKind] = useState([]);
  const [serialNumber, setSerialNumber] = useState('');
  const [dateGte, setDateGte] = useState(startOfDay(new Date()));
  const [dateLte, setDateLte] = useState(endOfDay(new Date()));
  const [salesFunction, setSalesFunction] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [extractProvider, setExtractProvider] = useState(null);
  const [company, setCompany] = useState('');
  const [localLoading, setLocalLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanies({ listAll: true }));
    dispatch(
      getPaymentMachines({
        limit: 1000,
        companyDocumentIn: getCompanyDocumentFilter(company, loggedUser),
      }),
    );
    dispatch(
      getPicPayOperators({
        limit: 1000,
        companyDocumentIn: getCompanyDocumentFilter(company, loggedUser),
      }),
    );
    CompaniesService.fetchAllCompanies()
      .then((response) => {
        setAllCompanies(response.data);
      })
      .catch((error) => {
        Toastify.addError(
          'Ocorreu um erro durante o carregamento dos dados, por favor recarregue a página e tente novamente.',
          error,
        );
      });
  }, []);

  useEffect(() => {
    dispatch(
      getExtracts({
        page: page + 1,
        limit,
        companyDocuments: getCompanyDocumentFilter(company, loggedUser),
        value: searchValue,
        salesFunction,
        paymentKind: paymentKind.map((item) => parseInt(item, 10)),
        serialNumber,
        dateGte: dateGte.toISOString(),
        dateLte: dateLte.toISOString(),
      }),
    );
    setLocalLoading(true);
  }, [page, limit]);

  useEffect(() => {
    if (
      !extractsLoading
      && allCompanies?.length
      && (paymentMachines?.length
          || picPayOperators?.length)
    ) {
      setExtractProvider(
        ExtractProvider({
          extracts,
          companies: allCompanies,
          paymentMachines,
          picPayOperators,
        }),
      );
    }
  }, [
    extractsLoading,
    extracts,
    allCompanies,
    paymentMachines,
    picPayOperators,
  ]);

  useEffect(() => {
    if (extractProvider) {
      const formattedItems = extractProvider.getFormattedExtracts();
      setExtractsFormatted(formattedItems);
      setLocalLoading(false);
    }
  }, [extractProvider]);

  const chipList = [
    {
      key: 'refDate',
      value: `${format(dateGte, 'dd/MM/yyyy HH:mm')} - ${format(
        dateLte,
        'dd/MM/yyyy HH:mm',
      )}`,
      dateField: true,
      icon: 'calendar_today',
    },
    {
      key: 'paymentKind',
      value: paymentKind,
      icon: 'account_balance_wallet',
      arrayField: true,
    },
    {
      key: 'searchValue',
      value: searchValue,
      icon: 'paid',
    },
    {
      key: 'salesFunction',
      value: salesFunction,
      icon: 'shopping_cart',
    },
    {
      key: 'serialNumber',
      value: serialNumber,
      icon: 'print',
    },
    { key: 'company', value: company?.name, icon: 'place' },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSearch = (data) => {
    setDateGte(data.dateGte);
    setDateLte(data.dateLte);
    setPaymentKind(data.paymentKind);
    setSearchValue(data.searchValue);
    setSalesFunction(data.salesFunction);
    setSerialNumber(data.serialNumber);
    setCompany(data.company);
    dispatch(
      getExtracts({
        page: 1,
        limit,
        companyDocuments: getCompanyDocumentFilter(data.company, loggedUser),
        value: data.searchValue,
        salesFunction: data.salesFunction,
        paymentKind: data.paymentKind.map((item) => parseInt(item, 10)),
        serialNumber: data.serialNumber,
        dateGte: data.dateGte.toISOString(),
        dateLte: data.dateLte.toISOString(),
      }),
    );
    handleCloseModal();
  };

  const handleClear = () => {
    setDateGte(startOfDay(new Date()));
    setDateLte(endOfDay(new Date()));
    setSearchValue(null);
    setPaymentKind([]);
    setSerialNumber(null);
    setSalesFunction(null);
    setCompany('');
    dispatch(
      getPaymentMachines({
        limit: 1000,
        companyDocumentIn: getCompanyDocumentFilter(null, loggedUser),
      }),
    );
    dispatch(
      getPicPayOperators({
        limit: 1000,
        companyDocumentIn: getCompanyDocumentFilter(null, loggedUser),
      }),
    );
  };

  const downloadCallback = () =>
    download(
      'extracts',
      {
        companyDocuments: getCompanyDocumentFilter(company, loggedUser),
        value: searchValue,
        serialNumber,
        salesFunction,
        paymentKind: paymentKind.map((item) => parseInt(item, 10)),
        dateGte: dateGte.toISOString(),
        dateLte: dateLte.toISOString(),
        isAdmin: userIsAdmin(loggedUser),
      },
      {
        filename: `extracts-${new Date().toISOString()}`,
        fileExtension: 'xlsx',
        callback: (_extracts) => {
          const tempExtractProvider = ExtractProvider({
            extracts: _extracts,
            companies: allCompanies,
            paymentMachines,
            picPayOperators,
          });
          const formattedItems = tempExtractProvider.getFormattedExtracts();
          return formattedItems.map((item) => ({
            'Código único': item.id,
            'Data e hora': item.referenceDateTime,
            'Valor total (R$)': item.value.replace(/\./, ','),
            'Tipo de pagamento': item.paymentKind,
            Status: item.paymentStatus,
            POS: item.serialNumber || item.picpayOperator,
            Padaria: item.companyName,
            'Função de Vendas': item.paymentMachineFunction,
          }));
        },
      },
    );

  const handleRemoveFilter = ({ key, arrayField, dateField }) => {
    let value = '';
    if (arrayField) value = [];
    if (dateField) value = new Date();
    const removedField = {};
    if (key !== 'refDate') removedField[key] = value;
    else {
      removedField.dateGte = startOfDay(new Date());
      removedField.dateLte = endOfDay(new Date());
    }
    handleSearch({
      dateGte,
      dateLte,
      paymentKind,
      searchValue,
      salesFunction,
      serialNumber,
      company,
      ...removedField,
    });
  };

  function hasValue(value) {
    return (
      (!Array.isArray(value) && !!value) ||
      (Array.isArray(value) && value.length !== 0)
    );
  }

  return (
    <>
      <Container>
        <SubHeader title="Extratos" breakOnSmallScreens>
          <ActionButtons
            loggedUser={loggedUser}
            refDate={dateGte && dateLte}
            downloadCallback={downloadCallback}
            handleClear={handleClear}
            handleOpenModal={handleOpenModal}
          />
        </SubHeader>

        <FiltersModal
          open={openModal}
          dateGte={dateGte}
          dateLte={dateLte}
          paymentKind={paymentKind}
          searchValue={searchValue}
          handleSearch={handleSearch}
          serialNumber={serialNumber}
          salesFunction={salesFunction}
          handleCloseModal={handleCloseModal}
          company={company}
        />

        {chipList.map(
          (item) =>
            hasValue(item.value) && (
              <FilterChip item={item} onRemoveFilter={handleRemoveFilter} />
            ),
        )}

        <SectionContent
          title="Transações"
          subtitle={`${numberFormatter.format(
            totalDocs,
          )} transações - Valor bruto total: ${currencyFormatter.format(
            totalValue,
          )}`}
          loading={extractsLoading || localLoading}
          columnsMap={{
            'Código único': 'id',
            'Data e hora': 'referenceDateTime',
            'Valor total': 'formattedValue',
            'Tipo de pagamento': 'paymentKind',
            Status: 'paymentStatus',
            POS: ['serialNumber', 'picpayOperator'],
            Padaria: 'companyName',
            'Função de Vendas': 'paymentMachineFunction',
          }}
          rows={extractsFormatted}
          totalValue={totalValue}
          total={totalDocs}
          limit={limit}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedUser: state.loggedUser,
  extractsLoading: state.ui.extractsLoading,
  extracts: state.extracts?.docs,
  totalDocs: state.extracts?.total || 0,
  totalValue: state.extracts?.totalValue || 0,
  paymentMachines: state.paymentMachines?.docs,
  picPayOperators: state.picPayOperators?.docs,
});

export default connect(mapStateToProps)(Extracts);
