import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import React from 'react';
import NestedMenuItem from 'material-ui-nested-menu-item';

const useStyles = makeStyles((theme) => ({
  button: {
    height: 40,
    borderRadius: '1.5rem',
    padding: '1rem',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 20,
    },
  },
}));

const MenuButton = ({
  children,
  CustomButton,
  menuItems,
  endIcon,
  buttonStyle,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {CustomButton ? (
        <CustomButton onClick={handleClick}>{children}</CustomButton>
      ) : (
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          color="secondary"
          variant="contained"
          size="small"
          className={classes.button}
          endIcon={endIcon || <Publish />}
          style={buttonStyle}
          fullWidth
        >
          {children}
        </Button>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {menuItems.map((item) => {
          if (item.subItems?.length) {
            return (
              <NestedMenuItem
                label={item.label}
                parentMenuOpen={open}
                onClick={() => {
                  item.onClick && item.onClick();
                }}
              >
                {item.subItems.map((subItem) => (
                  <MenuItem
                    key={`simple-sub-menu-item--${subItem.label}`}
                    onClick={() => {
                      subItem.onClick && subItem.onClick();
                      handleClose();
                    }}
                  >
                    {subItem.label}
                  </MenuItem>
                ))}
              </NestedMenuItem>
            );
          }
          return (
            <MenuItem
              key={`simple-menu-item--${item.label}`}
              onClick={() => {
                item.onClick && item.onClick();
                handleClose();
              }}
              menuItems={item.menuItems}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default MenuButton;
