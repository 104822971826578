export const GET_PRODUCTS = '[products] GET';
export const GET_MARKET_STRUCTURES = '[marketStructures] GET';
export const GET_PRODUCT_SUMMARY = '[productSummary] GET';
export const FETCH_PRODUCTS_SUCCESS = '[products] Fetch success';
export const FETCH_PRODUCTS_ERROR = '[products] Fetch Error';
export const FETCH_MARKET_STRUCTURES_SUCCESS = '[marketStructures] Fetch success';
export const FETCH_MARKET_STRUCTURES_ERROR = '[marketStructures] Fetch Error';
export const UPDATE_PRODUCTS = '[products] Update';
export const UPDATE_MARKET_STRUCTURES = '[marketStructures] Update';
export const SHOW_PRODUCTS_SPINNER = '[products - ui] show spinner';
export const HIDE_PRODUCTS_SPINNER = '[products - ui] hide spinner';
export const SHOW_MARKET_STRUCTURE_SPINNER = '[market structure - ui] show spinner';
export const HIDE_MARKET_STRUCTURE_SPINNER = '[market structure - ui] hide spinner';

export const getProducts = (filter) => ({
  type: GET_PRODUCTS,
  payload: filter,
});

export const getMarketStructures = (filter) => ({
  type: GET_MARKET_STRUCTURES,
  payload: filter,
});

export const getProductSummary = (filter) => ({
  type: GET_PRODUCT_SUMMARY,
  payload: filter,
});

export const updateProducts = (data) => ({
  type: UPDATE_PRODUCTS,
  payload: data,
});

export const updateMarketStructures = (data) => ({
  type: UPDATE_MARKET_STRUCTURES,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_PRODUCTS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PRODUCTS_SPINNER,
});
