import React, { useEffect, useState } from 'react';
import {
  Button,
  Icon,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import DropZone from '../../../../../sharedComponents/DropZone';
import { download } from '../../../../../services/Download';
import OffersService from '../../../../../services/Offers';
import { SubContainer, Header, SubTitle, useStyles } from './styles';
import { userIsAdmin } from '../../../../../utils/globalFunctions';
import Toastify from '../../../../../utils/Toastify/index';
import Spinner from '../../../../../sharedComponents/Spinner/index';

function CompanyOffersImport({
  setCompanyOffers,
  setMissingFile,
}) {
  const classes = useStyles();
  const loggedUser = useSelector((state) => state.loggedUser);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    setMissingFile(!file);
  }, [file]);

  const handleDownload = () => {
    download(
      'offers/template',
      { isAdmin: userIsAdmin(loggedUser) },
      {
        responseType: 'blob',
        filename: 'Template_Importacao_Ofertas_1.0.0',
        fileExtension: 'xlsx',
        forceDefaultDownloaded: true,
      },
    );
  };

  // eslint-disable-next-line consistent-return
  const handleUpload = async (newFile) => {
    setFile(newFile);
    if (!newFile) return;
    setUploading(true);

    OffersService.importFile(newFile)
      .then(({ data }) => {
        setCompanyOffers(data.docs);
        setUploading(false);
      })
      .catch((error) => {
        Toastify.addError(
          'O arquivo contém um erro ou não pôde ser lido corretamente.',
          error,
        );
        setFile(null);
        setUploading(false);
      });
  };

  return (
    <>
      <Header>
        <SubTitle>
          Importar planilha de ofertas
        </SubTitle>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Icon>file_download</Icon>}
          onClick={handleDownload}
        >
          Baixar Template
        </Button>
      </Header>
      <div className={classes.root}>
        {uploading && <Spinner overlay />}
        <SubContainer>
          <center>
            <DropZone file={file} onChangeFile={handleUpload} format="xlsx" />
          </center>
        </SubContainer>
      </div>
    </>
  );
}

export default CompanyOffersImport;
