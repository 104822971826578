/* eslint-disable indent */
import {
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_ERROR,
  UPDATE_LOGGED_USER,
  LOGIN,
  GET_LOGGED_USER,
  REFRESH_LOGIN_SUCCESS,
  showLoginSpinner,
  hideLoginSpinner,
  updateLoggedUser as updateObject,
} from '../actions/loggedUser';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';
import { sessionStorageKey } from '../../config/consts';

import { api, apiV2 } from '../../services/Api';

const URL = 'login';

export const login = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === LOGIN) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        FETCH_LOGIN_SUCCESS,
        FETCH_LOGIN_ERROR,
      ),
    );
    dispatch(showLoginSpinner());
  }
};

export const getLoggedUserFlow = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === GET_LOGGED_USER) {
    const { loggedUser } = getState();
    dispatch(
      apiRequest(
        'GET-id',
        'users',
        { id: loggedUser.id },
        REFRESH_LOGIN_SUCCESS,
        FETCH_LOGIN_ERROR,
        {},
        true,
      ),
    );
  }
};

export const updateLoggedUser = () => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_LOGGED_USER) {
    const { token } = localStorage.getItem(sessionStorageKey)
      ? JSON.parse(localStorage.getItem(sessionStorageKey))
      : {};
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    apiV2.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

export const processLoginCollection = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === FETCH_LOGIN_SUCCESS) {
    dispatch(updateLoggedUser({ ...action.payload, authenticated: true }));
    Toastify.addSuccess('Bem-vindo ao Painel BakeryTech');
    dispatch(hideLoginSpinner());
  } else if (action.type === REFRESH_LOGIN_SUCCESS) {
    if (action.payload.id) {
      dispatch(
        updateObject({
          ...action.payload,
          authenticated: true,
          token: getState().loggedUser.token,
        }),
      );
    }
  } else if (action.type === FETCH_LOGIN_ERROR) {
    if (
      action.payload === 'Please, check your email inbox and copy the code.'
    ) {
      Toastify.addWarning(
        'Por favor, cheque o seu email e copie o código enviado.',
      );
      dispatch(hideLoginSpinner());
    } else {
      Toastify.addError(
        'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
      );
      console.error(action.payload);
      dispatch(hideLoginSpinner());
    }
  }
};

export const loggedUserMdl = [
  login,
  updateLoggedUser,
  getLoggedUserFlow,
  processLoginCollection,
];
