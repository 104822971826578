import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const defaultOptions = {
  legend: {
    display: false,
  },
  responsive: true,
  maintainAspectRatio: false,
};

const DoughnutChart = ({ data, options }) => (
  <>
    <Doughnut data={data} options={{ ...defaultOptions, ...options }} />
  </>
);

export default DoughnutChart;
