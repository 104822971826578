import React, { useState } from 'react';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { Paper, Button, FormGroup, TextField, Grid } from '@material-ui/core';
import {
  Container,
  ModalTitle,
  ButtonContainer,
  InputContent,
  SubTitle,
  useStyles,
} from './styles';

const EditModal = ({
  newCommissionData,
  commissionData,
  handleClose,
  handleSave,
}) => {
  const classes = useStyles();
  const [validSince, setValidSince] = useState(moment(new Date()).startOf('day'));

  return (
    <Container>
      <Paper
        elevation={2}
        style={{
          minWidth: '60vw',
          padding: 20,
        }}
        fullWidth
      >
        <FormGroup>
          <ModalTitle>Editar Comissionamento</ModalTitle>
          <SubTitle>Taxa Atual</SubTitle>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={6}>
              <InputContent>
                <TextField
                  label="Taxa Atual"
                  type="text"
                  variant="outlined"
                  value={commissionData.commissionValue}
                  disabled
                />
              </InputContent>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Desde"
                inputVariant="outlined"
                value={commissionData.validSince}
                ampm={false}
                format="dd/MM/yyyy"
                disabled
              />
            </Grid>
          </Grid>
          <SubTitle>Nova Taxa</SubTitle>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Grid item xs={12} sm={6}>
              <InputContent>
                <TextField
                  label="Nova taxa"
                  type="text"
                  variant="outlined"
                  value={newCommissionData.commissionValue}
                  disabled
                />
              </InputContent>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Data Para início da Mudança de Taxa"
                inputVariant="outlined"
                value={validSince}
                onChange={setValidSince}
                format="dd/MM/yyyy"
                helperText="Com base no horário de Brasília (GMT-03)"
              />
            </Grid>
          </Grid>
          <ButtonContainer>
            <Button
              color="secondary"
              onClick={handleClose}
              variant="contained"
              className={classes.formButton}
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              onClick={() => handleSave(newCommissionData, validSince)}
              variant="contained"
              className={classes.formButton}
              disabled={false}
            >
              Salvar
            </Button>
          </ButtonContainer>
        </FormGroup>
      </Paper>
    </Container>
  );
};

export default EditModal;
