import React, { useState, useEffect } from 'react';
import {
  FormControl,
  Grid,
  IconButton,
  ListItemIcon,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import {
  ChevronLeft as PrevIcon,
  ChevronRight as NextIcon,
  Close as ClearIcon,
  InfoOutlined,
} from '@material-ui/icons';
import {
  AiOutlineSortAscending as ASCIcon,
  AiOutlineSortDescending as DESCIcon,
} from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMarketStructures,
  getProductSummary,
} from '../../redux/actions/products';
import Container from '../../sharedComponents/Container';
import OverviewCard from '../../sharedComponents/OverviewCard';
import SectionHeader from '../../sharedComponents/SectionHeader';
import Row from './components/Row';
import {
  ButtonContainer,
  Divider,
  FilterContainerBottom,
  FilterContainerTop,
  HeaderTableCell,
  Pagination,
  SelectCompanyText,
  SelectTitle,
  StyledLabel,
  StyledSelect,
  StyledSelect2,
  SubTitle,
  SubtitleContainer,
  TableContainer,
  TextContainer,
  Title,
  useStyles,
} from './styles';
import { NotableProductsProvider } from '../../providers/NotableProducts';
import { userIsAdmin } from '../../utils/globalFunctions';
import StatelessCompanySelector from '../../sharedComponents/StatelessCompanySelector';
import { getCompanies } from '../../redux/actions/companies';
import SectionContent from '../../sharedComponents/SectionContent';
import Spinner from '../../sharedComponents/Spinner';

const orderList = [
  {
    label: 'Nome do Produto',
    order: 'name',
    orderDirection: 'ASC',
  },
  {
    label: 'Última Venda',
    order: 'last_sale_value_cents',
    orderDirection: 'ASC',
  },
  {
    label: 'Última Venda',
    order: 'last_sale_value_cents',
    orderDirection: 'DESC',
  },
  {
    label: 'Média Supermercado',
    order: 'supermarket_mean_value_cents',
    orderDirection: 'ASC',
  },
  {
    label: 'Média Supermercado',
    order: 'supermarket_mean_value_cents',
    orderDirection: 'DESC',
  },
  {
    label: '% vs Média do Supermercado',
    order: 'percentage_vs_supermarket_mean',
    orderDirection: 'ASC',
  },
  {
    label: '% vs Média do Supermercado',
    order: 'percentage_vs_supermarket_mean',
    orderDirection: 'DESC',
  },
];

const skeletonProps = {
  style: {
    minHeight: 121,
    borderRadius: 24,
  },
};

const NotableProducts = () => {
  const [page, setPage] = useState(1);
  const [marketStructure, setMarketStructure] = useState('');
  const [orderIndex, setOrderIndex] = useState(0);
  const [bakeryRef, setBakeryRef] = useState('');
  const [supermarketRef, setSupermarketRef] = useState('');
  const [loadingCompetitors, setLoadingCompetitors] = useState(true);
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const loggedUser = useSelector((state) => state.loggedUser);
  const companies = useSelector(
    (state) => state.companies.docs || state.companies,
  );
  const marketStructures = useSelector((state) => state.marketStructures);
  const baseLoading = useSelector(
    (state) =>
      state.ui.marketResourcesLoading ||
      !state.marketStructures?.length ||
      state.ui.companiesLoading,
  );
  const loadingProducts = useSelector((state) => state.ui.productsLoading);
  const allCompanies =
    (userIsAdmin(loggedUser) ? companies : loggedUser.companies) || [];

  const classes = useStyles();
  const isTablet = useMediaQuery('(max-width:1023px)');
  const gridSpacing = isTablet ? 2 : 3;
  const [bakeries, setBakeries] = useState([]);
  const [supermarkets, setSupermarkets] = useState([]);
  const [company, setCompany] = useState(allCompanies[0]);

  useEffect(() => {
    if (userIsAdmin(loggedUser)) {
      if (!companies?.length) {
        dispatch(getCompanies({ listAll: true }));
      } else {
        setCompany(companies[0]);
      }
    }
  }, [companies]);

  useEffect(() => {
    setLoadingCompetitors(true);
    NotableProductsProvider.fetchCompetitors()
      .then((data) => {
        setBakeries(data.bakeries);
        setSupermarkets(data.supermarkets);
        setBakeryRef(data.bakeries[0]?.document);
        setSupermarketRef(data.supermarkets[0]?.document);
      })
      .finally(() => {
        setLoadingCompetitors(false);
      });
  }, []);

  useEffect(() => {
    dispatch(getMarketStructures());
  }, []);

  useEffect(() => {
    if (!company?.id) return;

    if (loadingCompetitors) return;

    dispatch(
      getProductSummary({
        filter: {
          page,
          limit: 150,
          order: orderList[orderIndex]?.order,
          orderDirection: orderList[orderIndex]?.orderDirection,
          bakery_ref: bakeryRef,
          supermarket_ref: supermarketRef,
          market_structure: marketStructure,
          company: company.id,
        },
      }),
    );
  }, [page, orderIndex, bakeryRef, supermarketRef, marketStructure, company, loadingCompetitors]);

  return (
    <Container flex id="section-container">
      <SectionHeader
        title={
          <span>
            Produtos Notáveis
            <Tooltip title="Informações">
              <IconButton
                size="small"
                className={classes.infoButton}
                style={{ marginLeft: '0.5rem' }}
              >
                <InfoOutlined />
              </IconButton>
            </Tooltip>
          </span>
        }
        subtitle="Em relação aos últimos 30 dias"
        hideDatePicker
        hideCompanySelector
      >
        <StatelessCompanySelector
          style={{ minWidth: 'auto' }}
          value={company?.id}
          companies={allCompanies}
          onChange={(e) => {
            const companyToSelect = allCompanies.find(
              (_company) => _company?.id === e.target.value,
            );
            setCompany(companyToSelect);
          }}
          disableClearing
        />
      </SectionHeader>
      <SectionContent isLoading={baseLoading || loadingCompetitors}>
        {!company?.id ? (
          <SelectCompanyText>Selecione uma unidade</SelectCompanyText>
        ) : (
          <>
            <SubtitleContainer>
              <Title>Indicadores</Title>
            </SubtitleContainer>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12} sm={isTablet ? 6 : 3}>
                <OverviewCard
                  style={{ maxWidth: '10rem' }}
                  value={products?.total || '--'}
                  colorTheme="primary"
                  description="Total de notáveis"
                  isLoading={loadingProducts}
                  skeletonProps={skeletonProps}
                />
              </Grid>
              <Grid item xs={12} sm={isTablet ? 6 : 3}>
                <OverviewCard
                  value={products?.totalSold || '--'}
                  colorTheme="success"
                  description="Notáveis Vendidos"
                  isLoading={loadingProducts}
                  skeletonProps={skeletonProps}
                />
              </Grid>
              <Grid item xs={12} sm={isTablet ? 6 : 3}>
                <OverviewCard
                  value={products?.recommendedTotal || '--'}
                  colorTheme="secondary"
                  description="Preço  Recomendado  Correto"
                  isLoading={loadingProducts}
                  skeletonProps={skeletonProps}
                />
              </Grid>
              <Grid item xs={12} sm={isTablet ? 6 : 3}>
                <OverviewCard
                  value={
                    products.percentageOverRevenue
                      ? `${products.percentageOverRevenue.toFixed(2)}%`
                      : '--'
                  }
                  colorTheme="info"
                  description="% Sobre Faturamento"
                  isLoading={loadingProducts}
                  skeletonProps={skeletonProps}
                />
              </Grid>
            </Grid>
            <TableContainer>
              <FilterContainerTop>
                <TextContainer>
                  <Title>Lista de Produtos</Title>
                  <SubTitle>
                    Use os filtros abaixo para restringir a lista de produtos
                  </SubTitle>
                </TextContainer>
                <ButtonContainer>
                  <FormControl variant="outlined">
                    <SelectTitle>Supermercado Referência</SelectTitle>
                    <StyledSelect
                      value={supermarketRef}
                      variant="outlined"
                      onChange={(e) => setSupermarketRef(e.target.value)}
                    >
                      {supermarkets.map(({ name, document }) => (
                        <MenuItem value={document}>{name}</MenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                  <FormControl variant="outlined">
                    <SelectTitle>Padaria Referência</SelectTitle>
                    <StyledSelect
                      value={bakeryRef}
                      variant="outlined"
                      onChange={(e) => setBakeryRef(e.target.value)}
                    >
                      {bakeries.map(({ name, document }) => (
                        <MenuItem value={document}>{name}</MenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                </ButtonContainer>
              </FilterContainerTop>
              <Divider />
              <FilterContainerBottom>
                <FormControl variant="outlined">
                  <StyledLabel id="department-label" shrink={!!marketStructure}>
                    Departamento
                  </StyledLabel>
                  <StyledSelect2
                    labelId="department-label"
                    value={marketStructure}
                    variant="outlined"
                    onChange={(e) => setMarketStructure(e.target.value)}
                    renderValue={(value) => (
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          textTransform: 'capitalize',
                        }}
                      >
                        {value}
                        <IconButton
                          onMouseDown={(e) => e.stopPropagation()}
                          onClick={() => setMarketStructure('')}
                          size="small"
                        >
                          <ClearIcon size="small" />
                        </IconButton>
                      </span>
                    )}
                  >
                    {marketStructures.map((item) => (
                      <MenuItem
                        value={item}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </StyledSelect2>
                </FormControl>
                <FormControl variant="outlined">
                  <StyledLabel id="sort-label">Ordenar por</StyledLabel>
                  <StyledSelect2
                    labelId="sort-label"
                    value={orderIndex}
                    variant="outlined"
                    onChange={(e) => setOrderIndex(e.target.value)}
                  >
                    {orderList.map(({ label, orderDirection }, index) => (
                      <MenuItem value={index}>
                        <ListItemIcon style={{ minWidth: 35 }}>
                          {orderDirection === 'ASC' ? (
                            <ASCIcon size="1.5rem" />
                          ) : (
                            <DESCIcon size="1.5rem" />
                          )}
                        </ListItemIcon>
                        <span style={{ bottomMargin: 5 }}>{label}</span>
                      </MenuItem>
                    ))}
                  </StyledSelect2>
                </FormControl>
              </FilterContainerBottom>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <HeaderTableCell align="center">
                      Código Ean
                    </HeaderTableCell>
                    <HeaderTableCell align="center">
                      Nome do Produto
                    </HeaderTableCell>
                    <HeaderTableCell align="center">
                      Última Venda
                    </HeaderTableCell>
                    <HeaderTableCell align="center">
                      Padaria Referência
                    </HeaderTableCell>
                    <HeaderTableCell align="center">
                      Média Supermercado
                    </HeaderTableCell>
                    <HeaderTableCell align="center">
                      % vs Média do Supermercado
                    </HeaderTableCell>
                    <HeaderTableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingProducts ? (
                    <TableCell colSpan={6}>
                      <Spinner color="black" />
                    </TableCell>
                  ) : (
                    products.docs.map((row) => (
                      <Row
                        key={row.name}
                        row={row}
                        supermarketRef={
                          supermarkets.find(
                            ({ document }) => document === supermarketRef,
                          )?.name
                        }
                        bakeryRef={
                          bakeries.find(
                            ({ document }) => document === bakeryRef,
                          )?.name
                        }
                      />
                    ))
                  )}
                </TableBody>
              </Table>
              <Pagination>
                <IconButton
                  disabled={page === 1}
                  onClick={() => setPage((currentPage) => currentPage - 1)}
                >
                  <PrevIcon />
                </IconButton>
                <span>
                  {(products.page - 1) * 10 + 1} -{' '}
                  {(products.page - 1) * 10 + products.docs.length} de{' '}
                  {products.total}
                </span>
                <IconButton
                  disabled={page === products.pages}
                  onClick={() => setPage((currentPage) => currentPage + 1)}
                >
                  <NextIcon />
                </IconButton>
              </Pagination>
            </TableContainer>
          </>
        )}
      </SectionContent>
    </Container>
  );
};

export default NotableProducts;
