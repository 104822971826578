export default [
  { label: 'ID da Transação', type: 'String', value: 'transacao_id' },
  { label: 'Nome do Cliente', type: 'String', value: 'cliente_nome' },
  { label: 'E-mail do Cliente', type: 'String', value: 'cliente_email' },
  { label: 'Débito ou Crédito', type: 'String', value: 'debito_credito' },
  { label: 'Tipo de Transação', type: 'String', value: 'tipo_transacao' },
  { label: 'Status', type: 'String', value: 'status' },
  { label: 'Tipo de Pagamento', type: 'String', value: 'tipo_pagamento' },
  { label: 'Valor Bruto', type: 'String', value: 'valor_bruto' },
  { label: 'Valor de Desconto', type: 'String', value: 'valor_desconto' },
  { label: 'Valor de Taxa', type: 'String', value: 'valor_taxa' },
  { label: 'Valor Líquido', type: 'String', value: 'valor_liquido' },
  { label: 'Transportadora', type: 'String', value: 'transportadora' },
  { label: 'Número de Envio', type: 'String', value: 'num_envio' },
  { label: 'Data da Transação', type: 'String', value: 'data_transacao' },
  { label: 'Data de Compensação', type: 'String', value: 'data_compensacao' },
  { label: 'Referência da Transação', type: 'String', value: 'ref_transacao' },
  { label: 'Parcelas', type: 'String', value: 'parcelas' },
  { label: 'Código de Usuário', type: 'String', value: 'codigo_usuario' },
  { label: 'Código da Venda', type: 'String', value: 'codigo_venda' },
  {
    label: 'Número de Série da Máquina',
    type: 'String',
    value: 'serial_leitor',
  },
  { label: 'Recebimentos', type: 'String', value: 'recebimentos' },
  { label: 'Recebidos', type: 'String', value: 'recebidos' },
  { label: 'Valor Recebido', type: 'String', value: 'valor_recebido' },
  {
    label: 'Valor da Tarifa de Intermediação',
    type: 'String',
    value: 'valor_tarifa_intermediacao',
  },
  {
    label: 'Valor da Taxa de Intermediação',
    type: 'String',
    value: 'valor_taxa_intermediacao',
  },
  {
    label: 'Valor da Taxa de Parcelamento',
    type: 'String',
    value: 'valor_taxa_parcelamento',
  },
  {
    label: 'Valor da Taxa de Boleto',
    type: 'String',
    value: 'valor_tarifa_boleto',
  },
  {
    label: 'Bandeira do Cartão de Crédito',
    type: 'String',
    value: 'bandeira_cartao_credito',
  },
];
