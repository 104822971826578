import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { EditOutlined, Add } from '@material-ui/icons';
import { arraySum, isEmptyObject } from '../../../../utils/globalFunctions';
import ValuesCard from '../ValuesCard';

export default function CashierGrid({ items = {}, onCashierEdit, onPosAdd }) {
  const { 'Sem caixa': posWithoutCashier, ...cashiersMap } = items;
  if (
    Object.keys(cashiersMap).every((key) =>
      isEmptyObject(cashiersMap[key].values))
    && isEmptyObject(posWithoutCashier || {})
  ) {
    return (
      <div style={{ marginTop: 24, marginLeft: 4 }}>
        Não houve transações no período filtrado
      </div>
    );
  }

  const fieldsToAggregate = ['Crédito', 'Débito', 'PIX'];
  return (
    <div style={{ marginTop: 16 }}>
      <Grid container spacing={2}>
        {Object.keys(cashiersMap).map((cashier) => {
          const { values, id, ...cashierMembers } = cashiersMap[cashier];
          const { sum: totalValue, ...valuesByPaymentKind } = values;
          const aggregatedFields = [];
          const nonAggregatedFields = [];
          Object.keys(valuesByPaymentKind).forEach((key) => {
            const objToAdd = {
              title: key,
              value: valuesByPaymentKind[key],
            };
            fieldsToAggregate.indexOf(key) >= 0 ?
              aggregatedFields.push(objToAdd)
              : nonAggregatedFields.push(objToAdd);
          });
          aggregatedFields.sort(
            (a, b) => fieldsToAggregate.indexOf(a.title) - fieldsToAggregate.indexOf(b.title),
          );
          return (
            <ValuesCard
              key={id}
              title={cashier}
              headerColor="#192D4D33"
              buttonComponent={
                <EditCashierButton
                  onClick={() => onCashierEdit(cashier)}
                />
              }
              aggregated={[aggregatedFields]}
              nonAggregated={nonAggregatedFields}
              total={totalValue}
              members={cashierMembers}
            />
          );
        })}
        {Object.keys(posWithoutCashier || {}).map((pos) => {
          const entries = posWithoutCashier[pos];
          const totalValue = arraySum(entries, 'value');
          const aggregatedFields = [];
          const nonAggregatedFields = [];
          entries.forEach((entry) => {
            const { title, value } = entry;
            fieldsToAggregate.indexOf(title) >= 0 ?
              aggregatedFields.push({ title, value })
              : nonAggregatedFields.push({ title, value });
          });
          const isPicPay = nonAggregatedFields.some((field) => field.title === 'PicPay');
          const title = `${isPicPay ? 'PICPAY' : 'POS'} ${pos}`;
          const member = isPicPay ? { picPayOperators: [pos] } : { paymentMachines: [pos] };
          return (
            <ValuesCard
              key={pos}
              title={title}
              headerColor="#F9B87033"
              buttonComponent={
                <AddPosButton
                  onClick={() => onPosAdd(
                    pos,
                    isPicPay ? 'picPayOperator' : 'paymentMachine',
                  )}
                />
                }
              aggregated={[aggregatedFields]}
              nonAggregated={nonAggregatedFields}
              total={totalValue}
              members={member}
            />
          );
        })}
      </Grid>
    </div>
  );
}

function EditCashierButton({ onClick }) {
  return (
    <IconButton
      onClick={onClick}
    >
      <EditOutlined fontSize="small" />
    </IconButton>
  );
}

function AddPosButton({ onClick }) {
  return (
    <IconButton
      onClick={onClick}
    >
      <Add fontSize="small" />
    </IconButton>
  );
}
