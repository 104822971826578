import {
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
  GET_PRODUCTS,
  updateProducts,
  showSpinner,
  hideSpinner,
  GET_MARKET_STRUCTURES,
  FETCH_MARKET_STRUCTURES_SUCCESS,
  FETCH_MARKET_STRUCTURES_ERROR,
  updateMarketStructures,
  GET_PRODUCT_SUMMARY,
  SHOW_MARKET_STRUCTURE_SPINNER,
  HIDE_MARKET_STRUCTURE_SPINNER,
} from '../actions/products';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'sellableProducts';

export const getProductsFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_PRODUCTS) {
          dispatch(
            apiRequest(
              'GET',
              URL,
              {
                ...action.payload,
                filter: { active: true, ...action.payload?.filter },
              },
              FETCH_PRODUCTS_SUCCESS,
              FETCH_PRODUCTS_ERROR,
              ['customer', 'active'],
              true,
            ),
          );
          dispatch(showSpinner());
        }
      };

export const getProductSummaryFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_PRODUCT_SUMMARY) {
          dispatch(
            apiRequest(
              'GET',
              `${URL}/notableProductsSummary`,
              {
                ...action.payload,
                filter: { active: true, ...action.payload?.filter },
              },
              FETCH_PRODUCTS_SUCCESS,
              FETCH_PRODUCTS_ERROR,
              [
                'company',
                'page',
                'limit',
                'bakery_ref',
                'supermarket_ref',
                'market_structure',
                'order',
                'orderDirection',
              ],
              true,
            ),
          );
          dispatch(showSpinner());
        }
      };

export const getMarketStructuresFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_MARKET_STRUCTURES) {
          dispatch(
            apiRequest(
              'GET',
              `${URL}/marketStructures`,
              {
                ...action.payload,
                filter: { active: true, ...action.payload?.filter },
              },
              FETCH_MARKET_STRUCTURES_SUCCESS,
              FETCH_MARKET_STRUCTURES_ERROR,
              [],
              true,
            ),
          );
          dispatch({
            type: SHOW_MARKET_STRUCTURE_SPINNER,
          });
        }
      };

export const processProductsCollection =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_PRODUCTS_SUCCESS) {
          dispatch(updateProducts(action.payload));
          dispatch(hideSpinner());
        }
        if (action.type === FETCH_MARKET_STRUCTURES_SUCCESS) {
          dispatch(updateMarketStructures(action.payload));
          dispatch({
            type: HIDE_MARKET_STRUCTURE_SPINNER,
          });
        }
        if (
          action.type === FETCH_PRODUCTS_ERROR ||
          action.type === FETCH_MARKET_STRUCTURES_ERROR
        ) {
          Toastify.addError(
            'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
          );
          dispatch(hideSpinner());
        }
      };

export const productsMdl = [getProductsFlow, processProductsCollection, getMarketStructuresFlow, getProductSummaryFlow];
