import {
  GET_COMMISSIONINGS,
  UPDATE_COMMISSIONINGS,
} from '../actions/commissionings';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function commissioningsReducer(commissionings = initState, action) {
  switch (action.type) {
    case GET_COMMISSIONINGS:
      return action.payload;
    case UPDATE_COMMISSIONINGS:
      return action.payload;

    default:
      return commissionings;
  }
}
