import {
  UPDATE_PAYMENT_MACHINE_SUCCESS,
  UPDATE_PAYMENT_MACHINE_ERROR,
  FETCH_PAYMENT_MACHINE_SUCCESS,
  FETCH_PAYMENT_MACHINE_ERROR,
  GET_PAYMENT_MACHINE,
  CREATE_PAYMENT_MACHINE,
  UPDATE_PAYMENT_MACHINE,
  updateObjectPaymentMachine,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/paymentMachine';
import { getPaymentMachines } from '../actions/paymentMachines';
import { getPaymentMachineRegisters } from '../actions/paymentMachineRegisters';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'paymentMachines';

export const createPaymentMachineFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === CREATE_PAYMENT_MACHINE) {
          dispatch(
            apiRequest(
              'POST',
              `${URL}/registers`,
              action.payload,
              UPDATE_PAYMENT_MACHINE_SUCCESS,
              UPDATE_PAYMENT_MACHINE_ERROR,
              {},
              true,
            ),
          );
          dispatch(showSpinner());
        }
      };

export const updatePaymentMachineFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === UPDATE_PAYMENT_MACHINE) {
          dispatch(
            apiRequest(
              'PUT',
              URL,
              action.payload,
              UPDATE_PAYMENT_MACHINE_SUCCESS,
              UPDATE_PAYMENT_MACHINE_ERROR,
              {},
              true,
            ),
          );
          dispatch(showSpinner());
        }
      };

export const getPaymentMachineFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_PAYMENT_MACHINE) {
          const { _id } = action.payload;
          dispatch(
            apiRequest(
              'GET-id',
              `${URL}/index`,
              { _id },
              FETCH_PAYMENT_MACHINE_SUCCESS,
              FETCH_PAYMENT_MACHINE_ERROR,
              {},
              true,
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processPaymentMachineCollection =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_PAYMENT_MACHINE_SUCCESS) {
          dispatch(updateObjectPaymentMachine(action.payload));
          dispatch(hideSpinner());
        } else if (action.type === UPDATE_PAYMENT_MACHINE_SUCCESS) {
          dispatch(updateObjectPaymentMachine(action.payload));
          dispatch(hideModal());
          dispatch(hideSpinner());
          // To reload the page
          const { page, limit } = getState().paymentMachines;
          dispatch(getPaymentMachines({ page, limit }));
          dispatch(
            getPaymentMachineRegisters({
              page: getState().paymentMachineRegisters?.page,
              limit: getState().paymentMachineRegisters?.limit,
            }),
          );
          Toastify.addSuccess('Operação concluída com sucesso.');
        } else if (action.type === UPDATE_PAYMENT_MACHINE_ERROR) {
          dispatch(hideSpinner());
          Toastify.addError(
            'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
          );
          console.error(action.payload);
        }
      };

export const paymentMachineMdl = [
  createPaymentMachineFlow,
  updatePaymentMachineFlow,
  getPaymentMachineFlow,
  processPaymentMachineCollection,
];
