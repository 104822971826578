import moment from 'moment';
import { api2FrontInterface } from '../../utils/globalFunctions';

export const paymentKindMap = {
  // 1: 'Débito Online',
  // 2: 'Boleto',
  3: 'Cartão de Crédito',
  // 4: 'Saldo',
  // 7: 'Débito em conta',
  8: 'Cartão de Débito',
  // 9: 'Débito Automático em Conta',
  11: 'PIX',
  98: 'Voucher',
  99: 'PicPay',
};

const ExtractProvider = ({
  extracts,
  companies,
  paymentMachines,
  picPayOperators,
}) => {
  const paymentStatusMap = {
    1: 'Confirmado',
    2: 'Pago',
    3: 'Disponível',
    4: 'Disponível por antecipação',
    5: 'Solicitado',
  };

  const companyNameMap = {};
  companies &&
    companies.forEach((company) => {
      companyNameMap[company.document] = company.name;
    });

  const serialNumbersMap = {};
  paymentMachines &&
    paymentMachines.forEach((paymentMachine) => {
      serialNumbersMap[paymentMachine.serial_number] =
        serialNumbersMap[paymentMachine.serial_number] || [];
      serialNumbersMap[paymentMachine.serial_number].push(paymentMachine);
    });

  const operatorsMap = {};
  picPayOperators &&
    picPayOperators.forEach((picPayOperator) => {
      operatorsMap[picPayOperator.operator] =
        operatorsMap[picPayOperator.operator] || [];
      operatorsMap[picPayOperator.operator].push(picPayOperator);
    });

  function _findRegisterByMoment(register, referenceMoment) {
    const startMoment = moment(register.valid_since);
    const endMoment = register.valid_until && moment(register.valid_until);
    return (
      startMoment <= referenceMoment &&
      (endMoment ? referenceMoment <= endMoment : true)
    );
  }

  function _getCompanyName(referenceMoment, serialNumber) {
    const paymentMachineRegisters = serialNumbersMap[serialNumber] || [];
    const validRegister =
      paymentMachineRegisters.find((register) =>
        _findRegisterByMoment(register, referenceMoment),
      ) || {};
    const companyId = validRegister.company_document;
    return companyNameMap[companyId] || '';
  }
  function _getPaymentMachineFunction(referenceMoment, serialNumber) {
    const paymentMachineRegisters = serialNumbersMap[serialNumber] || [];
    const validRegister =
      paymentMachineRegisters.find((register) =>
        _findRegisterByMoment(register, referenceMoment),
      ) || {};
    return validRegister?.sales_function === 'ONLINE'
      ? 'Venda online'
      : 'Loja física';
  }
  function _getCompanyNameByOperator(operator) {
    const [picPayOperatorsRegister] = operatorsMap[operator] || [];
    const companyId = picPayOperatorsRegister?.company_document;
    return companyNameMap[companyId] || '';
  }

  function _formatExtract(extract) {
    // Convert snake case from api to camel case for front
    const formattedItem = api2FrontInterface(extract);

    const formattedValue = parseFloat(formattedItem.value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });

    const referenceMoment = moment(formattedItem.transactionDate);
    const referenceDateTime = referenceMoment.format('DD/MM/YYYY, HH:mm');

    const companyName = formattedItem.serialNumber
      ? _getCompanyName(referenceMoment, formattedItem.serialNumber.toString())
      : _getCompanyNameByOperator(formattedItem.picpayOperator);
    const paymentMachineFunction = _getPaymentMachineFunction(
      referenceMoment,
      formattedItem.serialNumber?.toString(),
    );
    const paymentKind = paymentKindMap[formattedItem.paymentKindCode] || '';

    const paymentStatus =
      paymentStatusMap[formattedItem.paymentStatusCode] || '';

    return {
      ...formattedItem,
      formattedValue,
      referenceDateTime,
      companyName,
      paymentKind,
      paymentStatus,
      paymentMachineFunction,
    };
  }

  function getFormattedExtracts() {
    const formattedItems = (extracts || []).map(_formatExtract);
    return formattedItems;
  }

  const factory = { getFormattedExtracts };

  return factory;
};

export default ExtractProvider;
