import { Popover, useMediaQuery } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import { Menu } from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import React from 'react';
import { Link } from 'react-router-dom';
import LogoImage from '../../../../assets/images/bt_logo_dark.svg';
import LogoutIcon from '../../../../assets/images/logout-icon.svg';
import ProfileIcon from '../../../../assets/images/profile-icon.svg';
import PATHS from '../../../../config/routePaths';
import { theme } from '../../../../theme';
import {
  Hideable,
  Logo,
  MenuButton,
  PanelContainer,
  StyledAppBar,
  StyledIcon,
  StyledToolbar,
  HeaderUserName,
  useStyles,
} from '../../styles';

export const PanelHeader = ({
  setOpen,
  setOpenByHover,
  menuButtonRef,
  open,
  popoverButtonRef,
  handleOpenPopover,
  popoverOpen,
  popoverButton,
  handleClosePopover,
  setPopoverOpen,
  handleLogout,
  loggedUser,
}) => {
  const classes = useStyles();
  const sm = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <StyledAppBar
      id="panel-header"
      elevation={0}
      position="relative"
      color="transparent"
    >
      <StyledToolbar sm={sm ? 12 : undefined}>
        <MenuButton
          id="menuButton"
          onClick={() => {
            setOpen((current) => !current);
            setOpenByHover(false);
          }}
          ref={menuButtonRef}
        >
          {open ? (
            <ChevronLeftIcon className={classes.menuButton} />
          ) : (
            <Menu className={classes.menuButton} />
          )}
        </MenuButton>

        <Link to={PATHS.HOME}>
          <Logo src={LogoImage} />
        </Link>

        <PanelContainer>
          {sm && (
            <>
              <StyledIcon
                ref={popoverButtonRef}
                onClick={handleOpenPopover}
                src={ProfileIcon}
              />
              <Popover
                className={classes.popover}
                open={popoverOpen}
                anchorEl={popoverButton}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <List>
                  <ListItem
                    button
                    onClick={() => {
                      setPopoverOpen(false);
                      handleLogout();
                    }}
                  >
                    <ListItemText style={{ textAlign: 'right' }}>
                      Sair
                    </ListItemText>
                    <ListItemIcon style={{ justifyContent: 'flex-end' }}>
                      <img
                        style={{ height: '1rem' }}
                        src={LogoutIcon}
                        alt="Ícone de logout"
                      />
                    </ListItemIcon>
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    onClick={() => setPopoverOpen(false)}
                    to={PATHS.PROFILE}
                  >
                    <ListItemText style={{ textAlign: 'right' }}>
                      Minha Conta
                    </ListItemText>
                    <ListItemIcon style={{ justifyContent: 'flex-end' }}>
                      <Icon style={{ color: 'var(--primary-text-color)' }}>
                        person_outline
                      </Icon>
                    </ListItemIcon>
                  </ListItem>
                </List>
              </Popover>
            </>
          )}

          <Hideable sm={sm}>
            <Tooltip
              title="Meu Perfil"
              arrow
              classes={{ tooltip: classes.tooltip }}
            >
              <Link to={PATHS.PROFILE} style={{ paddingTop: '0.3rem' }}>
                <StyledIcon src={ProfileIcon} />
              </Link>
            </Tooltip>

            <HeaderUserName>{loggedUser.name}</HeaderUserName>

            <Tooltip title="Sair" arrow classes={{ tooltip: classes.tooltip }}>
              <StyledIcon src={LogoutIcon} onClick={handleLogout} />
            </Tooltip>
          </Hideable>
        </PanelContainer>
      </StyledToolbar>
    </StyledAppBar>
  );
};
