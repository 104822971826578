import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { initHotjar } from './integrations/Hotjar';
import { initSalesIntegration } from './integrations/Sales/index';
import { App } from './app';
import history from './services/history';
import { initGoogleAnalytics } from './integrations/GoogleAnalytics';

const app = document.getElementById('root');

// Below logic is necessary for S3 hosting (see https://via.studio/journal/hosting-a-reactjs-app-with-routing-on-aws-s3)
if (app) {
  // 1. Set up the browser history with the updated location (minus the !# sign)
  // eslint-disable-next-line no-restricted-globals
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  // 2. Render our app
  ReactDOM.render(<App />, app);
}

initHotjar();
initSalesIntegration();
initGoogleAnalytics();
