import { GET_ROLES, UPDATE_ROLES } from '../actions/roles';

const initState = {
  docs: [],
  page: 1,
  limit: 50,
  totalDocs: 0,
  searchText: '',
};

export function rolesReducer(roles = initState, action) {
  switch (action.type) {
    case GET_ROLES:
      return action.payload;
    case UPDATE_ROLES:
      return action.payload;

    default:
      return roles;
  }
}
