import React from 'react';

import { isNumber } from '../../../../utils/globalFunctions';
import { ItemContainer } from '../../styles';

export function DetailsCardItem({ title, value, grossValue, tax, fontSize }) {
  const formattedValue = `${value > 0 ? '+' : ''}${
    value < 0 ? '-' : ''
  } ${Math.abs(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })}`;
  const formattedGrossValue =
    (grossValue &&
      `${grossValue < 0 ? '-' : ''} ${Math.abs(grossValue).toLocaleString(
        'pt-BR',
        {
          style: 'currency',
          currency: 'BRL',
        },
      )}`) ||
    0;
  return (
    <>
      <ItemContainer>
        {isNumber(value) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              fontSize: '0.875rem',
              fontWeight: 500,
              marginTop: '.5rem',
            }}
          >
            <span style={{ fontSize }}>{title}</span>
            <span
              style={{
                color: value > 0 ? '#00A389' : undefined,
                fontSize,
                whiteSpace: 'nowrap',
              }}
            >
              {formattedValue}
            </span>
          </div>
        )}
        {isNumber(grossValue) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              color: '#A3A3A3',
              fontWeight: 400,
              fontSize: '0.75rem',
            }}
          >
            <span>Bruto</span>
            <span>{formattedGrossValue}</span>
          </div>
        )}
        {isNumber(tax) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              color: '#A3A3A3',
              fontWeight: 400,
              fontSize: '0.75rem',
            }}
          >
            <span>Taxa</span>
            <span>{+parseFloat(tax).toFixed(2)}%</span>
          </div>
        )}
      </ItemContainer>
    </>
  );
}
