import { useMediaQuery } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import {
  AddRounded,
  AssignmentRounded as CopyIcon,
  CancelRounded,
  CheckCircle,
  EditRounded,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ModalContainer from '../../sharedComponents/ModalContainer/index';
import Spinner from '../../sharedComponents/Spinner/index';
import SubHeader from '../../sharedComponents/SubHeader';
import { hideModal, showModal } from '../../redux/actions/provider';
import { getProviders } from '../../redux/actions/providers';
import EditModal from '../Modals/Provider';
import { Container, useStyles } from './styles';
import { hasPermission } from '../../utils/globalFunctions';
import PATHS from '../../config/routePaths';

const Providers = ({
  dispatch,
  providers,
  providerModal,
  providersLoading,
}) => {
  const classes = useStyles();
  const [providersFormated, setProvidersFormated] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [copied, setCopied] = React.useState(false);

  const isTablet = useMediaQuery('(max-width:812px)');

  useEffect(() => {
    dispatch(getProviders({ page: page + 1, limit }));
  }, [page, limit]);

  useEffect(() => {
    if (providers.docs) {
      setProvidersFormated(providers.docs);
      setTotal(providers.totalDocs);
    }
  }, [providers]);

  React.useEffect(() => {
    if (!copied) return;
    setTimeout(() => setCopied(false), 2000);
  }, [copied]);

  const handleOpenModal = () => {
    dispatch(showModal());
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getProviders({ page: 1, limit, searchText }));
  };

  function handleCopy(index) {
    return () => {
      navigator.clipboard.writeText(providersFormated[index].code);
      setCopied(true);
    };
  }

  return (
    <>
      <ModalContainer open={providerModal} handleClose={handleCloseModal}>
        <EditModal id={modalContentId} handleClose={handleCloseModal} />
      </ModalContainer>
      <Container>
        <SubHeader
          title={
            <span>
              Fornecedores
              <IconButton
                size="small"
                className={classes.addButton}
                onClick={() => handleClick('new')}
                disabled={
                  !hasPermission(PATHS.TRANSFORMATION_PROVIDERS, 'create')
                }
              >
                <AddRounded />
              </IconButton>
            </span>
          }
        >
          <span
            style={{
              paddingTop: 4,
              width: '100%',
              maxWidth: isTablet ? undefined : 300,
            }}
          >
            <TextField
              label={!searchText ? 'Pesquisar' : ''}
              type="text"
              size="small"
              variant="outlined"
              value={searchText}
              name="vendor_name"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(e) => (e.key === 'Enter' ? handleSearch(e) : null)}
              fullWidth
              InputProps={{
                style: {
                  borderRadius: '1.5rem',
                  border: '1px solid var(--primary-text-color)',
                  color: 'var(--primary-text-color)',
                },
              }}
              InputLabelProps={{
                shrink: false,
                style: { color: 'var(--primary-text-color)' },
              }}
            />
          </span>
        </SubHeader>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Ativo</TableCell>
                    <TableCell colspan={2}>Nome</TableCell>
                    <TableCell>CPF/CNPJ</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>Telefone</TableCell>
                    <TableCell>Contato Comercial</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Recomendado</TableCell>
                    <TableCell>Código</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {providersLoading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="12" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!providersLoading &&
                    providersFormated.map((rr, index) => (
                      <TableRow className={classes.row}>
                        <TableCell className={classes.cell}>
                          {rr.active ? (
                            <CheckCircle className={classes.checkButton} />
                          ) : (
                            <CancelRounded className={classes.closeButton} />
                          )}
                        </TableCell>
                        <TableCell colspan={2} className={classes.cell}>
                          {rr.name}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {rr.document}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {rr.email}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {rr.phone}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {rr.contact}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {rr.type}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          style={{ textAlign: 'center' }}
                        >
                          {rr.recommended ? (
                            <CheckCircle className={classes.checkButton} />
                          ) : (
                            <CancelRounded className={classes.closeButton} />
                          )}
                        </TableCell>
                        <TableCell>
                          <TextField
                            disabled
                            size="small"
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <Tooltip title={copied ? 'Copiado' : 'Copiar'}>
                                  <IconButton onClick={handleCopy(index)}>
                                    <CopyIcon />
                                  </IconButton>
                                </Tooltip>
                              ),
                            }}
                            value={rr.code}
                            style={{ width: '10rem' }}
                          />
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <IconButton
                            onClick={() => handleClick(rr._id)}
                            disabled={
                              !hasPermission(
                                PATHS.SETTINGS_PAYMENT_MACHINES,
                                'update',
                              )
                            }
                          >
                            <EditRounded />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Resultados por página"
            />
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  providersLoading: state.ui.providersLoading,
  providers: state.providers,
  providerModal: state.ui.providerModal,
});

export default connect(mapStateToProps)(Providers);
