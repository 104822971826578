import {
  GET_PAYMENT_MACHINE,
  UPDATE_PAYMENT_MACHINE,
  UPDATE_OBJECT_PAYMENT_MACHINE,
  CREATE_PAYMENT_MACHINE,
  DELETE_PAYMENT_MACHINE,
} from '../actions/paymentMachine';

export function paymentMachineReducer(paymentMachine = {}, action) {
  switch (action.type) {
    case GET_PAYMENT_MACHINE:
      return action.payload;
    case UPDATE_PAYMENT_MACHINE:
      return action.payload;
    case CREATE_PAYMENT_MACHINE:
      return action.payload;
    case DELETE_PAYMENT_MACHINE:
      return action.payload;
    case UPDATE_OBJECT_PAYMENT_MACHINE:
      return action.payload;

    default:
      return paymentMachine;
  }
}
