import styled from 'styled-components';

export const ButtonContainer = styled.span`
  margin-left: ${({ noMargins }) => (noMargins ? 0 : '1rem')};
  width: ${({ fullWidth }) => (fullWidth ? '75%' : undefined)};

  @media (max-width: 400px) {
    margin-top: ${({ noMargins }) => (noMargins ? 0 : '0.5rem')};
    margin-left: ${({ noMargins }) => (noMargins ? 0 : '0.25rem')};
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`;
