import { UPDATE_MARKET_STRUCTURES } from '../actions/products';

const initState = [];

export function marketStructuresReducer(marketStructures = initState, action) {
  switch (action.type) {
    case UPDATE_MARKET_STRUCTURES:
      return action.payload;

    default:
      return marketStructures;
  }
}
