export const GET_ORDERS = '[orders] GET';
export const FETCH_ORDERS_SUCCESS = '[orders] Fetch success';
export const FETCH_ORDERS_ERROR = '[orders] Fetch Error';
export const UPDATE_ORDERS = '[orders] Update';
export const SHOW_ORDERS_SPINNER = '[orders - ui] show spinner';
export const HIDE_ORDERS_SPINNER = '[orders - ui] hide spinner';

export const getOrders = (filter) => ({
  type: GET_ORDERS,
  payload: filter,
});

export const updateOrders = (data) => ({
  type: UPDATE_ORDERS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_ORDERS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_ORDERS_SPINNER,
});
