import React, { useState } from 'react';

import { TextField } from '@material-ui/core';
import HoverButton from '../HoverButton';
import Alert from '../Alert';

import {
  StyledIconButton,
  StyledTableCellContent,
  StyledEditRounded,
  StyledInput,
  StyledTableCell,
} from './styles';

function EditableTableCell(props) {
  const { value, onEditCallback, validator, isLoading } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const [valueError, setValueError] = useState(false);

  const handleEditing = async (e) => {
    e.preventDefault();

    const isValid = validator ? validator(newValue) : true;
    setValueError(!isValid);

    if (isValid) {
      onEditCallback(newValue);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleFocus = () => {
    setNewValue(value);
  };

  return (
    <StyledTableCell align="center">
      <StyledTableCellContent>
        {isEditing ? (
          <StyledInput
            style={{
              padding: 0,
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              minWidth: 45,
            }}
          >
            <TextField
              size="small"
              margin="dense"
              error={valueError}
              variant="outlined"
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
              autoFocus
              inputProps={{
                style: {
                  paddingLeft: 5,
                  paddingRight: 5,
                  textAlign: 'center',
                },
              }}
              onKeyPress={(e) => (e.key === 'Enter' ? handleEditing(e) : null)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              disabled={isLoading}
            />
          </StyledInput>
        ) : (
          <HoverButton
            style={{
              position: 'relative',
              width: 'auto',
              padding: '0 5px',
            }}
            NoHoverComponent={() => (
              <span>{value || value === 0 ? `${value}%` : '-'}</span>
            )}
            OnHoverComponent={() => (
              <StyledIconButton
                onClick={() => setIsEditing(!isEditing)}
                size="small"
              >
                <StyledEditRounded />
              </StyledIconButton>
            )}
          />
        )}
      </StyledTableCellContent>
    </StyledTableCell>
  );
}

export default EditableTableCell;
