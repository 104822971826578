import {
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentMachineRegisters } from '../../../redux/actions/paymentMachineRegisters';
import Spinner from '../../../sharedComponents/Spinner/index';
import {
  ButtonsContainer,
  FiltersTitle,
  StyledPaper,
  StyledTableCell,
  StyledTablePagination,
  StyledTextField,
} from '../styles';
import Clearable from './Clearable';
import Row from './CompanyRow';

export default function CompaniesContent({
  total,
  paymentMachineRegistersFormatted,
}) {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState({});

  const paymentMachinesLoading = useSelector(
    (state) => state.ui.paymentMachineRegistersLoading,
  );
  const loadingCompanies = useSelector((state) => state.ui.loadingCompanies);

  const dispatch = useDispatch();
  const companies = useSelector((state) => state.companies);

  useEffect(() => {
    dispatch(
      getPaymentMachineRegisters({
        page: page + 1,
        limit,
        ...filters,
        companyDocumentIn: filters.companyDocument
          ? [filters.companyDocument]
          : undefined,
        companiesTab: true,
      }),
    );
  }, [page, limit, filters]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilters = (event) => {
    const { name, value } = event.target;
    setFilters((currentFilters) => ({ ...currentFilters, [name]: value }));
  };

  const handleExpand = (document) => () => {
    setOpen((currentOpen) => (document === currentOpen ? null : document));
  };

  const handleClear = (field) => () =>
    setFilters((currentFilters) => ({ ...currentFilters, [field]: '' }));

  return (
    <StyledPaper>
      <FiltersTitle>Filtros</FiltersTitle>
      <ButtonsContainer>
        <Grid container spacing={2}>
          <Grid item md={6} lg={3}>
            <StyledTextField
              label={!filters.companyDocument ? 'Padaria' : ''}
              type="text"
              value={filters.companyDocument || ''}
              variant="outlined"
              name="companyDocument"
              onChange={handleFilters}
              fullWidth
              InputLabelProps={{ shrink: false }}
              select
              SelectProps={{
                IconComponent: filters.companyDocument
                  ? (props) => (
                    <Clearable
                      {...props}
                      onClear={handleClear('companyDocument')}
                    />
                  )
                  : KeyboardArrowDown,
              }}
            >
              {companies?.docs?.map((option) => (
                <MenuItem key={option.id} value={option.document}>
                  {option.name}
                </MenuItem>
              ))}
            </StyledTextField>
          </Grid>
        </Grid>
      </ButtonsContainer>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell isHeader>Nome da Padaria</StyledTableCell>
              <StyledTableCell isHeader>Máquinas Ativas</StyledTableCell>
              <StyledTableCell isHeader>Registros Totais</StyledTableCell>
              <StyledTableCell isHeader>Última Atualização</StyledTableCell>
              <StyledTableCell isHeader />
            </TableRow>
          </TableHead>
          <TableBody>
            {(paymentMachinesLoading || loadingCompanies) ? (
              <TableRow>
                <StyledTableCell colSpan="7">
                  <Spinner parent="Table" />
                </StyledTableCell>
              </TableRow>
            ) :
              paymentMachineRegistersFormatted?.map((company) => (
                <Row
                  company={company}
                  open={company.document === open}
                  onClick={handleExpand}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledTablePagination
        rowsPerPageOptions={[2, 5, 10, 25]}
        component="div"
        count={total}
        rowsPerPage={limit}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Resultados por página"
      />
    </StyledPaper>
  );
}
