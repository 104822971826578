import { GET_TRANSITORY_VALUES, UPDATE_TRANSITORY_VALUES } from '../actions/transitoryValues';

const initState = {
  docs: [],
};

export function transitoryValuesReducer(transitoryValues = initState, action) {
  switch (action.type) {
    case GET_TRANSITORY_VALUES:
      return action.payload;
    case UPDATE_TRANSITORY_VALUES:
      return action.payload;

    default:
      return transitoryValues;
  }
}
