import React from 'react';
import { Backdrop, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const ModalContainer = ({
  open,
  handleClose,
  children,
  modalStyle = {},
  paperStyle = {},
}) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      paddingTop: '2rem',
      paddingBottom: '2rem',
      justifyContent: 'center',
      overflow: 'auto',
      ...modalStyle,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      ...paperStyle,
    },
  }));
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        zIndex: 100,
      }}
    >
      {children}
    </Modal>
  );
};

export default ModalContainer;
