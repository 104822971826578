import React from 'react';
import { colors } from '../../theme';
import { SectionContentProps } from './interfaces';
import { Container, StyledSpinner } from './styles';

const SectionContent: React.FC<React.PropsWithChildren<
  SectionContentProps
>> = ({ contents, isLoading, children, ...props }) => (
  <Container id="section-content" {...props}>
    {isLoading ? (
      <StyledSpinner color={colors.primary.main} />
    ) : (
      contents || children
    )}
  </Container>
);

export default SectionContent;
