import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '50vw',
    paddingTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: 0,
      minHeight: '100vh',
    },
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '1.5rem',
    '&.filter': {
      padding: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    height: 50,
    padding: '0px 15px',
    textAlign: 'center',
  },
  buttons: {
    color: 'blue',
  },
  headerGridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  headerGridContainer: {
    marginLeft: '6px',
    marginRight: '6px',
    marginTop: '1rem',
    width: '100%',
    '&:first-child': {
      marginTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 16px)',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  input: {
    borderRadius: '1rem',
  },
}));

export const Container = styled.div`
  padding: 20px;
  @media (max-width: 767px) {
    padding: 0 1rem;
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #a3a3a333;
  align-self: stretch;
  margin: 1rem;
  margin-bottom: 0;
  &.first-divider {
    margin-top: 0;
  }
`;

export const Title = styled.p`
  color: var(--primary-text-color);
  font-size: 1.6rem;
  font-weight: 400;
  align-self: center;
  @media (max-width: 560px) {
    font-size: 1.3rem;
    margin-left: 0.5rem;
  }
`;

export const FieldTitle = styled.div`
  color: #464255;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;

  @media (max-width: 700px) {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
`;
