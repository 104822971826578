import axios from 'axios';
import { store } from '../../redux/store';
import { obj2QueryString, userIsAdmin } from '../../utils/globalFunctions';
import Service from '../Service';

async function fetchBanks() {
  // docs: https://brasilapi.com.br/docs#tag/BANKS
  const response = await axios.get('https://brasilapi.com.br/api/banks/v1');
  if (response.status !== 200) {
    throw Error(response.statusText);
  }
  return {
    data: response.data,
    ok: true,
    status: response.status,
  };
}

async function fetchAllCompanies() {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const queryString = obj2QueryString({
    listAll: true,
    showUnactiveCompanies: true,
    isAdmin,
  });
  const apiService = new Service({
    route: `/companies?${queryString}`,
    apiV2: true,
  });
  const response = await apiService.get({});
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: 200,
  };
}

export default {
  fetchBanks,
  fetchAllCompanies,
};
