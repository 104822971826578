import React, { useState, useRef } from 'react';
import { Grid, MenuItem, Typography, Icon } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import ModalContainer from '../../../sharedComponents/ModalContainer';
import Input from '../../../sharedComponents/Input';
import TextButton from '../../../sharedComponents/TextButton';
import { updateRegister } from '../../../services/PaymentMachines';
import { ModalContainer as Container, Divider, CloseButton } from '../styles';
import { getPaymentMachineRegisters, showSpinner } from '../../../redux/actions/paymentMachineRegisters';
import Toastify from '../../../utils/Toastify';
import DatePicker from '../../../sharedComponents/DatePicker';

function ActivateModal({ open, onClose, id, page, limit, action }) {
  const [data, setData] = useState({
    company: '',
    salesFunction: '',
    validSince: new Date(),
  });

  const dispatch = useDispatch();
  const companies = useSelector(
    (state) => state.companies?.docs || state.companies || [],
  );
  const paymentMachineRegisterSpinner = useSelector(
    (state) => state.ui.paymentMachineRegistersLoading,
  );

  const handleClose = () => {
    onClose();
    setData({
      company: '',
      salesFunction: '',
      validSince: new Date(),
    });
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  const handleEditDate = (value) =>
    setData((currentData) => ({ ...currentData, validSince: value }));

  const handleSave = async (e) => {
    e.preventDefault();
    if (!paymentMachineRegisterSpinner) {
      dispatch(showSpinner());
      const result = await updateRegister(id, {
        sales_function: data.salesFunction,
        company_document: data.company,
        valid_since: data.validSince,
        ...(action === 'ACTIVATE' ? { status: 'ACTIVE' } : {}),
      });
      if (!result.ok) {
        Toastify.addError(
          result.data?.message ||
            'Ocorreu um erro durante a operação, por favor tente novamente.',
        );
        return;
      }
      Toastify.addSuccess('POS atualizado com sucesso.');
      handleClose();
      dispatch(getPaymentMachineRegisters({ page, limit }));
    }
  };

  const title = {
    TRANSFER: 'nova padaria',
    FUNCTION: 'função de venda',
    ACTIVATE: 'nova padaria e função de venda',
  };

  const fromDateRef = useRef();

  return (
    <ModalContainer open={open} handleClose={handleClose}>
      <Container elevation={2}>
        <CloseButton onClick={handleClose}>
          <Icon>close</Icon>
        </CloseButton>
        <Typography variant="h5">Selecione {title[action]}</Typography>
        <Divider style={{ marginTop: '.5rem', marginBottom: '2rem' }} />
        <form onSubmit={handleSave}>
          <Grid container spacing={2}>
            {(action === 'TRANSFER' || action === 'ACTIVATE') && (
              <Grid item xs={12} sm={6}>
                <Input
                  value={data.company}
                  name="company"
                  onChange={handleEdit}
                  label="Padaria"
                  icon="store_filled"
                  fullWidth
                  select
                  required
                >
                  {companies?.map(({ document, name }) => (
                    <MenuItem value={document}>{name}</MenuItem>
                  ))}
                </Input>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Input
                value={data.salesFunction}
                name="salesFunction"
                onChange={handleEdit}
                label="Função de Venda"
                icon="point_of_sale"
                select
                required
                fullWidth
              >
                <MenuItem value="ONLINE">ONLINE</MenuItem>
                <MenuItem value="OFFLINE">OFFLINE</MenuItem>
              </Input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                dateRef={fromDateRef}
                value={data.validSince}
                name="validSince"
                onChange={handleEditDate}
                label="Válido a partir de"
              />
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid
                item
                xs={6}
                container
                justifyContent="flex-end"
                style={{ paddingTop: '1rem' }}
              >
                <TextButton
                  text="Cancelar"
                  color="secondary"
                  fullWidth={false}
                  noMargins
                  onClick={handleClose}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingTop: '1rem' }}>
                <TextButton
                  text="Salvar"
                  type="submit"
                  fullWidth={false}
                  noMargins
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
    </ModalContainer>
  );
}

export default ActivateModal;
