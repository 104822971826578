import {
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  FETCH_ROLE_SUCCESS,
  FETCH_ROLE_ERROR,
  GET_ROLE,
  CREATE_ROLE,
  UPDATE_ROLE,
  updateObjectRole,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/role';
import { getRoles } from '../actions/roles';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';
import { getLoggedUser } from '../actions/loggedUser';

const URL = 'roles';

export const createRoleFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_ROLE) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_ROLE_SUCCESS,
        UPDATE_ROLE_ERROR,
        {},
        true,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateRoleFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_ROLE) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_ROLE_SUCCESS,
        UPDATE_ROLE_ERROR,
        {},
        true,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getRoleFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_ROLE) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_ROLE_SUCCESS,
        FETCH_ROLE_ERROR,
        {},
        true,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processRoleCollection = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === FETCH_ROLE_SUCCESS) {
    dispatch(updateObjectRole(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_ROLE_SUCCESS) {
    dispatch(updateObjectRole(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    dispatch(getLoggedUser());
    const { page, limit } = getState().roles;
    dispatch(getRoles({ page, limit }));
    Toastify.addSuccess('Operação concluída com sucesso.');
  } else if (action.type === UPDATE_ROLE_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(
      'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
    );
    console.error(action.payload);
  }
};

export const roleMdl = [
  createRoleFlow,
  updateRoleFlow,
  getRoleFlow,
  processRoleCollection,
];
