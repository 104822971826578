import { GET_WEEKLY_TAXES, UPDATE_WEEKLY_TAXES } from '../actions/weeklyTaxes';

const initState = {
  docs: [],
  page: 1,
  limit: 50,
  totalDocs: 0,
  searchText: '',
};

export function weeklyTaxesReducer(weeklyTaxes = initState, action) {
  switch (action.type) {
    case GET_WEEKLY_TAXES:
      return action.payload;
    case UPDATE_WEEKLY_TAXES:
      return action.payload;

    default:
      return weeklyTaxes;
  }
}
