export const GET_USER = '[user] GET';
export const UPDATE_USER = '[user] UPDATE';
export const CREATE_USER = '[user] CREATE';
export const DELETE_USER = '[user] DELETE';
export const UPDATE_OBJECT_USER = '[user] UPDATE OBJECT USER';
// UI
export const SHOW_USER_MODAL = '[user] SHOW MODAL';
export const HIDE_USER_MODAL = '[user] HIDE MODAL';
// Spinner
export const UPDATE_USER_SUCCESS = '[user] Update success';
export const UPDATE_USER_ERROR = '[user] Update Error';
export const FETCH_USER_SUCCESS = '[user] Fetch success';
export const FETCH_USER_ERROR = '[user] Fetch Error';
export const SHOW_USER_SPINNER = '[user - ui] show spinner';
export const HIDE_USER_SPINNER = '[user - ui] hide spinner';

export const getUser = (id) => ({
  type: GET_USER,
  payload: { _id: id },
});

export const createUser = (data) => ({
  type: CREATE_USER,
  payload: data,
});

export const updateUser = (data) => ({
  type: UPDATE_USER,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_USER_MODAL,
});

export const hideModal = () => ({
  type: HIDE_USER_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_USER_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_USER_SPINNER,
});

export const updateObjectUser = (data) => ({
  type: UPDATE_OBJECT_USER,
  payload: data,
});
