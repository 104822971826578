import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import PATHS from '../../config/routePaths';
import { hasPermission } from '../../utils/globalFunctions';
import Toastify from '../../utils/Toastify';
import Navigation from '../Navigation';

function RouteWrapper({
  loggedUser: { authenticated },
  modulesLoading,
  isPrivate,
  permission,
  component: Component,
  wrapWithNavigation,
  ...rest
}) {
  if (isPrivate && !authenticated) {
    return <Redirect to={PATHS.LOGIN} />;
  }
  if (permission && !hasPermission(rest.path, permission)) {
    Toastify.addWarning(
      'Você não tem permissão para ver a página que tentou acessar e foi redirecionado para a página inicial.',
    );
    return <Redirect to={PATHS.HOME} />;
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Route {...rest}>
      {wrapWithNavigation ? (
        <Navigation component={<Component />} />
      ) : (
        <Component />
      )}
    </Route>
  );
}

function mapStateToProps(state) {
  return {
    loggedUser: state.loggedUser,
    modulesLoading: state.ui.modulesLoading,
  };
}

export default connect(mapStateToProps)(RouteWrapper);
