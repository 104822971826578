/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem, Icon } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardArrowDown } from '@material-ui/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanies } from '../../redux/actions/companies';
import { updateObjectCompany } from '../../redux/actions/company';
import { CompanySelectorProps } from './interfaces';
import { labelStyle, Select, selectStyle } from './styles';

const CompanySelector: React.FC<CompanySelectorProps> = ({
  style,
  value: controlledValue,
  onChange,
  fullWidth,
  disableClearing,
  error,
  label,
  fieldToGet,
}) => {
  const selectClasses = selectStyle();
  const labelClasses = labelStyle();
  const dispatch = useDispatch();
  const company = useSelector((state: { company: any }) => state.company);
  const companies = useSelector(
    (state: { companies: any }) => state.companies?.docs || state.companies,
  );

  React.useEffect(() => {
    dispatch(getCompanies({ listAll: true }));
  }, []);

  const handleCompanyChange = (event: any) => {
    const { value } = event.target;
    if (onChange) {
      if (fieldToGet) {
        const companySelected = companies.find(({ id }: any) => id === value);
        return onChange(
          companySelected ? companySelected[fieldToGet] : undefined,
        );
      }
      onChange(value);
      return;
    }
    dispatch(
      updateObjectCompany(companies.find(({ id }: any) => id === value)),
    );
  };

  const handleClearCompany = () => {
    dispatch(updateObjectCompany({}));
  };
  const getControlledValue = () => {
    if (fieldToGet) {
      const selectedCompany = companies.find(
        (item: any) => item[fieldToGet] === controlledValue,
      );
      return selectedCompany?.id;
    }
    return controlledValue;
  };

  return (
    <Select
      id="company-selector-select"
      variant="outlined"
      select
      value={getControlledValue() || company?.id || ''}
      onChange={handleCompanyChange}
      label={controlledValue || company.id ? '' : label || 'Selecione'}
      SelectProps={{
        classes: selectClasses,
        IconComponent:
          (controlledValue || company?.id) && !disableClearing
            ? () => (
                <>
                  <KeyboardArrowDown />
                  <IconButton onClick={handleClearCompany}>
                    <Icon>close</Icon>
                  </IconButton>
                </>
              )
            : KeyboardArrowDown,
      }}
      InputLabelProps={{ shrink: false, classes: labelClasses }}
      style={style}
      fullWidth={fullWidth}
      InputProps={{
        startAdornment: (
          <Icon
            style={{ color: '#192D4D', marginRight: '0.7rem' }}
            className="material-icons-outlined"
          >
            place
          </Icon>
        ),
      }}
      error={!!error}
      helperText={error}
    >
      {companies?.map(({ id, name }: any) => (
        <MenuItem value={id} key={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CompanySelector;
