import moment from 'moment';
import { getDailySummary, getHistoricalSummary } from '../../services/Home';

export class HomeProvider {
  static fetchDailyData = async (
    companyDocuments,
    refDate,
  ) => {
    const { data } = await getDailySummary({
      companyDocuments,
      referenceISOString: new Date(
        moment(refDate).startOf('day').format(),
      ).toISOString(),
    });
    return data;
  }

  static fetchHistoricalData = async (
    companyDocuments,
    refDate,
    comparisonDays,
  ) => {
    const { data } = await getHistoricalSummary({
      companyDocuments,
      startISOString: new Date(
        moment(refDate)
          .subtract(comparisonDays * 2 + 1, 'days')
          .startOf('day')
          .format(),
      ).toISOString(),
      endISOString: new Date(
        moment(refDate)
          .startOf('day')
          .format(),
      ).toISOString(),
    });
    return data;
  }
}
