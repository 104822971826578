/* eslint-disable react/require-default-props */
import React from 'react';
import {
  Collapse,
  TableContainer,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useDebounce } from '../../../../hooks';
import {
  Divider,
  Input,
  Row,
  SearchIcon,
  Table,
  TableBody,
  TableHead,
  Cell,
  SubValue,
  DetailsBar,
  DetailItem,
  DetailLabel,
  DetailValue,
  ExpandIcon,
  ExpandButton,
  SubOrderDetailsContainer,
  Title,
  DetailsDivider,
  Grid,
  Field,
  Label,
  SearchInput,
  DetailsGrid,
  GridItem,
  MobileLabel,
  SeeMoreButton,
  StatusIndicator,
} from './styles';
import { OrderProductWithSupplier } from '../interfaces';
import {
  SanitizedOrderStatus,
  SupplierOrder,
} from '../../../../interfaces/orders';
import { FieldType } from './interfaces';

const statusColorMap: {
  [key in SanitizedOrderStatus]: string;
} = {
  Aguardando: '#9EA3A5',
  Realizado: '#2A96C1',
  'Em entrega': '#EBCD0C',
  Entregue: '#48B748',
  Cancelado: '#F95F52',
};

const columns: {
  label: string;
  key: keyof OrderProductWithSupplier;
  subKey?: keyof OrderProductWithSupplier;
  onlyAllProductsView?: boolean;
}[] = [
  { label: 'Descrição', key: 'productDescription', subKey: 'eanCode' },
  { label: 'Fornecedor', key: 'supplier', onlyAllProductsView: true },
  { label: 'Quant.', key: 'quantity' },
  { label: 'Preço Original', key: 'unitPlatformPrice' },
  { label: 'Preço Negociado', key: 'unitNegotiatedPrice' },
  { label: 'Total', key: 'total' },
  { label: 'Preço digitado', key: 'unitTypedPrice' },
  { label: 'Preço de custo', key: 'unitCostPrice' },
];

const detailsFields: {
  label: string;
  key: keyof SupplierOrder;
  customClass?: string;
}[] = [
  { label: 'Status do pedido', key: 'status', customClass: 'highlight' },
  {
    label: 'Número do pedido',
    key: 'supplierOrderNumber',
    customClass: 'highlight',
  },
  { label: 'Previsão de entrega', key: 'deliveryDate' },
  { label: 'Valor total', key: 'total' },
  { label: 'Preço Digitado', key: 'typedTotal' },
];

const expandedFields: FieldType[] = [
  {
    key: 'supplierOrderId',
    label: 'Número do pedido no fornecedor',
    columns: 3,
  },
  { key: 'status', label: 'Status do pedido', columns: 3 },
  { key: 'deliveryExpectedDate', label: 'Previsão de entrega', columns: 3 },
  { key: 'deliveryDate', label: 'Data de emissão da nota', columns: 3 },
  { key: 'paymentMethod', label: 'Forma de pagamento', columns: 4 },
  { key: 'paymentConditions', label: 'Prazo de pagamento', columns: 4 },
  { key: 'receipt', label: 'Comprovante', columns: 4 },
  { key: 'observation', label: 'Observações', columns: 12, multiline: true },
];

const SubOrder: React.FC<{
  products?: OrderProductWithSupplier[];
  subOrder?: SupplierOrder;
}> = ({ products, subOrder }) => {
  const [input, setInput] = React.useState<string>('');
  const [productsToRender, setProductsToRender] = React.useState<
    OrderProductWithSupplier[]
  >([]);
  const [detailsExpanded, setDetailsExpanded] = React.useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const debouncedInput = useDebounce(input, 250);

  React.useEffect(() => {
    const productList = products || subOrder?.orderProducts || [];
    if (!debouncedInput) {
      setProductsToRender(productList);
      return;
    }
    setProductsToRender(
      productList.filter(
        ({ productDescription, eanCode }) =>
          productDescription
            .toUpperCase()
            .includes(debouncedInput.toUpperCase()) ||
          eanCode?.toUpperCase()?.includes(debouncedInput.toUpperCase()),
      ),
    );
  }, [products, subOrder?.orderProducts, debouncedInput]);

  const handleInput: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setInput(value);
  };

  const handleToggleDetails = () => {
    setDetailsExpanded((currentState) => !currentState);
  };

  const columnsToRender = columns.filter(
    ({ onlyAllProductsView }) =>
      !onlyAllProductsView || (onlyAllProductsView && products),
  );

  return (
    <>
      {subOrder && (
        <>
          <DetailsBar open={detailsExpanded}>
            {isMobile ? (
              <DetailsGrid>
                <GridItem columns={2} className="divider-bottom align-left">
                  <MobileLabel alignLeft>#{subOrder.supplierOrderNumber}</MobileLabel>
                </GridItem>
                <GridItem columns={1} className="divider-after">
                  <MobileLabel>
                    <StatusIndicator
                      color={
                        statusColorMap[subOrder.status as SanitizedOrderStatus]
                      }
                    />{' '}
                    Status do pedido
                  </MobileLabel>
                  <DetailValue>{subOrder.status}</DetailValue>
                </GridItem>
                <GridItem columns={1}>
                  <MobileLabel>Previsão de entrega</MobileLabel>
                  <DetailValue>{subOrder.deliveryExpectedDate}</DetailValue>
                </GridItem>
                <GridItem columns={1} className="divider-after divider-bottom">
                  <MobileLabel>Valor Total</MobileLabel>
                  <DetailValue>{subOrder.total}</DetailValue>
                </GridItem>
                <GridItem columns={1} className="divider-bottom">
                  <MobileLabel>Valor Digitado</MobileLabel>
                  <DetailValue>{subOrder.typedTotal}</DetailValue>
                </GridItem>
                <SeeMoreButton
                  onClick={handleToggleDetails}
                  endIcon={
                    <ExpandIcon
                      fontSize="small"
                      className={detailsExpanded ? 'open' : ''}
                    />
                  }
                >
                  Ver detalhes
                </SeeMoreButton>
              </DetailsGrid>
            ) : (
              <>
                {detailsFields.map(({ label, key, customClass }) => (
                  <DetailItem
                    status={subOrder.status as SanitizedOrderStatus}
                    key={key}
                    className={customClass}
                  >
                    <DetailLabel>{label}</DetailLabel>
                    <DetailValue>{subOrder[key] as string}</DetailValue>
                  </DetailItem>
                ))}
                <ExpandButton onClick={handleToggleDetails}>
                  <ExpandIcon
                    fontSize="small"
                    className={detailsExpanded ? 'open' : ''}
                  />
                </ExpandButton>
              </>
            )}
          </DetailsBar>
          <Collapse in={detailsExpanded}>
            <SubOrderDetailsContainer>
              <Title>Detalhes do pedido</Title>
              <DetailsDivider />
              <Grid>
                {expandedFields.map(
                  ({ key, columns: cols, label, multiline }) => (
                    <Field columns={isMobile ? 12 : cols}>
                      <Label>{label}</Label>
                      <Input
                        disableUnderline
                        fullWidth
                        value={subOrder[key]}
                        multiline={multiline}
                        disabled
                      />
                    </Field>
                  ),
                )}
              </Grid>
            </SubOrderDetailsContainer>
          </Collapse>
          <Divider />
        </>
      )}
      <SearchInput
        onChange={handleInput}
        value={input}
        endAdornment={<SearchIcon />}
        disableUnderline
        placeholder="Pesquisar produto"
        fullWidth
      />
      <Divider />
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <Row>
              {columnsToRender.map(({ label, key }) => (
                <Cell key={key}>{label}</Cell>
              ))}
            </Row>
          </TableHead>
          <TableBody>
            {productsToRender.map((product) => (
              <Row key={product.id}>
                {columnsToRender.map(({ key, subKey }) => (
                  <Cell key={key} className={subKey ? 'with-sub-value' : ''}>
                    <>
                      {subKey && (
                        <SubValue>{product[subKey] as string}</SubValue>
                      )}
                      {product[key]}
                    </>
                  </Cell>
                ))}
              </Row>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SubOrder;
