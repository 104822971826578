import React from 'react';
import { FooterContainer, FooterText } from './styles';
import * as Package from '../../../package.json';

const Footer = ({ mainSize = 0, drawerSize = 0 }) => (
  <FooterContainer mainSize={mainSize} drawerSize={drawerSize}>
    <FooterText>
      {Package.version} © bakerytech.com.br - Todos os direitos reservados.{' '}
      <span role="img" aria-label="Cup of coffee">
        ☕
      </span>
    </FooterText>
  </FooterContainer>
);

export default Footer;
