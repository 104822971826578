import React, { forwardRef, useState } from 'react';
import Spinner from '../../../../sharedComponents/Spinner/index';
import { ListItem } from '../ListItem';

export const SectionList = forwardRef(
  ({ data, isLoading, onItemClick, selectedItemId, itemRef }, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(-1);
    return (
      <React.Fragment key="section-list">
        {!isLoading ? (
          <div
            style={{
              flex: 2,
            }}
            ref={ref}
          >
            {data.map((item, index) => (
              <ListItem
                item={item}
                index={index}
                ref={selectedIndex === index ? itemRef : null}
                onItemClick={() => {
                  onItemClick(item);
                  setSelectedIndex(index);
                }}
                selectedItemId={selectedItemId}
                id={`list-item-${item.id}`}
                key={`list-item-${item.id}`}
              />
            ))}
          </div>
        ) : (
          <Spinner color="#000" />
        )}
      </React.Fragment>
    );
  },
);
