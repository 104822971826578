import {
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_ERROR,
  FETCH_SUPPLIER_SUCCESS,
  FETCH_SUPPLIER_ERROR,
  GET_SUPPLIER,
  CREATE_SUPPLIER,
  UPDATE_SUPPLIER,
  updateObjectSupplier,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/supplier';
import { getSuppliers } from '../actions/suppliers';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'suppliers';

export const createSupplierFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === CREATE_SUPPLIER) {
          dispatch(
            apiRequest(
              'POST',
              URL,
              action.payload,
              UPDATE_SUPPLIER_SUCCESS,
              UPDATE_SUPPLIER_ERROR,
              {},
              true,
            ),
          );
          dispatch(showSpinner());
        }
      };

export const updateSupplierFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === UPDATE_SUPPLIER) {
          dispatch(
            apiRequest(
              'PUT',
              URL,
              action.payload,
              UPDATE_SUPPLIER_SUCCESS,
              UPDATE_SUPPLIER_ERROR,
              {},
              true,
            ),
          );
          dispatch(showSpinner());
        }
      };

export const getSupplierFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_SUPPLIER) {
          const { _id } = action.payload;
          dispatch(
            apiRequest(
              'GET-id',
              URL,
              { _id },
              FETCH_SUPPLIER_SUCCESS,
              FETCH_SUPPLIER_ERROR,
              {},
              true,
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processSupplierCollection =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_SUPPLIER_SUCCESS) {
          dispatch(updateObjectSupplier(action.payload));
          dispatch(hideSpinner());
        } else if (action.type === UPDATE_SUPPLIER_SUCCESS) {
          dispatch(updateObjectSupplier(action.payload));
          dispatch(hideModal());
          dispatch(hideSpinner());
          const { page, limit } = getState().suppliers;
          dispatch(getSuppliers({ page, limit }));
          Toastify.addSuccess('Operação concluída com sucesso.');
        } else if (action.type === UPDATE_SUPPLIER_ERROR) {
          dispatch(hideSpinner());
          if (action.payload === 'E-mail invalid.') {
            Toastify.addError(
              'O e-mail inserido é inválido. Corrija e tente novamente.',
            );
          } else if (action.payload === 'Supplier already exists.') {
            Toastify.addError('Fornecedor com CNPJ informado já cadastrado.');
          } else {
            Toastify.addError(
              'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
            );
          }
          console.error(action.payload);
        }
      };

export const supplierMdl = [
  createSupplierFlow,
  updateSupplierFlow,
  getSupplierFlow,
  processSupplierCollection,
];
