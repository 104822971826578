import { STORE_EMBEDDED_RESOURCES } from '../actions/embeddedResources';

const initState = [];

export function embeddedResourcesReducer(
  embeddedResources = initState,
  action,
) {
  switch (action.type) {
    case STORE_EMBEDDED_RESOURCES:
      return action.payload;

    default:
      return embeddedResources;
  }
}
