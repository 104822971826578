/* eslint-disable no-case-declarations */
import {
  UPDATE_LOGGED_USER,
  LOGOUT,
} from '../actions/loggedUser';
import { sessionStorageKey } from '../../config/consts';

const initLoggedUser = () => {
  const sessionStorageUser = JSON.parse(
    localStorage.getItem(sessionStorageKey),
  );
  return {
    active: sessionStorageUser ? sessionStorageUser.active : false,
    authenticated: sessionStorageUser
      ? sessionStorageUser.authenticated
      : false,
    createdAt: sessionStorageUser ? sessionStorageUser.createdAt : '',
    companies: sessionStorageUser ? sessionStorageUser.companies : [],
    email: sessionStorageUser ? sessionStorageUser.email : '',
    name: sessionStorageUser ? sessionStorageUser.name : '',
    phone_number: sessionStorageUser ? sessionStorageUser.phone_number : '',
    roles: sessionStorageUser ? sessionStorageUser.roles : {},
    group: sessionStorageUser ? sessionStorageUser.group : {},
    token: sessionStorageUser ? sessionStorageUser.token : '',
    updatedAt: sessionStorageUser ? sessionStorageUser.updatedAt : '',
    _id: sessionStorageUser ? sessionStorageUser.id : '',
    id: sessionStorageUser ? sessionStorageUser.id : '', // Deixando as duas (id e _id) por enquanto para não quebrar
    metadata: sessionStorageUser ? sessionStorageUser.metadata : {},
  };
};

export function loggedUserReducer(user = initLoggedUser(), action) {
  switch (action.type) {
    case UPDATE_LOGGED_USER:
      localStorage.setItem(sessionStorageKey, JSON.stringify(action.payload));
      return action.payload;
    case LOGOUT:
      localStorage.removeItem(sessionStorageKey);
      return initLoggedUser();
    default:
      return user;
  }
}
