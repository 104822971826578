import { GET_USERS, UPDATE_USERS } from '../actions/users';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function usersReducer(users = initState, action) {
  switch (action.type) {
    case GET_USERS:
      return action.payload;
    case UPDATE_USERS:
      return action.payload;

    default:
      return users;
  }
}
