import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { EditRounded, AddRounded, DeleteRounded } from '@material-ui/icons';
import format from 'date-fns/format';
import { Tooltip, useMediaQuery } from '@material-ui/core';
import { getItems } from '../../redux/actions/items';
import { showModal, hideModal, updateItem } from '../../redux/actions/item';
import {
  showModal as showProviderModal,
  hideModal as hideProviderModal,
} from '../../redux/actions/provider';
import ModalContainer from '../../sharedComponents/ModalContainer/index';
import EditModal from '../Modals/Item';
import EditProviderModal from '../Modals/Provider';
import Spinner from '../../sharedComponents/Spinner/index';
import { Container, StatusCircle, FakeLink } from './styles';
import Alert from '../../sharedComponents/Alert';

import SubHeader from '../../sharedComponents/SubHeader';
import CompanySelector from '../../sharedComponents/CompanySelector';
import { hasPermission } from '../../utils/globalFunctions';
import PATHS from '../../config/routePaths';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '1rem',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '1rem',
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
  },
  addButton: {
    border: '2px solid var(--primary-text-color)',
    marginLeft: '1rem',
    color: 'var(--primary-text-color)',
  },
}));

const searchStyles = makeStyles((theme) => ({
  root: {
    marginLeft: '1rem',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--primary-text-color)',
        borderRadius: '1rem',
        borderWidth: 2,
      },
      '&:hover fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
    },
    color: 'var(--primary-text-color)',
    minWidth: '8.313rem',
    fontWeight: 500,
    [theme.breakpoints.down(540)]: {
      marginLeft: 0,
    },
  },
}));

const Items = ({
  dispatch,
  items,
  itemModal,
  itemsLoading,
  loggedUser,
  providerModal,
  company,
}) => {
  const searchClasses = searchStyles();
  const classes = useStyles();
  const [itemsFormated, setItemsFormated] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');
  const statusColor = {
    Aprovado: '#c5e1a5',
    Aguardando: undefined,
    Reprovado: '#ef9a9a',
  };

  const isTablet = useMediaQuery('(max-width:812px)');
  const isMobile = useMediaQuery('(max-width:480px)');

  useEffect(() => {
    if (!company.id) return;
    dispatch(
      getItems({ page: page + 1, limit, filter: { customer: company?.idV1 } }),
    );
  }, [page, limit, company]);

  useEffect(() => {
    if (items.docs) {
      setItemsFormated(items.docs);
      setTotal(items.totalDocs);
    }
  }, [items]);

  const handleOpenModal = () => {
    dispatch(showModal());
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(
      getItems({
        page: 1,
        limit,
        searchText,
        filter: { company: company?.idV1 },
      }),
    );
  };

  const handleOpenProviderModal = () => {
    dispatch(showProviderModal());
  };

  const handleCloseProviderModal = () => {
    dispatch(hideProviderModal());
  };

  const handleProviderClick = (id) => {
    setModalContentId(id);
    handleOpenProviderModal();
  };

  const handleDeleteClick = (item) => {
    Alert('Tem certeza?', 'Deseja realmente excluir este item?').then(
      ({ confirm }) => {
        if (!confirm) return;
        dispatch(
          updateItem({
            ...item,
            active: false,
            provider_code: item.provider?.code,
          }),
        );
      },
    );
  };

  const maskCurrency = (value) => `R$ ${value.toFixed(2).replace('.', ',')}`;

  return (
    <>
      <ModalContainer open={itemModal} handleClose={handleCloseModal}>
        <EditModal id={modalContentId} handleClose={handleCloseModal} />
      </ModalContainer>
      <ModalContainer
        open={providerModal}
        handleClose={handleCloseProviderModal}
      >
        <EditProviderModal
          id={modalContentId}
          disableEdit
          handleClose={handleCloseProviderModal}
        />
      </ModalContainer>
      <Container>
        <SubHeader
          title={
            <span>
              Orçamento
              <IconButton
                className={classes.addButton}
                onClick={() => handleClick('new')}
                disabled={!hasPermission(PATHS.TRANSFORMATION_ITEMS, 'create')}
                size="small"
                style={{
                  margin: isMobile ? '1rem' : null,
                }}
              >
                <AddRounded />
              </IconButton>
            </span>
          }
        >
          <span
            style={{
              paddingTop: 4,
              width: isMobile ? '100%' : undefined,
              marginBottom: isMobile ? '1rem' : null,
              display: isMobile ? 'flex' : null,
              flexDirection: isMobile ? 'column' : null,
              justifyContent: isMobile ? 'center' : null,
            }}
          >
            <CompanySelector loggedUser={loggedUser} />
            <TextField
              style={{
                width: isTablet ? null : 300,
                marginTop: isMobile ? '1rem' : null,
                display: isMobile ? 'flex' : null,
                flexDirection: isMobile ? 'column' : null,
                justifyContent: isMobile ? 'center' : null,
              }}
              classes={searchClasses}
              label={!searchText ? 'Pesquisar' : ''}
              type="text"
              variant="outlined"
              value={searchText}
              name="vendor_name"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(e) => (e.key === 'Enter' ? handleSearch(e) : null)}
              fullWidth={!isTablet}
              InputProps={{
                style: {
                  color: 'var(--primary-text-color)',
                },
              }}
              InputLabelProps={{
                shrink: false,
                style: { color: 'var(--primary-text-color)' },
              }}
            />
          </span>
        </SubHeader>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colspan={2}>Descrição</TableCell>
                    <TableCell>Quantidade</TableCell>
                    <TableCell>Valor Unitário</TableCell>
                    <TableCell>Valor Total</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>% Total Pago</TableCell>
                    <TableCell>Valor Pago</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Fornecedor</TableCell>
                    <TableCell>Observações</TableCell>
                    <TableCell>Última Modificação</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemsLoading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="14" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!itemsLoading && itemsFormated.length === 0 && company.idV1 && (
                    <TableRow>
                      <TableCell colSpan={14}>
                        <Box textAlign="center" fontSize="1.5rem">
                          Nenhum item Encontrado.
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                  {!company.idV1 && (
                    <TableRow>
                      <TableCell colSpan={14}>
                        <Box textAlign="center" fontSize="1.5rem">
                          Selecione uma unidade para começar.
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}

                  {!itemsLoading &&
                    company.idV1 &&
                    itemsFormated.map((item) => (
                      <TableRow className={classes.row}>
                        <TableCell colSpan={2} className={classes.cell}>
                          {item.description}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {item.qty}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {maskCurrency(item.unit_value)}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {maskCurrency(item.unit_value * item.qty)}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          <StatusCircle color={statusColor[item.status]} />{' '}
                          {item.status}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {item.total_paid_percentage} %
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {maskCurrency(
                            (item.total_paid_percentage *
                              item.unit_value *
                              item.qty) /
                              100,
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {item.type}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Tooltip title={item.provider?.code}>
                            <FakeLink
                              onClick={() =>
                                handleProviderClick(item.provider?.idV1)
                              }
                            >
                              {item.provider?.name}
                            </FakeLink>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {item.observation}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {format(new Date(item.updatedAt), 'dd/MM/yyyy')}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          <IconButton
                            onClick={() => handleClick(item.idV1)}
                            disabled={
                              !hasPermission(
                                PATHS.TRANSFORMATION_ITEMS,
                                'update',
                              )
                            }
                          >
                            <EditRounded />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteClick(item)}
                            disabled={
                              !hasPermission(
                                PATHS.TRANSFORMATION_ITEMS,
                                'update',
                              )
                            }
                            color="secondary"
                          >
                            <DeleteRounded />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Resultados por página"
            />
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedUser: state.loggedUser,
  company: state.company,
  itemsLoading: state.ui.itemsLoading,
  items: state.items,
  itemModal: state.ui.itemModal,
  providerModal: state.ui.providerModal,
});

export default connect(mapStateToProps)(Items);
