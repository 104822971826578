import React from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

export function DetailsCardHeader({
  refDateString,
  transferString,
  onCloseCallback,
  actionClickCallback,
  canEdit,
  companyName,
}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          marginBottom: '0.5rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          style={{
            fontSize: '18px',
            fontWeight: '500',
            lineHeight: '27px',
          }}
        >
          Transferência -&nbsp;
          {transferString || 'Sem data de repasse'}
        </Typography>

        <div style={{ display: 'flex' }}>
          {canEdit && (
            <IconButton
              onClick={actionClickCallback}
              style={{ marginLeft: '0.5rem', padding: 0 }}
            >
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            onClick={onCloseCallback}
            style={{ marginLeft: '0.5rem', padding: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div>
        <span
          style={{
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '26px',
            color: '#A3A3A3',
          }}
        >
          {companyName} - Referente à {refDateString}
        </span>
      </div>
    </div>
  );
}
