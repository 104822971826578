import {
  GET_SUPPLIER,
  UPDATE_SUPPLIER,
  UPDATE_OBJECT_SUPPLIER,
  CREATE_SUPPLIER,
  DELETE_SUPPLIER,
} from '../actions/supplier';

export function supplierReducer(supplier = {}, action) {
  switch (action.type) {
    case GET_SUPPLIER:
      return action.payload;
    case UPDATE_SUPPLIER:
      return action.payload;
    case CREATE_SUPPLIER:
      return action.payload;
    case DELETE_SUPPLIER:
      return action.payload;
    case UPDATE_OBJECT_SUPPLIER:
      return action.payload;

    default:
      return supplier;
  }
}
