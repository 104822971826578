import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import Alert from '../../../sharedComponents/Alert/index';
import Spinner from '../../../sharedComponents/Spinner/index';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
  StyledPaper,
  FormButton,
} from './styles';

import {
  getSupplier,
  createSupplier,
  updateSupplier,
} from '../../../redux/actions/supplier';
import { cnpjValidation, emailValidator, sanitizeCNPJ } from '../../../utils/globalFunctions';

const SupplierModal = ({
  id,
  dispatch,
  supplier,
  supplierModal,
  handleClose,
  supplierSpinner,
}) => {
  const [active, setActive] = useState(false);
  const [corporateName, setCorporateName] = useState('');
  const [document, setDocument] = useState('');
  const [email, setEmail] = useState('');
  const [corporateNameError, setCorporateNameError] = useState(false);
  const [documentError, setDocumentError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getSupplier(id));
    }
  }, [id]);

  useEffect(() => {
    if (supplier && id !== 'new') {
      setActive(supplier.active);
      setCorporateName(supplier.corporate_name);
      setDocument(supplier.document);
      setEmail(supplier.email);
    }
  }, [supplier]);

  useEffect(() => {
    if (!supplierModal) {
      handleClose();
    }
  }, [supplierModal]);

  const handleDocumentErrorrMessage = document
    ? 'CNPJ inválido!'
    : 'CNPJ é obrigatório';

  const handleEmailErrorrMessage = email
    ? 'Endereço de e-mail inválido!'
    : 'E-mail é obrigatório';

  const handleSave = async () => {
    let err = false;
    setCorporateNameError(!corporateName);
    const cnpjWithError = !cnpjValidation(document);
    setDocumentError(cnpjWithError);
    const emailWithError = !emailValidator(email);
    setEmailError(emailWithError);
    err = !corporateName || cnpjWithError || emailWithError;

    if (!err) {
      const response = await Alert();
      if (response?.confirm) {
        if (id === 'new') {
          dispatch(
            createSupplier({
              active,
              corporate_name: corporateName,
              document,
              email,
            }),
          );
        } else {
          dispatch(
            updateSupplier({
              id,
              active,
              corporate_name: corporateName,
              document,
              email,
            }),
          );
        }
      }
    }
  };

  const handleDocument = (e) => {
    const { value } = e.target;
    setDocument(sanitizeCNPJ(value));
  };

  return (
    <>
      {supplierSpinner && <Spinner overlay />}
      <StyledPaper
        elevation={2}
        fullWidth
        style={{ maxWidth: '90vw', height: 'fit-content' }}
      >
        <Container style={{ opacity: supplierSpinner ? 0.5 : 1 }}>
          <ModalTitle>
            {id === 'new' ? 'Adicionar' : 'Editar'} Fornecedor
          </ModalTitle>
          <FormGroup>
            <CheckboxContent>
              <FormControlLabel
                control={
                  <Switch
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                  />
                }
                label="Ativo"
                labelPlacement="start"
              />
            </CheckboxContent>
            <InputContent>
              <TextField
                label="Razão Social*"
                type="text"
                error={corporateNameError}
                helperText={corporateNameError && 'Razão Social é obrigatório'}
                variant="outlined"
                value={corporateName}
                onChange={(e) => setCorporateName(e.target.value)}
                fullWidth
              />
            </InputContent>
            <InputContent>
              <InputMask
                mask="99.999.999/9999-99"
                type="text"
                value={document}
                onChange={handleDocument}
                disabled={false}
                maskChar=" "
              >
                {() => (
                  <TextField
                    label="CNPJ*"
                    type="text"
                    fullWidth
                    error={documentError}
                    helperText={documentError && handleDocumentErrorrMessage}
                    variant="outlined"
                  />
                )}
              </InputMask>
            </InputContent>
            <InputContent>
              <TextField
                label="E-mail*"
                type="text"
                error={emailError}
                helperText={emailError && handleEmailErrorrMessage}
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </InputContent>
            <ButtonContainer>
              <FormButton
                color="secondary"
                onClick={handleClose}
                variant="contained"
              >
                Cancelar
              </FormButton>
              <FormButton
                color="primary"
                onClick={handleSave}
                variant="contained"
              >
                Salvar
              </FormButton>
            </ButtonContainer>
          </FormGroup>
        </Container>
      </StyledPaper>
    </>
  );
};

export default connect((state) => ({
  supplier: state.supplier,
  modules: state.modules,
  supplierModal: state.ui.supplierModal,
  supplierSpinner: state.ui.supplierLoading,
}))(SupplierModal);
