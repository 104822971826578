import React from 'react';

export function DetailsCardSubtitle({ color, text }) {
  return (
    <>
      <span
        style={{
          fontSize: '15px',
          fontWeight: '400',
          lineHeight: '26px',
          color,
          marginTop: '1rem',
        }}
      >
        {text}
      </span>
    </>
  );
}
