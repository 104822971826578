import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Poppins', sans-serif;
  padding: 8px 0px;
`;

export const Card = styled.div`
  position: relative;
  cursor: pointer;
  padding: 16px;
  min-height: 200px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  height: 100%;
`;

export const Title = styled.h1`
  font-size: 21px;
  line-height: 31.5px;
  font-weight: 500;
`;

export const Subtitle = styled.p`
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
`;

export const Description = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  min-height: 100px;
  color: #464255;
  margin-top: 8px;
  padding-bottom: 80px;
`;

export const ActionBadgeContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  row-gap: 8px;
  column-gap: 8px;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 10px;
  bottom: 16px;
`;

export const ActionBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor || '#000'};
  color: #fff;
  font-weight: 600;
  border: none;
  padding: 8px 16px;
  border-radius: 16px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 5px lightgray;
  }
`;
