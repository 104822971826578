import React from 'react';
import { ButtonsContainer } from '../../styles';
import ApplyFiltersButton from '../ApplyFiltersButton';

const ActionButtons = ({ refDate, handleSubmit, shouldUpdateItem }) => (
  <ButtonsContainer>
    <ApplyFiltersButton
      refDate={refDate}
      handleSubmit={handleSubmit}
      shouldUpdateItem={shouldUpdateItem}
    />
  </ButtonsContainer>
);

export default ActionButtons;
