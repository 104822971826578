import {
  FETCH_WEEKLY_TAXES_SUCCESS,
  FETCH_WEEKLY_TAXES_ERROR,
  GET_WEEKLY_TAXES,
  updateWeeklyTaxes,
  showSpinner,
  hideSpinner,
} from '../actions/weeklyTaxes';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'tasks';

export const getWeeklyTaxesFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_WEEKLY_TAXES) {
          dispatch(
            apiRequest(
              'GET',
              `${URL}/complete`,
              action.payload,
              FETCH_WEEKLY_TAXES_SUCCESS,
              FETCH_WEEKLY_TAXES_ERROR,
              {},
              true,
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processWeeklyTaxesCollection =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_WEEKLY_TAXES_SUCCESS) {
          dispatch(
            updateWeeklyTaxes(
              action.payload.docs ? action.payload : { docs: action.payload },
            ),
          );
          dispatch(hideSpinner());
        } else if (action.type === FETCH_WEEKLY_TAXES_ERROR) {
          Toastify.addError(
            'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
          );
          dispatch(hideSpinner());
        }
      };

export const weeklyTaxesMdl = [getWeeklyTaxesFlow, processWeeklyTaxesCollection];
