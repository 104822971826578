import {
  GET_GROUP,
  UPDATE_GROUP,
  UPDATE_OBJECT_GROUP,
  CREATE_GROUP,
  DELETE_GROUP,
} from '../actions/group';

export function groupReducer(group = {}, action) {
  switch (action.type) {
    case GET_GROUP:
      return action.payload;
    case UPDATE_GROUP:
      return action.payload;
    case CREATE_GROUP:
      return action.payload;
    case DELETE_GROUP:
      return action.payload;
    case UPDATE_OBJECT_GROUP:
      return action.payload;

    default:
      return group;
  }
}
