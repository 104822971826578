import React from 'react';
import { connect } from 'react-redux';
import { Animated } from 'react-animated-css';
import {
  EditOutlined as EditIcon,
  LockOutlined as PasswordIcon,
} from '@material-ui/icons';
import {
  Grid,
  Button,
  Paper,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import { Container, Picture } from './styles';
import ModalContainer from '../../sharedComponents/ModalContainer';
import EditUserModal from '../Modals/Users';
import EditPasswordModal from '../Modals/Password';
import {
  showModal as showUserModal,
  hideModal as hideUserModal,
} from '../../redux/actions/user';
import {
  showModal as showPasswordModal,
  hideModal as hidePasswordModal,
} from '../../redux/actions/password';

const NoImage =
  'https://nemsmbr.org/wp-content/uploads/2019/03/no-image-icon-md-2.png';

const Profile = ({ loggedUser, dispatch, userModal, passwordModal }) => {
  const inputStyle = {
    width: '80%',
    marginTop: 20,
  };

  const isMobile = useMediaQuery('(max-width:480px)');

  function handleOpenModal(modal) {
    return () => {
      dispatch(modal === 'password' ? showPasswordModal() : showUserModal());
    };
  }

  function handleCloseModal(modal) {
    return () => {
      dispatch(modal === 'password' ? hidePasswordModal() : hideUserModal());
    };
  }

  return (
    <Container>
      <ModalContainer open={userModal} handleClose={handleCloseModal('user')}>
        <EditUserModal
          id={loggedUser.id}
          handleClose={handleCloseModal('user')}
        />
      </ModalContainer>
      <ModalContainer
        open={passwordModal}
        handleClose={handleCloseModal('password')}
      >
        <EditPasswordModal
          id={loggedUser.id}
          handleClose={handleCloseModal('password')}
        />
      </ModalContainer>
      <Animated
        animationIn="fadeInLeft"
        animationOut="fadeOut"
        animationInDuration={500}
        animationOutDuration={1000}
      >
        <Paper style={{ padding: 20, paddingBottom: 50, borderRadius: '1rem' }}>
          <Grid container style={{ maxWidth: 924 }}>
            <Grid item lg={12} md={12} sm={12} xs={12} align="right">
              <Button
                onClick={handleOpenModal('password')}
                startIcon={<PasswordIcon />}
                color="secondary"
                style={{ marginRight: 10 }}
              >
                Alterar Senha
              </Button>
              <Button
                onClick={handleOpenModal('user')}
                startIcon={<EditIcon />}
                color="primary"
              >
                Editar
                {isMobile ? '' : ' Usuário'}
              </Button>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} align="center">
              <Picture src={NoImage} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} align="center">
              <TextField
                disabled
                value={loggedUser?.name}
                label="Name"
                style={inputStyle}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} align="center">
              <TextField
                disabled
                value={loggedUser?.email}
                label="E-mail"
                style={inputStyle}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} align="center">
              <TextField
                disabled
                value={loggedUser?.phone_number}
                label="Telefone"
                style={inputStyle}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} align="center">
              <TextField
                disabled
                value={loggedUser?.roles?.name}
                label="Perfil"
                style={inputStyle}
              />
            </Grid>
          </Grid>
        </Paper>
      </Animated>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    loggedUser: state.loggedUser,
    userModal: state.ui.userModal,
    passwordModal: state.ui.passwordModal,
  };
}

export default connect(mapStateToProps)(Profile);
