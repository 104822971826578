import React from 'react';
import { SpinnerProps } from './interfaces';

import { Container } from './styles';

const Spinner: React.FC<SpinnerProps> = ({ parent, overlay, color, style }) => (
  <Container parent={parent} overlay={overlay} color={color} style={style}>
    <div className="lds-ellipsis">
      <div />
      <div />
      <div />
      <div />
    </div>
  </Container>
);

export default Spinner;
