import {
  GET_TRANSFER,
  UPDATE_TRANSFER,
  UPDATE_OBJECT_TRANSFER,
  CREATE_TRANSFER,
  DELETE_TRANSFER,
  BULK_CREATE_TRANSFER,
} from '../actions/transfer';

export function transferReducer(transfer = {}, action) {
  switch (action.type) {
    case GET_TRANSFER:
      return action.payload;
    case UPDATE_TRANSFER:
      return action.payload;
    case CREATE_TRANSFER:
      return action.payload;
    case DELETE_TRANSFER:
      return action.payload;
    case UPDATE_OBJECT_TRANSFER:
      return action.payload;
    case BULK_CREATE_TRANSFER:
      return action.payload;
    default:
      return transfer;
  }
}
