import {
  GET_COMPANY,
  UPDATE_COMPANY,
  UPDATE_OBJECT_COMPANY,
  CREATE_COMPANY,
  DELETE_COMPANY,
} from '../actions/company';

export function companyReducer(company = {}, action) {
  switch (action.type) {
    case GET_COMPANY:
      return action.payload;
    case UPDATE_COMPANY:
      return action.payload;
    case CREATE_COMPANY:
      return action.payload;
    case DELETE_COMPANY:
      return action.payload;
    case UPDATE_OBJECT_COMPANY:
      return action.payload;

    default:
      return company;
  }
}
