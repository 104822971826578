import { GET_TRANSFERS, UPDATE_TRANSFERS } from '../actions/transfers';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function transfersReducer(transfers = initState, action) {
  switch (action.type) {
    case GET_TRANSFERS:
      return action.payload;
    case UPDATE_TRANSFERS:
      return action.payload;

    default:
      return transfers;
  }
}
