import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import {
  CheckCircle,
  CancelRounded,
  EditRounded,
  AddRounded,
} from '@material-ui/icons';
import { getModules } from '../../redux/actions/modules';
import { showModal, hideModal } from '../../redux/actions/module';
import ModalContainer from '../../sharedComponents/ModalContainer/index';
import EditModal from '../Modals/Modules';
import Spinner from '../../sharedComponents/Spinner/index';
import { Container } from './styles';
import SubHeader from '../../sharedComponents/SubHeader';
import { hasPermission } from '../../utils/globalFunctions';
import PATHS from '../../config/routePaths';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: '1rem',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '1rem',
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'flex-start',
  },
  addButton: {
    border: '2px solid var(--primary-text-color)',
    marginLeft: '1rem',
    color: 'var(--primary-text-color)',
  },
}));

const Modules = ({ dispatch, modules, moduleModal, modulesLoading }) => {
  const classes = useStyles();
  const [modulesFormatted, setModulesFormatted] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    dispatch(getModules({ page: page + 1, limit }));
  }, [page, limit]);

  useEffect(() => {
    if (modules.docs) {
      setModulesFormatted(modules.docs);
      setTotal(modules.total);
    }
  }, [modules]);

  const handleOpenModal = () => {
    dispatch(showModal());
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getModules({ page: 1, limit, query: searchText }));
  };

  return (
    <>
      <ModalContainer open={moduleModal} handleClose={handleCloseModal}>
        <EditModal id={modalContentId} handleClose={handleCloseModal} />
      </ModalContainer>
      <Container>
        <SubHeader
          title={
            <span>
              Módulos
              <IconButton
                className={classes.addButton}
                onClick={() => handleClick('new')}
                disabled={!hasPermission(PATHS.SETTINGS_MODULES, 'create')}
                size="small"
              >
                <AddRounded />
              </IconButton>
            </span>
          }
        >
          <span
            style={{
              paddingTop: 4,
            }}
          >
            <TextField
              style={{
                width: 300,
              }}
              label={searchText ? '' : 'Pesquisar'}
              type="text"
              size="small"
              variant="outlined"
              value={searchText}
              name="module_name"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(e) => (e.key === 'Enter' ? handleSearch(e) : null)}
              fullWidth
              InputProps={{
                style: {
                  borderRadius: '1.5rem',
                  border: '1px solid var(--primary-text-color)',
                  color: 'var(--primary-text-color)',
                },
              }}
              InputLabelProps={{
                shrink: false,
                style: { color: 'var(--primary-text-color)' },
              }}
            />
          </span>
        </SubHeader>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Ativo</TableCell>
                    <TableCell colSpan={2}>Nome</TableCell>
                    <TableCell colSpan={2}>Key</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modulesLoading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="5" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!modulesLoading &&
                    modulesFormatted.map((module) => (
                      <TableRow key={module.key} className={classes.row}>
                        <TableCell className={classes.cell}>
                          {module.active ? (
                            <CheckCircle className={classes.checkButton} />
                          ) : (
                            <CancelRounded className={classes.closeButton} />
                          )}
                        </TableCell>
                        <TableCell colSpan="2" className={classes.cell}>
                          <b>{module.name}</b>
                        </TableCell>
                        <TableCell colSpan="2" className={classes.cell}>
                          <b>{module.key}</b>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <IconButton
                            onClick={() => handleClick(module.id)}
                            disabled={
                              !hasPermission(PATHS.SETTINGS_MODULES, 'update')
                            }
                          >
                            <EditRounded />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 100, 200, 500, 1000]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Resultados por página"
            />
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  modulesLoading: state.ui.modulesLoading,
  modules: state.modules,
  moduleModal: state.ui.moduleModal,
});

export default connect(mapStateToProps)(Modules);
