import {
  GET_COMPETITOR,
  UPDATE_COMPETITOR,
  UPDATE_OBJECT_COMPETITOR,
  CREATE_COMPETITOR,
  DELETE_COMPETITOR,
} from '../actions/competitor';

export function competitorReducer(competitor = {}, action) {
  switch (action.type) {
    case GET_COMPETITOR:
      return action.payload;
    case UPDATE_COMPETITOR:
      return action.payload;
    case CREATE_COMPETITOR:
      return action.payload;
    case DELETE_COMPETITOR:
      return action.payload;
    case UPDATE_OBJECT_COMPETITOR:
      return action.payload;

    default:
      return competitor;
  }
}
