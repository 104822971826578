import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { AddRounded, DeleteRounded } from '@material-ui/icons';
import { InputContent } from './style';

// From: https://www.pluralsight.com/guides/creating-dynamic-editable-tables-with-reactjs
export default class DynamicList extends React.Component {
  constructor(props) {
    super(props);

    const { setItemsCallback } = props;

    this.setItemsCallback = setItemsCallback;

    this.state = {
      message: '',
    };
  }

  updateMessage(event) {
    this.setState({
      message: event.target.value,
    });
  }

  handleClick() {
    const { message } = this.state;
    const { items } = this.props;
    const _items = [...items];
    if (_items.indexOf(message) < 0) {
      _items.push(message);
      this.setItemsCallback(_items);
      this.setState({
        message: '',
      });
    }
  }

  handleItemChanged(i, event) {
    const { items } = this.props;
    const _items = [...items];
    const itemToAdd = event.target.value;
    if (_items.indexOf(itemToAdd) < 0) {
      _items[i] = itemToAdd;
      this.setItemsCallback(_items);
    }
  }

  handleItemDeleted(i) {
    const { items } = this.props;
    const _items = [...items];
    _items.splice(i, 1);
    this.setItemsCallback(_items);
  }

  renderRows() {
    const { items, error } = this.props;

    return items.map((item, index) => (
      <tr key={`item-${item}`}>
        <td>
          <InputContent>
            <TextField
              id="outlined-title-input"
              type="text"
              variant="outlined"
              value={item}
              onChange={(e) => this.handleItemChanged(index, e)}
              fullWidth
              error={error}
            />
          </InputContent>
        </td>
        <td>
          <IconButton
            onClick={() => this.handleItemDeleted(index)}
            size="small"
          >
            <DeleteRounded />
          </IconButton>
        </td>
      </tr>
    ));
  }

  render() {
    const { message } = this.state;
    const { title, error } = this.props;
    return (
      <div>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>{title}</th>
            </tr>
          </thead>
          <tbody>
            {this.renderRows()}
            <hr />
            <tr>
              <td>
                <InputContent>
                  <TextField
                    id="outlined-title-input"
                    type="text"
                    variant="outlined"
                    value={message}
                    onChange={(e) => this.updateMessage(e)}
                    fullWidth
                    error={error}
                  />
                </InputContent>
              </td>
              <td>
                <IconButton onClick={() => this.handleClick()} size="small">
                  <AddRounded />
                </IconButton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

DynamicList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  setItemsCallback: PropTypes.func.isRequired,
  title: PropTypes.string,
  error: PropTypes.bool,
};

DynamicList.defaultProps = {
  title: 'Itens',
  error: false,
};
