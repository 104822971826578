import { UPDATE_ORDERS } from '../actions/orders';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function ordersReducer(orders = initState, action) {
  switch (action.type) {
    case UPDATE_ORDERS:
      return action.payload;

    default:
      return orders;
  }
}
