import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: '1rem',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '1.5rem',
    '&.filter': {
      padding: '1rem',
    },
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    textAlign: 'center',
  },
  headerCell: {
    textAlign: 'center',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  headerGridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  alertDiffValues: {
    color: theme.palette.danger.main,
  },
}));
