import { getCompetitors } from '../../services/NotableProducts';

export class NotableProductsProvider {
  static fetchCompetitors = async () => {
    const { data: bakeries } = await getCompetitors({
      listAll: true,
      active: true,
      kind: 'BAKERY',
    });
    const { data: supermarkets } = await getCompetitors({
      listAll: true,
      active: true,
      kind: 'SUPERMARKET',
    });
    return { bakeries, supermarkets };
  };
}
