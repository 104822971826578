import styled from 'styled-components';

export const ChartContainer = styled.div`
  flex: 1;
  height: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ChartLegend = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: row;
  }
`;
