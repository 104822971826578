import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  font-weight: 700;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  /* align-items: center; */
  justify-content: stretch;
  min-height: 70vh;
  padding: 20px;
`;

export const StyledPaper = styled(Paper)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  border-radius: 24px !important;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const StyledH1 = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  line-height: 48px;
  text-align: left;
  color: #192d4d;
  margin-left: 2rem;
  width: 100%;
  @media screen and (max-width: 600px) {
    font-size: 1.4rem;
  }
`;

export const StyledP = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  color: #464255;
  margin: 1rem 2rem;
  max-width: 29rem;
`;
