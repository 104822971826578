import Service from '../Service';

const api = new Service({ route: '/ABCAnalyses', apiV2: true });
export const importFile = ({ file, company, month, year, isAdmin }) => {
  const form = new FormData();
  form.append('file', file);
  form.append('month', `${month}`);
  form.append('year', year);
  form.append('company_document', company);
  return api
    .post(form, { isAdmin: `${isAdmin}` })
    .then(({ data, ok, message }) => ({
      ok,
      data,
      error: message,
    }))
    .catch((e) => ({ ok: false, error: e?.response?.data?.message }));
};

export const checkExistence = ({ company, month, year, isAdmin }) =>
  api
    .get({
      company_document: company,
      month: `${month}`,
      year,
      isAdmin: `${isAdmin}`,
    })
    .then(({ data, ok, message }) => ({ ok, exists: data, error: message }))
    .catch((e) => ({ ok: false, error: e?.response?.data?.message }));
