import styled from 'styled-components';

export const FooterContainer = styled.footer`
  width: 100%;
  color: #192d4d;
  margin-bottom: 1.5rem;
  padding: 0 1rem;
  position: absolute;
  top: ${({ mainSize, drawerSize }) =>
    (mainSize >= drawerSize ? mainSize : drawerSize) + 64}px;
`;

export const FooterText = styled.p`
  display: block;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
`;
