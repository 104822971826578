import {
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PATHS from '../../../config/routePaths';
import { getPaymentMachineRegisters } from '../../../redux/actions/paymentMachineRegisters';
import { getSerialNumbers } from '../../../services/PaymentMachines';
import Spinner from '../../../sharedComponents/Spinner/index';
import { hasPermission } from '../../../utils/globalFunctions';
import {
  ButtonsContainer,
  FiltersTitle,
  StyledAddRounded,
  StyledButton,
  StyledPaper,
  StyledTableCell,
  StyledTablePagination,
  StyledTextField,
} from '../styles';
import { statusMap } from '../../../config/consts';
import Row from './TerminalRow';
import ActivateModal from './ActivateModal';
import Clearable from './Clearable';

export default function TerminalsContent({
  handleClick,
  allCompanies,
  paymentMachineRegistersFormatted,
  total,
}) {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState({});
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [activateModalOpen, setActivateModalOpen] = useState(false);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [action, setAction] = useState(false);

  const paymentMachinesLoading = useSelector(
    (state) => state.ui.paymentMachineRegistersLoading,
  );
  const loadingCompanies = useSelector((state) => state.ui.loadingCompanies);

  const dispatch = useDispatch();
  const companies = useSelector((state) => state.companies);

  useEffect(() => {
    dispatch(
      getPaymentMachineRegisters({
        page: page + 1,
        limit,
        ...filters,
        companyDocumentIn: filters.companyDocument
          ? [filters.companyDocument]
          : undefined,
      }),
    );
  }, [page, limit, filters]);

  useEffect(() => {
    getSerialNumbers().then((result) => {
      if (result.ok) setSerialNumbers(result.data);
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilters = (event) => {
    const { name, value } = event.target;
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));
  };

  const handleExpand = (serialNumber) => () => {
    setOpen((currentOpen) =>
      (serialNumber === currentOpen ? null : serialNumber),
    );
  };

  const handleOpenModal = (id, selectedAction) => () => {
    setSelectedMachine(id);
    setActivateModalOpen(true);
    setAction(selectedAction);
  };

  const handleCloseActivateModal = () => {
    setSelectedMachine(null);
    setActivateModalOpen(false);
    setAction('');
  };

  const handleClear = (field) => () =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      [field]: '',
    }));

  return (
    <>
      <ActivateModal
        open={activateModalOpen}
        id={selectedMachine}
        onClose={handleCloseActivateModal}
        action={action}
        page={page}
        limit={limit}
      />
      <StyledPaper>
        <FiltersTitle>Filtros</FiltersTitle>
        <ButtonsContainer>
          <Grid container spacing={2}>
            <Grid item md={6} lg={3}>
              <StyledTextField
                label={!filters.companyDocument ? 'Padaria' : ''}
                type="text"
                value={filters.companyDocument || ''}
                variant="outlined"
                name="companyDocument"
                onChange={handleFilters}
                fullWidth
                InputLabelProps={{ shrink: false }}
                select
                SelectProps={{
                  IconComponent: filters.companyDocument
                    ? (props) => (
                      <Clearable
                        {...props}
                        onClear={handleClear('companyDocument')}
                      />
                    )
                    : KeyboardArrowDown,
                }}
                width="11.75rem"
              >
                {companies?.docs?.map((option) => (
                  <MenuItem key={option.id} value={option.document}>
                    {option.name}
                  </MenuItem>
                ))}
              </StyledTextField>
            </Grid>
            <Grid item md={6} lg={2}>
              <StyledTextField
                label={!filters.status ? 'Status' : ''}
                type="text"
                value={filters.status || ''}
                variant="outlined"
                name="status"
                onChange={handleFilters}
                fullWidth
                InputLabelProps={{ shrink: false }}
                select
                SelectProps={{
                  IconComponent: filters.status
                    ? (props) => (
                      <Clearable {...props} onClear={handleClear('status')} />
                    )
                    : KeyboardArrowDown,
                }}
                width="8rem"
              >
                {Object.keys(statusMap).map((value) => (
                  <MenuItem key={value} value={value}>
                    <span style={{ textTransform: 'capitalize' }}>
                      {statusMap[value].toLowerCase()}
                    </span>
                  </MenuItem>
                ))}
              </StyledTextField>
            </Grid>
            <Grid item md={6} lg={2}>
              <Autocomplete
                options={serialNumbers}
                value={filters.serialNumber}
                onChange={(_, newValue) =>
                  setFilters((currentFilters) =>
                    ({ ...currentFilters, serialNumber: newValue }),
                  )}
                popupIcon={<KeyboardArrowDown />}
                renderInput={(params) =>
                  <StyledTextField
                    {...params}
                    label="POS"
                    variant="outlined"
                    fullWidth
                  />}
              />
            </Grid>
            <Grid item md={6} lg={2}>
              <StyledTextField
                label={!filters.salesFunction ? 'Função' : ''}
                type="text"
                value={filters.salesFunction || ''}
                variant="outlined"
                name="salesFunction"
                onChange={handleFilters}
                fullWidth
                InputLabelProps={{ shrink: false }}
                select
                SelectProps={{
                  IconComponent: filters.salesFunction
                    ? (props) => (
                      <Clearable
                        {...props}
                        onClear={handleClear('salesFunction')}
                      />
                    )
                    : KeyboardArrowDown,
                }}
                width="8rem"
              >
                <MenuItem value="ONLINE">Online</MenuItem>
                <MenuItem value="OFFLINE">Offline</MenuItem>
              </StyledTextField>
            </Grid>
            <Grid item md={6} lg={3}>
              <StyledButton
                onClick={handleClick}
                disabled={
                  !hasPermission(PATHS.SETTINGS_PAYMENT_MACHINES, 'create')
                }
              >
                <StyledAddRounded />
                Novo POS
              </StyledButton>
            </Grid>
          </Grid>
        </ButtonsContainer>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell isHeader>Nº de série</StyledTableCell>
                <StyledTableCell isHeader>Status</StyledTableCell>
                <StyledTableCell isHeader>Padaria</StyledTableCell>
                <StyledTableCell isHeader>Função de venda</StyledTableCell>
                <StyledTableCell isHeader>Última atualização</StyledTableCell>
                <StyledTableCell isHeader />
                <StyledTableCell isHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {(paymentMachinesLoading || loadingCompanies) ? (
                <TableRow>
                  <StyledTableCell colSpan="7">
                    <Spinner parent="Table" />
                  </StyledTableCell>
                </TableRow>
              ) :
                paymentMachineRegistersFormatted?.map((paymentMachine) => (
                  <Row
                    paymentMachine={paymentMachine}
                    companies={allCompanies}
                    open={paymentMachine.serialNumber === open}
                    onClick={handleExpand}
                    onOpenModal={handleOpenModal}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledTablePagination
          rowsPerPageOptions={[2, 5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={limit}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Resultados por página"
        />
      </StyledPaper>
    </>
  );
}
