import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  cell: {
    fontSize: 12,
    padding: '0px 15px',
  },
  headerCell: {
    padding: '5px 15px',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
}));
