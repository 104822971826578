import styled from 'styled-components';
import { TextField, withStyles } from '@material-ui/core';

export const Select = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--primary-text-color)',
        borderRadius: '1rem',
        borderWidth: 2,
      },
      '&:hover fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--primary-text-color)',
      },
    },
    color: 'var(--primary-text-color)',
    minWidth: '8.313rem',
    fontWeight: 500,
  },
})(TextField);

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalTitle = styled.div`
  padding-left: 15px;
  padding-bottom: 20px;
  font-size: 1.3rem;
`;

export const InputContent = styled.form`
  width: 100%;
  padding: 20px 10px 10px 10px;
`;

export const CheckboxContent = styled.form`
  width: 100%;
  padding: 12px 10px 15px 0px;
`;

export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const UploadTitle = styled.h1`
  text-align: center;
  font-size: 1.3rem;
  padding: 20px;
`;

export const UploadSubTitle = styled.h2`
  text-align: center;
  font-size: 1.3rem;
  padding: 20px;
`;

export const InputFile = styled.input`
  display: none;
`;

export const UploadLabel = styled.label`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;

export const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  border: 4px dashed rgba(0, 0, 0, 0.6);
  margin: 12px;
`;

export const FileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 20px;
`;

export const FileRemove = styled.div`
  cursor: pointer;
  color: #000;
`;

export const FileSelectedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 10px;
`;
