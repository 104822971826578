import {
  GET_BAKERY_ADDITIONAL_INFORMATION,
  UPDATE_BAKERY_ADDITIONAL_INFORMATION,
  UPDATE_OBJECT_BAKERY_ADDITIONAL_INFORMATION,
  CREATE_BAKERY_ADDITIONAL_INFORMATION,
  DELETE_BAKERY_ADDITIONAL_INFORMATION,
} from '../actions/bakeryAdditionalInformation';

export function bakeryAdditionalInformationReducer(
  bakeryAdditionalInformation = {},
  action,
) {
  switch (action.type) {
    case GET_BAKERY_ADDITIONAL_INFORMATION:
      return action.payload;
    case UPDATE_BAKERY_ADDITIONAL_INFORMATION:
      return action.payload;
    case CREATE_BAKERY_ADDITIONAL_INFORMATION:
      return action.payload;
    case DELETE_BAKERY_ADDITIONAL_INFORMATION:
      return action.payload;
    case UPDATE_OBJECT_BAKERY_ADDITIONAL_INFORMATION:
      return action.payload;

    default:
      return bakeryAdditionalInformation;
  }
}
