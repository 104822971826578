import styled from 'styled-components';

export const IframeContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  margin-left: ${(props) => (props.index && props.index !== 0 ? '1rem' : '0')};

  iframe {
    webkit-border-radius: 1.5rem;
    -moz-border-radius: 1.5rem;
    border-radius: 1.5rem;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  }
`;

export const EmbeddedCardsRow = styled.div`
  padding-top: ${(props) => (props.index && props.index !== 0 ? '1rem' : '0')};
  display: flex;
`;
