import { combineReducers } from 'redux';
import { usersReducer } from './users';
import { userReducer } from './user';
import { providersReducer } from './providers';
import { providerReducer } from './provider';
import { itemsReducer } from './items';
import { itemReducer } from './item';
import { bakeryAdditionalInformationsReducer } from './bakeryAdditionalInformations';
import { bakeryAdditionalInformationReducer } from './bakeryAdditionalInformation';
import { loggedUserReducer } from './loggedUser';
import { rolesReducer } from './roles';
import { roleReducer } from './role';
import { modulesReducer } from './modules';
import { moduleReducer } from './module';
import { companyReducer } from './company';
import { companiesReducer } from './companies';
import { competitorReducer } from './competitor';
import { competitorsReducer } from './competitors';
import { groupReducer } from './group';
import { groupsReducer } from './groups';
import { uiReducer } from './ui';
import { paymentMachineRegistersReducer } from './paymentMachineRegisters';
import { paymentMachinesReducer } from './paymentMachines';
import { paymentMachineReducer } from './paymentMachine';
import { picPayOperatorsReducer } from './picPayOperators';
import { picPayOperatorReducer } from './picPayOperator';
import { extractsReducer } from './extracts';
import { commissioningsReducer } from './commissionings';
import { commissioningReducer } from './commissioning';
import { transfersReducer } from './transfers';
import { transferReducer } from './transfer';
import { embeddedResourcesReducer } from './embeddedResources';
import { productsReducer } from './products';
import { productReducer } from './product';
import { marketStructuresReducer } from './marketStructures';
import { suppliersReducer } from './suppliers';
import { supplierReducer } from './supplier';
import { offersReducer } from './offers';
import { offerReducer } from './offer';
import { weeklyTaxesReducer } from './weeklyTaxes';
import { weeklyTaxReducer } from './weeklyTax';
import { auditReducer } from './audit';
import { reportSplitValuesReducer } from './reportSplitValues';
import { acquirersReducer } from './acquirers';
import { receivedPaymentsReducer } from './receivedPayments';
import { transitoryValuesReducer } from './transitoryValues';
import { ordersReducer } from './orders';
import { orderReducer } from './order';

export const reducers = combineReducers({
  users: usersReducer,
  user: userReducer,
  providers: providersReducer,
  provider: providerReducer,
  items: itemsReducer,
  item: itemReducer,
  bakeryAdditionalInformations: bakeryAdditionalInformationsReducer,
  bakeryAdditionalInformation: bakeryAdditionalInformationReducer,
  loggedUser: loggedUserReducer,
  role: roleReducer,
  roles: rolesReducer,
  module: moduleReducer,
  modules: modulesReducer,
  company: companyReducer,
  companies: companiesReducer,
  competitor: competitorReducer,
  competitors: competitorsReducer,
  group: groupReducer,
  groups: groupsReducer,
  ui: uiReducer,
  paymentMachines: paymentMachinesReducer,
  paymentMachine: paymentMachineReducer,
  paymentMachineRegisters: paymentMachineRegistersReducer,
  picPayOperators: picPayOperatorsReducer,
  picPayOperator: picPayOperatorReducer,
  extracts: extractsReducer,
  commissionings: commissioningsReducer,
  commissioning: commissioningReducer,
  transfers: transfersReducer,
  transfer: transferReducer,
  embeddedResources: embeddedResourcesReducer,
  products: productsReducer,
  product: productReducer,
  marketStructures: marketStructuresReducer,
  supplier: supplierReducer,
  suppliers: suppliersReducer,
  offer: offerReducer,
  offers: offersReducer,
  weeklyTax: weeklyTaxReducer,
  weeklyTaxes: weeklyTaxesReducer,
  audit: auditReducer,
  reportSplitValues: reportSplitValuesReducer,
  acquirers: acquirersReducer,
  receivedPayments: receivedPaymentsReducer,
  transitoryValues: transitoryValuesReducer,
  orders: ordersReducer,
  order: orderReducer,
});
