import {
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_ERROR,
  GET_GROUPS,
  updateGroups,
  showSpinner,
  hideSpinner,
} from '../actions/groups';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'groups';

export const getGroupsFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GET_GROUPS) {
      dispatch(
        apiRequest(
          'GET',
          URL,
          action.payload,
          FETCH_GROUPS_SUCCESS,
          FETCH_GROUPS_ERROR,
          {},
          true, // To call the apiV2
        ),
      );
      dispatch(showSpinner());
    }
  };

export const processGroupsCollection =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === FETCH_GROUPS_SUCCESS) {
      dispatch(
        updateGroups(
          action.payload.docs ? action.payload : { docs: action.payload },
        ),
      );
      dispatch(hideSpinner());
    } else if (action.type === FETCH_GROUPS_ERROR) {
      dispatch(hideSpinner());
      Toastify.addError(
        'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
      );
    }
  };

export const groupsMdl = [getGroupsFlow, processGroupsCollection];
