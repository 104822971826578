import {
  GET_OFFER,
  UPDATE_OFFER,
  UPDATE_OBJECT_OFFER,
  CREATE_OFFER,
  DELETE_OFFER,
} from '../actions/offer';

export function offerReducer(offer = {}, action) {
  switch (action.type) {
    case GET_OFFER:
      return action.payload;
    case UPDATE_OFFER:
      return action.payload;
    case CREATE_OFFER:
      return action.payload;
    case DELETE_OFFER:
      return action.payload;
    case UPDATE_OBJECT_OFFER:
      return action.payload;

    default:
      return offer;
  }
}
