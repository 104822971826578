export const GET_COMMISSIONINGS = '[commissionings] GET';
export const FETCH_COMMISSIONINGS_SUCCESS = '[commissionings] Fetch success';
export const FETCH_COMMISSIONINGS_ERROR = '[commissionings] Fetch Error';
export const UPDATE_COMMISSIONINGS = '[commissionings] Update';
export const SHOW_COMMISSIONINGS_SPINNER = '[commissionings - ui] show spinner';
export const HIDE_COMMISSIONINGS_SPINNER = '[commissionings - ui] hide spinner';

export const getCommissionings = (filter) => ({
  type: GET_COMMISSIONINGS,
  payload: filter,
});

export const updateCommissionings = (data) => ({
  type: UPDATE_COMMISSIONINGS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_COMMISSIONINGS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_COMMISSIONINGS_SPINNER,
});
