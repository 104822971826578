import { Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { colors } from '../../theme';

const skeletonDefaultProps = {
  variant: 'rectangular',
  width: '100%',
  height: '100%',
  style: {
    minHeight: 150,
    borderRadius: 24,
  },
};

const OverviewCard = ({
  value,
  colorTheme,
  description,
  note,
  isLoading,
  skeletonProps,
}) => {
  if (isLoading) {
    return <Skeleton {...skeletonDefaultProps} {...skeletonProps} />;
  }
  return (
    <Paper
      style={{
        flex: 1,
        borderRadius: '1.5rem',
        padding: '2rem',
        backgroundColor: colorTheme ? colors[colorTheme].main : '',
        color: colors.white.main,
        height: '100%',
      }}
    >
      <div>
        <Typography
          style={{ fontWeight: 700, fontSize: 14, lineHeight: '21px' }}
        >
          {description}
        </Typography>
        <Typography
          style={{ fontWeight: 500, fontSize: 24, lineHeight: '36px' }}
        >
          {value}
        </Typography>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: 10,
            lineHeight: '15px',
            width: '161px',
          }}
        >
          {note}
        </Typography>
      </div>
    </Paper>
  );
};

export default OverviewCard;
