import { Chip, Icon } from '@material-ui/core';
import React from 'react';
import { paymentKindMap } from '../../../../providers/Extracts';

export default function ({ item, onRemoveFilter }) {
  return (
    <Chip
      icon={
        <Icon
          className="material-icons-outlined"
          style={{
            color: '#192D4D',
            fontSize: '1.125rem',
            marginRight: '0.438rem',
          }}
        >
          {item.icon}
        </Icon>
      }
      style={{
        color: '#192D4D',
        fontSize: '1rem',
        paddingLeft: '1rem',
        paddingRight: '.375rem',
        borderRadius: '0.875rem',
        marginTop: '.5rem',
        marginRight: '1rem',
      }}
      label={
        item.arrayField
          ? item.value.map((val) => paymentKindMap[val]).join(', ')
          : item.value
      }
      deleteIcon={
        <Icon
          className="material-icons-outlined"
          style={{
            color: '#192D4D',
            fontSize: '1.125rem',
            marginLeft: '0.438rem',
          }}
        >
          close
        </Icon>
      }
      onDelete={() => onRemoveFilter(item)}
    />
  );
}
