import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import styled from 'styled-components';

export const Container = styled.div`
  min-width: 60vw;
  padding: 20px;

  @media (max-width: 767px) {
    width: 90vw;
  }
`;

export const SubTitle = styled.h2`
  font-weight: 700;
  margin: 1rem;
  font-size: 1rem;
`;

export const ModalTitle = styled.div`
  padding-left: 15px;
  padding-bottom: 20px;
  font-size: 1.3rem;
  display: flex;
  justify-content: space-between;
`;

export const InputContent = styled.form`
  margin: 0.5rem 1rem;
`;

export const StyledCurrencyTextField = styled(CurrencyTextField)`
  width: 100%;
`;

export const CheckboxContent = styled.form`
  width: 100%;
  padding: 0.5rem 0;
`;

export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;
