import { GET_ITEMS, UPDATE_ITEMS } from '../actions/items';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function itemsReducer(items = initState, action) {
  switch (action.type) {
    case GET_ITEMS:
      return action.payload;
    case UPDATE_ITEMS:
      return action.payload;

    default:
      return items;
  }
}
