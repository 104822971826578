import moment from 'moment';
import CashierClosureService from '../../services/CashierClosure';
import { api2FrontInterface, aggregateArrayOfDicts } from '../../utils/globalFunctions';
import { paymentKindMap } from '../Extracts';

let _aggregatedExtracts = {};
let _formattedCashiers = {};

function _format(item) {
  // Format as view need it
  const formattedItem = api2FrontInterface(item);
  return {
    ...formattedItem,
    title: paymentKindMap[formattedItem.paymentKindCode].split(' ').reverse()[0],
    value: formattedItem.sum,
  };
}

function _aggregateItems(items) {
  // Aggregate by companyDocument and by POS (map of map of arrays)
  const companyMap = aggregateArrayOfDicts(items, 'document');
  const result = {};
  Object.keys(companyMap).forEach((companyDocument) => {
    result[companyDocument] = aggregateArrayOfDicts(
      companyMap[companyDocument],
      'pos',
    );
  });
  return result;
}

function _addEntry2Values(valuesObj, { title: paymentKind, sum: entrySum }) {
  // Adds new entry sum to cashier values aggregated by payment kind
  const newValues = {
    [paymentKind]: 0,
    sum: 0,
    ...valuesObj,
  };
  newValues[paymentKind] += entrySum;
  newValues.sum += entrySum;
  return newValues;
}

async function _setAggregatedExtracts({ refDate, refStartTime, refEndTime }) {
  const refMoment = moment(refDate);
  const refStartTimeMoment = moment(refStartTime);
  const refEndTimeMoment = moment(refEndTime);
  const startMoment = refMoment
    .clone()
    .startOf('day')
    .add(refStartTimeMoment.hours(), 'hours')
    .add(refStartTimeMoment.minutes(), 'minutes')
    .add(refStartTimeMoment.seconds(), 'seconds');
  const endMoment = refMoment
    .clone()
    .startOf('day')
    .add(refEndTimeMoment.hours(), 'hours')
    .add(refEndTimeMoment.minutes(), 'minutes')
    .add(refEndTimeMoment.seconds(), 'seconds');
  const { data } = await CashierClosureService.fetchAggregatedExtracts({
    dateGte: startMoment.toDate().toISOString(),
    dateLte: endMoment.toDate().toISOString(),
    aggregateBy: {
      company_document: true,
      pos: true,
      payment_kind_code: true,
    },
  });
  const formattedItems = (data || []).map(_format);
  _aggregatedExtracts = _aggregateItems(formattedItems);
}

async function _setFormattedCashiers() {
  const { data } = await CashierClosureService.fetchCashiers({ listAll: true });
  const formattedCashiers = {};
  data.docs.forEach((cashier) => {
    if (formattedCashiers[cashier.company_document] === undefined) {
      formattedCashiers[cashier.company_document] = {};
    }
    formattedCashiers[cashier.company_document][cashier.name] = {
      id: cashier.id,
      paymentMachines: cashier.payment_machines.map(
        (machine) => machine.serial_number),
      picPayOperators: cashier.pic_pay_operators.map(
        (operator) => operator.operator),
      values: {},
    };
  });
  _formattedCashiers = formattedCashiers;
}

async function getFormattedExtracts2CompanyMap({
  refDate,
  refStartTime,
  refEndTime,
  loadExtracts = true,
  loadCashiers = true,
}) {
  if (loadExtracts) {
    await _setAggregatedExtracts({ refDate, refStartTime, refEndTime });
  }
  if (loadCashiers) {
    await _setFormattedCashiers();
  }

  // Creates a deep copy
  const formattedExtracts = JSON.parse(JSON.stringify(_formattedCashiers));
  Object.keys(_aggregatedExtracts).forEach((company) => {
    if (formattedExtracts[company] === undefined) {
      formattedExtracts[company] = {};
    }
    formattedExtracts[company]['Sem caixa'] = {};
    Object.keys(_aggregatedExtracts[company]).forEach((pos) => {
      const foundInCashier = Object.keys(formattedExtracts[company])
        .some((cashier) => {
          if (
          formattedExtracts[company][cashier].paymentMachines?.find((e) => e === pos)
          || formattedExtracts[company][cashier].picPayOperators?.find((e) => e === pos)
          ) {
            _aggregatedExtracts[company][pos].forEach((entry) => {
              formattedExtracts[company][cashier].values = _addEntry2Values(
                formattedExtracts[company][cashier].values,
                entry,
              );
            });
            return true;
          }
          return false;
        });
      if (!foundInCashier) {
        formattedExtracts[company]['Sem caixa'][pos] =
          _aggregatedExtracts[company][pos];
      }
    });
  });
  return formattedExtracts;
}

async function getAvailablePos({ refDate, companyDocument }) {
  const { data } = await CashierClosureService.fetchAvailablePos({
    referenceISOString: refDate.toISOString(),
    companyDocuments: [companyDocument],
  });
  const formattedPos = api2FrontInterface(data.payload);
  return formattedPos;
}

async function upsertCashier(params) {
  const { id, payload } = params;
  if (id) {
    await CashierClosureService.updateCashier(id, payload);
  } else {
    await CashierClosureService.createCashier(payload);
  }
}

export default {
  getFormattedExtracts2CompanyMap,
  getAvailablePos,
  upsertCashier,
};
