import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Container,
  useStyles,
  PrimaryButton,
  Link,
  ChildContainer,
} from './styles';
import { RootState } from '../../interfaces/redux';
import Card from '../../sharedComponents/OrderCard';
import { Order } from '../../interfaces/orders';
import { Company } from '../../interfaces/companies';
import { getCompanies } from '../../redux/actions/companies';
import { formatOrder } from '../../utils/formatOrder';
import Spinner from '../../sharedComponents/Spinner';
import SubHeader from '../../sharedComponents/SubHeader';
import { getOrder } from '../../redux/actions/order';
import SupplierCard from './components/SupplierCard';

const orderFields: { [key: string]: string } = {
  orderNumber: 'Nº do pedido',
  wordpressOrderDate: 'Data do Pedido',
  total: 'Total',
  paymentMethod: 'Pagamento',
};

const Orders: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const companies: Company[] = useSelector(
    (state: RootState) => state.companies?.docs || state.companies,
  );

  const order: Order | {} = useSelector((state: RootState) =>
    (state.order.id ? formatOrder(state.order, companies, true) : {}),
  );

  const loading: boolean = useSelector(
    (state: RootState) => state.ui.orderLoading,
  );

  useEffect(() => {
    if (!id) return;
    dispatch(getOrder(id));
  }, [id]);

  useEffect(() => {
    if (companies && companies.length > 0) return;
    dispatch(getCompanies({ listAll: true }));
  }, [companies]);

  return (
    <Container>
      <SubHeader title="Acompanhamento de pedidos" />
      {loading ? (
        <Spinner color="#133DA5" />
      ) : (
        <>
          <div className={classes.root}>
            <Card
              order={order as Order}
              fields={orderFields}
              disableTransition
              ActionComponent={() => (
                <Link to="/finances/payments">
                  <PrimaryButton variant="contained">
                    Boletos e NFs
                  </PrimaryButton>
                </Link>
              )}
            />
          </div>
          <ChildContainer>
            {(order as Order).supplierOrders &&
              (order as Order).supplierOrders.map((supplierOrder) => (
                <SupplierCard
                  key={supplierOrder.id}
                  supplierOrder={supplierOrder}
                />
              ))}
          </ChildContainer>
        </>
      )}
    </Container>
  );
};

export default Orders;
