export const GET_ITEMS = '[items] GET';
export const FETCH_ITEMS_SUCCESS = '[items] Fetch success';
export const FETCH_ITEMS_ERROR = '[items] Fetch Error';
export const UPDATE_ITEMS = '[items] Update';
export const SHOW_ITEMS_SPINNER = '[items - ui] show spinner';
export const HIDE_ITEMS_SPINNER = '[items - ui] hide spinner';

export const getItems = (filter) => ({
  type: GET_ITEMS,
  payload: filter,
});

export const updateItems = (data) => ({
  type: UPDATE_ITEMS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_ITEMS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_ITEMS_SPINNER,
});
