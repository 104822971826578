import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Spinner from '../../../../sharedComponents/Spinner/index';
import { useStyles } from './styles';

const SectionContent = ({
  loading,
  columnsMap,
  rows,
}) => {
  const classes = useStyles();
  const headerLabels = Object.keys(columnsMap);
  const rowLabels = Object.values(columnsMap);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {/* <Summary>
          <Title>{title}</Title>
        </Summary> */}
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                {headerLabels.map((label) => (
                  <TableCell key={label} className={classes.headerCell}>
                    {label}
                  </TableCell>
                ))}
                <TableCell className={classes.headerCell} />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow className={classes.row}>
                  <TableCell
                    colSpan={headerLabels.length + 2}
                    className={classes.cell}
                  >
                    <Spinner parent="Table" />
                  </TableCell>
                </TableRow>
              )}
              {!loading &&
                rows.map((row) => (
                  <TableRow className={classes.row}>
                    {rowLabels.map((label) => {
                      let valueToShow;
                      if (typeof label === 'string') {
                        valueToShow = row[label];
                      } else {
                        label.forEach((labelItem) => {
                          valueToShow = valueToShow || row[labelItem];
                        });
                      }
                      return (
                        <TableCell
                          className={classes.cell}
                          key={label}
                        >
                          {valueToShow}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}

              {!loading && rows && rows.length === 0 && (
                <TableRow className={classes.row}>
                  <TableCell
                    colSpan={headerLabels.length + 2}
                    className={classes.headerCell}
                  >
                    Nenhum resultado para exibir
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default SectionContent;
